import { FC, useRef, useState } from 'react';
import { AnimatedAppIcon, Button, CalendarChecked, Card, Checkbox, ContainerFlex, Delimiter, IconButton, Portal, TrashIcon, Typography, useAppStoreHooks } from '../../../toolkit';
import { TitanCaseFitting } from '../../../models';
import { API_URLS, prettyDateTime } from '../../../lib';
import CaseFittingForm from './CaseFittingForm';
import { textMessages } from '../../../translations';
import { useAppSelector } from '../../../store';
import { useFittingsFinalize } from '../../hooks';

type Props = {
  titanCaseId?: number;
  fittings: TitanCaseFitting[];
  onFittingsChanged: (fittings: TitanCaseFitting[]) => void;
};

const CaseFittings: FC<Props> = ({ titanCaseId, fittings, onFittingsChanged }) => {
  const user = useAppSelector(state => state.auth.user);
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fittingIndexToDelete, setFittingIndexToDelete] = useState<number>();
  const { deleteRequest, postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const { toggleFinalize } = useFittingsFinalize(fittings, onFittingsChanged)

  const onAddFittingDate = async (date: Date, notes?: string) => {
    if (!titanCaseId) {
      onFittingsChanged([...fittings, {
        fittingDateTime: date,
        notes: notes || ''
      } as TitanCaseFitting]);
      setIsOpen(false);
      return;
    }
    loadingStart();
    try {
      const fitting = await postJson<TitanCaseFitting>(API_URLS.titan.fitting.fitting, {
        fittingDateTime: date.toISOString(),
        titanCaseId: titanCaseId,
        notes
      });
      onFittingsChanged([...fittings, fitting]);
      setIsOpen(false);
      loadingEnd();
      notify('Proba a fost adaugata.', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onRemoveFittingDate = async (index: number) => {
    const fitting = fittings[index];
    const _fittings = fittings.filter((_, i) => i !== index);
    if (!titanCaseId) {
      onFittingsChanged(_fittings);
      setFittingIndexToDelete(undefined);
      return;
    }

    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.fitting.fitting}/${fitting.fittingId}`);
      setFittingIndexToDelete(undefined);
      onFittingsChanged(_fittings);
      loadingEnd();
      notify('Proba a fost stearsa cu succes.', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
    return false;
  };

  return (
    <>
      <Card type='column' spacing={3}>
        <ContainerFlex type='row' justify='space-between'>
          <Typography type='h5'>Probe</Typography>
          {(user?.isManagerOrAbove || user?.isDataEntry)  && (
            <Button ref={ref} type='button' size='small' variant='outlined' onClick={() => setIsOpen(true)}>Adauga</Button>
          )}
        </ContainerFlex>
        <Delimiter />
        {fittings.length ? fittings.map((fitting, index) => (
          <ContainerFlex key={fitting.fittingId} type='row' justify='space-between'>
            <div>
              <Typography type='p' variation='bold'>{prettyDateTime(fitting.fittingDateTime)}</Typography>
              <Typography type='span'>{fitting.notes}</Typography>
            </div>
            <ContainerFlex type='row' align='center' justify='center' spacing={2}>
              <IconButton onClick={() => { setFittingIndexToDelete(index); }}>
                <TrashIcon />
              </IconButton>
              {titanCaseId && (
                <Checkbox value={fitting.isFinished} onChange={() => toggleFinalize(fitting)} label='Finalizat' />
              )}
            </ContainerFlex>
          </ContainerFlex>
        )) : (
          <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
            <AnimatedAppIcon>
              <CalendarChecked size={40} />
            </AnimatedAppIcon>
            {(user?.isManagerOrAbove || user?.isDataEntry)  ? (
              <Typography type='p' variation="center">
                Apasa pe butonul
                <strong> "Adauga Proba"</strong> pentru a adauga probe asociate cazului.
              </Typography>
            ) : (
              <Typography type='p' variation="center">
                Nu exista probe asociate cazului.
              </Typography>
            )}
          </ContainerFlex>
        )}
      </Card>
      <Portal
        isOpen={isOpen}
        anchorEl={ref.current}
        onDismiss={() => { setIsOpen(false); }}
        overlay={false}
        width={560}
        noPaddings={true}
      >
        <CaseFittingForm onSaveFitting={onAddFittingDate} onCancel={() => { setIsOpen(false); }} />
      </Portal>
      <Portal
        isOpen={fittingIndexToDelete !== undefined}
        onDismiss={() => { setFittingIndexToDelete(undefined); }}
        title='Sterge proba'
        overlay={true}
        width={560}
      >
        <ContainerFlex type='column' spacing={3}>
          <AnimatedAppIcon variation='danger'>
            <TrashIcon size={40} />
          </AnimatedAppIcon>
          <Typography type='p' variation='center'>
            Acesta proba va fi stearsa din baza de date.
          </Typography>
          <ContainerFlex spacing={1} type='row' justify='end'>
            <Button size='medium' variant='outlined' type="button" onClick={() => { setFittingIndexToDelete(undefined); }}>Renunta</Button>
            <Button type='button' size='medium' variant='danger' onClick={() => onRemoveFittingDate(fittingIndexToDelete!)}>Sterge</Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default CaseFittings;