import { FC } from 'react';
import { useAppSelector } from '../../../store';
import { Loading } from '../../../toolkit';

const LoadingLayout: FC = () => {
  const loading = useAppSelector(state => state.loaders.count > 0);

  return (
    <>
      {loading && (
        <Loading />
      )}
    </>
  );
};

export default LoadingLayout;