import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import Button from '../Button/Button';
import { ChevronDown } from '../assets';
import Portal from '../Portal/Portal';
import styles from './styles.module.css';

type Props = {
  text: string;
  children: ReactNode[];
};

const Menu: FC<Props> = ({ text, children }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const options = useMemo(() => children.map((c, index) => ({ id: index, content: c })), [children]);

  return (
    <div>
      <Button
        ref={ref}
        type='button'
        size='small'
        variant='outlined'
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}>
        {text}
        <ChevronDown />
      </Button>
      <Portal
        isOpen={isOpen}
        anchorEl={ref.current}
        onDismiss={() => {
          setIsOpen(false);
        }}
        width={200}
        noPaddings
      >
        <ul className={styles.menu}>
          {options.map((item) => (
            <li key={item.id} onClick={() => setIsOpen(false)}>
              {item.content}
            </li>
          ))}
        </ul>
      </Portal>
    </div>
  );
};

export default Menu;