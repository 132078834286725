import { API_URLS, downloadFile } from '../../lib';
import { TitanInvoice } from '../../models';
import { useAppStoreHooks } from '../../toolkit';

const useSubscriptionInvoice = (companyName: string) => {
  const { getRequest, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const onDownloadInvoice = async (item: TitanInvoice) => {
    loadingStart();

    try {
      const response = await getRequest(`${API_URLS.platform.downloadInvoice}/${item.platformInvoiceId}/download`);
      const blob = await response?.blob();
      downloadFile(blob!, `${companyName.replace(/ /g, '_')}_${item.invoiceNo}.pdf`);
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };
  
  return {
    onDownloadInvoice
  };
};

export default useSubscriptionInvoice;
