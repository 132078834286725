import { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { ContainerFlex } from '../../../../toolkit';

type Props = {
  children: ReactNode;
};

const CaseHumanArcadeContainer: FC<Props> = ({ children }) => {
  const [top, setTop] = useState<number>(0);
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const scrolledDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    function handleScroll(event: Event) {
      const div = event.target as HTMLElement;
      let top = div.scrollTop;
      const height = top + (scrolledDivRef.current?.clientHeight || 0);
      const contentHeight = fixedDivRef.current?.clientHeight || 0;
      if (height > contentHeight) {
        top = contentHeight - (scrolledDivRef.current?.clientHeight || 0);
      }

      setTop(top);
    }
    document.querySelector('#pageBody')?.addEventListener("scroll", handleScroll);
    return () => {
      document.querySelector('#pageBody')?.removeEventListener("scroll", handleScroll);
    };
  }, [fixedDivRef, scrolledDivRef]);

  return (
    <ContainerFlex ref={fixedDivRef} type="column" grow align='center' style={{ position: 'relative', minHeight: 460 }}>
      <ContainerFlex ref={scrolledDivRef} type="column" align='center' style={{ position: 'absolute', top: top }} >
        {children}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default CaseHumanArcadeContainer;