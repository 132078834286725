import { FC } from 'react';
import { TitanCase } from '../../../models';
import { useCaseEditNotes } from '../../hooks';
import { Button, ContainerFlex, Input } from '../../../toolkit';

type Props = {
  _case: TitanCase;
  onNotesChanged: (notes: string, medicNotes: string) => void;
  onClose: () => void;
};

const CaseEditNotes: FC<Props> = ({ _case, onNotesChanged, onClose }) => {
  const {
    form,
    errors,
    onChange,
    onSubmit
  } = useCaseEditNotes(_case.titanCaseId!, _case.notes, _case.medicNotes, onNotesChanged, onClose);

  return (
    <form noValidate onSubmit={onSubmit}>
      <ContainerFlex type='column' spacing={2}>
        <Input
          label="Notite medic"
          name="medicNotes"
          onChange={onChange}
          rows={6}
          errorMessage={errors.medicNotes}
          value={form.medicNotes || ''} />
        <Input
          label="Notite tehnician"
          name="notes"
          onChange={onChange}
          rows={6}
          errorMessage={errors.notes}
          value={form.notes || ''} />

        <ContainerFlex type='row' justify='end' spacing={2}>
          <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
          <Button size='medium' variant='contained' type='submit'>Salveaza</Button>
        </ContainerFlex>
      </ContainerFlex>
    </form>
  );
};

export default CaseEditNotes;