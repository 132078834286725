import { FC } from 'react';

type Props = {
  size?: number;
};

const EditNoteIcon: FC<Props> = ({ size = 20 }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_625_15245)">
        <path d="M9.1665 3.33344H5.6665C4.26637 3.33344 3.56631 3.33344 3.03153 3.60593C2.56112 3.84561 2.17867 4.22806 1.93899 4.69847C1.6665 5.23324 1.6665 5.93331 1.6665 7.33344V14.3334C1.6665 15.7336 1.6665 16.4336 1.93899 16.9684C2.17867 17.4388 2.56112 17.8213 3.03153 18.061C3.56631 18.3334 4.26637 18.3334 5.6665 18.3334H12.6665C14.0666 18.3334 14.7667 18.3334 15.3015 18.061C15.7719 17.8213 16.1543 17.4388 16.394 16.9684C16.6665 16.4336 16.6665 15.7336 16.6665 14.3334V10.8334M6.66648 13.3334H8.06193C8.46959 13.3334 8.67341 13.3334 8.86522 13.2874C9.03528 13.2466 9.19786 13.1792 9.34698 13.0878C9.51517 12.9848 9.6593 12.8406 9.94755 12.5524L17.9165 4.58344C18.6069 3.89309 18.6069 2.7738 17.9165 2.08344C17.2261 1.39309 16.1069 1.39308 15.4165 2.08344L7.44753 10.0524C7.15928 10.3406 7.01515 10.4848 6.91208 10.653C6.8207 10.8021 6.75336 10.9647 6.71253 11.1347C6.66648 11.3265 6.66648 11.5304 6.66648 11.938V13.3334Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_625_15245">
          <rect width={size}  height={size}  fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditNoteIcon;