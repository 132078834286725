import { useState } from 'react';
import { API_URLS, validations } from '../../lib';
import { TitanCase } from '../../models';
import { textMessages } from '../../translations';
import { useAppStoreHooks } from '../../toolkit';

const validate = validations({
  paymentAmount: [['required', 'Camp oligatoriu!'], ['number', 'Suma nu este valida!']],
});

const useCasePayment = (
  titanCase: TitanCase,
  onCasePaid: (amount?: number) => void
) => {
  const [casePayment, setCasePayment] = useState<{
    form: {
      paymentAmount?: number;
    },
    errors: {
      paymentAmount?: string;
    };
    isPayedInFull: boolean,
  }>({
    form: {},
    errors: {},
    isPayedInFull: titanCase.price === titanCase.paid
  });

  const { postJson, loadingStart, loadingEnd, notify } = useAppStoreHooks();

  const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setCasePayment(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value
      }
    }));
  };

  const onTogglePayInFull = (_?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean)=> {
    setCasePayment(prevState => ({
      ...prevState,
      isPayedInFull: checked || false,
      form: {
        paymentAmount: checked ? Number(titanCase.price || 0) - Number(titanCase.paid || 0) : prevState.form.paymentAmount
      }
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(casePayment.form);
    if (Number(casePayment.form.paymentAmount) + Number(titanCase.paid || 0) > Number(titanCase.price || 0)) {
      errors.paymentAmount = `Suma adaugata depaseste suma totala de pe caz. Suma de plata este de ${Number(titanCase.price || 0) - Number(titanCase.paid || 0)}`;
    }
    setCasePayment(prevState => ({
      ...prevState,
      errors
    }));
    if (Object.keys(errors).length) {
      return;
    }
    let amount = Number(casePayment.form.paymentAmount);
    if(amount > Number(titanCase.price || 0)) {
      amount =  Number(titanCase.price || 0) - Number(titanCase.paid || 0);
    }

    loadingStart();
    try {
      await postJson<TitanCase>(API_URLS.titan.cases.payCase, { titanCaseId: titanCase.titanCaseId, amount });
      onCasePaid(Number(titanCase.paid || 0) + amount);
      loadingEnd();
      notify('Plata a fost adaugata cu succes.', 'success');
      return true;
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  return {
    casePayment,
    onChange,
    onSubmit,
    onTogglePayInFull
  };
};

export default useCasePayment;