import { useCallback, useMemo, useState } from 'react';
import { ListCache, useAppDispatch } from '../../store';
import { TableProps } from '../types';

const sortFunc = (propName: string) => (a: any, b: any) => {
  if (a[propName] < b[propName]) {
    return -1;
  } else if (a[propName] > b[propName]) {
    return 1;
  }
  return 0;
};

const useCacheList = <T>(
  cacheList: ListCache<T>,
  filterFunc: (searchTerm: string) => (row: any) => boolean,
  reloadAction: () => {
    payload: any;
    type: string;
  }
): TableProps => {
  const [tableState, setTableState] = useState<{
    page: number;
    total?: number;
    searchTerm?: string;
    filtered?: any[];
    sortingParams?: Record<string, boolean>;
  }>({
    page: 0,
  });
  const dispatch = useAppDispatch();
  const results = useMemo(() => cacheList.data || [], [cacheList.data]);

  const filterAndSort = useCallback(
    (searchTerm?: string, sortingParams?: Record<string, boolean>) => {
      let filtered = !searchTerm
        ? undefined
        : results.filter(filterFunc(searchTerm));

      if (sortingParams) {
        const sortingKey = Object.keys(sortingParams || {})?.[0];
        const sortingDir = sortingParams?.[sortingKey];
        filtered = [...(filtered || results || [])].sort(sortFunc(sortingKey));
        if (!sortingDir) {
          filtered = filtered.reverse();
        }
      }

      setTableState((prevState) => ({
        ...prevState,
        filtered,
        searchTerm,
        sortingParams,
      }));
    },
    [filterFunc, results]
  );

  const setSearchTerm = useCallback(
    (searchTerm?: string) => {
      filterAndSort(searchTerm, tableState.sortingParams);
    },
    [filterAndSort, tableState.sortingParams]
  );

  const setSortingParam = useCallback(
    (sortingParams?: Record<string, boolean>) => {
      filterAndSort(tableState.searchTerm, sortingParams);
    },
    [filterAndSort, tableState.searchTerm]
  );

  const changePage = (page: number) => {
    setTableState((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const reload = (reset?: boolean) => {
    dispatch(reloadAction());
    if (reset) {
      changePage(0);
    }
  };

  const final = tableState.filtered || results;
  const start = tableState.page * 10;
  const end = final.length - 1 - start < 0 ? final.length - 1 : start + 10;
  return {
    loading: cacheList.loading,
    isFiltered: Boolean(tableState.searchTerm),
    page: tableState.page,
    results: final.slice(start, end),
    total: (tableState.filtered || results).length,
    setSearchTerm,
    setQueryParam: () => {},
    setSortingParam,
    sortingParams: tableState.sortingParams,
    reload,
    changePage,
  };
};

export default useCacheList;
