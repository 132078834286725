import { FC } from 'react';
import styles from './styles.module.css';
import { Button, CloseIcon, ContainerFlex, IconButton, Typography, useAppStoreHooks } from '../../toolkit';
import { toggleFeature } from '../../store';

const StagesFeature: FC = () => {
  const { dispatch } = useAppStoreHooks();
  return (
    <div className={styles.featureCard}>
      <ContainerFlex type='row' justify='space-between'>
        <Typography type='h5'>Etape</Typography>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </ContainerFlex>
      <Typography type='span' variation='secondary'>
        Etapele vor adauga posibilitatea de a urmări cazul sau chiar elementul creat de către un tehnician. Activeaza etapele apasand pe{' '}
        <strong>butonul de jos</strong>
      </Typography>
      <ContainerFlex type='row' align='center' spacing={2}>
        <Button size='small' type='button' variant='contained' onClick={() => { dispatch(toggleFeature({ feature: 'stages' })) }}>Activeaza etape</Button>
        <Typography type='span' variation='secondary'>
          Poti oricand sa le dezactivezi din meniul <strong>"Setari"</strong>
        </Typography>
      </ContainerFlex>
    </div>
  );
}

export default StagesFeature;