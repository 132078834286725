import useClientsCache from './cache/useClientCache';
import useClinicCache from './cache/useClinicCache';
import useCompanyCache from './cache/useCompany';
import useCurrentSubscriptionCache from './cache/useCurrentSubscriptionCache';
import useElementsCache from './cache/useElementsCache';
import useRolesCache from './cache/useRolesCache';
import useStagesCache from './cache/useStagesCache';
import useUsersCache from './cache/useUserCache';

const useCache = (expireAfterMinutes?: number) => {
  useCurrentSubscriptionCache(expireAfterMinutes);
  useCompanyCache(expireAfterMinutes);
};

export const useSubscribedCache = (expireAfterMinutes?: number) => {
  useClientsCache(expireAfterMinutes);
  useClinicCache(expireAfterMinutes);
  useElementsCache(expireAfterMinutes);
  useStagesCache(expireAfterMinutes);
  useUsersCache(expireAfterMinutes);
  useRolesCache();
};



export default useCache;
