import { FC } from 'react';

const AlignLeftIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M2.25 2.25V15.75M15.75 9H5.25M5.25 9L10.5 14.25M5.25 9L10.5 3.75" stroke="#0F2D3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AlignLeftIcon;