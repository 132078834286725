import { FC } from 'react';
import { AnimatedAppIcon, Button, Card, Checkbox, ContainerFlex, Delimiter, Portal, Radio, StagesIcon, Typography } from '../../../toolkit';
import { TitanCase, TitanCaseStage } from '../../../models';
import { useAppSelector } from '../../../store';
import { StagesFeature } from '../../../components';
import { useCaseStagesChange, useStageFinalize } from '../../hooks';

type Props = {
  _case: TitanCase;
  onCaseStagesChanged: (caseStages: TitanCaseStage[]) => void;
};

const CaseStages: FC<Props> = ({ _case, onCaseStagesChanged }) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages);
  const user = useAppSelector(state => state.auth.user);

  const { toggleFinalize } = useStageFinalize(_case.caseStages, onCaseStagesChanged);
  const { caseStage, userStatges, onToggleCaseStage, onAssignedUserChanged, onSaveCaseStages } = 
    useCaseStagesChange(_case.titanCaseId!, _case.caseStages, onCaseStagesChanged)

  if (!stagesFeature) {
    return <StagesFeature />;
  }

  return (
    <Card type='column' spacing={2}>
      <Typography type='h5'>Etape</Typography>
      <Delimiter />
      {_case.caseStages.map(stage => (
        <ContainerFlex key={stage.caseStageId} type='row' justify='space-between'>
          <div>
            <Typography type='p' variation='bold'>{stage.stageName}</Typography>
            <Typography type='span'>Atribuit la <strong>{stage.titanUserName}</strong></Typography>
          </div>
          <ContainerFlex type='row' align='center' justify='center' spacing={2}>
            {user?.isManagerOrAbove ? (
              <>
                <Button type='button' size='small' variant='outlined' onClick={() => onToggleCaseStage(stage)}>Schimba</Button>
                <Checkbox value={stage.isFinished} onChange={() => toggleFinalize(stage)} label='Finalizat' />
              </>
            ) : user?.isTechnicianOrAbove && user.userIdentifier === stage.titanUserId ? (
              <Checkbox value={stage.isFinished} onChange={() => toggleFinalize(stage)} label='Finalizat' />
            ) : null}
          </ContainerFlex>
        </ContainerFlex>
      ))}
      {!_case.caseStages?.length && (
        <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
          <div></div>
          <AnimatedAppIcon>
            <StagesIcon size={40} />
          </AnimatedAppIcon>
          <Typography type='p' variation="center">
            Pentru elementele asociate acestui caz, nu există etape asociate.
          </Typography>
          <div></div>
        </ContainerFlex>
      )}

      <Portal
        isOpen={caseStage !== undefined}
        onDismiss={() => onToggleCaseStage(undefined)}
        title={'Schimba tehnicianul atribuit etapei'}
        overlay={true}
        width={560}
      >
        <ContainerFlex type='column' spacing={1}>
          <Typography type='h6'>{caseStage?.stageName}</Typography>
            <Typography type='span'>Atribuit la</Typography>
            {userStatges.map(userStage => (
              <Radio
                key={userStage.userStageId}
                label={`${userStage.firstName} ${userStage.lastName}`}
                value={caseStage?.titanUserId === userStage.userIdentifier}
                onChange={() => onAssignedUserChanged(userStage.userIdentifier)}
              />
            ))}
            <ContainerFlex spacing={1} type='row' justify='end'>
              <Button size='medium' variant='outlined' type="button" onClick={() => onToggleCaseStage(undefined)}>Renunta</Button>
              <Button size='medium' variant='contained' type="button" onClick={() => onSaveCaseStages()}>Salveaza</Button>
            </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </Card>
  );
};

export default CaseStages;