import { FC } from 'react';
import { TitanCase } from '../../models';
import { Typography, Card, Delimiter } from '../../toolkit';
import { prettyDate } from '../../lib';

type Props = {
  _case: TitanCase
};

const CaseContact: FC<Props> = ({ _case }) => {
  return (
    <Card type='column' spacing={3}>
        <Typography type='h5'>Contact</Typography>
        <Delimiter />
        <div>
          <Typography type='label' variation='secondary'>Medic</Typography>
          <Typography type='p' variation='bold'>{_case.titanClientName}&nbsp;</Typography>
        </div>
        <div>
          <Typography type='label' variation='secondary'>Adresa de livrare</Typography>
          <Typography type='p' variation='bold'>{_case.deliveryAddress}&nbsp;</Typography>
        </div>
        <div>
          <Typography type='label' variation='secondary'>Pacient</Typography>
          <Typography type='p' variation='bold'>{_case.patientName}&nbsp;</Typography>
        </div>
        <div>
          <Typography type='label' variation='secondary'>Data</Typography>
          <Typography type='p' variation='bold'>{prettyDate(_case.caseDateTime)}&nbsp;</Typography>
        </div>
    </Card>
  );
};

export default CaseContact;