import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../store';

type Props = {
  roles?: string[];
}

const OwnerOrRolesRoute: FC<Props> = ({ roles }) => {
  const user = useAppSelector(state => state.auth.user);

  if(!user) {
    return <Navigate to='/login' />;
  }

  if(!user.isOwner && roles) {
    const isAllowed = user.roles.some(r => roles.includes(r.name));

    if (!isAllowed ) {
      return <Navigate to='/dashboard' />;
    }
  }

  return (
    <Outlet />
  );
};

export default OwnerOrRolesRoute;