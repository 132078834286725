import { FC, useState } from 'react';
import { setRefreshUsers, useAppSelector } from '../../../store';
import { Button, ContainerFlex, Portal, Typography } from '../../../toolkit';
import { prettyDate, useCacheList } from '../../../lib';
import { TitanUser } from '../../../models';
import UserList from './UserList';
import UserStatisticsDetails from './UserStatisticsDetails';

type Props = {
  startDate: Date;
  endDate: Date;
};

const filter = (searchTerm: string) =>
  (row: TitanUser) => {
    return `${row.firstName} ${row.lastName}`.toLowerCase().includes(searchTerm.toLowerCase());
  };


const UserStatistics: FC<Props> = ({ startDate, endDate }) => {
  const [selectedUser, setSelectedUser] = useState<TitanUser>();
  const users = useAppSelector(state => state.cache.technicians);
  const listProps = useCacheList(users, filter, setRefreshUsers);

  return (
    <>
      {/* <Card>
        <Typography type="h5">Top colaboratori</Typography>
        <Delimiter />
        <Responsive colsFrMd="1-1">
          <DonutChart name="Elemente" series={elements.series} labels={elements.labels} />
          <DonutChart name="Pret" series={paid.series} labels={paid.labels} />
        </Responsive>
      </Card> */}
      <UserList {...listProps} onOpenUserDetails={setSelectedUser} />
      <Portal
        isOpen={Boolean(selectedUser)}
        onDismiss={() => setSelectedUser(undefined)}
        header={(
          <ContainerFlex type='column'>
            <Typography type='h5'>
              Statistica pentru {selectedUser?.firstName} {selectedUser?.lastName}
            </Typography>
            <Typography type='span'>
              Perioada {prettyDate(startDate)} - {prettyDate(endDate)}
            </Typography>
          </ContainerFlex>
        )}
        overlay={true}
        width={780}
      >
        <ContainerFlex type='column' spacing={2}>
          <UserStatisticsDetails startDate={startDate} endDate={endDate} userIdentifier={selectedUser?.userIdentifier!} />
          <ContainerFlex type='row' justify='end'>
            <Button type='button' variant='outlined' size='medium' onClick={() => setSelectedUser(undefined)}>
              Inchide
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default UserStatistics;