import { useCallback, useEffect, useState } from 'react';
import { TitanPaymentMethod, TitanSubscriptionDetails } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { API_URLS } from '../../lib';
import { setSubscriptionData, useAppSelector } from '../../store';

const useCurrentSubscription = () => {
  // const [currentSubscription, setCurrentSubscription] =
  //   useState<TitanSubscriptionDetails>();
  const [paymentMethod, setPaymentMethod] = useState<TitanPaymentMethod>();
  const currentSubscription = useAppSelector(
    (state) => state.cache.subscription.subscription
  );

  const {
    getJson,
    postJson,
    deleteRequest,
    notify,
    dispatch,
    loadingStart,
    loadingEnd,
  } = useAppStoreHooks();

  const loadPaymentMethods = useCallback(
    async (paymentMethodId: string) => {
      loadingStart();
      try {
        const paymentMethods = await getJson<TitanPaymentMethod[]>(
          API_URLS.platform.paymentMethod
        );
        setPaymentMethod(
          paymentMethods?.find(
            (pm) => pm.paymentMethodId === paymentMethodId
          ) || paymentMethods?.[0]
        );
      } catch (e: any) {
        console.log(e);
      }
      loadingEnd();
    },
    [getJson, setPaymentMethod, loadingStart, loadingEnd]
  );

  const loadCurrentSubscription = useCallback(async () => {
    loadingStart();
    try {
      const subscription = await getJson<TitanSubscriptionDetails>(
        `${API_URLS.platform.subsciption.subsciption}/DentOps`
      );
      dispatch(setSubscriptionData(subscription));
      // setCurrentSubscription(subscription);
      if (subscription) {
        loadPaymentMethods(subscription.defaultPaymentMethodId);
      }
    } catch (e: any) {
      console.log(e);
    }
    loadingEnd();
  }, [
    getJson,
    dispatch,
    // setCurrentSubscription,
    loadingStart,
    loadingEnd,
    loadPaymentMethods,
  ]);

  const cancelSubscription = useCallback(
    async (subscriptionId: string) => {
      loadingStart();
      try {
        await deleteRequest(
          `${API_URLS.platform.subsciption.subsciption}/${subscriptionId}`
        );
        loadingEnd();
        notify('Subscriptia a fost revocata cu success', 'success');
        loadCurrentSubscription();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, 'error', e.message);
      }
    },
    [loadingStart, deleteRequest, loadingEnd, notify, loadCurrentSubscription]
  );

  const restoreSubscription = useCallback(async (subscriptionId: string) => {
    loadingStart();
    try {
      await postJson(API_URLS.platform.subsciption.removeCancellation, { subscriptionId });
      loadingEnd();
      notify('Subscriptia a fost reinstaurata cu success', 'success');
      loadCurrentSubscription();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  }, [loadingStart, postJson, loadingEnd, notify, loadCurrentSubscription]);

  const defaultPaymentMethodId = currentSubscription?.defaultPaymentMethodId;
  useEffect(() => {
    if (defaultPaymentMethodId) {
      loadPaymentMethods(defaultPaymentMethodId);
    }
  }, [loadPaymentMethods, defaultPaymentMethodId]);

  return {
    currentSubscription: currentSubscription?.stripePriceId ? currentSubscription : undefined,
    paymentMethod,
    cancelSubscription,
    restoreSubscription,
  };
};

export default useCurrentSubscription;
