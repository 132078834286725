import { FC } from 'react';

const CheckIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M33.3334 10L15 28.3333L6.66669 20" stroke="#3D9BCE" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CheckIcon;