import { FC } from 'react';

const MegaPhoneIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M22 8.38441V12.3844M10.25 5.8844H6.8C5.11984 5.8844 4.27976 5.8844 3.63803 6.21138C3.07354 6.499 2.6146 6.95795 2.32698 7.52243C2 8.16417 2 9.00425 2 10.6844L2 11.8844C2 12.8163 2 13.2822 2.15224 13.6498C2.35523 14.1398 2.74458 14.5292 3.23463 14.7322C3.60218 14.8844 4.06812 14.8844 5 14.8844V19.1344C5 19.3666 5 19.4827 5.00963 19.5804C5.10316 20.53 5.85441 21.2812 6.80397 21.3748C6.90175 21.3844 7.01783 21.3844 7.25 21.3844C7.48217 21.3844 7.59826 21.3844 7.69604 21.3748C8.64559 21.2812 9.39685 20.53 9.49037 19.5804C9.5 19.4827 9.5 19.3666 9.5 19.1344V14.8844H10.25C12.0164 14.8844 14.1772 15.8313 15.8443 16.74C16.8168 17.2702 17.3031 17.5353 17.6216 17.4963C17.9169 17.4601 18.1402 17.3275 18.3133 17.0855C18.5 16.8246 18.5 16.3024 18.5 15.2581V5.51069C18.5 4.4664 18.5 3.94425 18.3133 3.68329C18.1402 3.4413 17.9169 3.3087 17.6216 3.27253C17.3031 3.23352 16.8168 3.4986 15.8443 4.02876C14.1772 4.93751 12.0164 5.8844 10.25 5.8844Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MegaPhoneIcon;