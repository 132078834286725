import { FC, ReactNode } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  variation?: 'primary';
} & LinkProps;

const Link: FC<Props> = ({ children, className, variation, ...rest }) => {
  return (
    <RouterLink className={[className, styles.link, variation ? styles[variation] : '',].join(' ')} {...rest}>
      {children}
    </RouterLink>
  );
};

export default Link;