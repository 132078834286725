import { FC, ReactNode } from 'react';
import ContainerFlex, { Props as ContainerFlexProps } from '../ContainerFlex/ContainerFlex';
import styles from './styles.module.css';

type Props = {
  variant?: 'default' | 'danger';
  children: ReactNode;
} & ContainerFlexProps;

const MenuItem: FC<Props> = ({ children, variant = 'default', ...props }) => {
  return (
    <ContainerFlex type='row' align='center' spacing={1} className={styles[variant]} {...props}>
      {children}
    </ContainerFlex>
  );
};

export default MenuItem;