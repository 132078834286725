import { FC, useState } from 'react';
import { TitanCompany } from '../../../models';
import { useCompanyEdit } from '../../hooks';
import { Input, Button, Portal, InputPassword } from '../../../toolkit';
import { textForm } from '../../../translations';
import { Container } from '../../../layout';

type Props = {
  company: TitanCompany;
};

const UserCompany: FC<Props> = ({ company }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { companyForm, onChange, onSubmit } = useCompanyEdit(company);

  return (
    <div>
      <form noValidate>
        <Input
          label={textForm.companyLabel}
          placeholder={textForm.companyPlaceholder}
          name="name"
          onChange={onChange}
          value={companyForm.form.name}
          required
          errorMessage={companyForm.errors.name}
        />
        <Input
          label={textForm.companyAddressLabel}
          placeholder={textForm.companyAddressPlaceholder}
          name="address"
          onChange={onChange}
          value={companyForm.form.address}
          required
          errorMessage={companyForm.errors.address}
        />
        <Input
          label={textForm.cityLabel}
          placeholder={textForm.cityPlaceholder}
          name="city"
          onChange={onChange}
          value={companyForm.form.city}
          required
          errorMessage={companyForm.errors.city}
        />
        <Input
          label={textForm.fiscalCodeLabel}
          placeholder={textForm.fiscalCodePlaceholder}
          name="vat"
          onChange={onChange}
          value={companyForm.form.vat}
        />
        <Input
          label={textForm.phoneLabel}
          placeholder={textForm.phonePlaceholder}
          name="phoneNumber"
          onChange={onChange}
          value={companyForm.form.phoneNumber}
          required
          errorMessage={companyForm.errors.phoneNumber}
        />

        <Input
          label={textForm.certificateTechnicianNameLabel}
          placeholder={textForm.certificateTechnicianNamePlaceholder}
          name="certificateTechnicianName"
          onChange={onChange}
          value={companyForm.form.certificateTechnicianName}
          errorMessage={companyForm.errors.certificateTechnicianName}
        />

        <Input
          label={textForm.certificateSeriesLabel}
          placeholder={textForm.certificateSeriesPlaceholder}
          name="certificateSeries"
          onChange={onChange}
          value={companyForm.form.certificateSeries}
          required
          errorMessage={companyForm.errors.certificateSeries}
        />

        <Input
          label={textForm.certificateNumberLabel}
          placeholder={textForm.certificateNumberPlaceholder}
          name="certificateNumber"
          onChange={onChange}
          value={companyForm.form.certificateNumber}
          required
          errorMessage={companyForm.errors.certificateNumber}
        />

        <Container gap={10} horizontalAlign='middle'>
          <Button size='medium' variant='contained' type="button" onClick={() => { setIsOpen(true); }}>Salveaza</Button>
        </Container>
      </form>
      <Portal
        isOpen={isOpen}
        onDismiss={() => { setIsOpen(false); }}
        title="Confirma parola pentru a salva modificarile"
        overlay={true}
        width={560}
      >
        <InputPassword
          name="password"
          onChange={onChange}
          value={companyForm.form.password}
          required
          errorMessage={companyForm.errors.password}
        />
        <Container gap={10} horizontalAlign='end'>
          <Button size='medium' variant='outlined' type="button" onClick={() => { setIsOpen(false); }}>Renunta</Button>
          <Button size='medium' variant='contained' type="button" onClick={() => {
            onSubmit();
            setIsOpen(false);
          }}>Confirma</Button>
        </Container>
      </Portal>
    </div>
  );
};

export default UserCompany;