import { API_URLS } from "../../lib";
import { TitanCaseFitting } from "../../models";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";

const useFittingsFinalize = (
  caseFittings: TitanCaseFitting[],
  onCaseFittingsChanged: (caseFittings: TitanCaseFitting[]) => void
) => {
  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const toggleFinalize = async (caseFitting: TitanCaseFitting) => {
    loadingStart();
    try {
      await postJson(API_URLS.titan.fitting.toggleIsFinished, {
        fittingId: caseFitting.fittingId,
        isFinished: !caseFitting.isFinished,
      });
      loadingEnd();
      const caseFittingsCopy = [...caseFittings];
      const index = caseFittings.findIndex(
        (cs) => cs.fittingId === caseFitting.fittingId
      );
      caseFittingsCopy[index].isFinished = !caseFittingsCopy[index].isFinished;
      onCaseFittingsChanged(caseFittingsCopy);
      notify("Proba a fost salvata", "success");
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  return {
    toggleFinalize,
  };
};

export default useFittingsFinalize;
