import { FC, useState } from "react";
import { Responsive } from "../../../layout";
import { AreaChart, Metric } from "../../../components";
import {
  Card,
  CasesIcon,
  ContainerFlex,
  MoneyIcon,
  Tabs,
  ToothIcon,
  Typography,
} from "../../../toolkit";
import { TitanCompanyStatistic } from "../../../models";
import { prettyDate, prettyMonthYear, prettyWeekYear } from "../../../lib";

type Props = {
  statistics?: TitanCompanyStatistic;
};

const SHOW_BY = {
  monthly: "pe luna",
  weekly: "pe saptaman",
  daily: "pe zi",
  "no-data": "Nu exista rezultate",
};

const GeneralStatistic: FC<Props> = ({ statistics }) => {
  const [selected, setSelected] = useState("cases");
  const showBy = statistics?.monthlyTrendMetrics?.length
    ? "monthly"
    : statistics?.weeklyTrendMetrics?.length
    ? "weekly"
    : statistics?.dailyTrendMetrics?.length
    ? "daily"
    : "no-data";

  const trendMetrics = statistics?.monthlyTrendMetrics?.length
    ? statistics?.monthlyTrendMetrics
    : statistics?.weeklyTrendMetrics?.length
    ? statistics?.weeklyTrendMetrics
    : statistics?.dailyTrendMetrics?.length
    ? statistics?.dailyTrendMetrics
    : [];

  return (
    <>
      <Responsive colsMd={3}>
        <Metric
          label="Total cazuri"
          metric={String(statistics?.totalCases || 0)}
          icon={<CasesIcon />}
        />
        <Metric
          label="Total elemente"
          metric={String(statistics?.totalElements || 0)}
          icon={<ToothIcon />}
        />
        <Metric
          label="Total incasari"
          metric={`${statistics?.paid || 0}/${statistics?.total || 0} RON`}
          icon={<MoneyIcon />}
        />
      </Responsive>
      <Card>
        <ContainerFlex type="row" justify="space-between">
          <Typography type="h5">
            Statistici in timp
            <Typography type="span">
              {" "}
              (
              {selected === "cases"
                ? "Cazuri"
                : selected === "elements"
                ? "Elemente"
                : selected === "payments"
                ? "Incasari"
                : ""}{" "}
              {SHOW_BY[showBy]})
            </Typography>
          </Typography>
          <Tabs
            selected={selected}
            items={[
              { id: "cases", label: "Cazuri" },
              { id: "elements", label: "Elemente" },
              { id: "payments", label: "Incasari" },
            ]}
            onTabChanged={(id) => {
              setSelected(id);
            }}
          />
        </ContainerFlex>
        {selected === "cases" && (
          <AreaChart
            series={[
              {
                name: "Cazuri",
                data: trendMetrics.map((tm) => tm.cases),
              },
            ]}
            labels={trendMetrics.map((tm) => tm.key)}
            labelFormatFunc={
              statistics?.monthlyTrendMetrics?.length
                ? prettyMonthYear
                : statistics?.weeklyTrendMetrics?.length
                ? prettyWeekYear
                : prettyDate
            }
          />
        )}
        {selected === "elements" && (
          <AreaChart
            series={[
              {
                name: "Elemente",
                data: trendMetrics.map((tm) => tm.elements),
              },
            ]}
            labels={trendMetrics.map((tm) => tm.key)}
            labelFormatFunc={
              statistics?.monthlyTrendMetrics?.length
                ? prettyMonthYear
                : statistics?.weeklyTrendMetrics?.length
                ? prettyWeekYear
                : prettyDate
            }
          />
        )}
        {selected === "payments" && (
          <AreaChart
            series={[
              {
                name: "Incasari",
                data: trendMetrics.map((tm) => tm.paid),
              },
            ]}
            labels={trendMetrics.map((tm) => tm.key)}
            labelFormatFunc={
              statistics?.monthlyTrendMetrics?.length
                ? prettyMonthYear
                : statistics?.weeklyTrendMetrics?.length
                ? prettyWeekYear
                : prettyDate
            }
          />
        )}
      </Card>
    </>
  );
};

export default GeneralStatistic;
