const formatDate = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const formatDateTime = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const parseDate = (date: Date | string) => {
  let utcDate;
  if (typeof date === 'string') {
    utcDate = (date as string).endsWith('Z')
      ? new Date(date)
      : new Date(date + 'Z');
  } else {
    utcDate = date;
  }

  return utcDate;
};

const prettyDate = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const prettyDateTime = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const prettyDayMonth = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const prettyTime = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const prettyMonthYear = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    year: 'numeric',
  };
  return Intl.DateTimeFormat('ro-RO', options).format(utcDate);
};

const prettyWeekYear = (date: Date | string) => {
  if (!date) {
    return '';
  }
  const utcDate = parseDate(date);
  const d = new Date(
    Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate())
  );
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(
    ((utcDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );

  return `${weekNo}-${utcDate.getFullYear()}`;
};

const parseAsStartDate = (date: Date) => {
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

const parseAsEndDate = (date: Date) => {
  const d = new Date(date);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
};


const ONE_MINUTE = 1000 * 60;
const ONE_DAY = ONE_MINUTE * 60 * 24;
const END_OF_DAY = ONE_DAY - ONE_MINUTE;
const date = new Date();
date.setHours(0);
date.setMinutes(0);
date.setSeconds(0);
date.setMilliseconds(0);
const DEFAULT_TODAY_END = new Date(date.getTime() + END_OF_DAY);
date.setDate(1);
const DEFAULT_MONTH_START = new Date(date);

export {
  formatDate,
  formatDateTime,
  parseDate,
  prettyDate,
  prettyDateTime,
  prettyDayMonth,
  prettyTime,
  prettyMonthYear,
  prettyWeekYear,
  parseAsStartDate,
  parseAsEndDate,
  DEFAULT_TODAY_END,
  DEFAULT_MONTH_START
};
