import { useCallback, useEffect, useRef } from 'react';
import { setClinicsData, setLoadClinics, useAppSelector } from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { TitanClinic } from '../../../models';
import { textMessages } from '../../../translations';
import { API_URLS } from '../../constants';

const useClinicCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const { clinics, expireAfterMiliseconds } = useAppSelector(state => state.cache);
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadClinics = useCallback(async () => {
    refreshing.current = true;
    try {
      const clients = await getJson<TitanClinic[]>(API_URLS.titan.clinic.clinic);
      dispatch(setClinicsData(clients));
    } catch (e: any) {
      notify(textMessages.genericError, 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }
    if (!clinics.expireTime) {
      dispatch(setLoadClinics());
      loadClinics();
    } else {
      const timer = clinics.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadClinics();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [loadClinics, dispatch, clinics.expireTime, expireAfterMiliseconds, expireAfterMinutes]);
};
export default useClinicCache;
