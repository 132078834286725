import { useCallback, useEffect, useState } from 'react';
import { TableProps } from '../types';
import { TABLE_DEFAULTS } from '../TABLE_DEFAULTS';
import { useAppStoreHooks } from '../../toolkit';

type Params = Record<string, string | number | boolean>;

const usePaginatedList = (url: string, params?: { sortingParams?: Params; }): TableProps => {
  const [tableState, setTableState] = useState<{
    page: number;
    total?: number;
    loading: boolean;
    queryParams?: Params;
    sortingParams?: Params;
    results: any[];
  }>({
    page: 0,
    loading: false,
    results: [],
    sortingParams: params?.sortingParams
  });
  const { getJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const loadList = useCallback(async (page: number, queryParams?: Params, sortingParams?: Params) => {
    loadingStart();
    let internalUrl = url + (url.includes('?') ? '&' : '?');
    internalUrl += `skip=${page * TABLE_DEFAULTS.pageSize}`;
    if (queryParams) {
      internalUrl = Object.keys(queryParams).reduce((accumulator, current) => {
        if (queryParams![current] !== undefined) {
          accumulator += `&${current}=${queryParams![current]}`;
        }
        return accumulator;
      }, internalUrl);
    }
    if (sortingParams) {
      internalUrl = Object.keys(sortingParams).reduce((accumulator, current) => {
        if (sortingParams![current] !== undefined) {
          accumulator += `&${current}=${sortingParams![current]}`;
        }
        return accumulator;
      }, internalUrl);
    }
    try {
      const response = await getJson<{ count: number, results: any[]; }>(internalUrl);
      setTableState(prevState => ({
        ...prevState,
        results: [...(response?.results || [])],
        total: response.count
      }));
      loadingEnd();
    } catch (e: any) {
      notify(e.message || e, 'error');
      loadingEnd();
    }
  }, [url, setTableState, getJson, notify, loadingStart, loadingEnd]);

  const changePage = useCallback((page: number) => {
    setTableState(prevState => ({
      ...prevState,
      page
    }));
  }, [setTableState]);

  const reload = useCallback((reset?: boolean) => {
    if (reset) {
      changePage(0);
    }
  }, [changePage]);

  const setQueryParam = useCallback((queryParams?: Params) => {
    setTableState(prevState => ({
      ...prevState,
      queryParams
    }));
  }, [setTableState]);

  const setSortingParam = useCallback((sortingParams?: Params) => {
    setTableState(prevState => ({
      ...prevState,
      sortingParams
    }));
  }, [setTableState]);

  const _searchTerm = tableState.queryParams?.searchTerm;
  const setSearchTerm = useCallback((searchTerm?: string) => {
    if(searchTerm !== _searchTerm) {
      setTableState(prevState => {
        let queryParams = { ...(prevState.queryParams || {}) };
        if(queryParams.searchTerm !== searchTerm) {
  
        }
        if (searchTerm) {
          queryParams = {
            ...queryParams,
            searchTerm
          };
        } else {
          delete queryParams.searchTerm;
        }
  
        return {
          ...prevState,
          queryParams
        };
      });
    }
  }, [setTableState, _searchTerm]);



  useEffect(() => {
    loadList(tableState.page, tableState.queryParams, tableState.sortingParams);
  }, [loadList, tableState.page, tableState.queryParams, tableState.sortingParams]);

  return {
    loading: tableState.loading,
    isFiltered: Boolean(tableState.queryParams?.searchTerm),
    page: tableState.page,
    results: tableState.results,
    total: tableState.total || 0,
    sortingParams: tableState.sortingParams,
    setSearchTerm,
    setQueryParam,
    setSortingParam,
    reload,
    changePage
  };
};

export default usePaginatedList;