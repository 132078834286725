import { FC } from "react";
import { TitanClient } from "../../models";
import { Card, Delimiter, Typography } from "../../toolkit";

type Props = {
  client: TitanClient;
};

const ClientDetails: FC<Props> = ({ client }) => {
  return (
    <Card type="column" spacing={2}>
      <div>
        <Typography type="p" variation="bold">
          {client.lastName} {client.firstName}
        </Typography>
        <Typography type="span" variation="secondary">
          {client.email}
        </Typography>
      </div>
      <Delimiter />
      <div>
        <Typography type="label" variation="secondary">
          Adresa
        </Typography>
        <Typography type="p" variation="bold">
          {client.address}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          Oras
        </Typography>
        <Typography type="p" variation="bold">
          {client.city}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          Numar telefon
        </Typography>
        <Typography type="p" variation="bold">
          {client.phone}&nbsp;
        </Typography>
      </div>
      {client.clinicId && (
        <div>
          <Typography type="label" variation="secondary">
            Clinica
          </Typography>
          <Typography type="p" variation="bold">
            {client.clinicModel?.clinicName}&nbsp;
          </Typography>
        </div>
      )}
      <div>
        <Typography type="label" variation="secondary">
          Cont bancar (IBAN)
        </Typography>
        <Typography type="p" variation="bold">
          {client.bankAccount}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          CUI
        </Typography>
        <Typography type="p" variation="bold">
          {client.fiscalCode}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          Notite
        </Typography>
        <Typography type="p" variation="bold">
          {client.notes}&nbsp;
        </Typography>
      </div>
    </Card>
  );
};

export default ClientDetails;
