import { FC } from 'react';
import { Portal, UserAddIcon } from '../../../toolkit';
import ClientListTable from '../ClientList/ClientListTable';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import ClientForm from '../ClientForm/ClientForm';
import { TableProps } from '../../../lib';

type Props = {
  clinicId?: number;
  clientListProps: TableProps;
  isOpen: boolean;
  onReset: () => void;
  onAdd: () => void;
};


const ClinicClients: FC<Props> = ({ clinicId, clientListProps, isOpen, onReset, onAdd }) => {
  const navigate = useNavigate();
  const user = useAppSelector((store) => store.auth.user);

  return (
    <>
      <ClientListTable
        {...clientListProps}
        clinicId={clinicId}
        onRowClick={(item) => {
          navigate(`/clients/${item.titanClientId}`);
        }}
        stateAssets={{
          emptyIcon: <UserAddIcon size={40} />,
          emptyTitle: "Momentan nu ai nici un medic asociat acestei clinici",
          ...(user?.isAdmin
            ? {
              emptySubTitle:
                "Adauga primul tau colaborator apasand pe butonul de jos",
              emptyAction: () => {
                onAdd();
              },
              emptyActionText: "Adauga medic",
            }
            : {}),
        }}
      />
      <Portal
        isOpen={isOpen}
        onDismiss={onReset}
        title={"Adauga client"}
        overlay={true}
        width={560}
      >
        <ClientForm clinicId={clinicId} onClose={onReset} onSaved={() => { onReset(); clientListProps.reload(); }} />
      </Portal>
    </>
  );
};

export default ClinicClients;