import { configureStore } from "@reduxjs/toolkit";
import paginatedListReducer from "./paginatedListReducer";
import toastReducer from "./toastReducer";
import loadingReducer from "./loadingReducer";
import authReducer, { getUserWithRoleProps } from "./authReducer";
import appSettingsReducer from "./appSettingsReducer";
import {
  APP_LOCAL_STORAGE_KEYS,
  APP_LOCAL_STORAGE_LEGACY_KEYS,
} from "./constants";
import { AuthenticatedUser, TitanToken, TitanUser } from "../models";
import notificationReducer, {
  NotificationMessageType,
} from "./notificationReducer";
import cacheReducer from "./cacheReducer";
import paginatedListV2Reducer from "./paginatedListV2Reducer";

const loadAuth = () => {
  let parsedToken = undefined;
  const tokenString = localStorage.getItem(APP_LOCAL_STORAGE_LEGACY_KEYS.JWT);
  if (tokenString) {
    try {
      parsedToken = JSON.parse(tokenString) as TitanToken;
    } catch (e: any) {
      parsedToken = undefined;
    }
  }
  let parsedUser: AuthenticatedUser | undefined = undefined;
  const userString = localStorage.getItem(APP_LOCAL_STORAGE_LEGACY_KEYS.USER);
  if (userString) {
    try {
      const user = JSON.parse(userString) as TitanUser;
      parsedUser = getUserWithRoleProps(user);
    } catch (e: any) {
      parsedUser = undefined;
    }
  }

  const authString = localStorage.getItem(APP_LOCAL_STORAGE_KEYS.AUTH);
  let auth: { user?: TitanUser | AuthenticatedUser; token?: TitanToken } = {};
  if (authString) {
    auth = JSON.parse(authString);
  }

  return {
    token: auth.token || parsedToken,
    user: auth.user ? getUserWithRoleProps(auth.user) : parsedUser,
  };
};

export const getAppSettings = (): any => {
  const teethColorString = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.VITA_COLOR
  );
  const calendarView = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.CALENDAR_VIEW
  );
  const menuString = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.COLLAPSED_MENU
  );
  const caseFieldsString = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.COLLAPSED_CASE_FIELDS
  );
  const clientDetailsString = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.COLLAPSED_CLIENT_DETAILS
  );
  const titanFeatureString = localStorage.getItem(
    APP_LOCAL_STORAGE_LEGACY_KEYS.FEATURES
  );
  let features = {
    stages: false,
    v2: true,
    clinics: false,
  };
  if (titanFeatureString) {
    features = JSON.parse(titanFeatureString);
  }

  const legacySettings = {
    defaultVitaColor: Number(teethColorString) || 0,
    defaultCalendarView: calendarView || null,
    collapsedMenu: menuString === "true",
    collapsedCaseFields: caseFieldsString === "true",
    collapsedClientDetails: clientDetailsString === "true",
    features,
  };

  const titanSettingsString = localStorage.getItem(
    APP_LOCAL_STORAGE_KEYS.SETTINGS
  );
  let settings = {};
  if (titanSettingsString) {
    settings = JSON.parse(titanSettingsString);
  }

  return {
    ...legacySettings,
    ...settings,
  };
};

const staticNotifications: NotificationMessageType[] = [
  {
    id: 1,
    type: "functionality",
    title: "DentOps 2.0",
    details:
      "DentOps 2.0 vine cu un nou design mai intuitiv. Il poti incerca activand optiunea din setari",
    read: false,
    verison: "v1",
  },
];

const getNotifications = () => {
  const titanNotifString = localStorage.getItem(APP_LOCAL_STORAGE_KEYS.NOTIF);
  if (titanNotifString) {
    const readIds = JSON.parse(titanNotifString);
    staticNotifications.forEach((n) => {
      n.read = readIds.includes(n.id);
    });
  }

  return staticNotifications;
};

const preloadedState = {
  auth: loadAuth(),
  appSettings: getAppSettings(),
  notifications: {
    value: getNotifications(),
  },
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appSettings: appSettingsReducer,
    paginatedList: paginatedListReducer,
    toasts: toastReducer,
    notifications: notificationReducer,
    loaders: loadingReducer,
    cache: cacheReducer,
    paginatedListV2: paginatedListV2Reducer,
  },
  preloadedState,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
