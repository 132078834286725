import { FC } from 'react';
import { ForgotPasswordPageTranslation, textForm, textPage } from '../../../translations';
import { Button, Input, Link,Typography } from '../../../toolkit';
import useForgotPassword from './useForgotPassword';
import styles from './styles.module.css';

const texts = textPage('forgotPassword') as ForgotPasswordPageTranslation;

const ForgotPasswordForm: FC = () => {
  const {
    forgotPasswordForm,
    onChangeHandler,
    onSubmitHandler
  } = useForgotPassword();

  return (
    <form noValidate onSubmit={onSubmitHandler}>
      <Input
        label={textForm.emailLabel}
        placeholder={textForm.emailPlaceholder}
        name="email"
        onChange={onChangeHandler}
        value={forgotPasswordForm.form.email}
        required
        errorMessage={forgotPasswordForm.errors.email}
      />
      <Button size='medium' variant='contained' type="submit" className={styles.button}>{texts.btnTxt}</Button>
      <Typography type='p' variation='secondary' variation1='center'>
        {texts.haveAccount}{' '}
        <Link to='/login'>{texts.enterAccount}</Link>
      </Typography>
    </form>
  );
};

export default ForgotPasswordForm;