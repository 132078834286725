import { FC } from "react";
import { IColumn, TABLE_DEFAULTS, TableProps, prettyDate } from "../../../lib";
import { TitanTender } from "../../../models";
import {
  ContainerFlex,
  IconButton,
  MoneyIcon,
  TrashIcon,
} from "../../../toolkit";
import { TableList } from "../../../components";
import TenderDownloadMenu from './TenderDownloadMenu';

interface IDocument extends TitanTender {
  key: string;
}

type Props = {
  onDownload: (tender: TitanTender, type: 'elements' | 'cases' | 'both') => void;
  onDelete: (tender: TitanTender) => void;
  onRowClick: (tender: TitanTender) => void;
} & TableProps;

const TenderList: FC<Props> = (listProps) => {
  const columns: IColumn[] = [
    {
      key: "tenderName",
      fieldName: "tenderName",
      name: "Deviz din data de",
      onRender: (item: IDocument) => (
        <span>
          {prettyDate(item.from)} - {prettyDate(item.to)}
        </span>
      ),
    },
    {
      key: "actions",
      name: "",
      fieldName: "actions",
      width: 280,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type="row" spacing={1}>
            <IconButton
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                listProps.onRowClick(item);
              }}
            >
              <MoneyIcon />
            </IconButton>
            <TenderDownloadMenu
              onDownload={(type) => {
                listProps.onDownload(item, type);
              }}
            />
            <IconButton
              variant="outlined"
              color="danger"
              onClick={(e) => {
                e.stopPropagation();
                listProps.onDelete(item);
              }}
            >
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    },
  ];

  const items = listProps.results.map((item: TitanTender, index: number) => {
    return {
      key: item.tenderId,
      index: listProps.page * 10 + index + 1,
      ...item,
    };
  });

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      pageSize={TABLE_DEFAULTS.pageSize}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: "Nu a fost gasit nici un deviz",
        emptyIcon: <MoneyIcon size={40} />,
        emptyTitle: "Nu ai nici un deviz generat",
        emptySubTitle:
          'Genereaza un deviz din sectiunea "Raport" de mai sus, selectand perioada dorita',
      }}
      isSubList={true}
    />
  );
};

export default TenderList;
