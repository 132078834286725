import AuthContext from '../../models/AuthContext';
import fetchWithToken from './fetchWithToken';

const fetchJson = async <T>(context: AuthContext, url: string, method: string, payload?: any): Promise<T> => {
  const response = await fetchWithToken(context, url, method, payload, 'application/json');
  if (response && response.ok) {
    if (response.status !== 204) {
      return await response.json() as T;
    }
  }
  return Promise.resolve({} as T);
};

export default fetchJson;