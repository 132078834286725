import { FC } from 'react';

type Props = {
  size?: number;
};

const EditPencilIcon: FC<Props> = ({ size = 20 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4999 17.5H10.8333M2.08325 17.9167L6.70764 16.138C7.00343 16.0243 7.15132 15.9674 7.28968 15.8931C7.41259 15.8272 7.52975 15.751 7.63995 15.6655C7.76402 15.5692 7.87606 15.4572 8.10015 15.2331L17.4999 5.83332C18.4204 4.91285 18.4204 3.42046 17.4999 2.49999C16.5795 1.57951 15.0871 1.57951 14.1666 2.49999L4.76682 11.8998C4.54273 12.1238 4.43069 12.2359 4.33441 12.36C4.2489 12.4702 4.17275 12.5873 4.10678 12.7102C4.03251 12.8486 3.97563 12.9965 3.86186 13.2923L2.08325 17.9167ZM2.08325 17.9167L3.79835 13.4575C3.92108 13.1384 3.98245 12.9788 4.0877 12.9057C4.17969 12.8419 4.2935 12.8177 4.4035 12.8387C4.52936 12.8627 4.65024 12.9836 4.89199 13.2254L6.77456 15.1079C7.01631 15.3497 7.13719 15.4706 7.16122 15.5964C7.18223 15.7064 7.15807 15.8202 7.09421 15.9122C7.02112 16.0175 6.86158 16.0788 6.54248 16.2016L2.08325 17.9167Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default EditPencilIcon;