import { FC } from "react";
import { IColumn, TableProps, formatMoney } from "../../../lib";
import { TitanElementStatistic } from "../../../models";
import { TableList } from "../../../components";
import { ToothIcon } from "../../../toolkit";

interface IDocument extends TitanElementStatistic {
  key: string;
}

type Props = {} & TableProps;

const ElementStatisticsList: FC<Props> = (listProps) => {
  const columns: IColumn[] = [
    {
      key: "elementName",
      name: "Nume",
      fieldName: "elementName",
    },
    {
      key: "count",
      name: "Cantitate",
      fieldName: "count",
    },
    {
      key: "totalPrice",
      name: "Total",
      fieldName: "totalPrice",
      onRender: (item: IDocument) => <>{formatMoney(item.totalPrice)}</>,
    },
  ];

  const items = listProps.results.map(
    (item: TitanElementStatistic, index: number) => {
      return {
        key: item.elementId,
        index: listProps.page * 10 + index + 1,
        ...item,
      };
    }
  );

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: "Nu a fost gasit nici un element",
        emptyIcon: <ToothIcon size={40} />,
        emptyTitle: "Nu exista statistici in perioada selectata.",
      }}
    />
  );
};

export default ElementStatisticsList;
