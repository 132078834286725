import { FC } from "react";
import { useNavigate} from 'react-router-dom';

import { AppTitle } from '../../layout';
import { Button, Card, PasswordIcon, Typography, AnimatedAppIcon } from '../../toolkit';
import { ForgotPasswordPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('forgotPassword') as ForgotPasswordPageTranslation;

const ForgotPasswordSuccessPage: FC = () => {
  const navigate = useNavigate();
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <PasswordIcon />
        </AnimatedAppIcon>
        <Typography type='h2' variation='center'>{texts.title}</Typography>
        <Typography type='p' variation='center' variation1='secondary'>{texts.subTitleSuccess}</Typography>
        <Button 
        type='button'
          size='medium' 
          variant='contained' 
          className={styles.button} 
          onClick={() => navigate('/login')}>
            {texts.backToLogin}
          </Button>
      </Card>
    </AppTitle>
  );
};

export default ForgotPasswordSuccessPage;