import { FC, ReactNode, useRef } from "react";
import styles from "./styles.module.css";

type Props = {
  isExpanded: boolean;
  children: ReactNode;
};

const Collapsible: FC<Props> = ({ isExpanded, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentHeight = isExpanded ? ref?.current?.clientHeight || 0 : 0;

  return (
    <div className={styles.collapse} style={{ height: currentHeight + "px" }}>
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default Collapsible;
