import { FC } from "react";
import { TitanUser } from "../../../models";
import { Button, ContainerFlex, Input, InputPassword, Typeahead } from "../../../toolkit";
import { useUser } from "../../hooks";
import { textForm } from "../../../translations";
import { Responsive } from "../../../layout";
import { useAppSelector } from "../../../store";
import { StagesFeature } from "../../../components";
import UserFormConfigureStages from './UserFormConfigureStages';

type Props = {
  user?: TitanUser;
  onClose: () => void;
  onSaved: () => void;
};

const UserForm: FC<Props> = ({ user, onClose, onSaved }) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages);

  const {
    userForm,
    stagesOptions,
    rolesOptions,
    showStages,
    onChange,
    onDropdownChange,
    onSubmit,
    onStageChanged,
    onStageValueChanged,
    onStageIsPercentChanged,
    onRemoveUserStage,
  } = useUser(onSaved, user);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Responsive colsXs={2}>
        <Input
          label={textForm.lastNameLabel}
          placeholder={textForm.lastNamePlaceholder}
          name="lastName"
          onChange={onChange}
          value={userForm.form.lastName}
          required
          errorMessage={userForm.errors.lastName}
        />
        <Input
          label={textForm.firstNameLabel}
          placeholder={textForm.firstNamePlaceholder}
          name="firstName"
          onChange={onChange}
          value={userForm.form.firstName}
          required
          errorMessage={userForm.errors.firstName}
        />
      </Responsive>
      <Input
        label={textForm.emailLabel}
        placeholder={textForm.emailPlaceholder}
        name="email"
        onChange={onChange}
        value={userForm.form.email}
        required
        errorMessage={userForm.errors.email}
      />
      <Typeahead
        label="Rol"
        selectedKey={userForm.form.roleId}
        options={rolesOptions || []}
        onChange={selectedKey => onDropdownChange(selectedKey as string)}
      />
      {!user?.userIdentifier && (
        <Responsive colsXs={2}>
          <InputPassword
            name="password"
            onChange={onChange}
            value={userForm.form.password}
            required
            errorMessage={userForm.errors.password}
          />
          <InputPassword
            label={textForm.confirmPasswordLabel}
            placeholder={textForm.confirmPasswordPlaceholder}
            name="confirmPassword"
            onChange={onChange}
            value={userForm.form.confirmPassword}
            required
            errorMessage={userForm.errors.confirmPassword}
          />
        </Responsive>
      )}

      {showStages && (
        <>
          {stagesFeature ? (
            <UserFormConfigureStages
              userStages={userForm.userStages || []}
              stagesOptions={stagesOptions || []}
              onStageChanged={onStageChanged}
              onStageValueChanged={onStageValueChanged}
              onStageIsPercentChanged={onStageIsPercentChanged}
              onRemoveUserStage={onRemoveUserStage}
            />
          ) : (
            <>
              <StagesFeature />
            </>
          )}
          <br />
        </>
      )}
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default UserForm;
