import { useState } from "react";
import { API_URLS, validations } from "../../../lib";
import { useAppStoreHooks } from "../../../toolkit";

const validate = validations({
  currentPassword: [
    ["required", "Parola este obligatorie!"],
    ["minLength", "Lungimea minima este de 6 de caractere", "6"],
  ],
  newPassword: [
    ["required", "Parola nouă este obligatorie!"],
    ["minLength", "Lungimea minima este de 6 de caractere", "6"],
  ],
  confirmPassword: [
    ["required", "Confirmarea parolei este obligatorie!"],
    ["minLength", "Lungimea minima este de 6 de caractere", "6"],
  ],
});

const useUserPassword = (userIdentifier: string, onClose: () => void) => {
  const [userForm, setUserForm] = useState<{
    form: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
    };
    errors: {
      currentPassword?: string;
      newPassword?: string;
      confirmPassword?: string;
    };
  }>({
    form: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    errors: {},
  });

  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onChangeHandler = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setUserForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(userForm.form);
    const hasError = Object.keys(errors).length;
    setUserForm((prevState) => ({
      ...prevState,
      errors,
      isSubmitting: !hasError,
    }));
    if (hasError) {
      return;
    }

    const { currentPassword, newPassword, confirmPassword } = userForm.form;
    if (newPassword === confirmPassword) {
      loadingStart();
      try {
        await postJson(API_URLS.identity.changePassword, {
          titanUserId: userIdentifier,
          currentPassword,
          newPassword,
          confirmPassword,
        });
        setUserForm((prevState) => ({
          ...prevState,
          form: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          },
          isSubmitting: false,
        }));
        onClose();
        notify("Parola a fost schimbată cu succes.", "success");
        loadingEnd();
      } catch (e: any) {
        notify(e.message, "error");
        setUserForm((prevState) => ({
          ...prevState,
          isSubmitting: false,
        }));
        loadingEnd();
      }
    } else {
      notify("Parola nouă și confirmare parolă nu coincid!", "error");
    }
  };

  return {
    userForm,
    onChangeHandler,
    onSubmit,
  };
};

export default useUserPassword;
