import { FC } from "react";
import { Button, ContainerFlex, Typography } from "../../../toolkit";
import { prettyDate } from "../../../lib";

type Props = {
  currentPeriodEnd: Date;
  onClose: () => void;
  onSave: () => void;
};

const CancelSubscription: FC<Props> = ({
  currentPeriodEnd,
  onClose,
  onSave,
}) => {
  return (
    <ContainerFlex type="column" spacing={2}>
      <Typography type="h5" variation="bold">
        Ai ales sa revoci subsciptia.
      </Typography>
      <Typography type="p">
        Aceasta va fii valabila pana in data de{" "}
        <strong>{prettyDate(currentPeriodEnd)}</strong>, dupa care serviciile nu
        voi mai fii accesibile.
      </Typography>
      <Typography type="p">
        Te rugam sa verifici termenii si conditiile pentru protectia datelor.
      </Typography>
      <ContainerFlex type="row" spacing={2} justify="end">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={onClose}
        >
          Renunta
        </Button>
        <Button size="medium" variant="danger" type="button" onClick={onSave}>
          Continua
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default CancelSubscription;
