import { FC, useState } from 'react';
import { TitanElement } from '../../../models';
import { AnimatedAppIcon, Button, ContainerFlex, TrashIcon, Typography, Portal, AddIcon } from '../../../toolkit';
import { useElementDelete } from '../../hooks';
import { setRefreshElements, useAppSelector } from '../../../store';
import { useCacheList } from '../../../lib';
import ElementList from '../ElementList/ElementList';
import ElementForm from '../ElementForm/ElementForm';
import { SearchInput } from '../../../components';
import ElementStagesForm from '../ElementForm/ElementStagesForm';

const filter = (searchTerm: string) =>
  (row: TitanElement) => {
    return row.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

const CRUD_TITLE = {
  'add': "Adauga element",
  'edit': 'Modifica element',
  'delete': 'Sterge element',
  'configure-stages': 'Configureaza etape',
};

const ElementsCRUD: FC = () => {
  const [elementsCRUD, setElementsCRUD] = useState<{
    isOpen: boolean;
    type?: 'add' | 'edit' | 'delete' | 'configure-stages';
    element?: TitanElement;
  }>({
    isOpen: false,
  });
  const elements = useAppSelector(state => state.cache.elements);
  const listProps = useCacheList(elements, filter, setRefreshElements);
  const { onDelete } = useElementDelete(() => {
    setElementsCRUD({ isOpen: false });
    listProps.reload();
  });

  return (
    <>
      {(listProps.total > 0 || listProps.isFiltered) && (
        <ContainerFlex type='row' align='center' justify='space-between' style={{ width: 1200, margin: '0 auto' }}>
          <Typography type='h5' variation="center">{listProps.total} Elemente</Typography>
          <ContainerFlex type='row' spacing={2}>
            <SearchInput placeholder='Cauta' onChange={listProps.setSearchTerm} />
            <div>
              <Button
              type='button'
                size='medium'
                variant='contained'
                onClick={() => setElementsCRUD({ isOpen: true, type: 'add' })}>
                <AddIcon />Adauga element
              </Button>
            </div>
          </ContainerFlex>
        </ContainerFlex>
      )}
      <ElementList
        {...listProps}
        onOpenModal={(type, element) => setElementsCRUD({ isOpen: true, type, element })}
      />
      <Portal
        isOpen={elementsCRUD.isOpen}
        onDismiss={() => setElementsCRUD({ isOpen: false, type: undefined })}
        title={CRUD_TITLE[elementsCRUD.type!]}
        overlay={true}
        width={560}
      >
        <>
          {elementsCRUD.type === 'delete' && (
            <ContainerFlex type='column' spacing={3}>
              <AnimatedAppIcon variation='danger'>
                <TrashIcon size={40} />
              </AnimatedAppIcon>
              <Typography type='p' variation='center'>
                Acest element va fi sters din baza de date.
              </Typography>
              <ContainerFlex spacing={1} type='row' justify='end'>
                <Button size='medium' variant='outlined' type="button" onClick={() => setElementsCRUD({ isOpen: false })}>Renunta</Button>
                <Button type='button' size='medium' variant='danger' onClick={() => onDelete(elementsCRUD.element?.elementId!)}>Sterge</Button>
              </ContainerFlex>
            </ContainerFlex>
          )}
          {['add', 'edit'].includes(elementsCRUD.type || '') && (
            <ElementForm
              element={elementsCRUD.element}
              onClose={() => setElementsCRUD({ isOpen: false })}
              onSaved={() => {
                setElementsCRUD({ isOpen: false });
                listProps.reload();
              }}
            />
          )}
          {elementsCRUD.type === 'configure-stages' && (
            <ElementStagesForm
              element={elementsCRUD.element}
              onClose={() => setElementsCRUD({ isOpen: false })}
              onSaved={() => {
                setElementsCRUD({ isOpen: false });
                listProps.reload(true);
              }}
            />
          )}
        </>
      </Portal>
    </>
  );
};

export default ElementsCRUD;