import { FC } from "react";
import { Button, Card, Tag, Typography } from "../../toolkit";
import HumanArcadeSmall from "../HumanArcade/HumanArcadeSmall/HumanArcadeSmall";
import { TitanCase } from "../../models";
import { toothIdMap } from "../../lib";
import styles from "./styles.module.css";

type Props = {
  titanCase: TitanCase;
  onViewDetails: () => void;
};

const ActiveCase: FC<Props> = ({ titanCase, onViewDetails }) => {
  const primaryMap: string[] = [];
  const secondaryMap: string[] = [];
  titanCase.caseElements.forEach((ce) => {
    ce.teeth?.forEach((t) => {
      primaryMap.push(t.code || toothIdMap[t.toothId]);
    });
  });
  return (
    <Card className={styles.caseCard}>
      <div className={styles.header}>
        <Typography type="h5">#{titanCase.internalReference}</Typography>
        {!titanCase.isFinished && <Tag type="default">In Lucru</Tag>}
        {titanCase.isFinished && <Tag type="success">Finalizat</Tag>}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyDetails}>
          <div className={styles.caseDetails}>
            <div>
              <Typography type="label" variation="secondary">
                Medic
              </Typography>
              <Typography type="p" variation="bold">
                {titanCase.titanClientName}
              </Typography>
            </div>
            <div>
              <Typography type="label" variation="secondary">
                Pacient
              </Typography>
              <Typography type="p" variation="bold">
                {titanCase.patientName}
              </Typography>
            </div>
            <div>
              <Typography type="label" variation="secondary">
                {titanCase.caseElements.length} Elemente
              </Typography>
              {titanCase.caseElements?.map((element) => (
                <Typography
                  key={element.caseElementId}
                  type="p"
                  variation="bold"
                >
                  {element.count}x {element.name}
                </Typography>
              ))}
            </div>
          </div>
          <div>
            <Button
              size="medium"
              type="button"
              variant="contained"
              onClick={onViewDetails}
            >
              Vezi Detaliile Cazului
            </Button>
          </div>
        </div>
        <div>
          <HumanArcadeSmall
            primaryMap={primaryMap}
            secondaryMap={secondaryMap}
          />
        </div>
      </div>
    </Card>
  );
};

export default ActiveCase;
