import { Content } from 'pdfmake/interfaces';
import { formatDateTime } from '..';
import {
  TitanCase,
  TitanCaseFitting,
  TitanCaseStage,
  TitanCompany,
} from '../../models';
import { formatDate } from '../dateFunctions';
import calculateHumanArcade from './calculateHumanArcade';
import { createReport } from './reportUtils';

const getGeneralDetails = (caseDetails: TitanCase) => {
  return {
    layout: 'noBorders',
    margin: [0, 20, 0, 0],
    table: {
      headerRows: 0,
      widths: ['*', '*'],

      body: [
        [
          {
            stack: [
              {
                text: 'Data caz',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: formatDate(caseDetails.caseDateTime),
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Medic',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.titanClientName,
                color: '#ffffff',
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 0],
          },
        ],
        [
          {
            stack: [
              {
                text: 'Pacient',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.patientName,
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Adresa livrare',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.deliveryAddress,
                color: '#ffffff',
                bold: true,
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 5],
          },
        ],
        [
          {
            stack: [
              {
                text: 'Culoare',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.color,
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {},
        ],
      ],
    },
  };
};

const getCaseFittings = (caseFittings: TitanCaseFitting[]) => {
  const fittings = caseFittings.map((cf) => [
    {
      text: formatDateTime(cf.fittingDateTime),
      margin: [5, 5, 0, 0],
    },
    {
      text: cf.notes,
      margin: [5, 5, 0, 0],
    },
  ]);
  return {
    layout: 'lightHorizontalLines', // optional
    margin: [0, 20, 0, 0],
    table: {
      headerRows: 1,
      widths: [105, '*'],

      body: [
        [
          {
            text: 'Proba in data',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: '',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...fittings,
      ],
    },
  };
};

const getCaseStages = (caseStages: TitanCaseStage[]) => {
  const stages = caseStages.map((cs) => [
    {
      text: cs.stageName,
      margin: [5, 5, 0, 0],
    },
    {
      text: cs.titanUserName,
      margin: [5, 5, 0, 0],
    },
  ]);
  return {
    layout: 'lightHorizontalLines', // optional
    margin: [0, 20, 0, 0],
    table: {
      headerRows: 1,
      widths: ['*', '*'],

      body: [
        [
          {
            text: 'Etapa',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Tehnician',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...stages,
      ],
    },
  };
};

const getElementDetails = (caseDetails: TitanCase) => {
  const elements = caseDetails.caseElements.map((ce) => [
    {
      text: ce.name,
      margin: [5, 5, 0, 0],
    },
    {
      text: ce.count,
      margin: [5, 5, 0, 0],
      alignment: 'center',
    },
  ]);
  return {
    layout: 'lightHorizontalLines', // optional
    margin: [0, 40, 0, 0],
    table: {
      headerRows: 1,
      widths: ['*', 'auto'],
      body: [
        [
          {
            text: 'Element',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Cantitate',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 5, 0],
          },
        ],
        ...elements,
      ],
    },
  };
};

const getNotes = (caseDetails: TitanCase) => {
  return {
    layout: 'noBorders', // optional
    margin: [0, 30, 0, 0],
    table: {
      headerRows: 1,
      widths: ['*', '*'],
      body: [
        [
          {
            stack: [
              {
                text: 'Notite Medic',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.medicNotes,
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Notite Tehnician',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: caseDetails.notes,
              },
            ],
            margin: [5, 5, 0, 0],
          },
        ],
      ],
    },
  };
};

const generateCaseReport = (
  caseDetails: TitanCase,
  company: TitanCompany,
  companyLogo: string,
  teethMap: string[],
  isStagingActive: boolean
) => {
  const content = [
    getGeneralDetails(caseDetails),
    {
      columns: [
        {
          svg: calculateHumanArcade(teethMap).trim(),
          margin: [0, 50, 0, 0],
        },
        {
          stack: [
            getElementDetails(caseDetails),
            isStagingActive ? getCaseStages(caseDetails.caseStages) : [],
            getCaseFittings(caseDetails.caseFittings),
          ],
        },
      ],
    },
    getNotes(caseDetails),
  ] as Content[];

  createReport(content, {
    header: {
      title: 'FISA COMANDA',
      subTitle: `Ref. Interna: ${caseDetails.internalReference}`,
    },
    branding: {
      company,
      logo: companyLogo,
    },
    name: `raport ${caseDetails.patientName} ${formatDate(
      caseDetails.caseDateTime
    )}`,
  });
};

export default generateCaseReport;
