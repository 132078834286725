import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  IColumn,
  TABLE_DEFAULTS,
  TableProps,
  formatDate,
  formatMoney,
  getSortingProps,
} from "../../../lib";
import { TitanCase } from "../../../models";
import { CasesIcon, Tag } from "../../../toolkit";
import { TableList } from "../../../components";
import { useAppSelector } from "../../../store";

interface IDocument extends TitanCase {
  key: string;
}

type Props = {
  clientId?: number;
  clinicId?: number;
} & TableProps;

const CaseList: FC<Props> = (listProps) => {
  const user = useAppSelector((store) => store.auth.user);
  const navigate = useNavigate();

  const columns: IColumn[] = [
    {
      key: "no",
      name: "#",
      fieldName: "index",
      width: 70,
    },
    {
      key: "internalReference",
      name: "Ref. interna",
      fieldName: "internalReference",
      width: 70,
    },
    {
      key: "titanClientName",
      name: "Nume client",
      fieldName: "titanClientName",
      show: !listProps.clientId,
      ...getSortingProps('orderByClient', listProps),
      onRender: (item: IDocument) => <span>{item.titanClientName}</span>,
    },
    {
      key: "patientName",
      name: "Nume pacient",
      fieldName: "patientName",
      ...getSortingProps('orderByPatient', listProps)
    },
    {
      key: "caseDateTime",
      name: "Data",
      width: 120,
      fieldName: "caseDateTime",
      ...getSortingProps('orderByDate', listProps),
      onRender: (item: IDocument) => {
        return <span>{formatDate(item.caseDateTime || "")}</span>;
      },
    },
    {
      key: "summary",
      name: "Sumar",
      fieldName: "summary",
      widthFr: 2,
    },
    {
      key: "price",
      name: "Pret",
      fieldName: "price",
      width: 70,
      onRender: (item: IDocument) => {
        return formatMoney(item.price || 0);
      },
      show: user?.isManagerOrAbove ?? false,
    },
    {
      key: "amountPayed",
      name: "Platit",
      fieldName: "paid",
      width: 90,
      onRender: (item: IDocument) => {
        return formatMoney(item.paid || 0);
      },
      show: user?.isManagerOrAbove ?? false,
    },
    {
      key: "isFinished",
      name: "Finalizat",
      fieldName: "isFinished",
      width: 100,
      onRender: (item: IDocument) =>
        item.isFinished ? (
          <Tag type="success">Finalizat</Tag>
        ) : (
          <Tag type="default">In lucru</Tag>
        ),
    },
  ];

  const items = listProps.results.map((item: TitanCase, index: number) => {
    return {
      key: item.titanCaseId,
      index: listProps.page * TABLE_DEFAULTS.pageSize + index + 1,
      ...item,
    };
  });

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      pageSize={TABLE_DEFAULTS.pageSize}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      onRowClick={(item: IDocument) => navigate(`/cases/${item.titanCaseId}`)}
      stateAssets={{
        emptySearchText:
          "Nu a fost gasit nici un caz" +
          (listProps.clientId ? " pentru acest medic" : listProps.clinicId ? " pentru acesta clinica" : ""),
        emptyIcon: <CasesIcon size={40} />,
        emptyTitle:
          "Momentan nu ai nici un caz adaugat" +
          (listProps.clientId ? " pentru acest medic" : listProps.clinicId ? " pentru acesta clinica" : ""),
        emptySubTitle: "Adauga primul caz apasand pe butonul de jos",
        emptyAction: () =>
          navigate(
            `/cases/${listProps.clientId ? listProps.clientId + "/" : ""}new`
          ),
        emptyActionText: "Adauga caz",
      }}
      isSubList={listProps.clientId !== undefined}
    />
  );
};

export default CaseList;
