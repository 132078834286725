import { FC } from 'react';

type Props = {
  size?: number;
};

const Star4Icon: FC<Props> = ({ size = 12 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 33 32" fill="none">
      <path d="M25.1667 10.666V3.99935M7.83335 27.9993V21.3327M21.8334 7.33268H28.5M4.50002 24.666H11.1667M9.16669 2.66602L8.12074 4.7579C7.76676 5.46588 7.58976 5.81986 7.35331 6.12661C7.1435 6.39881 6.89948 6.64283 6.62728 6.85264C6.32053 7.08909 5.96655 7.26609 5.25858 7.62007L3.16669 8.66602L5.25858 9.71196C5.96655 10.0659 6.32053 10.2429 6.62728 10.4794C6.89948 10.6892 7.1435 10.9332 7.35331 11.2054C7.58976 11.5122 7.76676 11.8662 8.12074 12.5741L9.16669 14.666L10.2126 12.5741C10.5666 11.8662 10.7436 11.5122 10.9801 11.2054C11.1899 10.9332 11.4339 10.6892 11.7061 10.4794C12.0128 10.2429 12.3668 10.0659 13.0748 9.71196L15.1667 8.66602L13.0748 7.62007C12.3668 7.26609 12.0128 7.08909 11.7061 6.85264C11.4339 6.64283 11.1899 6.39881 10.9801 6.12661C10.7436 5.81986 10.5666 5.46588 10.2126 4.75791L9.16669 2.66602ZM23.1667 15.9993L21.8985 18.5357C21.5445 19.2437 21.3675 19.5976 21.1311 19.9044C20.9213 20.1766 20.6773 20.4206 20.4051 20.6304C20.0983 20.8669 19.7443 21.0439 19.0364 21.3978L16.5 22.666L19.0364 23.9342C19.7443 24.2882 20.0983 24.4652 20.4051 24.7016C20.6773 24.9114 20.9213 25.1554 21.1311 25.4276C21.3675 25.7344 21.5445 26.0884 21.8985 26.7963L23.1667 29.3327L24.4349 26.7964C24.7888 26.0884 24.9658 25.7344 25.2023 25.4276C25.4121 25.1554 25.6561 24.9114 25.9283 24.7016C26.2351 24.4652 26.5891 24.2882 27.297 23.9342L29.8334 22.666L27.297 21.3978C26.589 21.0439 26.2351 20.8669 25.9283 20.6304C25.6561 20.4206 25.4121 20.1766 25.2023 19.9044C24.9658 19.5976 24.7888 19.2437 24.4349 18.5357L23.1667 15.9993Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Star4Icon;