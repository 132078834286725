import { ButtonHTMLAttributes, FC, ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

type Props = {
  variant?: 'outlined';
  children?: ReactNode;
  color?: '' | 'danger';
} & ButtonHTMLAttributes<HTMLButtonElement> & React.RefAttributes<HTMLButtonElement>;

const IconButton: FC<Props> = forwardRef<HTMLButtonElement, Props>(({ className, children, variant = '', color = '', ...rest }, ref) => {
  return (
    <button ref={ref} className={[className, styles.icon, styles[color], styles[variant]].join(' ')} {...rest}>
      {children}
    </button>
  );
});

export default IconButton;