import { FC, ReactNode, useId } from 'react';
import styles from './styles.module.css';

type Props = {
  value?: boolean;
  label?: string;
  onRenderLabel?: () => ReactNode;
  onChange: (_?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
};

const Checkbox: FC<Props> = ({ value, onChange, label, onRenderLabel }) => {
  const id = useId();
  return (
    <div className={styles.checkbox}>
      <input id={id} type="checkbox" checked={value} onChange={e => onChange(e, e?.target?.checked)} />
      <label htmlFor={id}>
        {onRenderLabel ? onRenderLabel() : label}
      </label>
    </div>
  );
};

export default Checkbox;