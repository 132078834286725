import { FC } from 'react';

type Props = {
  size?: number;
};

const ToothIcon: FC<Props> = ({ size = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M19.9968 6.87405L19.9952 6.75367C19.9451 5.44927 19.4534 4.52669 18.4453 3.84793C17.6136 3.30124 16.4395 3 15.1199 3H15.0961C14.0433 3 12.9412 3.20834 12.1515 3.55716L11.9995 3.62237L11.8514 3.55818C11.239 3.28627 10.3926 3.09187 9.52954 3.0244C9.51987 3.02358 9.50872 3.02297 9.49884 3.02338L8.04085 3.04224C8.02991 3.04245 8.01877 3.04306 8.00783 3.0445C7.03774 3.15175 6.18877 3.43002 5.55073 3.85101C4.54509 4.52731 4.05299 5.44968 4.00315 6.76413L4.00147 6.90152C4.00084 6.9571 4 7.01041 4 7.06332C4.01577 8.93144 4.4513 9.69694 4.95517 10.5828L5.03718 10.7278C5.6155 11.7022 6.17846 12.8264 6.16311 14.8973C6.1608 17.7268 6.69201 19.1926 7.13868 19.9261C7.6518 20.7584 7.90836 21 8.61811 21C9.37056 21 9.69883 20.3112 9.73521 19.8549C9.76865 19.5217 9.77538 19.1329 9.78211 18.7211C9.79788 17.758 9.81744 16.5592 10.1903 15.3306L10.2021 15.2939C10.351 14.8219 10.5356 14.2344 10.8843 13.7217C11.1026 13.4006 11.57 13.0553 12.0815 13.0553C12.4874 13.0553 12.8339 13.2765 13.1119 13.7135C13.4446 14.2366 13.6436 14.7997 13.8089 15.3278C14.1821 16.5518 14.3441 17.9714 14.2404 19.1245C14.1796 19.8092 14.3047 20.3231 14.6143 20.6522C14.9253 20.9832 15.3089 21 15.3827 21H15.3834C16.0319 21 16.3354 20.7794 16.8611 19.9242C17.3076 19.1936 17.8386 17.7278 17.8371 14.8955C17.8224 12.8262 18.3856 11.7012 18.967 10.7206L19.044 10.5851C19.5479 9.69899 19.9823 8.93349 20 7.06045C19.9994 6.99954 19.9985 6.93803 19.9968 6.87405Z" strokeWidth="2" />
    </svg>
  );
};

export default ToothIcon;