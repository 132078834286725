import { FC } from 'react';

const Tooth43: FC = () => {
  return (
    <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M27.349 2.39898C21.6068 4.20298 15.664 8.21199 14.0579 11.379C12.0903 15.188 15.0216 22.284 19.4786 24.57C22.7713 26.294 30.802 25.331 33.171 22.966C34.858 21.282 35.54 18.155 35.58 11.861C35.58 6.72898 35.5 6.08698 34.657 4.52398C34.054 3.36098 33.251 2.59899 32.368 2.19799C30.682 1.51699 30.119 1.51698 27.349 2.39898Z" />
      <path className='interior' d="M26.33 20.328C26.001 20.123 25.445 19.823 24.649 19.43H24.647C22.8945 18.574 20.687 17.003 18.9484 15.385C18.0676 14.546 17.4116 13.971 17.0366 13.707C16.9448 13.642 16.8438 13.577 16.747 13.535C16.704 13.517 16.6169 13.482 16.5095 13.478C16.3888 13.473 16.1486 13.512 16.0189 13.755C15.9229 13.934 15.9623 14.106 15.974 14.153C15.9913 14.224 16.019 14.285 16.0412 14.33C16.0873 14.423 16.1535 14.526 16.2287 14.633L16.2303 14.635C16.9476 15.647 18.0777 16.781 19.3065 17.794C20.5365 18.808 21.8967 19.726 23.0899 20.291L23.0934 20.292C23.9428 20.686 24.643 20.986 25.167 21.19C25.428 21.292 25.651 21.372 25.828 21.428C25.987 21.478 26.161 21.526 26.301 21.531C26.339 21.533 26.406 21.533 26.482 21.513C26.549 21.496 26.716 21.44 26.816 21.258C26.922 21.064 26.871 20.881 26.837 20.801C26.802 20.719 26.755 20.662 26.729 20.633C26.633 20.525 26.477 20.42 26.33 20.328Z" />
      <path className='text' d="M3.78519 39.52V37.9H-0.00680542V36.556L3.32919 31.144H5.28519V36.676H6.37719V37.9H5.28519V39.52H3.78519ZM1.28919 36.676H3.78519V32.68H3.68919L1.28919 36.676Z"  />
      <path className='text' d="M9.9485 34.528C10.4285 34.528 10.7805 34.432 11.0045 34.24C11.2365 34.04 11.3525 33.788 11.3525 33.484V33.4C11.3525 33.056 11.2405 32.788 11.0165 32.596C10.8005 32.396 10.5005 32.296 10.1165 32.296C9.7485 32.296 9.4205 32.384 9.1325 32.56C8.8525 32.736 8.6085 32.984 8.4005 33.304L7.3925 32.392C7.5365 32.2 7.6925 32.02 7.8605 31.852C8.0365 31.676 8.2365 31.528 8.4605 31.408C8.6845 31.28 8.9365 31.18 9.2165 31.108C9.4965 31.036 9.8125 31 10.1645 31C10.5885 31 10.9725 31.052 11.3165 31.156C11.6685 31.252 11.9685 31.396 12.2165 31.588C12.4725 31.78 12.6685 32.012 12.8045 32.284C12.9405 32.548 13.0085 32.848 13.0085 33.184C13.0085 33.456 12.9645 33.7 12.8765 33.916C12.7965 34.132 12.6805 34.32 12.5285 34.48C12.3845 34.64 12.2125 34.772 12.0125 34.876C11.8205 34.972 11.6165 35.044 11.4005 35.092V35.164C11.6405 35.212 11.8685 35.288 12.0845 35.392C12.3005 35.496 12.4845 35.636 12.6365 35.812C12.7965 35.98 12.9205 36.18 13.0085 36.412C13.1045 36.644 13.1525 36.908 13.1525 37.204C13.1525 37.572 13.0765 37.908 12.9245 38.212C12.7805 38.516 12.5725 38.776 12.3005 38.992C12.0285 39.208 11.7045 39.376 11.3285 39.496C10.9525 39.608 10.5325 39.664 10.0685 39.664C9.6605 39.664 9.3005 39.62 8.9885 39.532C8.6765 39.452 8.4005 39.34 8.1605 39.196C7.9285 39.052 7.7245 38.888 7.5485 38.704C7.3725 38.512 7.2205 38.312 7.0925 38.104L8.2565 37.216C8.3525 37.384 8.4565 37.54 8.5685 37.684C8.6805 37.82 8.8085 37.94 8.9525 38.044C9.0965 38.148 9.2605 38.228 9.4445 38.284C9.6285 38.34 9.8365 38.368 10.0685 38.368C10.5325 38.368 10.8885 38.256 11.1365 38.032C11.3845 37.808 11.5085 37.5 11.5085 37.108V37.024C11.5085 36.64 11.3725 36.348 11.1005 36.148C10.8365 35.948 10.4525 35.848 9.9485 35.848H9.1205V34.528H9.9485Z"  />
    </svg>
  );
};

export default Tooth43;