import { FC, useMemo, useState } from 'react';
import { AnimatedAppIcon, Card, ContainerFlex, Delimiter, ToothIcon, Typeahead, Typography } from '../../../../toolkit';
import { Responsive } from '../../../../layout';
import CaseToothCard from './CaseToothCard/CaseToothCard';
import { HumanArcade } from '../../../../components';
import { formatMoney, toothCodeMap, toothIdMap } from '../../../../lib';
import { TitanCaseElement, TitanCaseToothElement } from '../../../../models';
import { useAppSelector } from '../../../../store';
import CaseHumanArcadeContainer from './CaseHumanArcadeContainer';

type Props = {
  caseElements: TitanCaseElement[];
  onElementsChanged: (caseElements: TitanCaseElement[]) => void;
};

const getCaseElement = (caseElements: TitanCaseElement[], elementId: number) => {
  const index = caseElements.findIndex(e => e.elementId === elementId);
  return { index, caseElement: caseElements[index] };
};

const CaseElementsEdit: FC<Props> = ({ caseElements, onElementsChanged }) => {
  const [selectedCaseElementId, setSelectedCaseElementId] = useState<number>(caseElements?.[0]?.elementId);
  const user = useAppSelector(state => state.auth.user);
  const elements = useAppSelector(state => state.cache.elements);
  const elementOptions = useMemo(() => elements?.data?.map(e => ({ key: e.elementId!, name: e.name })), [elements]);

  const primaryMap: string[] = [];
  const secondaryMap: string[] = [];
  caseElements.forEach(ce => {
    if (!selectedCaseElementId || ce.elementId === selectedCaseElementId) {
      ce.teeth?.forEach(t => {
        primaryMap.push(t.code || toothIdMap[t.toothId]);
      });
    } else {
      ce.teeth?.forEach(t => {
        secondaryMap.push(t.code || toothIdMap[t.toothId]);
      });
    }
  });

  const onAddElement = (elementId: number) => {
    const element = elements.data?.find(e => e.elementId === elementId);
    onElementsChanged([
      {
        elementId,
        name: element?.name,
        price: element?.price,
        elementStages: element?.elementStages?.map((elementStage) => ({
          ...elementStage,
          elementId
        })),
        count: 1,
      } as TitanCaseElement,
      ...caseElements,
    ]);
  };
  const onElementChange = (elementId: number, element: Partial<TitanCaseElement>) => {
    const _caseElements = [...caseElements];
    const { index, caseElement } = getCaseElement(_caseElements, elementId);
    _caseElements[index] = {
      ...caseElement,
      ...element,
    };
    onElementsChanged(_caseElements);
  };

  const onElementRemove = (elementId: number) => {
    const _caseElements = caseElements.filter(ce => ce.elementId !== elementId);
    onElementsChanged(_caseElements);
  };

  const onElementTeethChange = (elementId: number, tooth: TitanCaseToothElement) => {
    const _caseElements = [...caseElements];
    const { index, caseElement } = getCaseElement(_caseElements, elementId);
    let teeth = caseElement.teeth || [];
    const toothIndex = teeth.findIndex(e => e.code === tooth.code);
    if (toothIndex > -1) {
      teeth[toothIndex] = {
        ...teeth[toothIndex],
        ...tooth
      };
    } else {
      teeth = [...teeth, tooth];
    }

    _caseElements[index] = {
      ...caseElement,
      count: caseElement.countShouldBeOne ? 1 : teeth.length,
      teeth
    };
    onElementsChanged(_caseElements);
  };

  const onElementTeethRemove = (elementId: number, code: string) => {
    const _caseElements = [...caseElements];
    const { index, caseElement } = getCaseElement(_caseElements, elementId);
    let teeth = (caseElement.teeth || []).filter(t => t.code !== code);
    _caseElements[index] = {
      ...caseElement,
      count: caseElement.countShouldBeOne ? 1 : teeth.length,
      teeth
    };
    onElementsChanged(_caseElements);
  };

  return (
    <Card>
      <Responsive colsFrMd="1-1">
        <ContainerFlex type='column' grow spacing={2}>
          <Typeahead
            label="Alege Element"
            options={elementOptions || []}
            onChange={selectedKey => {
              const elementId = selectedKey as number;
              if (!caseElements.some(ce => ce.elementId === elementId)) {
                onAddElement(elementId);
              }
              setSelectedCaseElementId(elementId);
            }}
          />

          {caseElements.length ? caseElements.map((element) => (
            <CaseToothCard
              key={element.elementId}
              selectedCaseElementId={selectedCaseElementId}
              setSelectedCaseElementId={setSelectedCaseElementId}
              element={element}
              onElementChange={onElementChange}
              onElementRemove={onElementRemove}
              onElementTeethChange={onElementTeethChange}
              onElementTeethRemove={onElementTeethRemove}
            />
          )) : (
            <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
              <AnimatedAppIcon>
                <ToothIcon size={40} />
              </AnimatedAppIcon>
              <Typography type='p' variation="center">
                Selecteaza culoarea, cauta elementul necesar lucrarii in
                <strong> meniul "Element"</strong> apoi alege <strong>elementul</strong> dorit din partea dreapta
              </Typography>
            </ContainerFlex>
          )}

        </ContainerFlex>
        <ContainerFlex type='column' grow spacing={3}>
          <CaseHumanArcadeContainer>
            <HumanArcade primaryMap={primaryMap} secondaryMap={secondaryMap} onSelect={(tooth) => {
              if (selectedCaseElementId) {
                const { caseElement } = getCaseElement(caseElements, selectedCaseElementId);
                const toothIndex = (caseElement.teeth || []).findIndex(e => e.code === tooth);
                if (toothIndex > -1) {
                  onElementTeethRemove(selectedCaseElementId, tooth);
                } else {
                  onElementTeethChange(
                    selectedCaseElementId,
                    {
                      toothType: '',
                      code: tooth,
                      toothId: toothCodeMap[tooth]
                    }
                  );
                }
              }
            }} />
          </CaseHumanArcadeContainer>
          <Card variant='secondary' type="column" spacing={1}>
            <Typography type='p' variation="bold">
              Sumar Elemente
            </Typography>
            {caseElements.map(ce => (
              <ContainerFlex key={ce.elementId} type='row' align='center' justify='space-between'>
                <Typography type='p'>
                  <strong>{ce.count}x</strong> {ce.name}
                </Typography>
                {user?.isAdmin && (
                  <Typography type='p'>
                    {formatMoney(ce.count * ce.price)} LEI
                  </Typography>
                )}
              </ContainerFlex>
            ))}

            <Delimiter />
            <ContainerFlex type='row' align='center' justify='space-between'>
              <Typography type='p' variation="bold">
                Total: {caseElements.reduce((acc, ce) => acc + ce.count || 0, 0) || 0} elemente
              </Typography>
              {user?.isAdmin && (
                <Typography type='p' variation="bold">
                  {formatMoney(caseElements.reduce((acc, ce) => acc + (ce.count * ce.price) || 0, 0) || 0)} LEI
                </Typography>
              )}
            </ContainerFlex>
          </Card>
        </ContainerFlex>
      </Responsive>
    </Card>
  );
};

export default CaseElementsEdit;