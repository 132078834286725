import { FC } from 'react';

const Tooth11: FC = () => {
  return (
    <svg width="32" height="51" viewBox="0 0 32 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M13.946 18.6843C10.573 19.0452 6.076 21.2502 3.144 23.9764L0.815002 26.1414V28.8676C0.815002 31.3533 1.016 32.075 2.703 35.603C4.991 40.2938 7.361 43.7417 10.332 46.508C13.263 49.3144 14.789 50.0361 17.479 50.0361C21.655 49.996 24.145 47.831 26.675 42.0979C28.682 37.5274 30.73 29.8298 30.73 26.863C30.73 25.7405 30.529 24.1368 30.288 23.2949C29.927 21.9719 29.566 21.611 27.759 20.8092C23.422 18.8848 18.644 18.1631 13.946 18.6843ZM23.783 20.0875L25.871 20.8092L21.173 20.8894C15.873 20.9295 12.741 21.4106 8.00301 22.8539C3.90701 24.0566 3.747 24.0566 5.433 22.8138C7.28 21.4507 9.609 20.3682 12.259 19.6064C15.07 18.8447 20.852 19.0852 23.783 20.0875Z" />
      <path className='interior' d="M13.556 25.3223C13.63 25.3126 13.705 25.3036 13.78 25.2953C14.084 25.2619 14.404 25.2399 14.756 25.2279C14.34 25.2541 13.941 25.2855 13.556 25.3223ZM5.97797 28.5341C6.26497 28.4582 6.60798 28.3239 6.95198 28.1523L6.95698 28.1499C9.82598 26.6788 12.665 26.1601 18.174 26.1202C19.785 26.1202 20.983 26.0825 21.773 26.0209C22.163 25.9905 22.473 25.9527 22.687 25.9055C22.785 25.8839 22.909 25.851 23.015 25.7923C23.063 25.7654 23.168 25.7008 23.242 25.5755C23.339 25.4095 23.338 25.2098 23.247 25.0463C23.178 24.9226 23.078 24.8559 23.031 24.8276C22.975 24.7934 22.917 24.769 22.869 24.7513C22.772 24.7156 22.656 24.6863 22.532 24.6607C22.281 24.6088 21.934 24.5598 21.5 24.514C20.629 24.4222 19.362 24.339 17.711 24.2787C15.904 24.1985 14.716 24.1865 13.671 24.3013C12.616 24.4172 11.724 24.6602 10.506 25.0656C8.92597 25.5915 7.49997 26.1602 6.50297 26.6375C6.00897 26.874 5.59897 27.0979 5.32597 27.2917C5.19797 27.3828 5.05597 27.4972 4.96097 27.6318C4.91397 27.6989 4.84497 27.8178 4.83597 27.975C4.82597 28.1574 4.90098 28.3197 5.02198 28.4314C5.12598 28.5293 5.24397 28.5687 5.32197 28.5861C5.40697 28.6049 5.48897 28.6074 5.55597 28.6049C5.69097 28.5998 5.83697 28.5714 5.97797 28.5341Z" />
      <path className='text' d="M7.70099 8.52004V7.23604H10.005V1.28404H9.897L8.07299 3.44404L7.08899 2.61604L9.16499 0.144043H11.553V7.23604H13.473V8.52004H7.70099Z" />
      <path className='text' d="M14.896 8.52004V7.23604H17.2V1.28404H17.092L15.268 3.44404L14.284 2.61604L16.36 0.144043H18.748V7.23604H20.668V8.52004H14.896Z" />
    </svg>

  );
};

export default Tooth11;