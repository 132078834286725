import { FC } from 'react';
import styles from './styles.module.css';

type Props = {
  type?: 'horizontal' | 'vertical';
};

const Delimiter: FC<Props> = ({ type = 'horizontal' }) => {
  return (
    <div className={styles[type]} />
  );
};

export default Delimiter;