import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

type Props = {
  type: 'default' | 'info' | 'success' | 'warning' | 'error';
  children: ReactNode;
}

const Tag: FC<Props> = ({ children, type }) => {
  return (
    <span className={[styles.tag, styles[type]].join(' ')}>
      {children}
    </span>
  );
};

export default Tag;