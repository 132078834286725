export const APP_LOCAL_STORAGE_LEGACY_KEYS = {
  JWT: 'titan-jwt',
  USER: 'titan-user',
  VITA_COLOR: "titan-vita-color",
  CALENDAR_VIEW: 'titan-calendar-view',
  COLLAPSED_MENU: 'titan-menu',
  COLLAPSED_CASE_FIELDS: 'titan-case-fields',
  COLLAPSED_CLIENT_DETAILS: 'titan-client-details',
  FEATURES: 'titan-features',
  DEFAULT: 'titan-setings'
}

export const APP_LOCAL_STORAGE_KEYS = {
  SETTINGS: 'titan-setings',
  AUTH: 'titan-auth',
  NOTIF: 'titan-notif',
}