import { FC } from 'react';

const Tooth31: FC = () => {
  return (
    <svg width="22" height="46" viewBox="0 0 22 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M8.363 1.462C6.115 2.384 3.50499 6.87399 2.09899 12.287C0.512988 18.478 0.835989 20.823 3.99999 22.515C4.35999 22.707 4.75598 22.891 5.19098 23.071C8.72498 24.515 12.74 24.475 16.113 22.951C16.817 22.626 17.397 22.318 17.872 22C19.562 20.866 19.906 19.598 19.687 16.937C19.325 12.287 14.025 2.82501 11.134 1.62201C9.60799 0.981008 9.488 0.981005 8.363 1.462Z" />
      <path className='fundal' d="M3.99999 22.515C0.835989 20.823 0.512988 18.478 2.09899 12.287C3.50499 6.87399 6.115 2.384 8.363 1.462C9.488 0.981005 9.60799 0.981008 11.134 1.62201C14.025 2.82501 19.325 12.287 19.687 16.937C19.906 19.598 19.562 20.866 17.872 22M3.99999 22.515C4.35999 22.707 4.75598 22.891 5.19098 23.071C8.72498 24.515 12.74 24.475 16.113 22.951C16.817 22.626 17.397 22.318 17.872 22M3.99999 22.515C5.91399 22.51 11.369 22.4 17.872 22Z" />
      <path className='interior' d="M5.50197 19.101C6.50597 19.405 8.20696 19.512 9.75996 19.477C10.547 19.459 11.317 19.404 11.968 19.314C12.598 19.226 13.182 19.098 13.548 18.902L13.559 18.896L13.569 18.89C13.619 18.86 13.712 18.798 13.782 18.691C13.871 18.555 13.913 18.354 13.818 18.158C13.745 18.008 13.624 17.932 13.571 17.902C13.505 17.866 13.439 17.841 13.384 17.825C13.178 17.762 12.86 17.722 12.484 17.692C11.705 17.63 10.484 17.6 8.89596 17.6C7.70196 17.6 6.79097 17.602 6.12597 17.614C5.47397 17.625 5.01998 17.645 4.76398 17.687C4.69798 17.698 4.62096 17.714 4.54696 17.741C4.50996 17.755 4.45696 17.778 4.40196 17.816C4.34996 17.852 4.26397 17.925 4.21197 18.048C4.07597 18.366 4.28896 18.599 4.34596 18.652C4.41896 18.72 4.50197 18.763 4.54997 18.787C4.74497 18.881 5.10097 18.986 5.50197 19.101ZM4.97996 17.884C4.97796 17.882 4.97597 17.882 4.97597 17.882C4.97597 17.882 4.97696 17.883 4.97996 17.884Z" />
      <path className='text' d="M10.753 40.528C11.233 40.528 11.585 40.432 11.809 40.24C12.041 40.04 12.157 39.788 12.157 39.484V39.4C12.157 39.056 12.045 38.788 11.821 38.596C11.605 38.396 11.305 38.296 10.921 38.296C10.553 38.296 10.225 38.384 9.93697 38.56C9.65697 38.736 9.41297 38.984 9.20497 39.304L8.19698 38.392C8.34098 38.2 8.49696 38.02 8.66496 37.852C8.84096 37.676 9.04097 37.528 9.26497 37.408C9.48897 37.28 9.74097 37.18 10.021 37.108C10.301 37.036 10.617 37 10.969 37C11.393 37 11.777 37.052 12.121 37.156C12.473 37.252 12.773 37.396 13.021 37.588C13.277 37.78 13.473 38.012 13.609 38.284C13.745 38.548 13.813 38.848 13.813 39.184C13.813 39.456 13.769 39.7 13.681 39.916C13.601 40.132 13.485 40.32 13.333 40.48C13.189 40.64 13.017 40.772 12.817 40.876C12.625 40.972 12.421 41.044 12.205 41.092V41.164C12.445 41.212 12.673 41.288 12.889 41.392C13.105 41.496 13.289 41.636 13.441 41.812C13.601 41.98 13.725 42.18 13.813 42.412C13.909 42.644 13.957 42.908 13.957 43.204C13.957 43.572 13.881 43.908 13.729 44.212C13.585 44.516 13.377 44.776 13.105 44.992C12.833 45.208 12.509 45.376 12.133 45.496C11.757 45.608 11.337 45.664 10.873 45.664C10.465 45.664 10.105 45.62 9.79297 45.532C9.48097 45.452 9.20497 45.34 8.96497 45.196C8.73297 45.052 8.52897 44.888 8.35297 44.704C8.17697 44.512 8.02497 44.312 7.89697 44.104L9.06097 43.216C9.15697 43.384 9.26097 43.54 9.37297 43.684C9.48497 43.82 9.61297 43.94 9.75697 44.044C9.90097 44.148 10.065 44.228 10.249 44.284C10.433 44.34 10.641 44.368 10.873 44.368C11.337 44.368 11.693 44.256 11.941 44.032C12.189 43.808 12.313 43.5 12.313 43.108V43.024C12.313 42.64 12.177 42.348 11.905 42.148C11.641 41.948 11.257 41.848 10.753 41.848H9.92497V40.528H10.753Z"  />
      <path className='text' d="M15.897 45.52V44.236H18.201V38.284H18.093L16.269 40.444L15.285 39.616L17.361 37.144H19.749V44.236H21.669V45.52H15.897Z"  />
    </svg>
  );
};

export default Tooth31;