import { FC, useMemo } from 'react';
import { TitanDiscountElement } from '../../../models';
import { AnimatedAppIcon, Button, ContainerFlex, Input, TrashIcon, Typography, Typeahead } from '../../../toolkit';
import { textForm } from '../../../translations';
import { useDiscounts } from '../../hooks';
import { useAppSelector } from '../../../store';

type Props = {
  clientId: number;
  isDelete: boolean;
  discount?: TitanDiscountElement;
  onClose: () => void;
  onRefresh: () => void;
};

const DiscountCRUD: FC<Props> = ({ clientId, isDelete, discount, onClose, onRefresh }) => {
  const elements = useAppSelector(state => state.cache.elements.data);
  const elementsOptions = useMemo(() => elements?.map(e => ({ key: e.elementId, name: e.name })), [elements]);
  const { discountForm, onChange, onElementChange, onSubmit, onDelete } = useDiscounts(clientId, onRefresh, discount);

  return (
    <>
      {isDelete && (
        <ContainerFlex type='column' spacing={3}>
          <AnimatedAppIcon variation='danger'>
            <TrashIcon size={40} />
          </AnimatedAppIcon>
          <Typography type='p' variation='center'>
            Acest discount pe element va fi sters din baza de date.
          </Typography>
          <ContainerFlex spacing={1} type='row' justify='end'>
            <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
            <Button type='button' size='medium' variant='danger' onClick={() => onDelete(discount?.elementDiscountId!)}>Sterge</Button>
          </ContainerFlex>
        </ContainerFlex>
      )}
      {!isDelete && (
        <div>
          <form noValidate onSubmit={onSubmit}>
            <Typeahead
              selectedKey={discountForm.form.elementId}
              options={elementsOptions || []}
              onChange={selectedKey => onElementChange(selectedKey as number)}
            />

            <Input
              label={textForm.amountLabel}
              placeholder={textForm.amountPlaceholder}
              name="discount"
              onChange={onChange}
              value={discountForm.form.discount ? String(discountForm.form.discount) : ''}
              required
              errorMessage={discountForm.errors.discount}
            />

            <ContainerFlex spacing={1} type='row' justify='end'>
              <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
              <Button size='medium' variant='contained' type="submit">Salveaza</Button>
            </ContainerFlex>
          </form>
        </div>
      )}
    </>
  );
};

export default DiscountCRUD;