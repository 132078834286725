import { FC, HTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

export type Props = {
  children: ReactNode;
  type?: 'column' | 'row' | '';
  spacing?: number;
  grow?: boolean;
  align?: 'center' | 'end' | 'start' | '';
  justify?: 'center' | 'end' | 'start' | 'space-between' | '';
  container?: boolean;
} & HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>;

const ContainerFlex: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ children, className, type = '', spacing, grow = false, align = '', justify = '', container, ...rest }, ref) => {
    const spacingClass = spacing ? `spacing-${spacing}` : '';
    const justifyClass = justify !== '' ? `justify-${justify}` : '';
    const alignClass = align !== '' ? `align-${align}` : '';

    return (
      <div
        ref={ref}
        className={[
          className,
          styles[type],
          styles[align],
          styles[spacingClass],
          grow !== false ? styles.grow : '',
          container ? styles.container : '',
          styles[justifyClass],
          styles[alignClass]
        ].join(' ')} {...rest}>
        {children}
      </div>
    );
  });

export default ContainerFlex;