import { useCallback, useEffect, useState } from 'react';
import { TitanClient } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { API_URLS } from '../../lib';

const useClientDetails = (clientId?: number) => {
  const [client, setClient] = useState<TitanClient>();
  const { getJson, loadingStart, loadingEnd, notify } = useAppStoreHooks();

  const loadClient = useCallback(
    async (clientId: number) => {
      loadingStart();
      try {
        const clientDetails = await getJson<TitanClient>(
          `${API_URLS.titan.clients.details}/${clientId}`
        );
        if (clientDetails) {
          setClient(clientDetails);
        } else {
          notify(textMessages.genericError, 'error');
        }
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, 'error');
      }
    },
    [getJson, loadingStart, loadingEnd, notify, setClient]
  );

  useEffect(() => {
    if (clientId) {
      loadClient(clientId);
    }
  }, [loadClient, clientId]);

  return {
    client,
    reloadClient: loadClient,
  };
};

export default useClientDetails;
