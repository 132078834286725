import { FC } from 'react';
import { IColumn, TableProps, formatMoney } from '../../../lib';
import { TitanElement } from '../../../models';
import { Button, ContainerFlex, EditNoteIcon, IconButton, ToothIcon, TrashIcon } from '../../../toolkit';
import { TableList } from '../../../components';
import { useAppSelector } from '../../../store';

interface IDocument extends TitanElement {
  key: string;
}

type Props = {
  onOpenModal: (type: 'add' | 'edit' | 'delete' | 'configure-stages', element?: TitanElement) => void;
} & TableProps;

const ElementList: FC<Props> = (listProps) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages ?? false);

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Nume',
      fieldName: 'name',
    },
    {
      key: 'description',
      name: 'Descriere',
      fieldName: 'description',
    },
    {
      key: 'price',
      name: 'Pret',
      fieldName: 'price',
      onRender: (item: IDocument) => {
        return formatMoney(item.price);
      }
    },
    {
      key: 'stages',
      name: 'Etape',
      fieldName: 'elementStages',
      show: stagesFeature,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1} align='center'>
            {item.elementStages?.length || 0}
            <Button type='button' variant='text' size='small' onClick={() => listProps.onOpenModal('configure-stages', item)}>
              Configureaza
            </Button>
          </ContainerFlex>
        );
      }
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      width: 110,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1}>
            <IconButton variant='outlined' onClick={() => listProps.onOpenModal('edit', item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton variant='outlined' color='danger' onClick={() => listProps.onOpenModal('delete', item)}>
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    }
  ];

  const items = listProps.results.map((item: TitanElement, index: number) => {
    return {
      key: item.elementId,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });


  return (
    <TableList
      key={String(stagesFeature)}
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici un element',
        emptyIcon: <ToothIcon size={40} />,
        emptyTitle: 'Momentan nu ai nici un element adaugat',
        emptySubTitle: 'Adauga primul tau element apasand pe butonul de jos',
        emptyAction: () => listProps.onOpenModal('add'),
        emptyActionText: 'Adauga element'
      }}
      isSubList={true}
    />
  );
};

export default ElementList;