import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedListState } from '../lib';

type BasePayload = {
  pageKey: string;
};

type UrlQueryParamsPayload = {
  queryParams?: { [key: string]: any; },
  sortingParams?: { [key: string]: any; };
} & BasePayload;

type PagePayload = {
  page: number;
} & BasePayload;

type LoadingPayload = {
  loading: boolean;
} & BasePayload;

type SetPaginatedListPayload = {
  count: number;
  results: Array<any>;
  loaded: boolean;
} & UrlQueryParamsPayload;

type AddPaginatedItemPayload = {
  item: any;
} & BasePayload;

type RemovePaginatedItemPayload = {
  id: number;
  fieldId: string;
} & BasePayload;

type EditPaginatedItemPayload = {
  savedItem: any;
  fieldId: string;
} & BasePayload;

type SetPaginatedListOffsetPayload = {
  offset: number;
} & BasePayload;

export const initialListState: PaginatedListState<any> = {
  loading: false,
  loaded: false,
  page: 0,
  results: []
};

const initialState: {
  value: { [key: string]: PaginatedListState<any>; };
} = {
  value: {}
};

export const paginatedListSlice = createSlice({
  name: 'paginatedList',
  initialState,
  reducers: {
    reloadList: (state, action: PayloadAction<UrlQueryParamsPayload>) => {
      const { pageKey = '', ...rest } = action.payload || {};
      let newList: PaginatedListState<any> = initialListState;

      newList = { ...initialListState, ...rest };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    setPage: (state, action: PayloadAction<PagePayload>) => {
      const { pageKey = '', ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      newList = { ...listState, page: rest.page };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },

    toggleLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { pageKey = '', ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      newList = { ...listState, loading: rest.loading };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    setPaginatedList: (state, action: PayloadAction<SetPaginatedListPayload>) => {
      const { pageKey = '', count, results, loaded, sortingParams } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;
      if(!(results instanceof Array)) {
        console.trace(results)
      }
      const results12 = [...(results || [])];
      newList = {
        ...listState,
        count: count,
        results: [
          ...listState.results,
          ...results12
        ],
        loading: false,
        loaded,
        sortingParams
      };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    addPaginatedItem: (state, action: PayloadAction<AddPaginatedItemPayload>) => {
      const { pageKey = '', item } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      newList = {
        ...listState,
        count: (listState.count || 0) + 1,
        results: [
          ...listState.results,
          item
        ]
      };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    removePaginatedItem: (state, action: PayloadAction<RemovePaginatedItemPayload>) => {
      const { pageKey = '', id, fieldId } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      const itemIndex = listState.results.findIndex(item => (item as { [key: string]: any; })[fieldId] === id);
      newList = {
        ...listState,
        count: (listState.count || 0) - 1,
        results: [
          ...listState.results.filter((_, index) => index !== itemIndex)
        ]
      };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    editPaginatedItem: (state, action: PayloadAction<EditPaginatedItemPayload>) => {
      const { pageKey = '', savedItem, fieldId } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      const results = [...listState.results];
      const index = results.findIndex(item => (item as { [key: string]: any; })[fieldId] === (savedItem as { [key: string]: any; })[fieldId]);
      if (index > -1) {
        results[index] = { ...savedItem };
      } else {
        results.push({ ...savedItem });
      }
      newList = {
        ...listState,
        results
      };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    setPaginatedListOffset: (state, action: PayloadAction<SetPaginatedListOffsetPayload>) => {
      const { pageKey = '', offset } = action.payload || {};
      const listState = state.value[pageKey] || initialListState;
      let newList: PaginatedListState<any> = initialListState;

      newList = {
        ...listState,
        defaultOffset: Number(offset)
      };

      state.value = {
        ...state.value,
        [pageKey]: newList
      };
    },
    // defaultAction: (state, action: PayloadAction<UrlQueryParamsPayload>) => {
    //   const { pageKey = '', ...rest } = action.payload || {};
    //   // const listState = state.value[pageKey] || initialListState;
    //   let newList: PaginatedListState<any> = initialListState;


    //   state.value = {
    //     ...state.value,
    //     [pageKey]: newList
    //   };
    // },
  }
});

export const {
  reloadList,
  setPage,
  toggleLoading,
  setPaginatedList,
  addPaginatedItem,
  removePaginatedItem,
  editPaginatedItem,
  setPaginatedListOffset,
} = paginatedListSlice.actions;

export default paginatedListSlice.reducer;