import { FC, useState } from "react";
import Input, { Props } from "./Input";
import { textForm } from "../../translations";
import IconButton from "../IconButton/IconButton";
import { EyeIcon, EyeOffIcon } from "../assets";

const InputPassword: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      label={textForm.passwordLabel}
      placeholder={textForm.passwordPlaceholder}
      {...props}
      type={showPassword ? "text" : "password"}
      endButton={
        <IconButton
          type="button"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? <EyeOffIcon /> : <EyeIcon />}
        </IconButton>
      }
    />
  );
};

export default InputPassword;
