import { useCallback, useEffect, useState } from "react";
import { TitanCase, TitanCaseFitting } from "../../models";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";
import { API_URLS } from "../../lib";

export const DAY_MILISECOND: number = 86400000;

const useDashboard = (startDate: Date, endDate: Date) => {
  const [dashboard, setDashboard] = useState<{
    fittings: TitanCaseFitting[];
    titanCases: TitanCase[];
    totalCases: number;
    totalFittings: number;
    totalElements: number;
  }>({
    fittings: [],
    titanCases: [],
    totalCases: 0,
    totalFittings: 0,
    totalElements: 0,
  });
  const { getJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const loadFittings = useCallback(
    async (startDate: Date, endDate: Date) => {
      const from = startDate.toISOString();
      const to = new Date(endDate.getTime() + DAY_MILISECOND).toISOString();
      loadingStart();
      try {
        const fittings = await getJson<{
          caseCalendarModels: any[];
          fittingCalendarModels: TitanCaseFitting[];
        }>(`${API_URLS.titan.calendar}?from=${from}&to=${to}`);
        setDashboard((prevState) => ({
          ...prevState,
          fittings: fittings.fittingCalendarModels,
          totalFittings: fittings.fittingCalendarModels.length,
        }));
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, "error");
      }
    },
    [setDashboard, getJson, notify, loadingStart, loadingEnd]
  );

  const loadCases = useCallback(async () => {
    loadingStart();
    try {
      const response = await getJson<{ count: number; results: TitanCase[] }>(
        `${API_URLS.titan.cases.list}?isFinished=false`
      );
      setDashboard((prevState) => ({
        ...prevState,
        titanCases: response.results,
        totalCases: response.results.length,
        totalElements: response.results
          .filter((c) => !c.isFinished)
          .map((c) => c.caseElements)
          .flat().length,
      }));
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error");
    }
  }, [setDashboard, getJson, notify, loadingStart, loadingEnd]);

  useEffect(() => {
    loadFittings(startDate, endDate);
  }, [loadFittings, startDate, endDate]);

  useEffect(() => {
    loadCases();
  }, [loadCases]);

  return {
    dashboard,
  };
};

export default useDashboard;
