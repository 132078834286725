import { useCallback, useEffect, useRef } from 'react';
import { setClientsData, setLoadClients, useAppSelector } from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { TitanClient } from '../../../models';
import { textMessages } from '../../../translations';
import { API_URLS } from '../../constants';

const useClientsCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const { clients, expireAfterMiliseconds } = useAppSelector(state => state.cache);
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadClients = useCallback(async () => {
    refreshing.current = true;
    try {
      const clients = await getJson<TitanClient[]>(API_URLS.titan.clients.list);
      dispatch(setClientsData(clients));
    } catch (e: any) {
      notify(textMessages.genericError, 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }
    if (!clients.expireTime) {
      dispatch(setLoadClients());
      loadClients();
    } else {
      const timer = clients.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadClients();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [loadClients, dispatch, clients.expireTime, expireAfterMiliseconds, expireAfterMinutes]);
};
export default useClientsCache;
