import { FC } from "react";
import { API_URLS, formatMoney, usePaginatedListV3 } from "../../../lib";
import {
  AnimatedAppIcon,
  Button,
  ContainerFlex,
  MoneyIcon,
  Portal,
  TrashIcon,
  Typography,
} from "../../../toolkit";
import { TitanTender } from "../../../models";
import TenderList from "../TenderList/TenderList";
import { useTender } from "../../hooks";
import TenderCaseCheckList from "../TenderCaseCheckList/TenderCaseCheckList";

type Props = {
  clientId: number;
  clientName: string;
  isOpen: boolean;
  tender?: TitanTender;
  onDelete: (tender: TitanTender) => void;
  onReset: () => void;
};

const ClientTenders: FC<Props> = ({
  clientId,
  clientName,
  isOpen,
  tender,
  onDelete,
  onReset,
}) => {
  const tenderListProps = usePaginatedListV3(
    `${API_URLS.titan.tender.tender}?titanClientId=${clientId}`
  );
  const onRefreshRequested = () => {
    onReset();
    tenderListProps.reload();
  };

  const {
    payTenderOptions,
    onDownload,
    onDeleteConfirm,
    setPayTender,
    setStep,
    toggleSelectedCase,
    onSelectAllCasesChanged,
    onGeneratePayment,
  } = useTender(clientName, onRefreshRequested);

  return (
    <>
      <TenderList
        {...tenderListProps}
        onDownload={onDownload}
        onDelete={onDelete}
        onRowClick={(row) => setPayTender(row.tenderId)}
      />
      <Portal
        isOpen={isOpen}
        onDismiss={onReset}
        title="Stergere deviz"
        overlay={true}
        width={560}
      >
        <ContainerFlex type="column" spacing={3}>
          <AnimatedAppIcon variation="danger">
            <TrashIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Aceasta actiune va sterge devizul din baza de date.
            <br />
            Datele nu vor mai putea fi recuperate
          </Typography>
          <ContainerFlex spacing={1} type="row" justify="end">
            <Button
              size="medium"
              variant="outlined"
              type="button"
              onClick={onReset}
            >
              Renunta
            </Button>
            <Button
            type='button'
              size="medium"
              variant="danger"
              onClick={() => onDeleteConfirm(tender?.tenderId!)}
            >
              Sterge
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
      <Portal
        isOpen={payTenderOptions.tenderId !== undefined}
        onDismiss={() => setPayTender()}
        title="Adauga plata pentru cazuri incluse in deviz"
        overlay={true}
        width={800}
      >
        <ContainerFlex type="column" spacing={3}>
          {payTenderOptions.step === "list" && (
            <>
              <TenderCaseCheckList
                key="tender-list"
                tenderCases={payTenderOptions.caseTenderList || []}
                selectedCases={payTenderOptions.selectedCases}
                toggleSelectedCase={toggleSelectedCase}
                onSelectAllCasesChanged={onSelectAllCasesChanged}
              />
              <ContainerFlex spacing={1} type="row" justify="end">
                <Button
                  size="medium"
                  variant="outlined"
                  type="button"
                  onClick={() => setPayTender()}
                >
                  Renunta
                </Button>
                <Button
                type='button'
                  size="medium"
                  variant="contained"
                  disabled={!payTenderOptions.selectedCases?.length}
                  onClick={() => setStep("pay")}
                >
                  Continua
                </Button>
              </ContainerFlex>
            </>
          )}
          {payTenderOptions.step === "pay" && (
            <>
              <div></div>
              <ContainerFlex type="column" justify="center" spacing={2}>
                <AnimatedAppIcon>
                  <MoneyIcon size={40} />
                </AnimatedAppIcon>
                <Typography type="p" variation="center">
                  Urmeaza sa adaugati o plata in valoare de{" "}
                  <strong>
                    {formatMoney(payTenderOptions.amountToPay)} RON
                  </strong>
                </Typography>
              </ContainerFlex>
              <ContainerFlex spacing={1} type="row" justify="end">
                <Button
                  size="medium"
                  variant="outlined"
                  type="button"
                  onClick={() => setStep("list")}
                >
                  Inapoi
                </Button>
                <Button
                type='button'
                  size="medium"
                  variant="contained"
                  onClick={onGeneratePayment}
                  disabled={!payTenderOptions.amountToPay}
                >
                  Plateste
                </Button>
              </ContainerFlex>
            </>
          )}
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default ClientTenders;
