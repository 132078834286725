import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ContainerFlex } from '../../toolkit';

type Props = {
  series?: number[];
  labels?: string[];
  name: string;
};

const DonutChart: FC<Props> = ({ series, name, labels }) => {
  const isEmpty = series?.every(s => s === 0);
  if (isEmpty) {
    series = [100];
    labels = ['Nu exista rezultate'];
  }

  const options: ApexOptions = {
    labels: labels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: !isEmpty
            },
            total: {
              show: true,
              showAlways: true,
              label: name,
            },
          },
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      lineCap: "round",
    },
    colors: isEmpty ? ["#C0CFD8"] : ["#3D9BCE"],
    tooltip: {
      enabled: !isEmpty
    }
  };

  return (
    <ContainerFlex type='row' justify='center'>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="300"
      />
    </ContainerFlex>
  );
};

export default DonutChart;