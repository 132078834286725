import { useState } from 'react';
import { textMessages } from '../../translations';
import { useAppStoreHooks } from '../../toolkit';
import { API_URLS, validations } from '../../lib';

const validate = validations({
  cardNumber: [
    ['required', 'Camp oligatoriu!'],
    ['number', 'Doar cifre sunt permise'],
    ['length', 'Lungimea trebuie sa fie de 16 cifre', '16'],
  ],
  expDate: [['required', 'Camp oligatoriu!']],
  cvc: [
    ['required', 'Camp oligatoriu!'],
    ['number', 'Doar cifre sunt permise'],
    ['length', 'Lungimea trebuie sa fie de 3 cifre', '3'],
  ],
});
const usePaymentMethods = (onSaved: () => void) => {
  const [paymentMethod, setPaymentMethod] = useState<{
    form: {
      cardNumber: string;
      expDate: string;
      cvc: string;
    };
    errors: {
      cardNumber?: string;
      expDate?: string;
      cvc?: string;
    };
  }>({
    form: {
      cardNumber: '',
      expDate: '',
      cvc: '',
    },
    errors: {},
  });

  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    setPaymentMethod((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(paymentMethod.form);
    let hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      const expDateAndMonthValidation = paymentMethod.form.expDate.split('/');
      const expMonth = Number(expDateAndMonthValidation[0]);
      const expYear = expDateAndMonthValidation[1]
        ? Number(expDateAndMonthValidation[1])
        : 0;
      if (expMonth > 12 || expMonth < 1) {
        errors.expDate = 'Luna data expirare invalida!';
        hasErrors = true;
      } else if (expYear < new Date().getFullYear() - 2000) {
        errors.expDate = 'An data expirare invalid!';
        hasErrors = true;
      }
    }

    setPaymentMethod((prevState) => ({
      ...prevState,
      errors,
    }));
    if (hasErrors) {
      return;
    }
    loadingStart();
    const expDateAndMonth = paymentMethod.form.expDate.split('/');
    try {
      await postJson(API_URLS.platform.paymentMethod, {
        cardNumber: paymentMethod.form.cardNumber,
        expMonth: Number(expDateAndMonth[0]),
        expYear: Number(expDateAndMonth[1]) + 2000,
        cvc: paymentMethod.form.cvc,
      });
      loadingEnd();
      onSaved();
    } catch (e: any) {
      let message = e.message;
      const errors = { cardNumber: undefined, expDate: undefined };
      if (e.message === 'Your card number is incorrect.') {
        message = 'Numarul cardului este incorect!';
        errors.cardNumber = message;
      }
      if (e.message === "Your card's expiration month is invalid.") {
        message = 'Luna data expirare invalida!';
        errors.expDate = message;
      }

      setPaymentMethod((prevState) => ({
        ...prevState,
        errors,
      }));

      loadingEnd();
      notify(textMessages.genericError, 'error', message || e.message);
    }
  };

  return {
    paymentMethod,
    onChange,
    onSubmit,
  };
};

export default usePaymentMethods;
