import { FC } from 'react';
import { SignUpPageTranslation, textPage } from '../../translations';
import { SignUpForm } from '../../modules';
import { AppTitle } from '../../layout';
import { Card, Typography } from '../../toolkit';
import styles from './styles.module.css';
import { useFacebookPixelTag } from '../../lib';

const texts = textPage('signUp') as SignUpPageTranslation;

const SignUpPage: FC = () => {
  useFacebookPixelTag();
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <div>
          <Typography type='h2' variation='center'>{texts.title}</Typography>
          <Typography type='p' variation='center' variation1='secondary'>{texts.subTitle1}</Typography>
          <Typography type='p' variation='center' variation1='secondary'>{texts.subTitle2}</Typography>
        </div>
        <SignUpForm />
      </Card>
    </AppTitle>
  );
};

export default SignUpPage;