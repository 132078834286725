import { CSSProperties, FC, ReactNode, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import Typography from "../Typography/Typography";

import styles from "./styles.module.css";
import IconButton from '../IconButton/IconButton';
import { CloseIcon } from '../assets';

type BaseProps = {
  children: ReactNode;
  title?: string;
  header?: ReactNode;
  onDismiss?: () => void;
  noPaddings?: boolean;
};

type DialogProps = {
  width?: number;
} & BaseProps;

type AnchoredProps = {
  anchorEl: Element;
  align?: 'left' | 'right' | 'middle';
} & DialogProps;

type PortalProps = {
  anchorEl?: HTMLElement | null;
  align?: 'left' | 'right' | 'middle';
  overlay?: boolean;
} & DialogProps;

type Props = {
  isOpen: boolean;
} & PortalProps;

const createPortalContainer = (fail?: boolean): Element => {
  let portal = document.getElementsByClassName(styles.portalContainer)[0];

  if (!portal && !fail) {
    const portal = document.createElement("div");
    portal.className = styles.portalContainer;
    document.body.appendChild(portal);
    return createPortalContainer(true);
  }
  return portal;
};

const Dialog: FC<BaseProps & { style?: CSSProperties; }> = ({ children, onDismiss, title, header, style, noPaddings }) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={dialogRef}
      className={styles.dialog}
      style={style}
    >
      {(header || title) && (
        <div className={[styles.dialogHeader, noPaddings ? styles.noPaddings : ''].join(' ')}>
          {header || (
            <Typography type="h4">{title}</Typography>
          )}
          {onDismiss && (
            <IconButton onClick={onDismiss}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      )}
      <div className={[styles.dialogBody, noPaddings ? styles.noPaddings : ''].join(' ')}>
        {children}
      </div>
    </div>
  );
};

const AchoredEl: FC<AnchoredProps> = ({ anchorEl, width = 300, align = 'middle', ...props }) => {
  const elRect = anchorEl.getBoundingClientRect();

  if (width > document.body.clientWidth) {
    width = document.body.clientWidth - 20;
  }

  let left = elRect.left + elRect.width / 2;

  if (left + width / 2 + 20 > document.body.clientWidth) {
    left = document.body.clientWidth - 20 - width / 2;
  }

  if (align === 'left') {
    left = elRect.left;
  }

  const style = {
    top: elRect.top + elRect.height + 6,
    left,
  };
  return (
    <div className={styles.container} style={style} onClick={e => e.stopPropagation()}>
      <Dialog style={{ width, left: align === 'left' ? 0 : width / -2 }} {...props} />
    </div>
  );
};

const MiddleEl: FC<DialogProps> = ({ width, ...props }) => {
  return (
    <Dialog {...props} style={{ width }} />
  );
};

const BasePortal: FC<PortalProps> = ({ overlay, anchorEl, children, ...props }) => {
  return (
    <div className={styles.popupContainer}>
      <div className={overlay ? styles.overlay : styles.invisibleOverlay} onClick={props.onDismiss}></div>
      {anchorEl && (
        <AchoredEl anchorEl={anchorEl} {...props}>
          {children}
        </AchoredEl>
      )}
      {!anchorEl && <MiddleEl {...props}>{children}</MiddleEl>}
    </div>
  );
};

const Portal: FC<Props> = ({ isOpen, ...props }) => {
  const el = useMemo(() => createPortalContainer(), []);
  if (isOpen) {
    const content = <BasePortal {...props} />;
    return createPortal(content, el);
  }
  return <></>;
};

export default Portal;
