import { FC } from 'react';
import { useNavigate} from 'react-router-dom';

import { AppTitle } from '../../layout';
import { AnimatedAppIcon, Button, Card, CheckIcon, Typography } from '../../toolkit';
import { RecoverPasswordPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('recoverPassword') as RecoverPasswordPageTranslation;

const RecoverPasswordSuccessPage: FC = () => {
  const navigate = useNavigate()
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <CheckIcon />
        </AnimatedAppIcon>
        <Typography type='h2' variation='center'>{texts.title}</Typography>
        <Typography type='p' variation='center' variation1='secondary'>{texts.subTitleSuccess}</Typography>
        <Button
        type='button'
          size='medium'
          variant='contained'
          className={styles.button}
          onClick={() => navigate('/login')}>
          {texts.btnSuccessTxt}
        </Button>
      </Card>
    </AppTitle>
  );
};

export default RecoverPasswordSuccessPage;