import { FC } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  labels: string[];
  labelFormatFunc?: (date: string | Date) => string;
};

const AreaChart: FC<Props> = ({ series, labels, labelFormatFunc }) => {
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        formatter: function (value) {
          return labelFormatFunc ? labelFormatFunc(value) : value;
        },
      },
    },
    // plotOptions: {
    //   radialBar: {
    //     track: {
    //       background: "#EBF1F5",
    //     },
    //     dataLabels: {
    //       show: false,
    //       value: {
    //         show: true,
    //         fontSize: "16px"
    //       },
    //       total: {
    //         show: true,
    //         label: "ROI",
    //         color: "#373d3f"
    //       }
    //     }
    //   }
    // },
    stroke: {
      lineCap: "round",
    },
    colors: ["#3D9BCE"],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="400"
    />
  );
};

export default AreaChart;
