import { FC } from "react";
import { TitanStage } from "../../../models";
import { Button, ContainerFlex, Input } from "../../../toolkit";
import { useStage } from "../../hooks";
import { textForm } from "../../../translations";
import StageFormConfigureElements from './StageFormConfigureElements';
import StageFormConfigureUsers from './StageFormConfigureUsers';

type Props = {
  stage?: TitanStage;
  onClose: () => void;
  onSaved: () => void;
};

const StageForm: FC<Props> = ({ stage, onClose, onSaved }) => {
  const {
    stageForm,
    elementOptions,
    userOptions,
    onChange,
    onSubmit,
    onElementChanged,
    onRemoveElementStage,
    onUserChanged,
    onRemoveStageUser,
    onStageValueChanged,
    onStageIsPercentChanged,
  } = useStage(onSaved, stage);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        label={textForm.nameLabel}
        placeholder={textForm.namePlaceholder}
        name="name"
        onChange={onChange}
        value={stageForm.form.name}
        required
        errorMessage={stageForm.errors.name}
      />
      <Input
        label={textForm.descriptionLabel}
        placeholder={textForm.descriptionPlaceholder}
        name="description"
        onChange={onChange}
        value={stageForm.form.description}
        rows={2}
        errorMessage={stageForm.errors.description}
      />
      <StageFormConfigureElements
        stageElements={stageForm.form.stageElements || []}
        elementOptions={elementOptions || []}
        onElementChanged={onElementChanged}
        onRemoveElementStage={onRemoveElementStage}
      />
      <StageFormConfigureUsers
        stageUsers={stageForm.form.stageUsers || []}
        userOptions={userOptions || []}
        onRemoveStageUser={onRemoveStageUser}
        onStageIsPercentChanged={onStageIsPercentChanged}
        onStageValueChanged={onStageValueChanged}
        onUserChanged={onUserChanged}
      />
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default StageForm;
