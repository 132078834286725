import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useEffectOnMount = (effect: EffectCallback, deps?: DependencyList) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      effect();
    } 
  }, [effect, deps]);
};

export default useEffectOnMount;