import { FC } from "react";
import { TitanElement } from "../../../models";
import { useElement } from "../../hooks";
import { Button, ContainerFlex, Input } from "../../../toolkit";
import { textForm } from "../../../translations";
import { useAppSelector } from "../../../store";
import { StagesFeature } from "../../../components";
import ElementFormConfigureStages from './ElementFormConfigureStages';

type Props = {
  element?: TitanElement;
  onClose: () => void;
  onSaved: () => void;
};

const ElementForm: FC<Props> = ({ element, onClose, onSaved }) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages);
  const {
    elementForm,
    stagesOptions,
    onChange,
    onSubmit,
    onStageChanged,
    onRemoveElementStage,
  } = useElement(onSaved, element);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        label={textForm.nameLabel}
        placeholder={textForm.namePlaceholder}
        name="name"
        onChange={onChange}
        value={elementForm.form.name}
        required
        errorMessage={elementForm.errors.name}
      />
      <Input
        label={textForm.priceLabel}
        placeholder={textForm.pricePlaceholder}
        name="price"
        onChange={onChange}
        value={elementForm.form.price ? String(elementForm.form.price) : ""}
        required
        errorMessage={elementForm.errors.price}
      />
      <Input
        label={textForm.descriptionLabel}
        placeholder={textForm.descriptionPlaceholder}
        name="description"
        onChange={onChange}
        value={elementForm.form.description}
        rows={2}
        errorMessage={elementForm.errors.description}
      />

      {stagesFeature ? (
        <ElementFormConfigureStages
          elementStages={elementForm.form.elementStages || []}
          stagesOptions={stagesOptions || []}
          onStageChanged={onStageChanged}
          onRemoveElementStage={onRemoveElementStage}
        />
      ) : (
        <>
          <StagesFeature />
          <br />
        </>
      )}
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default ElementForm;
