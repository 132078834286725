import { createSlice } from '@reduxjs/toolkit';

export interface LoadingState {
  count: number;
}

export const initialState: LoadingState = {
  count: 0
};

export const loadingSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    setLoadingStart: (state) => {
      state.count = state.count + 1;
    },
    setLoadingEnd: (state) => {
      state.count = state.count - 1;
    },
  }
});

export const {
  setLoadingStart,
  setLoadingEnd,
} = loadingSlice.actions;

export default loadingSlice.reducer;