import { FC } from "react";
import { IColumn, formatMoney } from "../../../lib";
import { Table } from "../../../toolkit";
import TitanUserElementStageReport from "../../../models/TitanUserElementStageReport";

type Props = {
  stages: TitanUserElementStageReport[];
};

type IDocument = {
  key: string;
  index: number;
} & TitanUserElementStageReport;

const UserStageElementStatisticsList: FC<Props> = ({ stages }) => {
  const columns: IColumn[] = [
    {
      key: "name",
      name: "Etapa",
      fieldName: "name",
    },
    {
      key: "count",
      name: "Elemente incluse in etapa",
      fieldName: "count",
    },
    {
      key: "value",
      name: "Valuare per element",
      fieldName: "value",
      onRender: (item: any) =>
        item.isPercent ? `${item.value}%` : `${formatMoney(item.value)} RON`,
    },
    {
      key: "total",
      name: "Suma",
      fieldName: "total",
      onRender: (item: any) => `${formatMoney(item.total)} RON`,
    },
  ];

  const items: IDocument[] = stages.map(
    (item: TitanUserElementStageReport, index: number) => {
      return {
        key: String(item.name),
        index: index + 1,
        ...item,
      };
    }
  );

  return (
    <Table
      columns={columns}
      items={items}
      variant="subtable"
      //   changePage={listProps.changePage}
      //   isFiltered={listProps.isFiltered}
      //   page={listProps.page}
      //   reload={listProps.reload}
      //   loading={listProps.loading}
      //   total={listProps.total}
      //   subTableComponent={() => "bau"}
      //   stateAssets={{
      //     emptySearchText: "Nu a fost gasit nici un element",
      //     emptyIcon: <ToothIcon size={40} />,
      //     emptyTitle: "Nu exista statistici in perioada selectata.",
      //   }}
    />
  );
};

export default UserStageElementStatisticsList;
