import { FC } from "react";
import { TitanTenderCase } from "../../../models";
import { Checkbox, ContainerFlex, Table, Typography } from "../../../toolkit";
import { IColumn, formatMoney, prettyDate } from "../../../lib";

interface IDocument extends TitanTenderCase {
  key: string;
}

type Props = {
  tenderCases: TitanTenderCase[];
  selectedCases: number[];
  toggleSelectedCase?: (titanCaseId: number) => void;
  onSelectAllCasesChanged?: (checked?: boolean) => void;
};

const TenderCaseCheckList: FC<Props> = ({
  tenderCases,
  selectedCases,
  toggleSelectedCase,
  onSelectAllCasesChanged
}) => {
  const columns: IColumn[] = [
    {
      key: "checked",
      fieldName: "",
      name: "",
      width: 60,
      show: toggleSelectedCase !== undefined,
      onRenderHeader: () => (
        <Checkbox
          value={selectedCases.length > 0 && selectedCases.length === tenderCases.length}
          onChange={() => {
            onSelectAllCasesChanged!(selectedCases.length !== tenderCases.length);
          }}
        />
      ),
      onRender: (item: IDocument) => (
        <Checkbox
          value={selectedCases.includes(item.titanCaseId)}
          onChange={() => {
            toggleSelectedCase!(item.titanCaseId);
          }}
        />
      ),
    },
    {
      key: "patientName",
      name: "Pacient",
      fieldName: "patientName",
      width: 120,
    },
    {
      key: "date",
      name: "Data",
      fieldName: "",
      width: 120,
      onRender: (item: IDocument) => (
        <span>{prettyDate(item.caseDateTime)}</span>
      ),
    },
    {
      key: "summary",
      name: "Sumar",
      fieldName: "summary",
    },
    {
      key: "price",
      name: "Pret",
      fieldName: "price",
      width: 70,
      onRender: (item: IDocument) => <span>{formatMoney(item.price)}</span>,
    },
    {
      key: "paid",
      name: "Platit",
      fieldName: "paid",
      width: 70,
      onRender: (item: IDocument) => <span>{formatMoney(item.paid)}</span>,
    },
  ];

  const items = (tenderCases || []).map(
    (item: TitanTenderCase, index: number) => {
      return {
        key: item.titanCaseId,
        index: index + 1,
        ...item,
      };
    }
  );

  return (
    <ContainerFlex type="column" spacing={2}>
      <Typography type="p">Selecteaza cazurile incluse in raport</Typography>
      <Table columns={columns} items={items} />
    </ContainerFlex>
  );
};

export default TenderCaseCheckList;
