import { FC, ReactNode, useEffect } from 'react';
import styles from './styles.module.css';
import { Typography } from '../../toolkit';
import { useDispatch } from 'react-redux';
import { setCacheExpiry } from '../../store';

type Props = {
  title: string;
  tabs?: ReactNode;
  children: ReactNode;
};

const PageLayout: FC<Props> = ({ title, tabs, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `DentOps - ${title}`;
    dispatch(setCacheExpiry());
  }, [title, dispatch]);

  return (
    <>
      <div className={styles.header}>
        <Typography type='h4'>{title}</Typography>
        {tabs}
      </div>
      <div id='pageBody' className={styles.page}>{children}</div>
    </>
  );
};

export default PageLayout;