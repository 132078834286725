import { useState } from "react";
import { TitanClinic } from "../../models";
import { API_URLS, validations } from "../../lib";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";

const validate = validations({
  clinicName: [["required", "Camp oligatoriu!"]],
  email: [["email", "Adresa de email nu este valida!"]],
  phone: [["required", "Camp oligatoriu!"]],
  address: [["required", "Camp oligatoriu!"]],
  city: [["required", "Camp oligatoriu!"]],
});

const useClinic = (onSaved: () => void, clinic?: TitanClinic) => {
  const [clinicForm, setClinicForm] = useState<{
    form: TitanClinic;
    errors: {
      clinicName?: string;
      email?: string;
      phone?: string;
      address?: string;
      city?: string;
    };
  }>({
    form: clinic || ({} as TitanClinic),
    errors: {},
  });

  const { postJson, putJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setClinicForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(clinicForm.form);
    setClinicForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (Object.keys(errors).length) {
      return;
    }
    loadingStart();
    try {
      if (clinicForm.form.clinicId) {
        await putJson<any>(API_URLS.titan.clinic.clinic, clinicForm.form);
        notify("Clinica a fost modificata.", "success");
      } else {
        await postJson<TitanClinic>(
          API_URLS.titan.clinic.clinic,
          clinicForm.form
        );
        notify("Clinica a fost adaugata.", "success");
      }
      onSaved();
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };
  return {
    clinicForm,
    onChange,
    onSubmit,
  };
};

export default useClinic;
