import { FC } from "react";
import { Button, InputPassword } from '../../../toolkit';
import { textForm } from '../../../translations';
import useUserPassword from "./useUserPassword";
import { Container } from '../../../layout';

type Props = {
  titanUserId: string;
  onClose: () => void;
};

const UserPassword: FC<Props> = ({ titanUserId, onClose }) => {
  const { userForm, onChangeHandler, onSubmit } = useUserPassword(titanUserId, onClose);

  return (
    <form noValidate onSubmit={onSubmit}>
      <InputPassword
        label={textForm.currentPasswordLabel}
        placeholder={textForm.currentPasswordPlaceholder}
        name="currentPassword"
        onChange={onChangeHandler}
        value={userForm.form.currentPassword}
        required
        errorMessage={userForm.errors.currentPassword}
      />
      <InputPassword
        label={textForm.newPasswordLabel}
        placeholder={textForm.newPasswordPlaceholder}
        name="newPassword"
        onChange={onChangeHandler}
        value={userForm.form.newPassword}
        required
        errorMessage={userForm.errors.newPassword}
      />
      <InputPassword
        label={textForm.confirmPasswordLabel}
        placeholder={textForm.confirmPasswordPlaceholder}
        name="confirmPassword"
        onChange={onChangeHandler}
        value={userForm.form.confirmPassword}
        required
        errorMessage={userForm.errors.confirmPassword}
      />
      <Container gap={10} horizontalAlign='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Schimbă parola</Button>
      </Container>
    </form>
  );
};

export default UserPassword;
