import { FC } from 'react';
import styles from './styles.module.css';

type Props = {
  selected: string | number;
  items: {
    id: string | number;
    label: string;
  }[];
  onStepChanged: (id: string | number) => void;
};

const Stepper: FC<Props> = ({ selected, items, onStepChanged }) => {
  return (
    <div className={styles.stepper}>
      {items.map((item, index) => (
        <button
          key={item.id}
          className={[styles.step, item.id === selected ? styles.active : ''].join(' ')}
          onClick={() => onStepChanged(item.id)}>
          <span>{index + 1}</span>
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default Stepper;