import { FC } from 'react';

const MailIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M3.33325 11.667L16.9415 21.1927C18.0434 21.9641 18.5944 22.3498 19.1937 22.4992C19.7231 22.6311 20.2768 22.6311 20.8061 22.4992C21.4055 22.3498 21.9564 21.9641 23.0584 21.1927L36.6666 11.667M11.3333 33.3337H28.6666C31.4668 33.3337 32.867 33.3337 33.9365 32.7887C34.8773 32.3093 35.6423 31.5444 36.1216 30.6036C36.6666 29.5341 36.6666 28.1339 36.6666 25.3337V14.667C36.6666 11.8667 36.6666 10.4666 36.1216 9.39704C35.6423 8.45623 34.8773 7.69133 33.9365 7.21196C32.867 6.66699 31.4668 6.66699 28.6666 6.66699H11.3333C8.53299 6.66699 7.13286 6.66699 6.0633 7.21196C5.12249 7.69133 4.35759 8.45623 3.87822 9.39704C3.33325 10.4666 3.33325 11.8667 3.33325 14.667V25.3337C3.33325 28.1339 3.33325 29.5341 3.87822 30.6036C4.35759 31.5444 5.12249 32.3093 6.0633 32.7887C7.13286 33.3337 8.53299 33.3337 11.3333 33.3337Z" stroke="#3D9BCE" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MailIcon;