import { FC } from 'react';

type Props = {
  size?: number;
};

const CalendarChecked: FC<Props> = ({ size = 24 }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
      <path d="M21.333 10H3.33301M16.333 2V6M8.33301 2V6M9.33301 16L11.333 18L15.833 13.5M8.13301 22H16.533C18.2132 22 19.0532 22 19.695 21.673C20.2595 21.3854 20.7184 20.9265 21.006 20.362C21.333 19.7202 21.333 18.8802 21.333 17.2V8.8C21.333 7.11984 21.333 6.27976 21.006 5.63803C20.7184 5.07354 20.2595 4.6146 19.695 4.32698C19.0532 4 18.2132 4 16.533 4H8.13301C6.45285 4 5.61277 4 4.97104 4.32698C4.40655 4.6146 3.94761 5.07354 3.65999 5.63803C3.33301 6.27976 3.33301 7.11984 3.33301 8.8V17.2C3.33301 18.8802 3.33301 19.7202 3.65999 20.362C3.94761 20.9265 4.40655 21.3854 4.97104 21.673C5.61277 22 6.45285 22 8.13301 22Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CalendarChecked;