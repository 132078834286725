import { useCallback, useEffect, useRef } from 'react';
import { setLoadUsers, setUsersData, useAppSelector } from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { TitanUser } from '../../../models';
import { textMessages } from '../../../translations';
import { API_URLS } from '../../constants';

const useUsersCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const { users, expireAfterMiliseconds } = useAppSelector(
    (state) => state.cache
  );
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadUsers = useCallback(async () => {
    refreshing.current = true;
    try {
      const users = await getJson<{ model: TitanUser[] }>(
        API_URLS.identity.user.user
      );
      dispatch(setUsersData(users.model));
    } catch (e: any) {
      notify(textMessages.genericError, 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }
    if (!users.expireTime) {
      dispatch(setLoadUsers());
      loadUsers();
    } else {
      const timer = users.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadUsers();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    loadUsers,
    dispatch,
    users.expireTime,
    expireAfterMiliseconds,
    expireAfterMinutes,
  ]);
};

export default useUsersCache;
