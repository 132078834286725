import { FC } from "react";
import styles from "./styles.module.css";

type Props = {
  selected: string;
  items: {
    id: string;
    label: string;
  }[];
  onTabChanged: (id: string) => void;
};

const Tabs: FC<Props> = ({ selected, items, onTabChanged }) => {
  return items?.length ? (
    <div className={styles.tabs}>
      {items.map((item) => (
        <button
          key={item.id}
          className={[
            styles.tab,
            item.id === selected ? styles.active : "",
          ].join(" ")}
          onClick={(e) => {
            e.stopPropagation();
            onTabChanged(item.id);
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  ) : null;
};

export default Tabs;
