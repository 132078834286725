import { FC } from 'react';

type Props = {
  size?: number;
};

const DentOpsIcon: FC<Props> = ({ size = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 30" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2253 26.624C16.3562 27.3483 17.6208 28.0529 19.0299 28.7318C19.1527 28.7889 19.2725 28.8534 19.3932 28.9183C20.0373 29.2647 20.7042 29.6235 21.9662 28.9415C25.9729 26.7766 29.3112 10.9389 26.4763 3.87537C24.7598 -0.402004 17.728 0.517201 14.1018 2.31484C35.7076 13.7639 23.2237 22.0621 15.2253 26.624Z" fill="#27729B" />
      <path d="M0.571255 6.60229C0.851446 5.04602 1.46275 4.05361 1.92001 3.67422C4.24768 1.0581 8.51056 2.19496 13.3508 4.2742C39.2876 15.4157 18.6453 22.7328 15.2534 25.2711C15.1999 25.3111 11.9842 27.0853 8.12161 29.1515C5.59076 30.5055 4.28639 28.4324 4.25463 26.2274C4.03187 13.9473 -0.0874535 10.2552 0.571255 6.60229Z" fill="#3D9BCE" />
    </svg>
  );
};

export default DentOpsIcon;