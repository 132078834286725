import { FC, useState } from "react";
import { TitanStage } from "../../../models";
import {
  AnimatedAppIcon,
  Button,
  ContainerFlex,
  TrashIcon,
  Typography,
  Portal,
  AddIcon,
} from "../../../toolkit";
import { useStageDelete } from "../../hooks";
import {
  setRefreshElements,
  setRefreshStages,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import { useCacheList } from "../../../lib";
import { SearchInput, StagesFeature } from "../../../components";
import StagesList from "../StageList/StagesList";
import StagesForm from "../StagesForm/StagesForm";
import UserElementStagesForm from "../StagesForm/UserElementStagesForm";

const filter = (searchTerm: string) => (row: TitanStage) => {
  return row.name.toLowerCase().includes(searchTerm.toLowerCase());
};

const CRUD_TITLE = {
  add: "Adauga etapa",
  edit: "Modifica etapa",
  delete: "Sterge etapa",
  configure: "Configureaza etapa",
};

const StagesCRUD: FC = () => {
  const [stageCRUD, setStageCRUD] = useState<{
    isOpen: boolean;
    type?: "add" | "edit" | "delete" | "configure";
    stage?: TitanStage;
  }>({
    isOpen: false,
  });
  const stagesFeature = useAppSelector(
    (state) => state.appSettings.features.stages
  );
  const stages = useAppSelector((state) => state.cache.stages);
  const dispatch = useAppDispatch();
  const listProps = useCacheList(stages, filter, setRefreshStages);
  const { onDelete } = useStageDelete(() => {
    setStageCRUD({ isOpen: false });
    listProps.reload(true);
    dispatch(setRefreshElements());
  });

  return stagesFeature ? (
    <>
      {(listProps.total > 0 || listProps.isFiltered) && (
        <ContainerFlex
          type="row"
          align="center"
          justify="space-between"
          style={{ width: 1200, margin: "0 auto" }}
        >
          <Typography type="h5" variation="center">
            {listProps.total} Etape
          </Typography>
          <ContainerFlex type="row" spacing={2}>
            <SearchInput
              placeholder="Cauta"
              onChange={listProps.setSearchTerm}
            />
            <div>
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() => setStageCRUD({ isOpen: true, type: "add" })}
              >
                <AddIcon />
                Adauga etapa
              </Button>
            </div>
          </ContainerFlex>
        </ContainerFlex>
      )}
      <StagesList
        {...listProps}
        onOpenModal={(type, stage) =>
          setStageCRUD({ isOpen: true, type, stage })
        }
      />
      <Portal
        isOpen={stageCRUD.isOpen}
        onDismiss={() => setStageCRUD({ isOpen: false })}
        title={CRUD_TITLE[stageCRUD.type!]}
        overlay={true}
        width={560}
      >
        <>
          {stageCRUD.type === "delete" && (
            <ContainerFlex type="column" spacing={3}>
              <AnimatedAppIcon variation="danger">
                <TrashIcon size={40} />
              </AnimatedAppIcon>
              <Typography type="p" variation="center">
                Acesta etapa va fi stearsa din baza de date.
              </Typography>
              <ContainerFlex spacing={1} type="row" justify="end">
                <Button
                  size="medium"
                  variant="outlined"
                  type="button"
                  onClick={() => setStageCRUD({ isOpen: false })}
                >
                  Renunta
                </Button>
                <Button
                  type="button"
                  size="medium"
                  variant="danger"
                  onClick={() => onDelete(stageCRUD.stage?.stageId!)}
                >
                  Sterge
                </Button>
              </ContainerFlex>
            </ContainerFlex>
          )}
          {["add", "edit"].includes(stageCRUD.type || "") && (
            <StagesForm
              stage={stageCRUD.stage!}
              onClose={() => setStageCRUD({ isOpen: false })}
              onSaved={() => {
                setStageCRUD({ isOpen: false });
                listProps.reload();
                dispatch(setRefreshElements());
              }}
            />
          )}
          {stageCRUD.type === "configure" && (
            <UserElementStagesForm
              stage={stageCRUD.stage}
              onClose={() => setStageCRUD({ isOpen: false, type: undefined })}
              onSaved={() => {
                setStageCRUD({ isOpen: false, type: undefined });
                listProps.reload();
                dispatch(setRefreshElements());
              }}
            />
          )}
        </>
      </Portal>
    </>
  ) : (
    <ContainerFlex container={true}>
      <StagesFeature />
    </ContainerFlex>
  );
};

export default StagesCRUD;
