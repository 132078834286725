import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {
  LoginPage,
  ForgotPasswordPage,
  RecoverPasswordPage,
  SignUpPage,
  ActivateAccountPage,
  ForgotPasswordSuccessPage,
  SignUpSuccessPage,
  AcctivateAccountSuccessPage,
  RecoverPasswordSuccessPage,
  NotFoundPage,
  DashboardPage,
  ClientsPage,
  ClientDetailsPage,
  CasesPage,
  NewCasePage,
  EditCasePage,
  CaseDetailsPage,
  ConfigurePage,
  ReportsPage,
  SettingsPage,
  ClinicDetailsPage,
} from "../pages";
import OwnerOrRolesRoute from "./OwnerOrRolesRoute";
import { DENTOPS_ROLES } from "../lib";
import ActiveSubscription from "./ActiveSubscription";

const AppRoutes: FC = () => {

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="/forgot-password/success"
        element={<ForgotPasswordSuccessPage />}
      />
      <Route
        path="/recover-password/success"
        element={<RecoverPasswordSuccessPage />}
      />
      <Route
        path="/recover-password/:token"
        element={<RecoverPasswordPage />}
      />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/sign-up/success" element={<SignUpSuccessPage />} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route
        path="/confirm-email/success"
        element={<AcctivateAccountSuccessPage />}
      />
      <Route path="/confirm-email/:token" element={<ActivateAccountPage />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<ActiveSubscription />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Route>

        <Route path="/settings/:selected?" element={<SettingsPage />} />
        <Route
          path="/subscription/cancel"
          element={<Navigate to="/settings/cancel" />}
        />
        <Route
          path="/subscription/success/:subscriptionId"
          element={<Navigate to="/settings/success" />}
        />

        <Route path="/" element={<ActiveSubscription />}>
          <Route
            path="/"
            element={
              <OwnerOrRolesRoute
                roles={[
                  DENTOPS_ROLES.ADMIN,
                  DENTOPS_ROLES.MANAGER,
                  DENTOPS_ROLES.DATAENTRY,
                ]}
              />
            }
          >
            <Route path="/new-case" element={<NewCasePage />} />
          </Route>
        </Route>

        <Route path="/" element={<ActiveSubscription />}>
          <Route
            path="/"
            element={
              <OwnerOrRolesRoute
                roles={[
                  DENTOPS_ROLES.ADMIN,
                  DENTOPS_ROLES.MANAGER,
                  DENTOPS_ROLES.TECHNICIAN,
                ]}
              />
            }
          >
            <Route path="/cases" element={<CasesPage />} />
            <Route path="/cases/:caseId" element={<CaseDetailsPage />} />
          </Route>
          <Route
            path="/"
            element={
              <OwnerOrRolesRoute
                roles={[DENTOPS_ROLES.ADMIN, DENTOPS_ROLES.MANAGER]}
              />
            }
          >
            <Route
              path="/clients/clinics/:clinicId"
              element={<ClinicDetailsPage />}
            />
            <Route path="/clients/" element={<ClientsPage />} />
            <Route path="/clients/:clientId" element={<ClientDetailsPage />} />
            <Route path="/cases/new" element={<NewCasePage />} />
            <Route path="/cases/:clientId/new" element={<NewCasePage />} />
            <Route path="/cases/:caseId/edit" element={<EditCasePage />} />
          </Route>

          <Route
            path="/"
            element={<OwnerOrRolesRoute roles={[DENTOPS_ROLES.ADMIN]} />}
          >
            <Route path="/configure/:selected?" element={<ConfigurePage />} />
            <Route path="/reports/:selected?" element={<ReportsPage />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default AppRoutes;
