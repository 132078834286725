import { useEffect } from "react";
import { IS_DEV_ENV } from "../../utils";

const YOUR_PIXEL_ID = '1512757359110800';

const useFacebookPixelTag = () => {
  useEffect(() => {
    if (!IS_DEV_ENV && YOUR_PIXEL_ID) {
      //eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      //eslint-disable-next-line
      fbq("init", YOUR_PIXEL_ID);
      //eslint-disable-next-line
      fbq("track", "PageView");
    }
  }, []);

  return null;
};

export default useFacebookPixelTag;
