import { FC, useRef } from 'react';
import { IconButton, Button, Typography, TrashIcon } from '../../toolkit';
import Avatar from '../Avatar/Avatar';
import styles from './styles.module.css';

type Props = {
  text: string;
  url?: string;
  label: string;
  onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete?: () => void;
};

const AvatarProfile: FC<Props> = ({ text, url, label, onUpload, onDelete }) => {
  const inputFileRef = useRef(null);

  const onOpenInput = () => {
    const current = inputFileRef.current || { click: () => undefined };
    current.click();
  };

  return (
    <div className={styles.avatar}>
      <Avatar text={text} url={url} size='big' />
      <div className={styles.text}>
        <Typography type='p' variation='bold'>{label}</Typography>
        <Typography type='span' variation='secondary'>Incarca o fotografie de maxim 5MB</Typography>
      </div>
      <div className={styles.buttons}>
        <Button  size='small' type='button' variant='outlined' onClick={onOpenInput} disabled={!Boolean(onUpload)}>Schimba</Button>
        <IconButton variant='outlined' disabled={!Boolean(onDelete)} onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </div>
      <input type="file" style={{ display: 'none' }} ref={inputFileRef} onChange={onUpload} />
    </div>
  );
};

export default AvatarProfile;