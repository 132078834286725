import { FC } from 'react';
import { MegaPhoneIcon, Typography } from '../../toolkit';
import styles from './styles.module.css';

type Props = {
  id: number;
  type: 'functionality';
  title: string;
  details: string;
  url?: string;
  read: boolean;
};

const Notification: FC<Props> = ({ type, title, details, read }) => {
  return (
    <div className={[styles.notification, read ? styles.read : ''].join(' ')}>
      <span>
        {type === 'functionality' && (
          <MegaPhoneIcon />
        )}
      </span>
      <div>
        <Typography type='p' variation='bold' variation1={read ? 'secondary' : undefined}>
          {title}
        </Typography>
        <Typography type='p' variation1={read ? 'secondary' : undefined}>
          {details}
        </Typography>
      </div>
    </div>
  );
};

export default Notification;