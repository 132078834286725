import { FC } from 'react';
import { TitanStage } from '../../../models';
import { Button, ContainerFlex, Typography } from '../../../toolkit';
import StageFormConfigureElements from './StageFormConfigureElements';
import { useStage } from '../../hooks';
import StageFormConfigureUsers from './StageFormConfigureUsers';

type Props = {
  stage?: TitanStage;
  onClose: () => void;
  onSaved: () => void;
  includeElementsConfig?: boolean;
};

const UserElementStagesForm: FC<Props> = ({ stage, onClose, onSaved, includeElementsConfig = true }) => {
  const {
    stageForm,
    elementOptions,
    userOptions,
    onSubmit,
    onElementChanged,
    onRemoveElementStage,
    onUserChanged,
    onRemoveStageUser,
    onStageValueChanged,
    onStageIsPercentChanged,
  } = useStage(onSaved, stage);
  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography type='h6'>
        Configureaza etapa {stage?.name}
      </Typography>
      <br />
      {includeElementsConfig && (
        <StageFormConfigureElements
          stageElements={stageForm.form.stageElements || []}
          elementOptions={elementOptions || []}
          onElementChanged={onElementChanged}
          onRemoveElementStage={onRemoveElementStage}
        />
      )}
      <StageFormConfigureUsers
        stageUsers={stageForm.form.stageUsers || []}
        userOptions={userOptions || []}
        onRemoveStageUser={onRemoveStageUser}
        onStageIsPercentChanged={onStageIsPercentChanged}
        onStageValueChanged={onStageValueChanged}
        onUserChanged={onUserChanged}
      />
      <br />
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default UserElementStagesForm;