import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { TitanStoreProvider } from './provider';
import { TitanContainer } from './layout';
import { Routes } from './routes';

const App: FC = () => {
  return (
    <TitanStoreProvider>
      <BrowserRouter>
        <TitanContainer>
          <Routes />
        </TitanContainer>
      </BrowserRouter>
    </TitanStoreProvider>
  );
};

export default App;
