import { FC } from 'react';
import HumanArcade, { Props } from '../HumanArcade';
import styles from './styles.module.css';

const HumanArcadeSmall: FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.shrink}>
        <HumanArcade {...props} readonly={true} />
      </div>
    </div>
  );
};

export default HumanArcadeSmall;