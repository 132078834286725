import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

type ResponsiveCols = '1-1' | '1-2' | '2-1' | '1-1-1';

type Props = {
  colsXs?: number;
  colsSm?: number;
  colsMd?: number;
  colsLg?: number;
  colsXlg?: number;
  spanFirst?: boolean;
  children: ReactNode;

  colsFrMd?: ResponsiveCols;
  colsFrLg?: ResponsiveCols;
};

const Responsive: FC<Props> = ({ children, colsXs, colsSm, colsMd, colsLg, colsXlg, spanFirst, colsFrMd, colsFrLg }) => {
  let classNames = [];

  if (colsXs) {
    if (spanFirst && colsXs === 3) {
      classNames.push(styles['responsive-xs-3-grow']);
    } else {
      classNames.push(styles[`responsive-xs-${colsXs}`]);
    }
  }

  if (colsSm) {
    if (spanFirst && colsSm === 3) {
      classNames.push(styles['responsive-sm-3-grow']);
    } else {
      classNames.push(styles[`responsive-sm-${colsSm}`]);
    }
  }

  if (colsMd) {
    if (spanFirst && colsMd === 3) {
      classNames.push(styles['responsive-md-3-grow']);
    } else {
      classNames.push(styles[`responsive-md-${colsMd}`]);
    }
  }

  if (colsLg) {
    if (spanFirst && colsLg === 3) {
      classNames.push(styles['responsive-lg-3-grow']);
    } else {
      classNames.push(styles[`responsive-lg-${colsLg}`]);
    }
  }

  if (colsXlg) {
    if (spanFirst && colsXlg === 3) {
      classNames.push(styles['responsive-xlg-3-grow']);
    } else {
      classNames.push(styles[`responsive-xlg-${colsXlg}`]);

    }
  }

  if(colsFrMd) {
    classNames.push(styles[`responsive-md-${colsFrMd}`]);
  }

  if(colsFrLg) {
    classNames.push(styles[`responsive-lg-${colsFrLg}`]);
  }

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
};

export default Responsive;