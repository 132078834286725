import { useCallback, useEffect, useState } from "react";
import { TableProps } from "../types";
import { useAppStoreHooks } from "../../toolkit";
import { debounce } from "lodash";

type Params = Record<string, string | number | boolean>;

const useInternalPaginatedList = (
  url: string,
  filterFunc?: (searchTerm: string) => (row: any) => boolean,
  params?: { sortingParams?: Params; queryParams: Params }
): TableProps => {
  const [tableState, setTableState] = useState<{
    page: number;
    total?: number;
    searchTerm?: string;
    filtered?: any[];
    results: any[];
    queryParams?: Params;
    sortingParams?: Params;
  }>({
    page: 0,
    results: [],
    sortingParams: params?.sortingParams,
    queryParams: params?.queryParams,
  });

  const { getJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const loadList = useCallback(
    async (queryParams?: Params, sortingParams?: Params) => {
      loadingStart();
      let internalUrl = url + (url.includes("?") ? "&" : "?");
      if (queryParams) {
        internalUrl = Object.keys(queryParams).reduce(
          (accumulator, current) => {
            if (queryParams![current] !== undefined) {
              accumulator += `&${current}=${queryParams![current]}`;
            }
            return accumulator;
          },
          internalUrl
        );
      }
      if (sortingParams) {
        internalUrl = Object.keys(sortingParams).reduce(
          (accumulator, current) => {
            if (sortingParams![current] !== undefined) {
              accumulator += `&${current}=${sortingParams![current]}`;
            }
            return accumulator;
          },
          internalUrl
        );
      }
      try {
        const results = await getJson<any[]>(internalUrl);
        setTableState((prevState) => ({
          ...prevState,
          results: [...(results || [])],
        }));
        loadingEnd();
      } catch (e: any) {
        notify(e.message || e, "error");
        loadingEnd();
      }
    },
    [url, setTableState, getJson, notify, loadingStart, loadingEnd]
  );

  const changePage = useCallback(
    (page: number) => {
      setTableState((prevState) => ({
        ...prevState,
        page,
      }));
    },
    [setTableState]
  );

  const setSortingParam = useCallback((sortingParams?: Params) => {
    setTableState(prevState => ({
      ...prevState,
      sortingParams
    }));
  }, [setTableState]);

  const setSearchTerm = debounce((searchTerm?: string) => {
    const filtered = !searchTerm || !filterFunc
      ? undefined
      : tableState.results.filter(filterFunc(searchTerm));
    setTableState((prevState) => ({
      ...prevState,
      filtered,
      searchTerm,
    }));
  }, 500);

  const reload = useCallback(
    (reset?: boolean) => {
      if (reset) {
        changePage(0);
      }
      loadList(tableState.queryParams, tableState.sortingParams);
    },
    [changePage, tableState.queryParams, tableState.sortingParams, loadList]
  );

  const setQueryParam = useCallback(
    (queryParams?: Params) => {
      setTableState((prevState) => ({
        ...prevState,
        queryParams,
      }));
    },
    [setTableState]
  );

  useEffect(() => {
    loadList(tableState.queryParams, tableState.sortingParams);
  }, [loadList, tableState.queryParams, tableState.sortingParams]);

  const final = tableState.filtered || tableState.results;
  const start = tableState.page * 10;
  const end = final.length - 1 - start < 0 ? final.length - 1 : start + 10;
  return {
    loading: false,
    isFiltered: Boolean(tableState.searchTerm),
    page: tableState.page,
    results: final.slice(start, end),
    total: (tableState.filtered || tableState.results).length,
    setSearchTerm,
    setQueryParam,
    setSortingParam,
    reload,
    changePage,
  };
};

export default useInternalPaginatedList;
