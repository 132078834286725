import { FC } from 'react';
import { IColumn, TableProps } from '../../../lib';
import { TitanStage } from '../../../models';
import { Button, ContainerFlex, EditNoteIcon, IconButton, StagesIcon, TrashIcon } from '../../../toolkit';
import { TableList } from '../../../components';

interface IDocument extends TitanStage {
  key: string;
}

type Props = {
  onOpenModal: (type: 'add' | 'edit' | 'delete' | 'configure', stage?: TitanStage) => void;
} & TableProps;

const StagesList: FC<Props> = (listProps) => {

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Nume',
      fieldName: 'name',
    },
    {
      key: 'description',
      name: 'Descriere',
      fieldName: 'description',
    },
    {
      key: 'user-elements',
      name: 'Elemente/Angajati',
      fieldName: '',
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1} align='center'>
            {item.stageElements?.length || 0}/{item.stageUsers?.length || 0}
            <Button type='button' variant='text' size='small' onClick={() => listProps.onOpenModal('configure', item)}>
              Configureaza
            </Button>
          </ContainerFlex>
        );
      }
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      width: 110,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1}>
            <IconButton variant='outlined' onClick={() => listProps.onOpenModal('edit', item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton variant='outlined' color='danger' onClick={() => listProps.onOpenModal('delete', item)}>
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    }
  ];

  const items = listProps.results.map((item: TitanStage, index: number) => {
    return {
      key: item.stageId,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });


  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici o etapa',
        emptyIcon: <StagesIcon size={40} />,
        emptyTitle: 'Momentan nu ai nici o etapa adaugata',
        emptySubTitle: 'Adauga prima etapa apasand pe butonul de jos',
        emptyAction: () => listProps.onOpenModal('add'),
        emptyActionText: 'Adauga etapa'
      }}
      isSubList={true}
    />
  );
};

export default StagesList;