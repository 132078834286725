import { FC } from "react";
import {
  ContainerFlex,
  Delimiter,
  Dropdown,
  Tabs,
  useAppStoreHooks,
} from "../../toolkit";
import styles from "./styles.module.css";
import { updateDefaultVitaColor, useAppSelector } from "../../store";

type Props = {
  selected?: string;
  onColorChange: (color: string) => void;
  errorMessage?: string;
};

const VITA_3D_MASTER = [
  {
    id: 1,
    colors: [
      { id: 1, color: "0M3" },
      { id: 2, color: "0M2" },
      { id: 3, color: "0M1" },
    ],
  },
  {
    id: 2,
    colors: [
      { id: 1, color: "1M1" },
      { id: 2, color: "1M2" },
    ],
  },
  {
    id: 3,
    colors: [
      { id: 1, color: "2L2.5" },
      { id: 2, color: "2L1.5" },
    ],
  },
  {
    id: 4,
    colors: [
      { id: 1, color: "2M3" },
      { id: 2, color: "2M2" },
      { id: 3, color: "2M1" },
    ],
  },
  {
    id: 5,
    colors: [
      { id: 1, color: "2R2.5" },
      { id: 2, color: "2R1.5" },
    ],
  },
  {
    id: 6,
    colors: [
      { id: 1, color: "3L2.5" },
      { id: 2, color: "3L1.5" },
    ],
  },
  {
    id: 7,
    colors: [
      { id: 1, color: "3M3" },
      { id: 2, color: "3M2" },
      { id: 3, color: "3M1" },
    ],
  },
  {
    id: 8,
    colors: [
      { id: 1, color: "3R2.5" },
      { id: 2, color: "3R1.5" },
    ],
  },
  {
    id: 9,
    colors: [
      { id: 1, color: "4L2.5" },
      { id: 2, color: "4L1.5" },
    ],
  },
  {
    id: 10,
    colors: [
      { id: 1, color: "4M3" },
      { id: 2, color: "4M2" },
      { id: 3, color: "4M1" },
    ],
  },
  {
    id: 11,
    colors: [{ id: 1, color: "4R2.5" }],
  },
  {
    id: 12,
    colors: [
      { id: 1, color: "5M3" },
      { id: 2, color: "5M2" },
      { id: 3, color: "5M1" },
    ],
  },
];

const VITA_CLASSIC = [
  {
    id: 1,
    colors: [
      { id: 1, color: "BL1" },
      { id: 2, color: "BL2" },
      { id: 3, color: "BL3" },
      { id: 4, color: "" },
      { id: 5, color: "BL4" },
    ],
  },
  {
    id: 2,
    colors: [
      { id: 1, color: "A1" },
      { id: 2, color: "A2" },
      { id: 3, color: "A3" },
      { id: 4, color: "A3.5" },
      { id: 5, color: "A4" },
    ],
  },
  {
    id: 3,
    colors: [
      { id: 1, color: "B1" },
      { id: 2, color: "B2" },
      { id: 3, color: "B3" },
      { id: 4, color: "" },
      { id: 5, color: "B4" },
    ],
  },
  {
    id: 4,
    colors: [
      { id: 1, color: "C1" },
      { id: 2, color: "C2" },
      { id: 3, color: "C3" },
      { id: 4, color: "" },
      { id: 5, color: "C4" },
    ],
  },
  {
    id: 5,
    colors: [
      { id: 1, color: "" },
      { id: 2, color: "D2" },
      { id: 3, color: "D3" },
      { id: 4, color: "" },
      { id: 5, color: "D4" },
    ],
  },
];

const ColorButton: FC<{
  color: string;
  selected: boolean;
  onClick: () => void;
}> = ({ color, selected, onClick }) => {
  return (
    <button
      className={[styles.colorButton, selected ? styles.active : ""].join(" ")}
      onClick={onClick}
    >
      {color}
    </button>
  );
};

const ColorDropdown: FC<Props> = ({
  selected,
  onColorChange,
  errorMessage,
}) => {
  const selectedTab =
    useAppSelector((state) => state.appSettings.defaultVitaColor) || 0;
  const { dispatch } = useAppStoreHooks();
  return (
    <Dropdown
      label="Culoare"
      value={selected}
      placeholder="Selecteaza o culoare"
      errorMessage={errorMessage}
    >
      <ContainerFlex
        type="column"
        spacing={1}
        onClick={(e) => e.stopPropagation()}
      >
        <ContainerFlex type="row">
          <Tabs
            selected={String(selectedTab)}
            items={[
              {
                id: "0",
                label: "Vita 3d Master",
              },
              {
                id: "1",
                label: "Vita Clasic",
              },
            ]}
            onTabChanged={(id) => {
              dispatch(updateDefaultVitaColor(Number(id)));
            }}
          />
        </ContainerFlex>
        <Delimiter />
        <ContainerFlex type="column" spacing={1}>
          {selectedTab === 0 &&
            VITA_3D_MASTER.map((v) => (
              <div className={styles.colorRow} key={v.id}>
                {v.colors.map((c) => (
                  <ColorButton
                    key={c.id}
                    color={c.color}
                    selected={selected === c.color}
                    onClick={() => onColorChange(c.color)}
                  />
                ))}
              </div>
            ))}
          {selectedTab === 1 &&
            VITA_CLASSIC.map((v) => (
              <div className={styles.colorRowClassic} key={v.id}>
                {v.colors.map((c) =>
                  c.color ? (
                    <ColorButton
                      key={c.id}
                      color={c.color}
                      selected={selected === c.color}
                      onClick={() => onColorChange(c.color)}
                    />
                  ) : (
                    <div></div>
                  )
                )}
              </div>
            ))}
        </ContainerFlex>
      </ContainerFlex>
    </Dropdown>
  );
};

export default ColorDropdown;
