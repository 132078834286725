import { FC } from "react";
import styles from "./styles.module.css";
import { Link, StarIcon, Tag, Typography } from "../../toolkit";
import { prettyDate } from "../../lib";

type Props = {
  isDemo: boolean;
  subscriptionDate?: Date;
  status: string;
};

const SubscriptionInfo: FC<Props> = ({ isDemo, subscriptionDate, status }) => {
  return (
    <div className={styles.card}>
      {isDemo && (
        <>
          <Tag type="default">Demo</Tag>
          <Typography type="p" variation="bold">
            Abonament DEMO
          </Typography>
          {subscriptionDate && (
            <Typography type="label" variation="secondary">
              Valabil pana la {prettyDate(subscriptionDate)}{" "}
            </Typography>
          )}
          <Link to="/settings/payments" variation="primary">
            Aboneaza-te acum
          </Link>
        </>
      )}
      {!isDemo && (
        <>
          <Tag type="info">
            <StarIcon /> Pro
          </Tag>
          <Typography type="p" variation="bold">
            Abonament Pro
          </Typography>
          {status === "active" && (
            <Typography type="label" variation="secondary">
              Subscriptia este activa
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionInfo;
