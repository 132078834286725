import { FC } from "react";
import { TitanElementStatistic } from "../../../models";
import { Card, Delimiter, Typography } from "../../../toolkit";
import { Responsive } from "../../../layout";
import { DonutChart } from "../../../components";
import { useCacheList } from "../../../lib";
import ElementStatisticsList from "./ElementStatisticsList";

type Props = {
  elementStatistics: TitanElementStatistic[];
};

const getTop5 = (statistic: any[], propName: string) => {
  const top5 = statistic.sort((a, b) => Number(a[propName]) - Number(b[propName]))
  .filter((_, index) => index < 5);

  return {
    labels: top5.map(t => t.label),
    series: top5.map(t => t[propName]),
  }
}

const getTopStatistics = (elementStatistics: TitanElementStatistic[]) => {
  const mappedData = elementStatistics.map(row => ({
    label: row.elementName,
    totalElements: row.count,
    totalPrice: row.totalPrice
  }));
  return {
    elements: getTop5(mappedData, 'totalElements'),
    paid: getTop5(mappedData, 'totalPrice'),
  }
}

const ElementStatistics: FC<Props> = ({ elementStatistics}) => {
  const listProps = useCacheList({ data: elementStatistics, loading: false }, () => () => true, () => ({ payload: undefined, type: 'fake'}))
  const {  elements, paid } = getTopStatistics(elementStatistics);
  return (
    <>
      <Card>
        <Typography type="h5">Top elemente</Typography>
        <Delimiter />
        <Responsive colsFrMd="1-1">
          <DonutChart name="Elemente" series={elements.series} labels={elements.labels}  />
          <DonutChart name="Pret" series={paid.series} labels={paid.labels}  />
        </Responsive>
      </Card>
      <ElementStatisticsList {...listProps}/>
    </>
  )
}

export default ElementStatistics