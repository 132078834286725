import { useCallback, useEffect, useState } from 'react';
import { TitanPrice, TitanProduct } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { API_URLS } from '../../lib';

const intervalMap = {
  'Per month': '1month',
  'Every 6 months': '6month',
  'Per year': '1year',
};

const useCreateSubscription = () => {
  const [products, setProducts] = useState<{ [key: string]: TitanPrice }>();
  const [selectedPriceId, setSelectedPriceId] = useState<string>();
  const { getJson, postJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const checkoutSubscription = useCallback(
    async (priceId: string) => {
      loadingStart();
      try {
        const response = await postJson<{ sessionUrl: string }>(
          API_URLS.platform.subsciption.checkout,
          { priceId }
        );
        loadingEnd();
        window.location.href = response.sessionUrl;
      } catch (e: any) {
        loadingEnd();
        notify(e.message, 'error');
      }
    },
    [postJson, notify, loadingStart, loadingEnd]
  );

  const loadProducts = useCallback(async () => {
    loadingStart();
    try {
      const response = await getJson<TitanProduct>(
        API_URLS.platform.titanProduct
      );
      const prices = response.prices.reduce((accumulator, current) => {
        accumulator[intervalMap[current.interval]] = {
          ...current,
        };
        return accumulator;
      }, {} as { [key: string]: TitanPrice });
      setProducts(prices);
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  }, [getJson, setProducts, notify, loadingStart, loadingEnd]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  return {
    products,
    selectedPriceId,
    setSelectedPriceId,
    checkoutSubscription,
  };
};

export default useCreateSubscription;
