import { FC, ReactNode } from "react";
import styles from "./styles.module.css";

type Props = {
  vertical?: boolean;
  grow?: boolean;
  horizontalAlign?: "spaceBetween" | "middle" | "end";
  verticalAlign?: "center";
  children: ReactNode;
  gap?: number;
  maxWidth?: number;
  minHeight?: number;
  className?: string;
};

const Container: FC<Props> = ({
  vertical,
  grow,
  horizontalAlign,
  verticalAlign,
  children,
  gap,
  maxWidth = 1200,
  minHeight,
  className,
}) => {
  const classes = [styles.flex];
  if (className) {
    classes.push(className);
  }
  if (vertical) {
    classes.push(styles.vertical);
  }

  if(grow){
    classes.push(styles.grow);
  }

  if (horizontalAlign) {
    classes.push(styles[horizontalAlign]);
  }

  if (verticalAlign) {
    classes.push(styles[verticalAlign]);
  }

  return (
    <div className={classes.join(" ")} style={{ gap, maxWidth, minHeight }}>
      {children}
    </div>
  );
};

export default Container;
