import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
export type ToastMessageType = {
  id: number;
  type: 'error' | 'success' | 'warning';
  message: string;
  details?: string;
};

export interface AppToastState {
  value: ToastMessageType[];
}

const initialState: AppToastState = {
  value: []
};

type ToastMessagePayload = {
  type: 'error' | 'success' | 'warning';
  message: string;
  details?: string;
};

export const toastSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastMessagePayload>) => {
      state.value = [...state.value, { ...action.payload, id: Date.now() }];
    },
    clearToast: (state, action: PayloadAction<number>) => {
      state.value = state.value.filter(n => n.id !== action.payload);
    },
  }
});

export const {
  showToast,
  clearToast,
} = toastSlice.actions;

export default toastSlice.reducer;