import { useState } from "react";
import { TitanCaseStage } from "../../models";
import { useAppSelector } from "../../store";
import { useAppStoreHooks } from "../../toolkit";
import { API_URLS } from "../../lib";

const useCaseStagesChange = (titanCaseId: number, caseStages: TitanCaseStage[], onCaseStagesChanged: (caseStages: TitanCaseStage[]) => void) => {
  const stages = useAppSelector(state => state.cache.stages.data);
  const users = useAppSelector(state => state.cache.users.data);
  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const [caseStage, setCaseStage] = useState<TitanCaseStage>()
  const userStatges = stages?.find(s => s.stageId === caseStage?.stageId)?.stageUsers || [];

  const onToggleCaseStage = (caseStage?: TitanCaseStage) => {
    setCaseStage(caseStage);
  }

  const onAssignedUserChanged = (titanUserId: string) => {
    setCaseStage(prevState => ({
      ...(prevState || {} as TitanCaseStage),
      titanUserId
    }))
  }

  const onSaveCaseStages = async () => {
    const caseStagesCopy = [...caseStages];
    const index = caseStagesCopy.findIndex(cs => cs.caseStageId === caseStage?.caseStageId);
    caseStagesCopy[index] = {
      ...(caseStage || {} as TitanCaseStage)
    }
    loadingStart();
    try {
      const updatedCaseStages = await postJson<TitanCaseStage[]>(API_URLS.titan.cases.updateCaseStages, {
        caseStages: caseStagesCopy,
        titanCaseId
      });

      const properCaseStages = updatedCaseStages.map(cs => {
        const user = users?.find(u => u.userIdentifier === cs.titanUserId);
        const stage = stages?.find(s => s.stageId === cs.stageId);

        return {
          ...cs,
          stageName: stage?.name,
          titanUserName: `${user?.firstName} ${user?.lastName} `
        }
      }) as TitanCaseStage[]

      onCaseStagesChanged(properCaseStages);
      setCaseStage(undefined);
      loadingEnd();
      notify('Etapa a fost atribuita.', 'success');
    } catch (e: any) {
      setCaseStage(undefined);

      loadingEnd();
      notify(e.message, 'error');
    }
  }

  return {
    caseStage,
    userStatges,
    onToggleCaseStage,
    onAssignedUserChanged,
    onSaveCaseStages
  }
}

export default useCaseStagesChange;
