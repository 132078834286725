import { FC } from "react";
import { IColumn, TableProps, formatMoney } from "../../../lib";
import { TitanUserElementReport } from "../../../models";
import { TableList } from "../../../components";
import { ToothIcon } from "../../../toolkit";
import UserStageElementStatisticsList from "./UserStageElementStatisticsList";

type Props = {} & TableProps;

type IDocument = {
  key: string;
  index: number;
} & TitanUserElementReport;

const UserElementStatisticsList: FC<Props> = (listProps) => {
  const columns: IColumn[] = [
    {
      key: "name",
      name: "Element",
      fieldName: "name",
    },
    {
      key: "priceForElements",
      name: "Pret element",
      fieldName: "priceForElements",
      onRender: (item: any) => `${formatMoney(item.priceForElements)} RON`,
    },
    {
      key: "count",
      name: "Cantitate",
      fieldName: "count",
    },
    {
      key: "amountPaid",
      name: "Suma",
      fieldName: "amountPaid",
      onRender: (item: any) => `${formatMoney(item.amountPaid)} RON`,
    },
  ];

  const items: IDocument[] = listProps.results.map(
    (item: TitanUserElementReport, index: number) => {
      return {
        key: String(item.elementId),
        index: index + 1,
        ...item,
      };
    }
  );

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      subTableComponent={({ row }) => (
        <UserStageElementStatisticsList stages={row.stages} />
      )}
      stateAssets={{
        emptySearchText: "Nu a fost gasit nici un element",
        emptyIcon: <ToothIcon size={40} />,
        emptyTitle: "Nu exista statistici in perioada selectata.",
      }}
    />
  );
};

export default UserElementStatisticsList;
