import { FC } from 'react';
import { DownloadIcon, FileDownload1Icon, FileDownload2Icon, Menu, MenuItem } from '../../../toolkit';

type Props = {
  onDownload: (type: 'elements' | 'cases' | 'both') => void
}

const TenderDownloadMenu: FC<Props> = ({ onDownload }) => {
  return (
    <Menu text='Descarca Deviz'>
      <MenuItem onClick={() => onDownload('elements')}>
        <FileDownload1Icon /> Deviz Elemente
      </MenuItem>
      <MenuItem onClick={() => onDownload('cases')}>
        <FileDownload2Icon /> Deviz Cazuri
      </MenuItem>
      <MenuItem onClick={() => onDownload('both')}>
        <DownloadIcon /> Deviz Combinat
      </MenuItem>
    </Menu>
  );
};

export default TenderDownloadMenu;
