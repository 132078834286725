import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  variation?: 'default' | 'primary' | 'danger'
};

const AnimatedAppIcon: FC<Props> = ({ children, variation = 'primary' }) => {
  return (
    <div className={[styles.icon, styles[variation]].join(' ')}>
      <div className={styles.animation}>
        {children}
      </div>
    </div>
  );
};

export default AnimatedAppIcon;