import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ContainerFlex } from '../../toolkit';

type Props = {
  value: number;
};

const GaugeChart: FC<Props> = ({ value }) => {
  const series = [value];

  const options: ApexOptions = {
    plotOptions: {
      radialBar: {
        track: {
          background: "#EBF1F5",
        },
        dataLabels: {
          show: false,
          value: {
            show: true,
            fontSize: "16px"
          },
          total: {
            show: true,
            label: "ROI",
            color: "#373d3f"
          }
        }
      }
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["#3D9BCE"]
  };

  return (
    <ContainerFlex type='row' justify='center'>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        width="300"
      />
    </ContainerFlex>
  );
};

export default GaugeChart;