import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../store';
import LoginLayout from '../LoginLayout/LoginLayout';
import ToastLayout from './ToastLayout/ToastLayout';
import LoadingLayout from './LoadingLayout/LoadingLayout';

type Props = {
  children: ReactNode;
};

const isPublicFromPath = (path: string) =>
  path.includes('login') ||
  path.includes('sign-up') ||
  path.includes('forgot-password') ||
  path.includes('recover-password') ||
  path.includes('confirm-email');




const TitanContainer: FC<Props> = ({ children }) => {
  const token = useAppSelector(state => state.auth.token);
  const location = useLocation();
  const [isPublicPage, setIsPublicPage] = useState(isPublicFromPath(location.pathname));

  useEffect(() => {
    setIsPublicPage(isPublicFromPath(location.pathname));
  }, [setIsPublicPage, location.pathname]);


  const isLogin = !token?.value || isPublicPage;

  return (
    <>
      {isLogin ? (
        <LoginLayout>
          {children}
        </LoginLayout>

      ) : (
        <>{children}</>
      )}
      <ToastLayout />
      <LoadingLayout />
    </>
  );
};

export default TitanContainer;