import { FC, ReactNode } from "react";
import {
  Button,
  Card,
  Star3Icon,
  Star4Icon,
  StarIcon,
  Tag,
  Typography,
} from "../../toolkit";
import styles from "./styles.module.css";

export type SubscriptionConfig = {
  icon: ReactNode;
  title: string;
  details: string;
  price: string;
  discount?: string;
};

export const SUBSCRIPTIONS: Record<string, SubscriptionConfig> = {
  monthly: {
    icon: <StarIcon size={32} />,
    title: "Abonament Lunar",
    details: "abonamentul cu plata in fiecare luna",
    price: "150 RON",
  },
  semester: {
    icon: <Star3Icon size={32} />,
    title: "Abonament Bianual",
    details: "abonamentul cu plata la 6 luni",
    price: "810 RON",
    discount: "-10% Discount",
  },
  yearly: {
    icon: <Star4Icon size={32} />,
    title: "Abonament Anual",
    details: "abonamentul cu plata o data pe an",
    price: "1500 RON",
    discount: "2 Luni Gratuit",
  },
};

type Props = {
  type: keyof typeof SUBSCRIPTIONS;
  onPlanClick: () => void;
  isCurrent: boolean;
  hasSubscription: boolean;
};

const SubscriptionPlan: FC<Props> = ({
  type,
  onPlanClick,
  isCurrent,
  hasSubscription,
}) => {
  return (
    <Card className={styles.planCard}>
      {SUBSCRIPTIONS[type].icon}
      <div className={styles.plan}>
        <Typography type="h4">{SUBSCRIPTIONS[type].title}</Typography>
        <Typography type="span" variation="secondary">
          Cumpara {SUBSCRIPTIONS[type].details}
        </Typography>
      </div>
      <div className={styles.price}>
        <Typography type="h3">{SUBSCRIPTIONS[type].price}</Typography>
        {SUBSCRIPTIONS[type].discount && (
          <Tag type="info">{SUBSCRIPTIONS[type].discount}</Tag>
        )}
      </div>
      {isCurrent ? (
        <Button type='button' size="medium" variant="outlined" onClick={onPlanClick}>
          Genstioneaza abonamentul
        </Button>
      ) : (
        <Button type='button' size="medium" variant="contained" onClick={onPlanClick}>
          {hasSubscription ? "Schimba abonamentul" : "Alege abonament"}
        </Button>
      )}
    </Card>
  );
};

export default SubscriptionPlan;
