import { FC, ReactNode } from 'react';
import styles from './styles.module.css';
import { Card, Typography } from '../../toolkit';

type Props = {
  label: string;
  metric: string;
  icon: ReactNode;
};

const Metric: FC<Props> = ({  label, metric, icon}) => {
  return (
    <Card className={styles.metricCard}>
      <div className={styles.metric}>
        <Typography type='p' variation='secondary'>{label}</Typography>
        <Typography type='h4'>{metric}</Typography>
      </div>
      {icon}
    </Card>
  );
};

export default Metric;