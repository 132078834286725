import { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  LoginPageTranslation,
  textPage,
  textForm,
} from "../../../translations";
import {
  Button,
  Input,
  InputPassword,
  Link,
  Typography,
} from "../../../toolkit";
import useLoginForm from "./useLoginForm";
import styles from "./styles.module.css";

const texts = textPage("login") as LoginPageTranslation;

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const { loginForm, onChangeHandler, onSubmitHandler } = useLoginForm();

  return (
    <form noValidate onSubmit={onSubmitHandler}>
      <Input
        label={textForm.emailLabel}
        placeholder={textForm.emailPlaceholder}
        name="email"
        onChange={onChangeHandler}
        value={loginForm.form.email}
        required
        errorMessage={loginForm.errors.email}
      />
      <InputPassword
        name="password"
        onChange={onChangeHandler}
        value={loginForm.form.password}
        required
        errorMessage={loginForm.errors.password}
      />
      <Button
        size="medium"
        variant="text"
        type="button"
        onClick={() => navigate("/forgot-password")}
      >
        {texts.forgotPassword}
      </Button>
      <Button
        size="medium"
        variant="contained"
        type="submit"
        autoFocus={true}
        className={styles.button}
      >
        {texts.btnTxt}
      </Button>
      <Typography type="p" variation="secondary" variation1="center">
        {texts.noAccount}{" "}
        <Link to="/sign-up" role="link" aria-disabled="true">
          {texts.createAccount}
        </Link>
      </Typography>
    </form>
  );
};

export default LoginForm;
