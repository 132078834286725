import { FC } from "react";
import { useNavigate} from 'react-router-dom';

import { AppTitle } from '../../layout';
import { AnimatedAppIcon, Card, Typography, Button, CheckIcon } from '../../toolkit';
import { SignUpPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('signUp') as SignUpPageTranslation;

const AcctivateAccountSuccessPage: FC = () => {
  const navigate = useNavigate();
  return (
    <AppTitle title={texts.activateAccount}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <CheckIcon />
        </AnimatedAppIcon>
        <Typography type='h2' variation='center'>{texts.congrats}</Typography>
        <Typography type='p' variation='center' variation1='secondary'>{texts.activatedAccount}</Typography>
        <Button
        type='button'
          size='medium'
          variant='contained'
          className={styles.button}
          onClick={() => navigate('/')}>
          {texts.enterAccount}
        </Button>
      </Card>
    </AppTitle>
  );
};

export default AcctivateAccountSuccessPage;