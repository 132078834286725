import { FC, useEffect, useState } from 'react';
import { ArrowDownIcon, ArrowRightIcon, Card, Delimiter, FeatureIcon, IconButton, Typography } from '../../toolkit';
import styles from './styles.module.css';

type Props = {
  expanded: boolean;
};

const FunctionalityList: FC<Props> = ({ expanded }) => {
  const [collapsed, setCollapsed] = useState(!expanded);
  useEffect(() => {
    setCollapsed(!expanded);
  }, [expanded]);

  return (
    <Card type="column" spacing={2}>
      <div className={styles.listTitle}>
        <div>
          <Typography type='h4'>Functionalitati</Typography>
          <Typography type='p' variation='secondary'>Fiecare abonament include urmatoarele functionalitati</Typography>
        </div>
        <div>
          <IconButton variant='outlined' onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <ArrowRightIcon />
            ) : (
              <ArrowDownIcon />
            )}
          </IconButton>
        </div>
      </div>
      {!collapsed && (
        <>
          <Delimiter />
          <div className={styles.feature}>
            <div className={styles.title}>
              <FeatureIcon />
              <Typography type='p' variation='bold'>Administrare Colaboratori</Typography>
            </div>
            <Typography type='span' variation='secondary'>
              Adauga colaboratorii tai in platforma, asa vei putea urmarii cu usurinta cazurile adaugate, platile efectuate si vei putea acorda reduceri. Totodata vei avea acces la modulul de raportare, unde se pot extrage rapoarte pentru cazurile inregistrate pe orice perioada.
            </Typography>
          </div>
          <div className={styles.feature}>
            <div className={styles.title}>
              <FeatureIcon />
              <Typography type='p' variation='bold'>Administrare Cazuri</Typography>
            </div>
            <Typography type='span' variation='secondary'>
              Adauga cazurile in platforma configurand elemente, culoare dupa standardul Vita 3D Master sau Vita Clasic, forma lucrare si alte detalii cum ar fii notite, imagine cu fisa primita de la medic sau pur si simplu stabileste un termen lucrare alegand o data si ora pentru proba
            </Typography>
          </div>
          <div className={styles.feature}>
            <div className={styles.title}>
              <FeatureIcon />
              <Typography type='p' variation='bold'>Administrare Elemente</Typography>
            </div>
            <Typography type='span' variation='secondary'>
              Adauga elementele in platforma si vei putea configura lista de preturi intr-un mod unitar, ea fiind folosita pentru rapoarte si fisa caz.
            </Typography>
          </div>
          <div className={styles.feature}>
            <div className={styles.title}>
              <FeatureIcon />
              <Typography type='p' variation='bold'>Vizualizare Statistici</Typography>
            </div>
            <Typography type='span' variation='secondary'>
              Vezi in timp real care este statusul afacerii tale pe o perioada anume. Vei putea urmarii medici cu cele mai multe cazuri, elemente si care este statusul platii lucrarilor sau cele mai frecvente elemente.
            </Typography>
          </div>
          <div className={styles.feature}>
            <div className={styles.title}>
              <FeatureIcon />
              <Typography type='p' variation='bold'>Adauga angajati</Typography>
            </div>
            <Typography type='span' variation='secondary'>
              Nu esti limitat la un singur utilizator in aplicatie. Cu acest modul poti da drepturi unui angajat sa adauge cazuri, sa vizualizeze calendarul intr-un mod limitat, el neavand acces la anumite module ale aplicatiei
            </Typography>
          </div>
        </>
      )}
    </Card>
  );
};

export default FunctionalityList;