import { FC } from 'react';
import { AnimatedAppIcon, Card, ContainerFlex, IconButton, StagesIcon, TrashIcon, Typeahead, TypeaheadOption, Typography } from '../../../toolkit';
import { TitanElementStage } from '../../../models';

type Props = {
  elementStages: TitanElementStage[];
  stagesOptions: TypeaheadOption[];
  onStageChanged: (stageId: number) => void;
  onRemoveElementStage: (index: number) => void;
};

const ElementFormConfigureStages: FC<Props> = ({
  elementStages,
  stagesOptions,
  onStageChanged,
  onRemoveElementStage,
}) => {
  return (
    <ContainerFlex type="column" spacing={1}>
      <Typeahead
        label="Alege etapa"
        // selectedKey={elementForm.form.elementId}
        options={stagesOptions || []}
        onChange={selectedKey => onStageChanged(selectedKey as number)}
      />
      {elementStages.length > 0 && (
        <Card variant='secondary' type="column" spacing={2}>
          <Typography type="span" variation="bold">Etape Adaugate</Typography>
          {elementStages.map((stage, index) => (
            <ContainerFlex key={stage.stageId} type="row" justify="space-between">
              <Typography type="p">{stage.stageName}</Typography>
              <IconButton variant='outlined' color='danger' onClick={() => onRemoveElementStage(index)}>
                <TrashIcon />
              </IconButton>
            </ContainerFlex>
          ))}
        </Card>
      )}

      {elementStages.length === 0 && (
        <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
          <AnimatedAppIcon>
            <StagesIcon size={40} />
          </AnimatedAppIcon>
          <Typography type='p' variation="center">
            Nu exista etape asociate.<br />
            <Typography type='span' variation='secondary'>
              Alege o etapa pentru a incepe.
            </Typography>
          </Typography>

        </ContainerFlex>
      )}
      <br />
    </ContainerFlex>
  );
};

export default ElementFormConfigureStages;