import { FC } from 'react';

type Props = {
  size?: number;
};

const Star3Icon: FC<Props> = ({ size = 12 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill="none">
      <path d="M6.00001 29.3327V22.666M6.00001 9.33268V2.66602M2.66667 5.99935H9.33334M2.66667 25.9993H9.33334M17.3333 3.99935L15.0211 10.0112C14.6451 10.9888 14.4571 11.4776 14.1647 11.8888C13.9056 12.2532 13.5872 12.5716 13.2228 12.8307C12.8116 13.1231 12.3228 13.3111 11.3452 13.6871L5.33334 15.9993L11.3452 18.3116C12.3228 18.6876 12.8116 18.8756 13.2228 19.168C13.5872 19.4271 13.9056 19.7455 14.1647 20.1099C14.4571 20.5211 14.6451 21.0099 15.0211 21.9875L17.3333 27.9993L19.6456 21.9875C20.0216 21.0099 20.2096 20.5211 20.502 20.1099C20.7611 19.7455 21.0795 19.4271 21.4439 19.168C21.8551 18.8756 22.3439 18.6876 23.3215 18.3116L29.3333 15.9993L23.3215 13.6871C22.3439 13.3111 21.8551 13.1231 21.4439 12.8307C21.0795 12.5716 20.7611 12.2532 20.502 11.8888C20.2096 11.4776 20.0216 10.9888 19.6456 10.0112L17.3333 3.99935Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Star3Icon;