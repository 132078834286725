import { FC } from 'react';
import { AnimatedAppIcon, Button, ContainerFlex, TrashIcon, Typography } from '../../../toolkit';
import { useCaseDelete } from '../../hooks';

type Props = {
  caseId: number;
  onDismiss: () => void;
};

const CaseDelete: FC<Props> = ({ caseId, onDismiss }) => {
  const { onDelete } = useCaseDelete(caseId);
  return (
    <ContainerFlex type='column' spacing={3}>
      <AnimatedAppIcon variation='danger'>
        <TrashIcon size={40} />
      </AnimatedAppIcon>
      <Typography type='p' variation='center'>
        Acest caz va fi sters din baza de date.
      </Typography>
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onDismiss}>Renunta</Button>
        <Button type='button' size='medium' variant='danger' onClick={() => onDelete()}>Sterge</Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default CaseDelete;