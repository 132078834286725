import { FC } from "react";
import { TitanUser } from "../../../models";
import { Button, Input } from '../../../toolkit';
import { textForm } from '../../../translations';
import { Container } from '../../../layout';
import styles from './styles.module.css';
import { useUser } from "../../hooks";

type Props = {
  user: TitanUser;
  onChangePassword: () => void;
};

const MyUserForm: FC<Props> = ({ user, onChangePassword }) => {
  const { userForm, onChange, onSubmit } = useUser(() => { }, user, undefined, true);

  return (
    <div>
      <form noValidate onSubmit={onSubmit}>
        <Input
          label={textForm.lastNameLabel}
          placeholder={textForm.lastNamePlaceholder}
          name="lastName"
          onChange={onChange}
          value={userForm.form.lastName}
          required
          errorMessage={userForm.errors.lastName}
        />
        <Input
          label={textForm.firstNameLabel}
          placeholder={textForm.firstNamePlaceholder}
          name="firstName"
          onChange={onChange}
          value={userForm.form.firstName}
          required
          errorMessage={userForm.errors.firstName}
        />
        <Input
          label={textForm.emailLabel}
          placeholder={textForm.emailPlaceholder}
          name="email"
          onChange={onChange}
          value={userForm.form.email}
          required
          errorMessage={userForm.errors.email}
        />

        <div className={styles.passwordField}>
          <Input
            label={textForm.passwordLabel}
            placeholder={textForm.passwordPlaceholder}
            type="password"
            name="password"
            onChange={onChange}
            value="HARDCODED_PASSWORD"
            required
            errorMessage={userForm.errors.confirmPassword}
            disabled
          />
          <div className={styles.passwordButton}>
            <Button type='button' size='medium' variant='text' onClick={onChangePassword}>Schimba</Button>
          </div>
        </div>
        <Container gap={10} horizontalAlign='middle'>
          <Button size='medium' variant='contained' type="submit">Salveaza</Button>
        </Container>
      </form>
    </div>
  );
};

export default MyUserForm;