import { FC } from 'react';

const Tooth41: FC = () => {
  return (
    <svg width="21" height="46" viewBox="0 0 21 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M9.45101 1.82299C7.60401 2.94499 5.475 5.912 3.428 10.122C1.259 14.652 0.576997 17.459 1.219 19.503C1.604 20.785 2.088 21.526 3.29 22.208C3.968 22.593 4.873 22.959 6.118 23.392C9.692 24.635 12.543 24.475 16.076 22.871C16.614 22.631 17.088 22.384 17.5 22.118C19.98 20.519 20.241 18.251 18.967 12.888C17.642 7.39599 15.474 3.226 13.185 1.863C11.579 0.860996 11.017 0.860987 9.45101 1.82299Z" />
      <path className='fundal' d="M3.29 22.208C2.088 21.526 1.604 20.785 1.219 19.503C0.576997 17.459 1.259 14.652 3.428 10.122C5.475 5.912 7.60401 2.94499 9.45101 1.82299C11.017 0.860987 11.579 0.860996 13.185 1.863C15.474 3.226 17.642 7.39599 18.967 12.888C20.241 18.251 19.98 20.519 17.5 22.118M3.29 22.208C3.968 22.593 4.873 22.959 6.118 23.392C9.692 24.635 12.543 24.475 16.076 22.871C16.614 22.631 17.088 22.384 17.5 22.118M3.29 22.208C7.193 22.217 15.5 22.212 17.5 22.118Z" />
      <path className='interior' d="M14.043 18.649C14.043 18.649 14.042 18.649 14.038 18.648C14.041 18.649 14.043 18.649 14.043 18.649ZM13.46 19.02C11.94 19.483 10.018 19.614 8.30797 19.48C7.44997 19.413 6.62997 19.278 5.92897 19.078C5.23797 18.881 4.61497 18.607 4.19197 18.235L4.17997 18.224L4.16898 18.213C4.13498 18.179 3.94598 17.99 4.00898 17.703C4.07398 17.401 4.34197 17.314 4.39197 17.299C4.54297 17.252 4.72098 17.261 4.84298 17.271C5.13198 17.294 5.54997 17.37 6.03897 17.492C6.94897 17.679 9.33897 17.8 11.286 17.721C12.118 17.681 12.758 17.653 13.224 17.641C13.67 17.629 14.009 17.63 14.201 17.662C14.247 17.67 14.336 17.686 14.424 17.732C14.469 17.755 14.552 17.806 14.619 17.902C14.699 18.016 14.733 18.162 14.701 18.309C14.674 18.434 14.608 18.518 14.571 18.558C14.531 18.602 14.49 18.633 14.462 18.652C14.406 18.69 14.348 18.718 14.302 18.739C14.124 18.818 13.821 18.913 13.46 19.02Z" />
      <path className='text' d="M7.78499 45.52V43.9H3.99298V42.556L7.32898 37.144H9.28499V42.676H10.377V43.9H9.28499V45.52H7.78499ZM5.28899 42.676H7.78499V38.68H7.68898L5.28899 42.676Z"  />
      <path className='text' d="M11.896 45.52V44.236H14.2V38.284H14.092L12.268 40.444L11.284 39.616L13.36 37.144H15.748V44.236H17.668V45.52H11.896Z"  />
    </svg>
  );
};

export default Tooth41;