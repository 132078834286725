import textPages from './pages';
import textForm from './forms';
import textMessages from './messages';

export type TransationPage = keyof typeof textPages;
export type LoginPageTranslation = Record<keyof typeof textPages['login'], string>;
export type SignUpPageTranslation = Record<keyof typeof textPages['signUp'], string>;
export type ForgotPasswordPageTranslation = Record<keyof typeof textPages['forgotPassword'], string>;
export type RecoverPasswordPageTranslation = Record<keyof typeof textPages['recoverPassword'], string>;
export type MenuTranslation = Record<keyof typeof textPages['menu'], string>;
export type NotFoundPageTranslation = Record<keyof typeof textPages['notFound'], string>;

export const textPage = (page: TransationPage) => {
  return textPages[page];
};

export { textForm };
export { textMessages };
