import { useCallback, useEffect, useState } from 'react';
import { TitanClinic } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { API_URLS } from '../../lib';

const useClinicDetails = (clinicId?: number) => {
  const [clinic, setClinic] = useState<TitanClinic>();
  const { getJson, loadingStart, loadingEnd, notify } = useAppStoreHooks();

  const loadClinic = useCallback(async (clinicId: number) => {
    loadingStart();
    try {
      const clinicDetails = await getJson<TitanClinic>(`${API_URLS.titan.clinic.clinic}/${clinicId}`);
      if (clinicDetails) {
        setClinic(clinicDetails);
      } else {
        notify(textMessages.genericError, 'error');
      }
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error');
    }
  }, [getJson, loadingStart, loadingEnd, notify, setClinic]);


  useEffect(() => {
    if (clinicId) {
      loadClinic(clinicId);
    }
  }, [loadClinic, clinicId]);

  return clinic;
};

export default useClinicDetails
