import { FC } from 'react';
import { Container } from '../../layout';
import { Card, Typography } from '../../toolkit';
import { useAppSelector } from '../../store';
import AvatarProfile from '../ProfileAvatar/ProfileAvatar';
import { UserCompany, useCompanyLogo } from '../../modules';

const CompanyProfile: FC = () => {
  const company = useAppSelector(state => state.cache.company.company!);
  const {companyLogo, onUploadLogo } = useCompanyLogo();
  return (
    <Container vertical gap={24} maxWidth={560}>
      <Typography type='h5'>Profil companie</Typography>
      <Card>
        <Container vertical gap={24}>
          <AvatarProfile 
            text=''
            url={companyLogo}
            label="Logo Companie"
            onUpload={onUploadLogo}
          />
          <UserCompany company={company} />
        </Container>
      </Card>
    </Container>
  );
};

export default CompanyProfile;