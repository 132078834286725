import { Content } from 'pdfmake/interfaces';
import {
  TitanCompany,
  TitanUserElementReport,
  TitanUserStatistic,
} from '../../models';
import { formatDate } from '../dateFunctions';
import { createReport } from './reportUtils';

const getGeneralDetails = (userStatistics: TitanUserStatistic) => {
  return {
    layout: 'noBorders',
    table: {
      headerRows: 0,
      widths: ['*', '*'],

      body: [
        [
          {
            stack: [
              {
                text: 'Data',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: formatDate(new Date()),
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Angajat',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: `${userStatistics.firstName} ${userStatistics.lastName}`,
                color: '#ffffff',
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 0],
          },
        ],
        [
          {
            stack: [
              {
                text: 'Perioada raport',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: `${formatDate(userStatistics.from)} - ${formatDate(
                  userStatistics.to
                )}`,
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Cheltuieli salariale',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: userStatistics.amountPaid.toFixed(2),
                color: '#ffffff',
                bold: true,
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 5],
          },
        ],
      ],
    },
  };
};

const getElements = (elementReportModels: TitanUserElementReport[]) => {
  const elements = elementReportModels.map((element) => [
    {
      text: element.name,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${element.priceForElements} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: element.count,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${element.amountPaid.toFixed(2)} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
  ]);
  return {
    elementTotal: elementReportModels.reduce(
      (acc, current) => acc + current.count,
      0
    ),
    elements,
  };
};

const getElementList = (userStatistics: TitanUserStatistic) => {
  const { elementTotal, elements } = getElements(
    userStatistics.elementReportModels
  );
  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['*', 'auto', 'auto', 'auto'],

      body: [
        [
          {
            text: 'Element',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Pret Element',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Cantitate',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Suma',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...elements,
        [
          {
            text: 'TOTAL',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            fillColor: '#2F3640',
          },
          {
            text: elementTotal,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: `${userStatistics.amountPaid.toFixed(2)} RON`,
            bold: true,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
        ],
      ],
    },
  };
};

const getStages = (elementReportModels: TitanUserElementReport[]) => {
  const stageArray = elementReportModels
    .map((element) => {
      return element.stages.map((stage) => ({
        stageName: stage.name,
        elementName: element.name,
        elementPrice: element.priceForElements,
        paidRate: stage.value,
        isPercentage: stage.isPercent,
        count: element.count,
        paid: stage.total,
      }));
    })
    .flat();

  const stages = stageArray.map((stage) => [
    {
      text: stage.stageName,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: stage.elementName,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${stage.elementPrice} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: stage.count,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: stage.isPercentage ? `${stage.paidRate}%` : `${stage.paidRate} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },

    {
      text: `${stage.paid.toFixed(2)} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
  ]);
  return stages;
};

const getStagesList = (userStatistics: TitanUserStatistic) => {
  const stages = getStages(
    userStatistics.elementReportModels
  );
  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['*', '*', 'auto', 'auto', 'auto', 'auto'],

      body: [
        [
          {
            text: 'Etapa',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Element',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Pret',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Cantitate',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Rata',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Suma',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...stages,
        [
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: '',
          },
          {
            text: 'TOTAL',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            fillColor: '#2F3640',
          },
          {
            text: `${userStatistics.amountPaid.toFixed(2)} RON`,
            bold: true,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
        ],
      ],
    },
  };
};

const generateEmployeeReport = (
  userStatistics: TitanUserStatistic,
  company: TitanCompany,
  logo: string
): void => {
  const content = [
    {
      text: 'DETALII GENERALE',
      style: 'section',
      margin: [0, 20, 0, 0],
    },
    getGeneralDetails(userStatistics),
    {
      text: 'ELEMENTE',
      style: 'section',
      margin: [0, 50, 0, 0],
    },
    getElementList(userStatistics),
    {
      text: 'ETAPE',
      style: 'section',
      margin: [0, 50, 0, 0],
    },
    getStagesList(userStatistics),
  ] as Content[];

  createReport(content, {
    header: {
      title: 'RAPORT ANGAJAT',
    },
    branding: {
      company,
      logo,
    },
    name: `raport ${userStatistics.firstName} ${
      userStatistics.lastName
    } ${formatDate(userStatistics.from)} ${formatDate(userStatistics.to)}`,
  });
};

export default generateEmployeeReport;
