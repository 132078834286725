const forms = {
  lastNameLabel: "Nume",
  lastNamePlaceholder: "Adauga numele",
  firstNameLabel: "Prenume",
  firstNamePlaceholder: "Adauga prenumele",
  companyLabel: "Nume companie",
  companyPlaceholder: "Adauga numele companiei",
  phoneLabel: "Telefon",
  phonePlaceholder: "Adauga numarul de telefon",
  addressLabel: "Adresa",
  addressPlaceholder: "Adauga adresa",
  companyAddressLabel: "Adresa companie",
  companyAddressPlaceholder: "Adauga adresa companiei",
  cityLabel: "Oras",
  cityPlaceholder: "Adauga orasul",
  emailLabel: "Adresa email",
  emailPlaceholder: "Adauga adresa de email",
  passwordLabel: "Parola",
  passwordPlaceholder: "Adauga parola",
  confirmPasswordLabel: "Confirma parola",
  confirmPasswordPlaceholder: "Confirma parola",
  currentPasswordLabel: "Parola curentă",
  currentPasswordPlaceholder: "Parola curentă",
  newPasswordLabel: "Parola nouă",
  newPasswordPlaceholder: "Parola nouă",
  fiscalCodeLabel: "CUI",
  fiscalCodePlaceholder: "CUI",
  certificateTechnicianNameLabel: "Nume tehnician (certificat conformitate)",
  certificateTechnicianNamePlaceholder: "Nume tehnician",
  certificateSeriesLabel: "Serie certificat conformitate",
  certificateSeriesPlaceholder: "Serie certificat conformitate",
  certificateNumberLabel: "Numar certificat conformitate",
  certificateNumberPlaceholder: "Numar certificat conformitate",
  bankAccountLabel: "Cont bancar",
  bankAccountPlaceholder: "Cont bancar",
  notesLabel: "Notite",
  notesPlaceholder: "Notite",
  amountLabel: "Suma",
  amountPlaceholder: "Adauga suma",
  nameLabel: "Nume",
  namePlaceholder: "Adauga nume",
  priceLabel: "Pret",
  pricePlaceholder: "Adauga pret",
  descriptionLabel: "Descriere",
  descriptionPlaceholder: "Adauga o descriere",
  patientNameLabel: "Nume pacient",
  patientNamePlaceholder: "Adauga nume pacient",
  patientAddressLabel: "Adresa de livrare",
  patientAddressPlaceholder: "Adauga adresa de livrare",
  medicNotesLabel: "Notite medic",
  medicNotesPlaceholder: "Adauga notitele medicului",
  technicianNotesLabel: "Notite tehnician",
  technicianNotesPlaceholder: "Adauga notitele tehnicianului",
  clinicNameLabel: "Nume clinica",
  clinicNamePlaceholder: "Adauga nume clinica",
  cardNumberLabel: "Numar card",
  cardNumberPlaceholder: "Adauga numar card",
  expirationDateLabel: "Data expirare",
  expirationDatePlaceholder: "Ex: 03/26",
  cvcLabel: "CVV / CVC",
  cvcPlaceholder: "Ex: 123",
};

export default forms;
