import { FC, useState } from 'react';
import { AnimatedAppIcon, Button, DentOpsIcon, Portal, Typography } from '../../toolkit';
import { useAppSelector } from '../../store';
import Container from '../Container/Container';
const ActivateUserAlert: FC = () => {
  const isUserActive = useAppSelector(state => state.auth.user?.isActive);
  const [isUserActiveDialog, setIsUserActiveDialog] = useState(true);

  return (
    <Portal
      isOpen={isUserActive ? false : isUserActiveDialog}
      title="Activare cont"
      overlay={true}
      onDismiss={() => setIsUserActiveDialog(false)}
    >
      <Container vertical={true} gap={36}  >
        <AnimatedAppIcon>
          <DentOpsIcon />
        </AnimatedAppIcon>
        <Typography type="p">
          Vă rugăm să activați contul pentru a avea acces gratuit 45 de zile.
        </Typography>
        {/* <Typography type="p">
          Email-ul cu link-ul de activare a fost trimis.
        </Typography> */}

        <Container horizontalAlign='end'  >
          <Button type='button' size='medium' variant='outlined' onClick={() => setIsUserActiveDialog(false)}>
            Am înțeles
          </Button>
        </Container>
      </Container>
    </Portal>
  );
};

export default ActivateUserAlert;