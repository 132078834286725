import { FC } from 'react';
import { useAppSelector } from '../store';
import { Navigate, Outlet } from 'react-router-dom';

const ActiveSubscription: FC = () => {
  const subscription = useAppSelector(state => state.cache.subscription);

  if (!subscription?.hasSubscription) {
    return <Navigate to='/settings' />;
  }

  return (
    <Outlet />
  );
};

export default ActiveSubscription;
