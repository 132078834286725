import { FC } from "react";
import { AppTitle } from '../../layout';
import { ForgotPasswordForm } from '../../modules';
import { AnimatedAppIcon, Card, PasswordIcon, Typography } from '../../toolkit';
import { ForgotPasswordPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('forgotPassword') as ForgotPasswordPageTranslation;

const ForgotPasswordPage: FC = () => {
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <PasswordIcon />
        </AnimatedAppIcon>
        <div>
          <Typography type='h2' variation='center'>{texts.title}</Typography>
          <Typography type='p' variation='center' variation1='secondary'>{texts.subTitle}</Typography>
        </div>
        <ForgotPasswordForm />
      </Card>
    </AppTitle>
  );
};

export default ForgotPasswordPage;
