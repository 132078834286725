import { FC, useState } from "react";
import { TitanClientStatistic } from "../../../models";
import { Button, Card, ContainerFlex, Delimiter, Portal, Typography } from "../../../toolkit";
import { Responsive } from "../../../layout";
import { DonutChart } from "../../../components";
import { useCacheList } from "../../../lib";
import ClientStatisticsList from "./ClientStatisticsList";
import ClientElementStatisticsList from './ClientElementStatisticsList';

type Props = {
  clientStatistics: TitanClientStatistic[];
};

const getTop5 = (statistic: any[], propName: string) => {
  const top5 = statistic.sort((a, b) => Number(a[propName]) - Number(b[propName]))
    .filter((_, index) => index < 5);

  return {
    labels: top5.map(t => t.label),
    series: top5.map(t => t[propName]),
  };
};

const getTopStatistics = (clientStatistics: TitanClientStatistic[]) => {
  const mappedData = clientStatistics.map(row => ({
    label: `${row.lastName} ${row.firstName}`,
    totlaCases: row.totalCases,
    totalElements: row.elementStatistics?.reduce((acc, current) => acc + current.count, 0),
    totalPaid: row.paidPrice
  }));
  return {
    cases: getTop5(mappedData, 'totlaCases'),
    elements: getTop5(mappedData, 'totalElements'),
    paid: getTop5(mappedData, 'totalPaid'),
  };
};

const ClientStatistics: FC<Props> = ({ clientStatistics }) => {
  const [statisticDetails, setStatisticDetails] = useState<TitanClientStatistic>();
  const listProps = useCacheList({ data: clientStatistics, loading: false }, () => () => true, () => ({ payload: undefined, type: 'fake' }));
  const { cases, elements, paid } = getTopStatistics(clientStatistics);

  return (
    <>
      <Card>
        <Typography type="h5">Top colaboratori</Typography>
        <Delimiter />
        <Responsive colsFrMd="1-1-1">
          <DonutChart name="Cazuri" series={cases.series} labels={cases.labels} />
          <DonutChart name="Elemente" series={elements.series} labels={elements.labels} />
          <DonutChart name="Incasari" series={paid.series} labels={paid.labels} />
        </Responsive>
      </Card>
      <ClientStatisticsList {...listProps} onOpenClientDetails={setStatisticDetails} />
      <Portal
        isOpen={Boolean(statisticDetails)}
        onDismiss={() => setStatisticDetails(undefined)}
        title={`Statistica elemente pentru ${statisticDetails?.firstName} ${statisticDetails?.lastName}`}
        overlay={true}
        width={780}
      >
        <ContainerFlex type='column' spacing={2}>
          <ClientElementStatisticsList elementStatistics={statisticDetails?.elementStatistics || []} />
          <ContainerFlex type='row' justify='end'>
            <Button type='button' variant='outlined' size='medium' onClick={() => setStatisticDetails(undefined)}>
              Inchide
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default ClientStatistics;