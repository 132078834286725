import { FC } from 'react';
import { TitanElementStatistic } from '../../../models';
import ElementStatisticsList from '../ElementStatistics/ElementStatisticsList';
import { useCacheList } from '../../../lib';

type Props = {
  elementStatistics: TitanElementStatistic[];
};

const ClientElementStatisticsList: FC<Props> = ({ elementStatistics }) => {
  const listProps = useCacheList({ data: elementStatistics, loading: false }, () => () => true, () => ({ payload: undefined, type: 'fake' }));

  return (
    <ElementStatisticsList  {...listProps} />
  );
};

export default ClientElementStatisticsList;