import { FC, HTMLAttributes, ReactNode, forwardRef } from "react";
import styles from "./styles.module.css";
import ContainerFlex, {
  Props as ContainerProps,
} from "../ContainerFlex/ContainerFlex";

export type Props = {
  children: ReactNode;
  noPadding?: boolean;
  variant?: "default" | "secondary" | "subtable";
} & ContainerProps &
  HTMLAttributes<HTMLDivElement> &
  React.RefAttributes<HTMLDivElement>;

const Card: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    { children, className, noPadding = false, variant = "default", ...rest },
    ref
  ) => {
    return (
      <ContainerFlex
        ref={ref}
        className={[
          className,
          styles.card,
          styles[variant],
          noPadding !== false ? styles.noPadding : "",
        ].join(" ")}
        {...rest}
      >
        {children}
      </ContainerFlex>
    );
  }
);

export default Card;
