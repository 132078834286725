import { FC } from 'react';

const Tooth22: FC = () => {
  return (
    <svg width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M6.34302 20.8252C5.54002 21.3063 4.69702 22.3487 4.25502 23.351C2.24702 27.8813 0.641019 37.8642 1.44402 40.7909C2.32702 44.1987 4.97802 46.4839 7.94902 46.4839C11.121 46.4839 16.903 42.9959 22.244 37.8642C27.223 33.1333 27.584 32.0509 25.577 28.1219C22.926 22.8298 17.586 20.0233 10.358 20.0233C8.35102 20.0233 7.30702 20.2238 6.34302 20.8252ZM18.911 22.4288C20.517 23.1906 22.083 24.3532 23.087 25.5159C25.014 27.6408 24.934 28.3624 23.007 26.5583C21.28 24.8744 17.907 22.8699 14.936 21.7473L12.486 20.7851L14.414 21.0256C15.498 21.1058 17.506 21.7473 18.911 22.4288Z" />
      <path className='interior' d="M17.931 29.5152C18.406 29.9675 18.856 30.3443 19.204 30.5841C19.366 30.6954 19.563 30.8173 19.754 30.8814C19.824 30.9049 20.053 30.9792 20.314 30.8979C20.475 30.848 20.639 30.7411 20.751 30.5639C20.855 30.3995 20.876 30.2351 20.876 30.1264C20.876 29.8009 20.713 29.521 20.607 29.3609C20.481 29.1677 20.312 28.9665 20.126 28.7684C19.752 28.3693 19.246 27.9174 18.687 27.471C17.579 26.5849 16.18 25.6515 15.055 25.1533C13.876 24.6081 12.081 24.0623 10.985 23.9299L9.183 23.69L8.87 25.1523L10.764 25.7156C13.595 26.5288 16.138 27.9184 17.931 29.5152Z" />
      <path className='text' d="M22.552 8.52H16.744V7.056L19.372 4.752C19.82 4.36 20.148 4.008 20.356 3.696C20.564 3.376 20.668 3.032 20.668 2.664V2.508C20.668 2.14 20.548 1.86 20.308 1.668C20.068 1.468 19.776 1.368 19.432 1.368C19 1.368 18.672 1.492 18.448 1.74C18.224 1.98 18.064 2.272 17.968 2.616L16.6 2.088C16.688 1.808 16.812 1.544 16.972 1.296C17.132 1.04 17.332 0.816 17.572 0.624C17.82 0.432 18.108 0.28 18.436 0.168C18.764 0.0560002 19.14 0 19.564 0C20.004 0 20.396 0.0639999 20.74 0.192C21.084 0.312 21.372 0.484 21.604 0.708C21.844 0.932 22.024 1.196 22.144 1.5C22.272 1.804 22.336 2.136 22.336 2.496C22.336 2.848 22.28 3.168 22.168 3.456C22.056 3.744 21.904 4.02 21.712 4.284C21.52 4.54 21.296 4.788 21.04 5.028C20.784 5.26 20.512 5.496 20.224 5.736L18.46 7.188H22.552V8.52Z"  />
      <path className='text' d="M29.747 8.52H23.939V7.056L26.567 4.752C27.015 4.36 27.343 4.008 27.551 3.696C27.759 3.376 27.8631 3.032 27.8631 2.664V2.508C27.8631 2.14 27.7431 1.86 27.5031 1.668C27.2631 1.468 26.971 1.368 26.627 1.368C26.195 1.368 25.8671 1.492 25.6431 1.74C25.4191 1.98 25.259 2.272 25.163 2.616L23.795 2.088C23.883 1.808 24.0071 1.544 24.1671 1.296C24.3271 1.04 24.527 0.816 24.767 0.624C25.015 0.432 25.303 0.28 25.631 0.168C25.959 0.0560002 26.335 0 26.759 0C27.199 0 27.591 0.0639999 27.935 0.192C28.279 0.312 28.567 0.484 28.799 0.708C29.039 0.932 29.2191 1.196 29.3391 1.5C29.4671 1.804 29.5311 2.136 29.5311 2.496C29.5311 2.848 29.4751 3.168 29.3631 3.456C29.2511 3.744 29.099 4.02 28.907 4.284C28.715 4.54 28.491 4.788 28.235 5.028C27.979 5.26 27.7071 5.496 27.4191 5.736L25.655 7.188H29.747V8.52Z"  />
    </svg>

  );
};

export default Tooth22;