import { FC } from 'react';
import { Container, PageLayout } from '../../../layout';
import { Button, ContainerFlex, Stepper } from '../../../toolkit';
import { CaseEdit, useCaseDetails, useCaseEdit } from '../../../modules';
import { useParams } from 'react-router-dom';


const EditCasePage: FC = () => {
  const params = useParams<{ caseId: string; }>();
  const caseId = params.caseId ? +params.caseId : undefined;
  const { _case } = useCaseDetails(caseId);
  const caseEditOptions = useCaseEdit(_case);

  return (
    <PageLayout title="Modifica caz" tabs={(
      <ContainerFlex type="row" justify="space-between" grow>
        <div></div>
        <Stepper
          selected={caseEditOptions.step}
          items={[
            {
              id: 1,
              label: 'Adauga elemente',
            },
            {
              id: 2,
              label: 'Detalii caz',
            }
          ]}
          onStepChanged={id => caseEditOptions.onStepChanged(id as number)}
        />
        <ContainerFlex type="row" spacing={2}>
          {caseEditOptions.step === 1 && (
            <>
              <Button type='button' size="small" variant='outlined' onClick={() => caseEditOptions.onSubmit(caseEditOptions.caseForm.form)}>Finalizeaza</Button>
              <Button type='button' size="small" variant='contained' onClick={() => caseEditOptions.onStepChanged(2)}>Continua</Button>
            </>
          )}
          {caseEditOptions.step === 2 && (
            <>
              <Button type='button' size="small" variant='outlined' onClick={() => caseEditOptions.onStepChanged(1)}>Inapoi</Button>
              <Button type='button' size="small" variant='contained' onClick={() => caseEditOptions.onSubmit(caseEditOptions.caseForm.form)}>Finalizeaza</Button>

            </>
          )}
        </ContainerFlex>
      </ContainerFlex>
    )}>
      <Container vertical gap={16}>
        {caseId ? (
          <CaseEdit {...caseEditOptions} />
        ) : 'Se incarca'}
      </Container>
    </PageLayout >
  );
};

export default EditCasePage;