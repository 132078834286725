import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URLS, validations } from '../../../lib';
import { TitanUser } from '../../../models';
import { textMessages } from '../../../translations';
import { useAppStoreHooks } from '../../../toolkit';
import { updateUser } from '../../../store';

const validate = validations({
  email: [
    ['required', textMessages.requiredError],
    ['email', textMessages.validEmailError],
  ],
  password: [
    ['required', textMessages.requiredError],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
  firstName: [['required', textMessages.requiredError]],
  lastName: [['required', textMessages.requiredError]],
  companyName: [['required', textMessages.requiredError]],
  companyAddress: [['required', textMessages.requiredError]],
  companyCity: [['required', textMessages.requiredError]],
  confirmPassword: [
    ['required', textMessages.requiredError],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
  phoneNumber: [['required', textMessages.requiredError]],
});

const useSignUp = () => {
  const [signUpForm, setSignUpForm] = useState<{
    form: {
      firstName?: string;
      lastName?: string;
      email?: string;
      companyName?: string;
      companyAddress?: string;
      companyCity?: string;
      password?: string;
      confirmPassword?: string;
      phoneNumber?: string;
    };
    errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
      companyName?: string;
      companyAddress?: string;
      companyCity?: string;
      password?: string;
      confirmPassword?: string;
      phoneNumber?: string;
    };
    termsAccepted?: boolean;
  }>({
    form: {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      companyAddress: '',
      companyCity: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
    },
    errors: {},
    termsAccepted: false,
  });

  const navigate = useNavigate();
  const { dispatch, postJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChangeHandler = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setSignUpForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!signUpForm.termsAccepted) {
      return;
    }
    const errors = validate(signUpForm.form);
    if (signUpForm.form.password !== signUpForm.form.confirmPassword) {
      if (!errors.confirmPassword) {
        errors.confirmPassword = textMessages.passwordNotmatch;
      }
    }

    const hasError = Object.keys(errors).length;
    setSignUpForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (hasError) {
      return;
    }
    loadingStart();
    try {
      const response = await postJson<{ model: TitanUser }>(
        API_URLS.identity.signUp,
        signUpForm.form
      );
      loadingEnd();
      notify(textMessages.accountCreatedSuccess, 'success');
      dispatch(updateUser(response.model));
      navigate('/sign-up/success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error');
    }
  };

  const onCheckboxChanged = (
    _?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setSignUpForm((prevState) => ({
      ...prevState,
      termsAccepted: checked,
    }));
  };

  return {
    signUpForm,
    onChangeHandler,
    onSubmitHandler,
    onCheckboxChanged,
  };
};

export default useSignUp;
