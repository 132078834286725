import { FC } from 'react';

const Tooth12: FC = () => {
  return (
    <svg width="31" height="48" viewBox="0 0 31 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M14.886 20.6247C10.469 21.8676 7.176 24.4334 5.2891 28.162C3.4019 31.8504 4.0443 33.8149 8.421 37.8642C13.762 42.7954 20.026 46.4839 23.117 46.4839C24.041 46.4839 24.945 46.1763 25.286 45.9226C26.5 45.02 28.056 43.9581 28.819 42.4346C30.827 38.5858 28.578 25.1551 25.406 21.7072C24.082 20.2247 24.041 20.2238 20.514 20.1438L20.507 20.1436C18.339 20.0634 16.171 20.2639 14.886 20.6247Z" />
      <path className='fundal' d="M5.2891 28.162C7.176 24.4334 10.469 21.8676 14.886 20.6247C16.171 20.2639 18.339 20.0634 20.507 20.1436M5.2891 28.162C3.4019 31.8504 4.0443 33.8149 8.421 37.8642C13.762 42.7954 20.026 46.4839 23.117 46.4839C24.041 46.4839 24.945 46.1763 25.286 45.9226C26.5 45.02 28.056 43.9581 28.819 42.4346C30.827 38.5858 28.578 25.1551 25.406 21.7072C24.081 20.2238 24.041 20.2238 20.507 20.1436M5.2891 28.162C6.8645 27.197 12 23.52 20.507 20.1436Z" />
      <path className='interior' d="M19.605 25.049C19.708 24.9609 19.881 24.7593 19.807 24.4648C19.739 24.1949 19.511 24.0856 19.432 24.0526C19.233 23.9688 18.976 23.9533 18.729 23.9735C18.607 23.9745 18.491 23.9833 18.388 24.0031C18.308 24.0184 18.129 24.058 17.992 24.1948C17.905 24.281 17.818 24.4272 17.83 24.6183C17.842 24.792 17.931 24.9107 17.989 24.9714C18.094 25.0807 18.23 25.1422 18.325 25.1784C18.547 25.2707 18.784 25.2974 18.988 25.2829C19.161 25.2705 19.417 25.2203 19.605 25.049ZM18.827 24.5338C18.822 24.5674 18.821 24.6042 18.825 24.644C18.829 24.615 18.83 24.5842 18.828 24.5517C18.828 24.5456 18.827 24.5397 18.827 24.5338ZM17.183 25.639C16.913 25.8142 16.495 26.0232 15.933 26.2579L15.928 26.2598C13.611 27.2008 11.891 28.254 9.883 29.9837C9.883 29.9836 9.883 29.9838 9.883 29.9837L8.67899 31.0257L7.98199 30.311L9.18499 28.9898C9.18499 28.9895 9.18499 28.9892 9.18599 28.9889C9.93899 28.1537 10.962 27.2786 12.001 26.537C13.038 25.7979 14.123 25.1682 15.006 24.8457L15.015 24.8425C15.61 24.6377 16.098 24.5022 16.464 24.4317C16.645 24.3967 16.811 24.3747 16.952 24.3722C17.023 24.3709 17.101 24.3741 17.18 24.3889C17.249 24.402 17.371 24.4335 17.482 24.5264C17.62 24.6425 17.699 24.8214 17.678 25.0149C17.66 25.1688 17.584 25.2799 17.539 25.337C17.446 25.4561 17.31 25.5567 17.183 25.639Z" />
      <path className='text' d="M0.700897 8.52001V7.23601H3.0049V1.28401H2.8969L1.0729 3.44401L0.0888977 2.61601L2.1649 0.144012H4.5529V7.23601H6.4729V8.52001H0.700897Z" />
      <path className='text' d="M13.356 8.52H7.54799V7.056L10.176 4.752C10.624 4.36 10.952 4.008 11.16 3.696C11.368 3.376 11.472 3.032 11.472 2.664V2.508C11.472 2.14 11.352 1.86 11.112 1.668C10.872 1.468 10.58 1.368 10.236 1.368C9.80399 1.368 9.47599 1.492 9.25199 1.74C9.02799 1.98 8.868 2.272 8.772 2.616L7.40399 2.088C7.49199 1.808 7.61599 1.544 7.77599 1.296C7.93599 1.04 8.13599 0.816 8.37599 0.624C8.62399 0.432 8.91199 0.28 9.23999 0.168C9.56799 0.0560002 9.94399 0 10.368 0C10.808 0 11.2 0.0639999 11.544 0.192C11.888 0.312 12.176 0.484 12.408 0.708C12.648 0.932 12.828 1.196 12.948 1.5C13.076 1.804 13.14 2.136 13.14 2.496C13.14 2.848 13.084 3.168 12.972 3.456C12.86 3.744 12.708 4.02 12.516 4.284C12.324 4.54 12.1 4.788 11.844 5.028C11.588 5.26 11.316 5.496 11.028 5.736L9.26399 7.188H13.356V8.52Z" />
    </svg>

  );
};

export default Tooth12;