import { useState } from 'react';
import { useAppStoreHooks } from '../../toolkit';
import { API_URLS } from '../../lib';

const useCaseEditNotes = (
  caseId: number,
  notes: string,
  medicNotes: string,
  onCaseNotesChanged: (notes: string, medicNotes: string) => void,
  onDismiss: () => void
) => {
  const [caseForm, setCaseForm] = useState<{
    form: {
      notes: string;
      medicNotes: string;
    };
    errors: {
      notes?: string,
      medicNotes?: string,
    };
  }>({
    form: { notes, medicNotes },
    errors: {},
  });

  const { putJson, loadingStart, loadingEnd, notify } = useAppStoreHooks();
  const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setCaseForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value
      }
    }));
  };

  const onSubmit = async  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let titanCase = { ...caseForm.form };
    loadingStart();
    try {
      await putJson(API_URLS.titan.cases.updateCaseNotes, {
        titanCaseId: caseId,
        ...titanCase
      });
      notify('Notitele au fost adaugate cu success', 'success');
      onCaseNotesChanged(titanCase.notes, titanCase.medicNotes);
      onDismiss();
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  return {
    form: caseForm.form,
    errors: caseForm.errors,
    onChange,
    onSubmit
  };
};

export default useCaseEditNotes;
