import { FC } from 'react';

type Props = {
  size?: number;
}

const LockIcon: FC<Props> = ({ size = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}  viewBox="0 0 41 40" fill="none">
      <path d="M28.8332 16.6667V13.3333C28.8332 8.73096 25.1022 5 20.4998 5C15.8975 5 12.1665 8.73096 12.1665 13.3333V16.6667M20.4998 24.1667V27.5M15.1665 35H25.8332C28.6334 35 30.0336 35 31.1031 34.455C32.0439 33.9757 32.8088 33.2108 33.2882 32.27C33.8332 31.2004 33.8332 29.8003 33.8332 27V24.6667C33.8332 21.8664 33.8332 20.4663 33.2882 19.3967C32.8088 18.4559 32.0439 17.691 31.1031 17.2116C30.0336 16.6667 28.6334 16.6667 25.8332 16.6667H15.1665C12.3662 16.6667 10.9661 16.6667 9.89655 17.2116C8.95574 17.691 8.19084 18.4559 7.71147 19.3967C7.1665 20.4663 7.1665 21.8664 7.1665 24.6667V27C7.1665 29.8003 7.1665 31.2004 7.71147 32.27C8.19084 33.2108 8.95574 33.9757 9.89655 34.455C10.9661 35 12.3662 35 15.1665 35Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default LockIcon;