import { useState } from "react";
import { useAppStoreHooks } from "../../toolkit";
import { API_URLS, downloadFile, formatDate } from "../../lib";
import { textMessages } from "../../translations";

const useExportCases = () => {
    const [exportDialog, setExportDialog] = useState<{
    isOpen: boolean,
    startDate?: Date,
    endDate?: Date;
  }>({
    isOpen: false,
  });

  const { getRequest, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const toggleExportDialog = () => {
    setExportDialog(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen
    }));
  };

  const onExportCases = async () => {
    loadingStart();
    try {
      const response = await getRequest(`${API_URLS.titan.cases.export}?from=${exportDialog.startDate!.toISOString()}&to=${exportDialog.endDate!.toISOString()}`);
      const blob = await response?.blob();
      if(blob) {
        const fileName = `cazuri-${formatDate(exportDialog.startDate!)}-${formatDate(exportDialog.endDate!)}.csv`
        downloadFile(blob, fileName)
      }
      setExportDialog(prevState => ({
        ...prevState,
        isOpen: false,
      }));
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onExportDatesChange = (startDate?: Date, endDate?: Date) => {
      setExportDialog(prevState => ({
      ...prevState,
      startDate,
      endDate
    }));
  }

  return {
    exportDialog,
    toggleExportDialog,
    onExportCases,
    onExportDatesChange
  }
}

export default useExportCases;
