import { FC } from 'react';

const Tooth44: FC = () => {
  return (
    <svg width="51" height="31" viewBox="0 0 51 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M36.0706 1.40202C30.8104 2.44502 27.3572 4.69002 25.3896 8.29802C24.185 10.583 24.0244 11.145 24.0244 14.071C24.0244 16.838 24.1449 17.479 25.0283 18.762C28.5216 23.854 37.2752 26.259 42.6558 23.613C46.2295 21.809 48.6387 17.72 49.7229 11.425C50.4858 7.296 50.004 5.13102 47.916 3.24702C47.0727 2.48502 45.6673 1.683 44.8241 1.442C43.0975 0.961 38.4396 0.921022 36.0706 1.40202Z" />
      <path className='interior stroke' d="M37.9178 5.05098C37.8776 5.85198 36.4722 7.095 34.4645 8.178C33.4607 8.699 33.2599 8.93898 33.8622 8.77898C34.4244 8.65898 35.5487 8.17798 36.3518 7.69698C37.7973 6.93498 37.8776 6.89498 39.6444 7.57598C40.9695 8.09798 41.7725 8.73898 42.6559 10.102C44.1015 12.267 44.1818 13.109 43.1378 14.552C42.7362 15.154 42.1741 16.076 41.9332 16.637L41.4513 17.639L42.2945 16.637C42.7764 16.076 43.9007 14.873 44.7841 13.911C46.3099 12.307 46.3501 12.187 45.3864 12.427C44.503 12.668 44.3424 12.548 44.1015 11.425C43.7401 9.78097 41.1301 7.17499 39.5641 6.81499C38.6405 6.61399 38.2792 6.29299 38.1587 5.49199C38.0784 4.88999 37.9579 4.68998 37.9178 5.05098Z" />
      <path className='text' d="M3.78519 30.52V28.9H-0.00680542V27.556L3.3292 22.144H5.28519V27.676H6.3772V28.9H5.28519V30.52H3.78519ZM1.2892 27.676H3.78519V23.68H3.6892L1.2892 27.676Z"  />
      <path className='text' d="M10.9805 30.52V28.9H7.18851V27.556L10.5245 22.144H12.4805V27.676H13.5725V28.9H12.4805V30.52H10.9805ZM8.48451 27.676H10.9805V23.68H10.8845L8.48451 27.676Z"  />
    </svg>
  );
};

export default Tooth44;