import { FC } from 'react';

const Tooth32: FC = () => {

  return (
    <svg width="27" height="43" viewBox="0 0 27 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M5.49999 24.144C3.49499 23.524 1.91099 22.643 1.45299 21.664C0.930989 20.501 0.930981 14.968 1.41298 13.485C1.45298 11.52 2.53699 5.14601 3.74199 3.46201C4.14399 2.90101 5.10698 2.13902 5.86998 1.77802C6.63298 1.37702 7.51698 1.056 7.83798 1.056C9.44398 1.096 12.174 2.94101 15.186 5.98801C21.45 12.322 22.895 15.57 21.089 19.298C20.978 19.528 20.844 19.763 20.69 20M5.49999 24.144C8.41599 25.047 12.222 25.4 14.744 24.711C16.718 24.137 19.398 21.994 20.69 20M5.49999 24.144C8.12699 23.929 14.842 22.8 20.69 20Z" />
      <path className='interior' d="M7.34996 20.671C7.91496 20.578 8.69995 20.418 9.68895 20.186L9.69395 20.185C10.881 19.894 12.74 19.188 14.289 18.508C15.066 18.167 15.779 17.826 16.304 17.538C16.563 17.396 16.7909 17.259 16.9579 17.137C17.0399 17.077 17.125 17.008 17.194 16.933C17.229 16.895 17.2699 16.845 17.3049 16.782C17.3389 16.722 17.3829 16.621 17.3829 16.492C17.3829 16.39 17.363 16.234 17.253 16.091C17.137 15.939 16.979 15.876 16.851 15.854C16.638 15.818 16.399 15.876 16.196 15.938C15.748 16.074 15.025 16.379 13.909 16.877L13.901 16.88C12.727 17.427 10.303 18.301 8.54996 18.819H8.54796C7.64096 19.091 6.97996 19.314 6.56296 19.484C6.36396 19.565 6.18595 19.648 6.06395 19.731C6.03095 19.753 5.99095 19.783 5.95195 19.821C5.91695 19.855 5.85496 19.922 5.81496 20.023C5.76796 20.142 5.75095 20.312 5.84395 20.477C5.92195 20.617 6.04096 20.681 6.09996 20.707C6.21396 20.758 6.33295 20.766 6.39395 20.768C6.47195 20.771 6.55995 20.768 6.64995 20.761C6.83295 20.748 7.06796 20.717 7.34996 20.671Z" />
      <path className='text' d="M15.753 37.528C16.233 37.528 16.585 37.432 16.809 37.24C17.041 37.04 17.157 36.788 17.157 36.484V36.4C17.157 36.056 17.045 35.788 16.821 35.596C16.605 35.396 16.305 35.296 15.921 35.296C15.553 35.296 15.225 35.384 14.937 35.56C14.657 35.736 14.413 35.984 14.205 36.304L13.197 35.392C13.341 35.2 13.497 35.02 13.665 34.852C13.841 34.676 14.041 34.528 14.265 34.408C14.489 34.28 14.741 34.18 15.021 34.108C15.301 34.036 15.617 34 15.969 34C16.393 34 16.777 34.052 17.121 34.156C17.473 34.252 17.773 34.396 18.021 34.588C18.277 34.78 18.473 35.012 18.609 35.284C18.745 35.548 18.813 35.848 18.813 36.184C18.813 36.456 18.769 36.7 18.681 36.916C18.601 37.132 18.485 37.32 18.333 37.48C18.189 37.64 18.017 37.772 17.817 37.876C17.625 37.972 17.421 38.044 17.205 38.092V38.164C17.445 38.212 17.673 38.288 17.889 38.392C18.105 38.496 18.289 38.636 18.441 38.812C18.601 38.98 18.725 39.18 18.813 39.412C18.909 39.644 18.957 39.908 18.957 40.204C18.957 40.572 18.881 40.908 18.729 41.212C18.585 41.516 18.377 41.776 18.105 41.992C17.833 42.208 17.509 42.376 17.133 42.496C16.757 42.608 16.337 42.664 15.873 42.664C15.465 42.664 15.105 42.62 14.793 42.532C14.481 42.452 14.205 42.34 13.965 42.196C13.733 42.052 13.529 41.888 13.353 41.704C13.177 41.512 13.025 41.312 12.897 41.104L14.061 40.216C14.157 40.384 14.261 40.54 14.373 40.684C14.485 40.82 14.613 40.94 14.757 41.044C14.901 41.148 15.065 41.228 15.249 41.284C15.433 41.34 15.641 41.368 15.873 41.368C16.337 41.368 16.693 41.256 16.941 41.032C17.189 40.808 17.313 40.5 17.313 40.108V40.024C17.313 39.64 17.177 39.348 16.905 39.148C16.641 38.948 16.257 38.848 15.753 38.848H14.925V37.528H15.753Z"  />
      <path className='text' d="M26.357 42.52H20.549V41.056L23.177 38.752C23.625 38.36 23.953 38.008 24.161 37.696C24.369 37.376 24.473 37.032 24.473 36.664V36.508C24.473 36.14 24.353 35.86 24.113 35.668C23.873 35.468 23.581 35.368 23.237 35.368C22.805 35.368 22.477 35.492 22.253 35.74C22.029 35.98 21.869 36.272 21.773 36.616L20.405 36.088C20.493 35.808 20.617 35.544 20.777 35.296C20.937 35.04 21.137 34.816 21.377 34.624C21.625 34.432 21.913 34.28 22.241 34.168C22.569 34.056 22.945 34 23.369 34C23.809 34 24.201 34.064 24.545 34.192C24.889 34.312 25.177 34.484 25.409 34.708C25.649 34.932 25.829 35.196 25.949 35.5C26.077 35.804 26.141 36.136 26.141 36.496C26.141 36.848 26.085 37.168 25.973 37.456C25.861 37.744 25.709 38.02 25.517 38.284C25.325 38.54 25.101 38.788 24.845 39.028C24.589 39.26 24.317 39.496 24.029 39.736L22.265 41.188H26.357V42.52Z"  />
    </svg>
  );
};

export default Tooth32;