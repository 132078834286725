import { FC } from "react";
import { TitanPayment } from "../../../models";
import { usePayments } from "../../hooks";
import {
  AnimatedAppIcon,
  Button,
  ContainerFlex,
  DateInput,
  Input,
  TrashIcon,
  Typography,
} from "../../../toolkit";
import { textForm } from "../../../translations";
import { parseDate } from "../../../lib";

type Props = {
  clientId: number;
  isDelete: boolean;
  payment?: TitanPayment;
  onClose: () => void;
  onRefresh: () => void;
};

const PaymentCRUD: FC<Props> = ({
  clientId,
  isDelete,
  payment,
  onClose,
  onRefresh,
}) => {
  const { paymentForm, onChange, onDateChange, onSubmit, onDelete } =
    usePayments(clientId, onRefresh, payment);

  return (
    <>
      {isDelete && (
        <ContainerFlex type="column" spacing={3}>
          <AnimatedAppIcon variation="danger">
            <TrashIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Aceasta actiune va sterge plata.
            <br />
            Detaliile platii nu vor mai putea fi recuperate
          </Typography>
          <ContainerFlex spacing={1} type="row" justify="end">
            <Button
              size="medium"
              variant="outlined"
              type="button"
              onClick={onClose}
            >
              Renunta
            </Button>
            <Button
              size="medium"
              variant="danger"
              type="button"
              onClick={() => onDelete(payment?.clientPaymentId!)}
            >
              Sterge
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      )}
      {!isDelete && (
        <div>
          <form noValidate onSubmit={onSubmit}>
            <Input
              label={textForm.amountLabel}
              placeholder={textForm.amountPlaceholder}
              name="amount"
              onChange={onChange}
              value={
                paymentForm.form.amount ? String(paymentForm.form.amount) : ""
              }
              required
              errorMessage={paymentForm.errors.amount}
            />
            <DateInput
              label="Data"
              date={parseDate(paymentForm.form.createdDateTime!)}
              onDateChanged={onDateChange}
            />

            <ContainerFlex spacing={1} type="row" justify="end">
              <Button
                size="medium"
                variant="outlined"
                type="button"
                onClick={onClose}
              >
                Renunta
              </Button>
              <Button size="medium" variant="contained" type="submit">
                Salveaza
              </Button>
            </ContainerFlex>
          </form>
        </div>
      )}
    </>
  );
};

export default PaymentCRUD;
