import { FC } from 'react';

const Tooth21: FC = () => {
  return (
    <svg width="33" height="51" viewBox="0 0 33 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M9.96899 18.8847C8.04199 19.2857 3.10299 21.1299 2.46099 21.6912C1.37699 22.6534 0.854992 25.9008 1.33599 28.7874C2.70199 37.367 6.15499 45.7462 9.32699 48.1517C10.251 48.8734 11.736 49.6351 12.66 49.9157C17.759 51.2789 23.903 46.1872 28.601 36.7657C32.094 29.7496 32.215 27.2238 29.203 24.3372C26.914 22.0921 24.746 20.7691 21.574 19.5663C19.406 18.7645 18.321 18.6041 14.868 18.6442C12.62 18.6843 10.411 18.7645 9.96899 18.8847ZM21.413 20.1677C23.903 21.0898 27.637 23.3349 27.316 23.6957C27.195 23.816 25.951 23.5354 24.545 23.0543C20.008 21.5709 16.113 20.9294 11.094 20.8492C5.95399 20.8091 5.713 20.5285 9.889 19.5663C13.302 18.8046 18.562 19.0852 21.413 20.1677Z" />
      <path className='interior' d="M15.266 25.1255C15.192 25.1157 15.117 25.1068 15.042 25.0985C14.738 25.0651 14.418 25.043 14.066 25.031C14.481 25.0573 14.881 25.0886 15.266 25.1255ZM22.843 28.3372C22.557 28.2614 22.214 28.1271 21.87 27.9555L21.865 27.9531C18.996 26.4821 16.157 25.9633 10.647 25.9233C9.03697 25.9233 7.83796 25.8857 7.04896 25.8241C6.65896 25.7936 6.34896 25.7559 6.13496 25.7087C6.03696 25.6871 5.91197 25.6542 5.80697 25.5954C5.75897 25.5686 5.65397 25.5039 5.57997 25.3786C5.48297 25.2126 5.48396 25.0129 5.57496 24.8495C5.64296 24.7257 5.74396 24.659 5.78996 24.6307C5.84696 24.5965 5.90497 24.5721 5.95297 24.5545C6.04997 24.5188 6.16596 24.4894 6.28996 24.4639C6.54096 24.412 6.88797 24.363 7.32197 24.3172C8.19297 24.2253 9.45996 24.1422 11.111 24.0818C12.918 24.0017 14.106 23.9897 15.151 24.1044C16.206 24.2203 17.098 24.4634 18.316 24.8687C19.896 25.3947 21.322 25.9634 22.319 26.4407C22.813 26.6772 23.223 26.9011 23.496 27.0949C23.624 27.1859 23.766 27.3004 23.86 27.435C23.907 27.502 23.977 27.6209 23.985 27.7782C23.996 27.9606 23.92 28.1228 23.799 28.2345C23.696 28.3325 23.578 28.3719 23.5 28.3892C23.415 28.4081 23.333 28.4106 23.266 28.4081C23.131 28.403 22.984 28.3746 22.843 28.3372Z" />
      <path className='text' d="M17.552 8.52H11.744V7.056L14.372 4.752C14.82 4.36 15.148 4.008 15.356 3.696C15.564 3.376 15.668 3.032 15.668 2.664V2.508C15.668 2.14 15.548 1.86 15.308 1.668C15.068 1.468 14.776 1.368 14.432 1.368C14 1.368 13.672 1.492 13.448 1.74C13.224 1.98 13.064 2.272 12.968 2.616L11.6 2.088C11.688 1.808 11.812 1.544 11.972 1.296C12.132 1.04 12.332 0.816 12.572 0.624C12.82 0.432 13.108 0.28 13.436 0.168C13.764 0.0560002 14.14 0 14.564 0C15.004 0 15.396 0.0640001 15.74 0.192C16.084 0.312 16.372 0.484 16.604 0.708C16.844 0.932 17.024 1.196 17.144 1.5C17.272 1.804 17.336 2.136 17.336 2.496C17.336 2.848 17.28 3.168 17.168 3.456C17.056 3.744 16.904 4.02 16.712 4.284C16.52 4.54 16.296 4.788 16.04 5.028C15.784 5.26 15.512 5.496 15.224 5.736L13.46 7.188H17.552V8.52Z"  />
      <path className='text' d="M19.287 8.52001V7.23601H21.591V1.28401H21.483L19.659 3.44401L18.675 2.61601L20.751 0.144012H23.139V7.23601H25.059V8.52001H19.287Z"  />
    </svg>
  );
};

export default Tooth21;