import { useCallback, useEffect, useRef } from 'react';
import {
  setSubscriptionData,
  setLoadSubscription,
  useAppSelector,
} from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { AuthenticatedUser, TitanSubscriptionDetails } from '../../../models';
import { API_URLS } from '../../constants';
import { parseDate } from '../../dateFunctions';

const DAYS_45 = 1000 * 60 * 60 * 24 * 45;

const getDefaultSubscription = (
  user?: AuthenticatedUser
): TitanSubscriptionDetails | undefined => {
  if (!user || !user.createdDateTime) {
    return undefined;
  }
  const date = parseDate(user.createdDateTime);
  const trialEnd = date.getTime() + DAYS_45;
  const trialEnded = trialEnd < Date.now();
  if (trialEnded) {
    return undefined;
  }

  return {
    status: 'trialing',
    currentPeriodEnd: new Date(trialEnd).toISOString(),
    amount: 0,
    currentPeriodStart: new Date().toISOString(),
    cancelAtPeriodEnd: false,
    subscriptionId: '',
    created: new Date().toISOString(),
    startDate: new Date().toISOString(),
    latestInvoiceId: '',
    defaultPaymentMethodId: '',
    customerId: '',
  };
};

const useCurrentSubscriptionCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const user = useAppSelector((state) => state.auth.user);
  const { subscription, expireAfterMiliseconds } = useAppSelector(
    (state) => state.cache
  );
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadSubscription = useCallback(async () => {
    refreshing.current = true;
    try {
      const subscription = await getJson<TitanSubscriptionDetails>(
        `${API_URLS.platform.subsciption.subsciption}/DentOps`
      );
      if (!subscription.subscriptionId) {
        dispatch(
          setSubscriptionData({
            ...subscription,
            status: 'trialing',
          })
        );
      } else {
        dispatch(setSubscriptionData(subscription));
      }
    } catch (e: any) {
      const defaultSubscription = getDefaultSubscription(user);
      dispatch(setSubscriptionData(defaultSubscription));
      notify('Nu exista subscriptie activa', 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify, user]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }

    if (!subscription.expireTime) {
      dispatch(setLoadSubscription());
      loadSubscription();
    } else {
      const timer = subscription.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadSubscription();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    loadSubscription,
    dispatch,
    subscription.expireTime,
    expireAfterMiliseconds,
    expireAfterMinutes,
  ]);
};
export default useCurrentSubscriptionCache;
