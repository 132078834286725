import { FC } from 'react';

type Props = {
  size?: number;
};

const CalendarIcon: FC<Props> = ({ size = 20 }) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 20" fill="none">
    <path d="M16.5 8.33335H1.5M12.3333 1.66669V5.00002M5.66667 1.66669V5.00002M5.5 18.3334H12.5C13.9001 18.3334 14.6002 18.3334 15.135 18.0609C15.6054 17.8212 15.9878 17.4387 16.2275 16.9683C16.5 16.4336 16.5 15.7335 16.5 14.3334V7.33335C16.5 5.93322 16.5 5.23316 16.2275 4.69838C15.9878 4.22797 15.6054 3.84552 15.135 3.60584C14.6002 3.33335 13.9001 3.33335 12.5 3.33335H5.5C4.09987 3.33335 3.3998 3.33335 2.86502 3.60584C2.39462 3.84552 2.01217 4.22797 1.77248 4.69838C1.5 5.23316 1.5 5.93322 1.5 7.33335V14.3334C1.5 15.7335 1.5 16.4336 1.77248 16.9683C2.01217 17.4387 2.39462 17.8212 2.86502 18.0609C3.3998 18.3334 4.09987 18.3334 5.5 18.3334Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
};

export default CalendarIcon;