import { useCallback, useEffect } from 'react';
import { TitanCase, TitanCaseElement, TitanCaseStage } from '../../models';
import useCaseBase, { validate } from './useCaseBase';
import { useAppStoreHooks } from '../../toolkit';
import { useNavigate } from 'react-router-dom';
import { reloadList, useAppSelector } from '../../store';
import { API_URLS, toothIdMap } from '../../lib';

const useCaseEdit = (_case?: TitanCase) => {
  const navigate = useNavigate();
  const { putJson, postJson, notify, dispatch, loadingStart, loadingEnd } = useAppStoreHooks();
  const elements = useAppSelector(state => state.cache.elements);
  const { setCaseForm, ...options } = useCaseBase();

  const onSubmit = useCallback(async (titanCase: TitanCase) => {
    const errors = validate(titanCase);
    setCaseForm(prevState => ({
      ...prevState,
      errors
    }));
    if (Object.keys(errors).length) {
      return;
    }
    loadingStart();
    try {
      const payload = {
        titanCaseId: titanCase.titanCaseId,
        titanClientId: titanCase.titanClientId,
        deliveryAddress: titanCase.deliveryAddress,
        patientName: titanCase.patientName,
        caseDateTime: titanCase.caseDateTime,
        toothShape: titanCase.toothShape,
        color: titanCase.color
      };
      await putJson(API_URLS.titan.cases.mainDetails, payload);
      await putJson<TitanCaseElement[]>(API_URLS.titan.cases.updateCaseElements, {
        elements: titanCase.caseElements,
        titanCaseId: titanCase.titanCaseId
      });
      await postJson<TitanCaseStage[]>(API_URLS.titan.cases.updateCaseStages, {
        caseStages: titanCase.caseStages,
        titanCaseId: titanCase.titanCaseId,
      });
      notify('Cazul a fost modificat.', 'success');
      loadingEnd();
      dispatch(reloadList({ pageKey: API_URLS.titan.cases.list }));
      navigate(`/cases/${titanCase.titanCaseId}`);

    } catch (e: any) {
      notify(e.message, 'error');
      loadingEnd();
    }
  }, [putJson, postJson, notify, navigate, dispatch, loadingStart, loadingEnd, setCaseForm]);

  useEffect(() => {
    if (_case && elements.data) {
      setCaseForm(prevState => ({
        ...prevState,
        form: {
          ..._case,
          caseElements: _case.caseElements.map(ce => {
            const element = elements.data?.find(e => e.elementId === ce.elementId);
            return {
            ...ce,
            teeth: ce.teeth?.map(t => ({
              ...t,
              code: toothIdMap[t.toothId] 
            })),
            elementStages: element?.elementStages?.map((elementStage) => ({
              ...elementStage,
              elementId: ce.elementId
            }))
          }}),
        }
      }));
    }
  }, [_case, setCaseForm, elements.data]);

  return {
    ...options,
    onSubmit
  };
};

export default useCaseEdit;