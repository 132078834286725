import { FC, ReactNode, useEffect } from 'react';

type Props = {
  title: string;
  children: ReactNode;
};

const AppTitle: FC<Props> = ({ title, children }) => {

  useEffect(() => {
    document.title = `DentOps - ${title}`;
  }, [title]);

  return (
    <>{children}</>
  );
};

export default AppTitle;