import { FC, useState } from "react";
import { useParams } from 'react-router-dom';
import { Container, PageLayout, Responsive } from '../../../layout';
import { ClientDetailTables, ClientForm, ClientReportTool, useClientDetails } from '../../../modules';
import { ClientDetails } from '../../../components';
import { Button, Portal } from '../../../toolkit';
import { useAppSelector } from '../../../store';

const ClientDetailsPage: FC = () => {
  const user = useAppSelector(state => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams<{ clientId: string; }>();
  const clientId = params.clientId ? Number(params.clientId) : undefined;
  const { client, reloadClient } = useClientDetails(clientId);
  const clientName = `${client?.lastName || ''} ${client?.firstName || ''}` || 'Detalii client';
  return (
    <PageLayout title={clientName} tabs={user?.isAdmin ?
      <Button type='button' size='small' variant='outlined' onClick={() => { setIsOpen(true); }}>Modifica</Button>
      : undefined
    }>
      {client && (
        <>
          <Container vertical gap={32}>
            <Responsive colsFrMd='1-2'>
              <ClientDetails client={client} />
              <ClientReportTool clientId={client.titanClientId} clientName={`${client.lastName} ${client.firstName}`} />
            </Responsive>
            <ClientDetailTables clientId={client.titanClientId} clientName={`${client.lastName} ${client.firstName}`} />
          </Container>
          <Portal
            isOpen={isOpen}
            onDismiss={() => { setIsOpen(false); }}
            title="Modifica Medic"
            overlay={true}
            width={560}
          >
            <ClientForm client={client} onClose={() => { setIsOpen(false); }} onSaved={() => { setIsOpen(false); reloadClient(clientId!); }} />
          </Portal>
        </>
      )}

    </PageLayout >
  );
};

export default ClientDetailsPage;