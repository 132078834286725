import { FC } from 'react';

const Tooth42: FC = () => {
  return (
    <svg width="23" height="44" viewBox="0 0 23 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M13.261 1.49702C12.378 1.77802 10.531 3.30201 7.84101 5.98801C2.42001 11.4 1.456 12.884 1.255 16.051C1.135 18.256 1.215 18.657 2.34 20.18C3.906 22.345 6.43501 24.189 8.56301 24.751C12.057 25.713 19.325 24.229 21.132 22.225C22.577 20.621 21.613 8.794 19.686 4.504C18.522 1.818 16.112 0.696016 13.261 1.49702Z" />
      <path className='fundal' d="M2.34 20.18C1.215 18.657 1.135 18.256 1.255 16.051C1.456 12.884 2.42001 11.4 7.84101 5.98801C10.531 3.30201 12.378 1.77802 13.261 1.49702C16.112 0.696016 18.522 1.818 19.686 4.504C21.613 8.794 22.577 20.621 21.132 22.225C19.325 24.229 12.057 25.713 8.56301 24.751C6.43501 24.189 3.906 22.345 2.34 20.18ZM2.34 20.18C5.479 21.712 13.205 24.52 19 23.5Z" />
      <path className='interior' d="M4.63598 17.199C4.63598 17.199 4.63797 17.2 4.63997 17.199C4.63697 17.199 4.63598 17.199 4.63598 17.199ZM14.646 20.76C13.996 20.781 13.086 20.638 12.103 20.412C11.105 20.183 9.99198 19.859 8.92498 19.496C7.85898 19.133 6.82898 18.729 5.99898 18.339C5.58498 18.144 5.21398 17.949 4.90998 17.761C4.61598 17.579 4.34698 17.381 4.17398 17.172L4.16598 17.162L4.15897 17.153C4.11897 17.1 4.00197 16.939 4.01797 16.721C4.02697 16.587 4.08497 16.46 4.18397 16.365C4.27297 16.28 4.36997 16.243 4.43497 16.225C4.55697 16.192 4.67797 16.198 4.75497 16.205C4.84497 16.214 4.94198 16.232 5.04098 16.255C5.43098 16.344 6.02198 16.546 6.74698 16.827L6.75397 16.83L6.76098 16.833C8.05998 17.383 10.646 18.258 12.45 18.819C13.377 19.08 14.12 19.327 14.568 19.513C14.681 19.56 14.783 19.606 14.868 19.651C14.938 19.688 15.047 19.75 15.13 19.836C15.164 19.871 15.273 19.989 15.288 20.177C15.308 20.433 15.149 20.598 15.033 20.668C14.937 20.726 14.842 20.743 14.797 20.75C14.745 20.758 14.693 20.76 14.646 20.76Z" />
      <path className='text' d="M3.78499 43.52V41.9H-0.00701904V40.556L3.32899 35.144H5.28499V40.676H6.37698V41.9H5.28499V43.52H3.78499ZM1.28899 40.676H3.78499V36.68H3.68899L1.28899 40.676Z"  />
      <path className='text' d="M13.356 43.52H7.54799V42.056L10.176 39.752C10.624 39.36 10.952 39.008 11.16 38.696C11.368 38.376 11.472 38.032 11.472 37.664V37.508C11.472 37.14 11.352 36.86 11.112 36.668C10.872 36.468 10.58 36.368 10.236 36.368C9.80399 36.368 9.47599 36.492 9.25199 36.74C9.02799 36.98 8.868 37.272 8.772 37.616L7.40399 37.088C7.49199 36.808 7.61599 36.544 7.77599 36.296C7.93599 36.04 8.13599 35.816 8.37599 35.624C8.62399 35.432 8.91199 35.28 9.23999 35.168C9.56799 35.056 9.94399 35 10.368 35C10.808 35 11.2 35.064 11.544 35.192C11.888 35.312 12.176 35.484 12.408 35.708C12.648 35.932 12.828 36.196 12.948 36.5C13.076 36.804 13.14 37.136 13.14 37.496C13.14 37.848 13.084 38.168 12.972 38.456C12.86 38.744 12.708 39.02 12.516 39.284C12.324 39.54 12.1 39.788 11.844 40.028C11.588 40.26 11.316 40.496 11.028 40.736L9.26399 42.188H13.356V43.52Z"  />
    </svg>
  );
};

export default Tooth42;