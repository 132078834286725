import { FC, useRef, useState } from "react";
import {
  DAY_MILISECOND,
  dateNoTime,
  getDaysInMonth,
  getDefaultState,
  getSelectedInterval,
} from "./utils";
import Button from "../Button/Button";
import Portal from "../Portal/Portal";
import MonthCalendarGrid from "./MonthCalendarGrid/MonthCalendarGrid";
import { parseAsEndDate, parseAsStartDate, prettyDate } from "../../lib";
import styles from "./styles.module.css";
import { CalendarIcon } from "../assets";

type Props = {
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onDatesSelected: (startDate: Date, endDate: Date) => void;
};

const DateRangePickerOpened: FC<Props & { onCancel: () => void; }> = ({
  defaultStartDate,
  defaultEndDate,
  onCancel,
  onDatesSelected,
}) => {
  const [dates, setDates] = useState<{
    startDate?: Date;
    endDate?: Date;
    refStartDate: Date;
    refEndDate: Date;
    isCalloutVisible: boolean;
  }>(getDefaultState(defaultStartDate, defaultEndDate));

  const onSelectDate = (date: Date) => {
    let startDate = dates.startDate;
    let endDate = dates.endDate;

    if (startDate && startDate.getTime() > date.getTime()) {
      endDate = startDate;
      startDate = date;
    } else if (endDate && date.getTime() > endDate.getTime()) {
      endDate = date;
    } else {
      startDate = date;
      endDate = date;
    }
    setDates((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  const onMonthChanged = (month: number) => {
    setDates((prevState) => ({
      ...prevState,
      refStartDate: new Date(
        prevState.refStartDate.getFullYear(),
        prevState.refStartDate.getMonth() + month,
        1
      ),
      refEndDate: new Date(
        prevState.refEndDate.getFullYear(),
        prevState.refEndDate.getMonth() + month,
        1
      ),
    }));
  };

  const setPeriodInDays = (days: number, interval: "d" | "m" | "y") => {
    let startDate = dates.startDate;
    let endDate = dates.endDate;
    const today = dateNoTime(new Date());
    endDate = today;
    if (interval === "d") {
      startDate = new Date(endDate.getTime() - (days - 1) * DAY_MILISECOND);
    } else if (interval === "m") {
      startDate = dateNoTime(new Date());
      startDate.setMonth(startDate.getMonth() - days);
    } else if (interval === "y") {
      startDate = dateNoTime(new Date());
      startDate.setFullYear(startDate.getFullYear() - days);
    }
    setDates((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };

  const days = getDaysInMonth(
    dates.refStartDate.getFullYear(),
    dates.refStartDate.getMonth() + 1,
    dates.startDate,
    dates.endDate
  );

  const selectedInterval =
    dates.startDate && dates.endDate
      ? getSelectedInterval(dates.startDate, dates.endDate)
      : undefined;

  return (
    <>
      <div className={styles.rangePicker}>
        <MonthCalendarGrid
          refDate={dates.refEndDate}
          days={days}
          onDateChange={onSelectDate}
          onMonthChanged={onMonthChanged}
        />
        <ul className={styles.range}>
          <li>
            <button
              className={selectedInterval === "7d" ? styles.selected : ""}
              onClick={() => {
                setPeriodInDays(7, "d");
              }}
            >
              Ultimele 7 zile
            </button>
          </li>
          <li>
            <button
              className={selectedInterval === "1m" ? styles.selected : ""}
              onClick={() => {
                setPeriodInDays(1, "m");
              }}
            >
              Ultimele 30 zile
            </button>
          </li>
          <li>
            <button
              className={selectedInterval === "3m" ? styles.selected : ""}
              onClick={() => {
                setPeriodInDays(3, "m");
              }}
            >
              Ultimele 3 luni
            </button>
          </li>
          <li>
            <button
              className={selectedInterval === "6m" ? styles.selected : ""}
              onClick={() => {
                setPeriodInDays(6, "m");
              }}
            >
              Ultimele 6 luni
            </button>
          </li>
          <li>
            <button
              className={selectedInterval === "1y" ? styles.selected : ""}
              onClick={() => {
                setPeriodInDays(1, "y");
              }}
            >
              Ultimele 12 luni
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.buttons}>
        <Button type='button' size="small" variant="text" onClick={onCancel}>
          Anuleaza
        </Button>
        <Button
          type='button'
          size="small"
          variant="contained"
          onClick={() => onDatesSelected(dates.startDate!, dates.endDate!)}
        >
          Aplica
        </Button>
      </div>
    </>
  );
};

const DateRangePicker: FC<Props> = ({
  defaultStartDate,
  defaultEndDate,
  onDatesSelected,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button
        type="button"
        ref={ref}
        size="small"
        className={styles.rangePickerButton}
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <span>
          {defaultStartDate && defaultEndDate
            ? `${prettyDate(defaultStartDate)} - ${prettyDate(defaultEndDate)}`
            : "Alege perioada"}
        </span>
        <CalendarIcon />
      </Button>
      <Portal
        isOpen={isOpen}
        anchorEl={ref.current}
        onDismiss={() => {
          setIsOpen(false);
        }}
        width={554}
      >
        <DateRangePickerOpened
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          onCancel={() => setIsOpen(false)}
          onDatesSelected={(startDate: Date, endDate: Date) => {
            setIsOpen(false);
            onDatesSelected(parseAsStartDate(startDate), parseAsEndDate(endDate));
          }}
        />
      </Portal>
    </div>
  );
};

export default DateRangePicker;
