import { FC } from 'react';

const BellIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M9.35395 21.3844C10.0591 22.0068 10.9853 22.3844 11.9998 22.3844C13.0142 22.3844 13.9405 22.0068 14.6456 21.3844M17.9998 8.3844C17.9998 6.7931 17.3676 5.26698 16.2424 4.14176C15.1172 3.01654 13.5911 2.3844 11.9998 2.3844C10.4085 2.3844 8.88235 3.01654 7.75713 4.14176C6.63192 5.26698 5.99977 6.7931 5.99977 8.3844C5.99977 11.4746 5.22024 13.5904 4.34944 14.9898C3.6149 16.1703 3.24763 16.7605 3.2611 16.9252C3.27601 17.1075 3.31463 17.177 3.46155 17.286C3.59423 17.3844 4.19237 17.3844 5.38863 17.3844H18.6109C19.8072 17.3844 20.4053 17.3844 20.538 17.286C20.6849 17.177 20.7235 17.1075 20.7384 16.9252C20.7519 16.7605 20.3846 16.1703 19.6501 14.9898C18.7793 13.5904 17.9998 11.4746 17.9998 8.3844Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BellIcon;