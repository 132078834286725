import { API_URLS } from "../../lib";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";

const useStageDelete = (onClose: () => void) => {
  const { deleteRequest, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onDelete = async (stageId: number) => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.stage.stage}/${stageId}`);
      loadingEnd();
      notify('Etapa a fost stearsa', 'success');
      onClose();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  return { onDelete }
}

export default useStageDelete;