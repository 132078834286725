import { FC } from 'react';
import styles from './styles.module.css';

const Loading: FC = () => {
  return (
    <div className={styles.overlay}>
      <span className={styles.loader}></span>
    </div>
  );
};

export default Loading;