import { FC } from "react";
import { Button, ContainerFlex, Typography } from "../../../toolkit";
import { prettyDate } from "../../../lib";

type Props = {
  currentPeriodEnd: Date;
  onClose: () => void;
  onSave: () => void;
};

const RestoreSubscription: FC<Props> = ({
  currentPeriodEnd,
  onClose,
  onSave,
}) => {
  return (
    <ContainerFlex type="column" spacing={2}>
      <Typography type="h5" variation="bold">
        Ai ales sa reinstaurezi subsciptia.
      </Typography>
      <Typography type="p">
        Plata se va relua pe acelasi interval stabilit anterior. Urmatoarea
        plata va fii in <strong>{prettyDate(currentPeriodEnd)}</strong>.
      </Typography>
      <Typography type="p">
        Te rugam sa verifici termenii si conditiile pentru protectia datelor.
      </Typography>
      <ContainerFlex type="row" spacing={2} justify="end">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={onClose}
        >
          Renunta
        </Button>
        <Button
          size="medium"
          variant="contained"
          type="button"
          onClick={onSave}
        >
          Continua
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default RestoreSubscription;
