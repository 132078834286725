import { FC, ReactNode } from 'react';
import ActivateUserAlert from './ActivateUserAlert';
import Menu from './Menu/Menu';
import styles from './styles.module.css';
import Header from './Header/Header';

type Props = {
  children: ReactNode;
};

const AppLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Menu />
        <main>
          <Header />
          {children}
        </main>
      </div>
      <ActivateUserAlert />
    </>
  );
};

export default AppLayout;