import { FC, ReactNode } from 'react';
import { DentOpsLogo, Typography } from '../../toolkit';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
};

const LoginLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.wrapper}>    
      <div className={styles.menu}>
        <DentOpsLogo />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          {children}
        </div>
        <Typography type='span' variation='secondary'>
          Copyright &copy; {new Date().getFullYear()} DentOps. All right reserved.
        </Typography>
      </div>
    </div>
  );
};

export default LoginLayout;
