import { FC } from 'react';

const PasswordIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M36.6666 18.333V13.6663C36.6666 11.7995 36.6666 10.8661 36.3033 10.153C35.9837 9.52583 35.4738 9.0159 34.8466 8.69632C34.1335 8.33301 33.2001 8.33301 31.3333 8.33301H8.66659C6.79974 8.33301 5.86632 8.33301 5.15328 8.69632C4.52608 9.0159 4.01614 9.52583 3.69656 10.153C3.33325 10.8661 3.33325 11.7995 3.33325 13.6663V19.6663C3.33325 21.5332 3.33325 22.4666 3.69656 23.1796C4.01614 23.8069 4.52608 24.3168 5.15328 24.6364C5.86632 24.9997 6.79974 24.9997 8.66659 24.9997H18.3333M19.9999 16.6663H20.0083M28.3333 16.6663H28.3416M11.6666 16.6663H11.6749M32.0833 28.333V25.4163C32.0833 23.8055 30.7774 22.4997 29.1666 22.4997C27.5558 22.4997 26.2499 23.8055 26.2499 25.4163V28.333M20.4166 16.6663C20.4166 16.8965 20.23 17.083 19.9999 17.083C19.7698 17.083 19.5833 16.8965 19.5833 16.6663C19.5833 16.4362 19.7698 16.2497 19.9999 16.2497C20.23 16.2497 20.4166 16.4362 20.4166 16.6663ZM28.7499 16.6663C28.7499 16.8965 28.5634 17.083 28.3333 17.083C28.1031 17.083 27.9166 16.8965 27.9166 16.6663C27.9166 16.4362 28.1031 16.2497 28.3333 16.2497C28.5634 16.2497 28.7499 16.4362 28.7499 16.6663ZM12.0833 16.6663C12.0833 16.8965 11.8967 17.083 11.6666 17.083C11.4365 17.083 11.2499 16.8965 11.2499 16.6663C11.2499 16.4362 11.4365 16.2497 11.6666 16.2497C11.8967 16.2497 12.0833 16.4362 12.0833 16.6663ZM25.9999 34.9997H32.3333C33.2667 34.9997 33.7334 34.9997 34.0899 34.818C34.4035 34.6582 34.6585 34.4033 34.8183 34.0897C34.9999 33.7331 34.9999 33.2664 34.9999 32.333V30.9997C34.9999 30.0663 34.9999 29.5995 34.8183 29.243C34.6585 28.9294 34.4035 28.6745 34.0899 28.5147C33.7334 28.333 33.2667 28.333 32.3333 28.333H25.9999C25.0665 28.333 24.5998 28.333 24.2433 28.5147C23.9297 28.6745 23.6747 28.9294 23.5149 29.243C23.3333 29.5995 23.3333 30.0663 23.3333 30.9997V32.333C23.3333 33.2664 23.3333 33.7331 23.5149 34.0897C23.6747 34.4033 23.9297 34.6582 24.2433 34.818C24.5998 34.9997 25.0665 34.9997 25.9999 34.9997Z" stroke="#3D9BCE" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PasswordIcon;