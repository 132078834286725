import { FC } from "react";
import { TitanClinic } from "../../models";
import { Card, Delimiter, Typography } from "../../toolkit";

type Props = {
  clinic: TitanClinic;
};

const ClinicDetails: FC<Props> = ({ clinic }) => {
  return (
    <Card type="column" spacing={2}>
      <div>
        <Typography type="p" variation="bold">
          {clinic.clinicName}
        </Typography>
        <Typography type="span" variation="secondary">
          {clinic.email}
        </Typography>
      </div>
      <Delimiter />
      <div>
        <Typography type="label" variation="secondary">
          Adresa
        </Typography>
        <Typography type="p" variation="bold">
          {clinic.address}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          Oras
        </Typography>
        <Typography type="p" variation="bold">
          {clinic.city}&nbsp;
        </Typography>
      </div>
      <div>
        <Typography type="label" variation="secondary">
          Numar telefon
        </Typography>
        <Typography type="p" variation="bold">
          {clinic.phone}&nbsp;
        </Typography>
      </div>
      {/* <div>
        <Typography type='label' variation='secondary'>Cont bancar (IBAN)</Typography>
        <Typography type='p' variation='bold'>{clinic.bankAccount}&nbsp;</Typography>
      </div>
      <div>
        <Typography type='label' variation='secondary'>CUI</Typography>
        <Typography type='p' variation='bold'>{clinic.fiscalCode}&nbsp;</Typography>
      </div>clinic
      <div>
        <Typography type='label' variation='secondary'>Notite</Typography>
        <Typography type='p' variation='bold'>{clinic.notes}&nbsp;</Typography>
      </div> */}
    </Card>
  );
};

export default ClinicDetails;
