import { useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

import { API_URLS, validations } from '../../../lib';
import { TitanToken, TitanUser } from '../../../models';
import { textMessages } from '../../../translations';
import { useAppStoreHooks } from '../../../toolkit';
import { login } from '../../../store';

const validate = validations({
  email: [['required', textMessages.requiredError], ['email', textMessages.validEmailError]],
  password: [['required', textMessages.requiredError]]
});

const useLoginForm = () => {
  const [loginForm, setloginForm] = useState<{
    form: {
      email: string;
      password: string;
    },
    errors: {
      email?: string;
      password?: string;
    };
  }>({
    form: {
      email: '',
      password: ''
    },
    errors: {}
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();
  const onChangeHandler = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setloginForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value
      }
    }));
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(loginForm.form);
    const hasError = Object.keys(errors).length;
    setloginForm(prevState => ({
      ...prevState,
      errors,
    }));
    if (hasError) {
      return;
    }
    loadingStart();
    try {
      const loginResponse = await postJson<{ model: { token: TitanToken; user: TitanUser; }}>(API_URLS.identity.login, loginForm.form);
      loadingEnd();
      if (loginResponse?.model) {
        dispatch(login(loginResponse.model));
        let redirect = '/';
        const query = new URLSearchParams(location.search);
        if (query.has('redirectUrl')) {
          redirect = query.get('redirectUrl') as string;
          redirect += location.hash;
        }
        navigate(redirect);
      } else {
        notify(textMessages.emailOrPasswordIcorrectError, "error");
      }
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.emailOrPasswordIcorrectError, "error");
    }
  };

  return {
    loginForm,
    onChangeHandler,
    onSubmitHandler
  };
};

export default useLoginForm;