import { FC, useEffect, useId, useRef, useState } from 'react';
import Input from '../Input/Input';
import IconButton from '../IconButton/IconButton';
import { ChevronDown, CloseIcon } from '../assets';
import styles from './styles.module.css';
import Typography from '../Typography/Typography';

export type TypeaheadOption = {
  key: string | number;
  name: string;
};

type Props = {
  label?: string;
  selectedKey?: string | number;
  placeholder?: string;
  required?: boolean;
  options: TypeaheadOption[];
  onChange: (selectedKey?: string | number) => void;
  noMargin?: boolean;
  errorMessage?: string;
};

const Typeahead: FC<Props> = ({ label, placeholder, required = false, noMargin = false, selectedKey, options, onChange, errorMessage }) => {
  const ref = useRef<HTMLInputElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<{
    index?: number;
    length: number;
  }>({
    length: options.length
  });

  const name = useId();
  const elRect = dialogRef.current?.getBoundingClientRect();

  const selectedOption = options.find(o => o.key === selectedKey);
  const filteredOptions = options.filter(o => o.name.toLowerCase().includes(query.toLowerCase()));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setActiveIndex(prevState => ({ ...prevState, index: undefined }));
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (dialogRef.current) {
        if (event.key === "ArrowDown") {
          setActiveIndex(prevState => {
            let index = (prevState.index ?? -1) + 1;
            if (index > prevState.length - 1) {
              index = prevState.length - 1;
            }
            return {
              ...prevState,
              index
            };
          });
        } else if (event.key === "ArrowUp") {
          setActiveIndex(prevState => {
            let index = (prevState.index ?? 1) - 1;
            if (index < 0) {
              index = 0;
            }
            return {
              ...prevState,
              index
            };
          });
        } else if (event.key === "Escape") {
          setIsOpen(false);
          setActiveIndex(prevState => ({ ...prevState, index: undefined }));
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dialogRef, setIsOpen]);

  useEffect(() => {
    setActiveIndex(prevState => ({
      ...prevState,
      length: options.length
    }));
  }, [setActiveIndex, options.length]);

  return (
    <div ref={dialogRef} className={styles.typeahead}>
      {label && (
        <Typography type='label' variation='bold' htmlFor={name}>
          {label}{required !== false && <span className={styles.required}>{' '}*</span>}
        </Typography>
      )}
      <Input
        ref={ref}
        type='text'
        placeholder={placeholder}
        name={name}
        value={isOpen ? query : selectedOption?.name}
        noMargin={noMargin}
        autoComplete='off'
        errorMessage={errorMessage}
        onChange={e => {
          setQuery(e.target.value);
        }}
        onFocus={() => {
          if (!isOpen) {
            setQuery(selectedOption?.name || '');
          }
          setIsOpen(true);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(false);
            onChange(filteredOptions[activeIndex.index ?? -1]?.key);
            setActiveIndex(prevState => ({ ...prevState, index: undefined }));
          }
        }}
        style={{ zIndex: 11 }}
        endButton={
          <>
            {(isOpen && query) ? (
              <IconButton type='button' onClick={() => { setQuery(''); }}>
                <CloseIcon />
              </IconButton>
            ) : (
              <>
                {selectedKey ? (
                  <IconButton type='button' onClick={() => { setIsOpen(false); setQuery(''); onChange(undefined); }}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton type='button' onClick={() => { setIsOpen(true); setQuery(selectedOption?.name || ''); }}>
                    <ChevronDown />
                  </IconButton>
                )}
              </>
            )}
          </>
        } />
      {isOpen && (
        <ul
          style={{ top: (elRect?.height ?? 0) - (noMargin ? -2 : 12) }}
        >
          {filteredOptions.length === 0 && (
            <li><Typography type='p'>Nu exista rezultate</Typography></li>
          )}
          {filteredOptions.map((option, index) => (
            <li
              key={option.key}
              onClick={() => {
                setIsOpen(false);
                onChange(option.key);
                setActiveIndex(prevState => ({ ...prevState, index: undefined }));
              }}
              autoFocus={activeIndex.index === index}
              className={activeIndex.index === index ? styles.active : ''}
            >
              <Typography type='p'>{option.name}</Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Typeahead;;