import { FC } from "react";
import { Button, ContainerFlex, Input } from "../../../toolkit";
import { textForm } from "../../../translations";
import usePaymentMethods from "../../hooks/usePaymentMethod";
import { Responsive } from "../../../layout";

type Props = {
  paymentMethodId: string;
  onClose: () => void;
  onSaved: () => void;
};

const PaymentMethodForm: FC<Props> = ({ onClose, onSaved }) => {
  const { paymentMethod, onChange, onSubmit } = usePaymentMethods(onSaved);

  let cardNumber = paymentMethod.form.cardNumber;
  if (cardNumber.length > 4 && cardNumber.length <= 8) {
    cardNumber = `${cardNumber.substring(0, 4)} ${cardNumber.substring(4)}`;
  } else if (cardNumber.length > 8 && cardNumber.length <= 12) {
    cardNumber = `${cardNumber.substring(0, 4)} ${cardNumber.substring(
      4,
      8
    )} ${cardNumber.substring(8)}`;
  } else if (cardNumber.length > 12) {
    cardNumber = `${cardNumber.substring(0, 4)} ${cardNumber.substring(
      4,
      8
    )} ${cardNumber.substring(8, 12)} ${cardNumber.substring(12)}`;
  }

  const onChangeCardNumber = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    const value = (target.value || "").replace(/ /g, "");
    onChange({
      ...e,
      target: { name: target.name, value } as HTMLInputElement,
    });
  };

  const onChangeExpDate = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    let value = target.value || "";
    if (value.length === 2) {
      value += " / ";
    }
    onChange({
      ...e,
      target: { name: target.name, value } as HTMLInputElement,
    });
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Input
        label={textForm.cardNumberLabel}
        placeholder={textForm.cardNumberPlaceholder}
        name="cardNumber"
        onChange={onChangeCardNumber}
        value={cardNumber}
        required
        errorMessage={paymentMethod.errors.cardNumber}
      />
      <Responsive colsXs={2}>
        <Input
          label={textForm.expirationDateLabel}
          placeholder={textForm.expirationDatePlaceholder}
          name="expDate"
          onChange={onChangeExpDate}
          value={paymentMethod.form.expDate}
          required
          errorMessage={paymentMethod.errors.expDate}
        />
        <Input
          label={textForm.cvcLabel}
          placeholder={textForm.cvcPlaceholder}
          name="cvc"
          onChange={onChange}
          value={paymentMethod.form.cvc}
          required
          errorMessage={paymentMethod.errors.cvc}
        />
      </Responsive>
      <ContainerFlex type="row" spacing={2} justify="end">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={onClose}
        >
          Renunta
        </Button>
        <Button size="medium" variant="contained" type="submit">
          Adauga
        </Button>
      </ContainerFlex>
    </form>
  );
};

export default PaymentMethodForm;
