import { useCallback, useEffect, useRef } from "react";
import {
  setElementsData,
  setLoadElements,
  useAppSelector,
} from "../../../store";
import { useAppStoreHooks } from "../../../toolkit";
import { TitanElement } from "../../../models";
import { textMessages } from "../../../translations";
import { API_URLS } from "../../constants";

const useElementsCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const { elements, expireAfterMiliseconds } = useAppSelector(
    (state) => state.cache
  );
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadElements = useCallback(async () => {
    refreshing.current = true;
    try {
      const elements = await getJson<TitanElement[]>(
        API_URLS.titan.elements.list
      );
      dispatch(setElementsData(elements));
    } catch (e: any) {
      notify(textMessages.genericError, "warning");
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }
    if (!elements.expireTime) {
      dispatch(setLoadElements());
      loadElements();
    } else {
      const timer = elements.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadElements();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    loadElements,
    dispatch,
    elements.expireTime,
    expireAfterMiliseconds,
    expireAfterMinutes,
  ]);
};
export default useElementsCache;
