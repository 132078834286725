import { FC } from 'react';
import { IColumn, TableProps } from '../../../lib';
import { TitanUser } from '../../../models';
import { Button, ContainerFlex, EditNoteIcon, IconButton, TrashIcon, UserAddIcon } from '../../../toolkit';
import { TableList } from '../../../components';
import { translateRole } from '../../../resources';
import { useAppSelector } from '../../../store';

interface IDocument extends TitanUser {
  key: string;
}

type Props = {
  onOpenModal: (type: 'add' | 'edit' | 'delete' | 'configure-stages', user?: TitanUser) => void;
} & TableProps;

const UserList: FC<Props> = (listProps) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages ?? false);

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Nume',
      fieldName: 'name',
      onRender: (item: IDocument) => (
        <span>{item.firstName} {item.lastName}</span>
      )
    },
    {
      key: 'email',
      name: 'Email',
      fieldName: 'email',
    },
    {
      key: 'roles',
      name: 'Roluri',
      fieldName: 'roles',
      onRender: (item: IDocument) => (
        <span>
          {item.roles.reduce((accumulator, current) => {
            if(current.name === 'Owner') {
              return accumulator
            }

            if (accumulator) {
              accumulator = `${accumulator}, `;
            }
            return accumulator + translateRole(current.name);
          }, '')}
        </span>
      )
    },
    {
      key: 'stages',
      name: 'Etape',
      fieldName: '',
      show: stagesFeature,
      onRender: (item: IDocument) => {
        return item.roles.some(r => r.name === 'DentopsTechnician') ? (
          <ContainerFlex type='row' spacing={1} align='center'>
            <Button type='button' variant='text' size='small' onClick={() => listProps.onOpenModal('configure-stages', item)}>
              Configureaza
            </Button>
          </ContainerFlex>
        ) : '';
      }
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      width: 110,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1}>
            <IconButton variant='outlined' onClick={() => listProps.onOpenModal('edit', item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton variant='outlined' color='danger' onClick={() => listProps.onOpenModal('delete', item)}>
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    }
  ];

  const items = listProps.results.map((item: TitanUser, index: number) => {
    return {
      key: item.userIdentifier,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });

  return (
    <TableList
      key={String(stagesFeature)}
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici un angajat',
        emptyIcon: <UserAddIcon size={40} />,
        emptyTitle: 'Momentan nu ai nici un angajat adaugat',
        emptySubTitle: 'Adauga primul tau angajat apasand pe butonul de jos',
        emptyAction: () => listProps.onOpenModal('add'),
        emptyActionText: 'Adauga angajat'
      }}
      isSubList={true}
    />
  );
};

export default UserList;