import { FC, useEffect, useState } from 'react';
import { CloseIcon, IconButton, Input, SearchIcon } from '../../toolkit';
import { useDebounce } from '../../lib';

type Props = {
  placeholder: string;
  onChange: (searchTerm?: string) => void;
};

const SearchInput: FC<Props> = ({ placeholder, onChange }) => {
  const [searchTem, setSearchTerm] = useState<string | undefined>();
  const debouncedValue = useDebounce(searchTem, 500);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <Input
      type='search'
      value={searchTem || ''}
      placeholder={placeholder}
      onChange={e => {
        const search = e.target?.value || '';
        setSearchTerm(search);
      }}
      startIcon={<SearchIcon />}
      endButton={
        <IconButton onClick={() => {
          setSearchTerm(undefined);
        }}>
          <CloseIcon /> 
        </IconButton>
      }
    />
  );
};

export default SearchInput;