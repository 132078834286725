import { FC } from 'react';
import styles from './styles.module.css';
import { DentOpsIcon, Typography } from '../../toolkit';

const InitialLoadLayout: FC = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <main>
          <div className={styles.animatedBox}>
            <DentOpsIcon size={160} />
          </div>
          <Typography type='p'>
            Se incarca ...
          </Typography>
        </main>
      </div>
    </>
  );
};

export default InitialLoadLayout;