import { TableProps } from '../types';

const getSortingProps = (key: string, listProps: TableProps): {
  isSorted: boolean;
  isSortedDescending: boolean;
  onColumnClick: () => void;
} => {
  const sortingParams = { ...(listProps.sortingParams || {}) };
  return {
    isSorted: sortingParams[key] !== undefined,
    isSortedDescending: !Boolean(sortingParams[key]),
    onColumnClick: () => {
      if (sortingParams[key] === undefined) {
        listProps.setSortingParam({ [key]: true });
      } else if (Boolean(sortingParams[key])) {
        listProps.setSortingParam({ [key]: false });
      } else {
        listProps.setSortingParam({});
      }
    },
  };
};

export default getSortingProps;