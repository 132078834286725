import { FC } from "react";
import {
  Button,
  Card,
  CasesIcon,
  ContainerFlex,
  DateRangePicker,
  Portal,
  Toggle,
  ToothIcon,
  Typography,
} from "../../../toolkit";
import { Responsive } from "../../../layout";
import { Metric } from "../../../components";
import styles from "./styles.module.css";
import { prettyDate } from "../../../lib";
import TenderCaseCheckList from "../TenderCaseCheckList/TenderCaseCheckList";
import { useAppSelector } from "../../../store";
import { useClinicReport } from "../../hooks";

type Props = {
  clinicId: number;
  clinicName: string;
};

const ClinicReportTool: FC<Props> = ({ clinicId, clinicName }) => {
  const user = useAppSelector((store) => store.auth.user);
  const {
    reportSettings,
    clinicReport,
    tenderConfig,
    onDatesSelected,
    onIncludePaidCasesChanged,
    onIncludeUnfinishedCasesChanged,
    onGenerateTender,
    onDismissTender,
    toggleSelectedCase,
    onSelectAllCasesChanged,
    generateReport,
  } = useClinicReport(clinicId, clinicName);

  return (
    <Card noPadding>
      <div className={styles.reportHeader}>
        <Typography type="h5">Raport</Typography>
        <DateRangePicker
          defaultStartDate={reportSettings.startDate}
          defaultEndDate={reportSettings.endDate}
          onDatesSelected={onDatesSelected}
        />
      </div>
      <div className={styles.reportBody}>
        <Responsive colsFrLg="1-1">
          <Metric
            label="Cazuri"
            metric={String(clinicReport?.totalCases || 0)}
            icon={<CasesIcon />}
          />
          <Metric
            label="Elemente"
            metric={String(clinicReport?.totalElements || 0)}
            icon={<ToothIcon />}
          />
          {user?.isManagerOrAbove && (
            <>
              <Metric
                label="Suma"
                metric={String(clinicReport?.total || 0)}
                icon={<CasesIcon />}
              />
              <Metric
                label="Suma Restanta"
                metric={String(clinicReport?.amountLeft || 0)}
                icon={<CasesIcon />}
              />
            </>
          )}
        </Responsive>
        {user?.isManagerOrAbove && (
          <div className={styles.tenderCard}>
            <div>
              <Typography type="h6">Genereaza deviz - </Typography>
              <Typography type="p" variation="secondary">
                {prettyDate(reportSettings.startDate)} -{" "}
                {prettyDate(reportSettings.endDate)}
              </Typography>
            </div>
            <Toggle
              value={reportSettings.includePaidCases}
              onChange={onIncludePaidCasesChanged}
              label="Include cazurile platite"
            />
            <Toggle
              value={reportSettings.includeUnfinishedCases}
              onChange={onIncludeUnfinishedCasesChanged}
              label="Include cazurile nefinalizate"
            />
            <div>
              <Button
              type='button'
                size="small"
                variant="outlined"
                onClick={onGenerateTender}
              >
                Genereaza
              </Button>
            </div>
          </div>
        )}
      </div>
      <Portal
        isOpen={tenderConfig.caseTenderList !== undefined}
        onDismiss={onDismissTender}
        title="Genereaza deviz"
        overlay={true}
        width={800}
      >
        <ContainerFlex type="column" spacing={3}>
          <TenderCaseCheckList
            key="tender-report"
            tenderCases={tenderConfig.caseTenderList!}
            selectedCases={tenderConfig.selectedCases}
            toggleSelectedCase={toggleSelectedCase}
            onSelectAllCasesChanged={onSelectAllCasesChanged}
          />
          <ContainerFlex type="row" spacing={2} justify="end">
            <Button
              size="medium"
              variant="outlined"
              type="button"
              onClick={onDismissTender}
            >
              Renunta
            </Button>
            <Button
              size="medium"
              variant="contained"
              type="button"
              disabled={!tenderConfig.selectedCases?.length}
              onClick={generateReport}
            >
              Genereaza
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </Card>
  );
};

export default ClinicReportTool;
