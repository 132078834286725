import { API_URLS } from "../../lib";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";

const useUserDelete = (onClose: () => void) => {
  const { deleteRequest, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onDelete = async (titanUserId: string) => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.identity.user.user}/${titanUserId}`);
      loadingEnd();
      notify("Angajatul a fost sters", "success");
      onClose();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  return { onDelete };
};

export default useUserDelete;
