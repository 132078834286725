export const translateRole = (role: string) => {
  switch (role) {
    case 'DentopsAdmin':
      return 'Administrator';
    case 'DentopsDataEntry':
      return 'Introducere Date';
    case 'DentopsTechnician':
      return 'Tehnician';
    case 'DentopsManager':
      return 'Manager';
    default:
      return '';
  }
};
