import { FC } from 'react';
import { AnimatedAppIcon, Card, ContainerFlex, Delimiter, IconButton, Input, Toggle, TrashIcon, Typeahead, TypeaheadOption, Typography, UserAddIcon } from '../../../toolkit';
import { TitanUserStage } from '../../../models';

type Props = {
  stageUsers: TitanUserStage[];
  userOptions: TypeaheadOption[];
  onUserChanged: (userId: string) => void;
  onRemoveStageUser: (index?: number | undefined) => void;
  onStageValueChanged: (titanUserId: string, value: string) => void;
  onStageIsPercentChanged: (titanUserId: string, value: boolean) => void;
};

const StageFormConfigureUsers: FC<Props> = ({
  stageUsers,
  userOptions,
  onUserChanged,
  onRemoveStageUser,
  onStageValueChanged,
  onStageIsPercentChanged,
}) => {

  return (
    <ContainerFlex type="column" spacing={1}>
      <Typeahead
        label="Atribuie tehnician"
        options={userOptions || []}
        onChange={selectedKey => onUserChanged(selectedKey as string)}
      />
      <ContainerFlex type="column" spacing={1}>
        {stageUsers.map((stage, index) => (
          <Card key={stage.userIdentifier} variant='secondary' type="column" spacing={2}>
            <ContainerFlex type="row" justify="space-between">
              <Typography type="p">{stage.firstName} {stage.lastName}</Typography>
              <IconButton variant='outlined' color='danger' onClick={() => onRemoveStageUser(index)}>
                <TrashIcon />
              </IconButton>
            </ContainerFlex>
            <Delimiter />
            <ContainerFlex type="row" spacing={2}>
              <Input
                placeholder="Adauga castigul tehnicianului"
                type="text"
                onChange={e => onStageValueChanged(stage.userIdentifier, e.target.value)}
                value={stage.value !== undefined ? String(stage.value) : ""}
              />
              <div style={{ paddingTop: 10 }}>
                <Toggle
                  value={stage.isPercent}
                  onChange={(_, checked) => onStageIsPercentChanged(stage.userIdentifier, checked!)}
                  label="Procent" />
              </div>
            </ContainerFlex>
          </Card>
        ))}
        {stageUsers.length === 0 && (
          <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
            <AnimatedAppIcon>
              <UserAddIcon size={40} />
            </AnimatedAppIcon>
            <Typography type='p' variation="center">
              Nu exista tehnicieni asociati.<br />
              <Typography type='span' variation='secondary'>
                Alege un tehnician pentru a incepe.
              </Typography>
            </Typography>
          </ContainerFlex>
        )}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default StageFormConfigureUsers;