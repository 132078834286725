import { FC, useState } from 'react';
import { PageLayout } from '../../layout';
import { Tabs } from '../../toolkit';
import { ClientList, ClinicList } from '../../modules';

const ClientsPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('clients');

  const tabs = (
    <Tabs
      selected={selectedTab}
      items={[
        { id: 'clients', label: 'Medici' },
        { id: 'clinics', label: 'Clinici' },
      ]}
      onTabChanged={id => { setSelectedTab(id); }}
    />
  );

  return (
    <PageLayout title="Colaboratori" tabs={tabs}>
      {selectedTab === 'clients' && (
        <ClientList />
      )}
      {selectedTab === 'clinics' && (
        <ClinicList />
      )}
    </PageLayout>
  );
};

export default ClientsPage;