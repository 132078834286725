import { FC } from "react";
import {
  Button,
  Card,
  CasesIcon,
  ContainerFlex,
  DateRangePicker,
  DownloadIcon,
  FileDownload1Icon,
  FileDownload2Icon,
  IconButton,
  Portal,
  Toggle,
  ToothIcon,
  Typography,
} from "../../../toolkit";
import useClientReport from "../../hooks/useClientReport";
import { Responsive } from "../../../layout";
import { Metric } from "../../../components";
import styles from "./styles.module.css";
import { prettyDate } from "../../../lib";
import TenderCaseCheckList from "../TenderCaseCheckList/TenderCaseCheckList";
import { useAppSelector } from "../../../store";

type Props = {
  clientId: number;
  clientName: string;
};

const ClientReportTool: FC<Props> = ({ clientId, clientName }) => {
  const user = useAppSelector((store) => store.auth.user);
  const {
    reportSettings,
    clientReport,
    tenderConfig,
    onDatesSelected,
    onIncludePaidCasesChanged,
    onIncludeUnfinishedCasesChanged,
    onGenerateTender,
    onDismissTender,
    toggleSelectedCase,
    onSelectAllCasesChanged,
    generateReport,
    onDownload
  } = useClientReport(clientId, clientName);

  return (
    <Card noPadding>
      <div className={styles.reportHeader}>
        <Typography type="h5">Raport</Typography>
        <DateRangePicker
          defaultStartDate={reportSettings.startDate}
          defaultEndDate={reportSettings.endDate}
          onDatesSelected={onDatesSelected}
        />
      </div>
      <div className={styles.reportBody}>
        <Responsive colsFrLg="1-1">
          <Metric
            label="Cazuri"
            metric={String(clientReport?.totalCases || 0)}
            icon={<CasesIcon />}
          />
          <Metric
            label="Elemente"
            metric={String(clientReport?.totalElements || 0)}
            icon={<ToothIcon />}
          />
          {user?.isManagerOrAbove && (
            <>
              <Metric
                label="Suma"
                metric={String(clientReport?.total || 0)}
                icon={<CasesIcon />}
              />
              <Metric
                label="Suma Restanta"
                metric={String(clientReport?.amountLeft || 0)}
                icon={<CasesIcon />}
              />
            </>
          )}
        </Responsive>
        {user?.isManagerOrAbove && (
          <div className={styles.tenderCard}>
            <div>
              <Typography type="h6">Genereaza deviz - </Typography>
              <Typography type="p" variation="secondary">
                {prettyDate(reportSettings.startDate)} -{" "}
                {prettyDate(reportSettings.endDate)}
              </Typography>
            </div>
            <Toggle
              value={reportSettings.includePaidCases}
              onChange={onIncludePaidCasesChanged}
              label="Include cazurile platite"
            />
            <Toggle
              value={reportSettings.includeUnfinishedCases}
              onChange={onIncludeUnfinishedCasesChanged}
              label="Include cazurile nefinalizate"
            />
            <div>
              <Button
                type='button'
                size="small"
                variant="outlined"
                onClick={onGenerateTender}
              >
                Genereaza
              </Button>
            </div>
          </div>
        )}
      </div>
      <Portal
        isOpen={tenderConfig.caseTenderList !== undefined}
        onDismiss={onDismissTender}
        title="Genereaza deviz"
        overlay={true}
        width={800}
      >
        {tenderConfig.report ? (
          <ContainerFlex type="column" spacing={3}>
            <ContainerFlex type="row" spacing={2} justify="center">
              <IconButton onClick={() => onDownload('elements')} className={styles.download} variant='outlined'>
                <FileDownload1Icon size={64} />
                Descarca deviz elemente
              </IconButton>
              <IconButton onClick={() => onDownload('cases')} className={styles.download} variant='outlined'>
                <FileDownload2Icon size={64} />
                Descarca deviz cazuri
              </IconButton>
              <IconButton onClick={() => onDownload('both')} className={styles.download} variant='outlined' >
                <DownloadIcon size={64} />
                Descarca deviz combinat
              </IconButton>
            </ContainerFlex>
            <ContainerFlex type="row" spacing={2} justify="end">
              <Button
                size="medium"
                variant="outlined"
                type="button"
                onClick={onDismissTender}
              >
                Inchide
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        ) : (

          <ContainerFlex type="column" spacing={3}>
            <TenderCaseCheckList
              key="tender-report"
              tenderCases={tenderConfig.caseTenderList!}
              selectedCases={tenderConfig.selectedCases}
              toggleSelectedCase={toggleSelectedCase}
              onSelectAllCasesChanged={onSelectAllCasesChanged}
            />
            <ContainerFlex type="row" spacing={2} justify="end">
              <Button
                size="medium"
                variant="outlined"
                type="button"
                onClick={onDismissTender}
              >
                Renunta
              </Button>
              <Button
                size="medium"
                variant="contained"
                type="button"
                disabled={!tenderConfig.selectedCases?.length}
                onClick={generateReport}
              >
                Genereaza
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        )}
      </Portal>
    </Card>
  );
};

export default ClientReportTool;
