import { useCallback } from "react";
import { reloadList } from "../../store";
import { TitanCase } from "../../models";
import { useAppStoreHooks } from "../../toolkit";
import { useNavigate } from "react-router-dom";
import useCaseBase, { validate } from "./useCaseBase";
import { API_URLS } from "../../lib";

const useCaseCreate = (clientId?: number) => {
  const navigate = useNavigate();
  const { postFormData, notify, dispatch, loadingStart, loadingEnd } =
    useAppStoreHooks();
  const { setCaseForm, ...options } = useCaseBase(clientId);

  const onSubmit = useCallback(
    async (titanCase: TitanCase) => {
      const errors = validate(titanCase);
      setCaseForm((prevState) => ({
        ...prevState,
        errors,
      }));
      if (Object.keys(errors).length) {
        return;
      }
      loadingStart();
      try {
        await postFormData<TitanCase>(API_URLS.titan.cases.create, titanCase);
        notify("Cazul a fost adaugat.", "success");
        loadingEnd();
        dispatch(reloadList({ pageKey: API_URLS.titan.cases.list }));
        if (clientId) {
          dispatch(
            reloadList({
              pageKey: `${API_URLS.titan.cases.list}?titanClientId=${clientId}`,
            })
          );
          navigate(`/clients/${clientId}`);
        } else {
          navigate("/cases");
        }
      } catch (e: any) {
        notify(e.message, "error");
        loadingEnd();
      }
    },
    [
      postFormData,
      notify,
      navigate,
      clientId,
      dispatch,
      loadingStart,
      loadingEnd,
      setCaseForm,
    ]
  );

  return {
    ...options,
    onSubmit,
  };
};

export default useCaseCreate;
