import { FC } from 'react';
import { useUserStatistics } from '../../hooks';
import { Responsive } from '../../../layout';
import { Metric } from '../../../components';
import { ToothIcon, MoneyIcon, ContainerFlex, Button, DownloadIcon, Checkbox } from '../../../toolkit';
import { formatMoney, useCacheList } from '../../../lib';
import UserElementStatisticsList from './UserElementStatisticsList';

type Props = {
  startDate: Date;
  endDate: Date;
  userIdentifier: string;
};

const UserStatisticsDetails: FC<Props> = ({ startDate, endDate, userIdentifier }) => {
  const { userStatistics, onDownloadUserReport, finishedDateTime, loadReport } = useUserStatistics(startDate, endDate, userIdentifier);
  const listProps = useCacheList({ data: userStatistics?.elementReportModels, loading: false }, () => () => true, () => ({ payload: undefined, type: 'fake' }));

  return (
    <ContainerFlex type='column' spacing={2}>
      <Checkbox
        label="Include cazurile terminate in perioada selectata"
        value={finishedDateTime}
        onChange={() => loadReport(startDate, endDate, userIdentifier, !finishedDateTime)} />
      <Responsive colsMd={2}>
        <Metric label="Elemente" metric={String(userStatistics?.elementsExecuted || 0)} icon={<ToothIcon />} />
        {/* <Metric 
          label="Incasari" 
          metric={`${formatMoney(userStatistics?.elementReportModels.reduce((acc, current) => acc + current.priceForElements, 0) || 0)} RON`} 
          icon={<MoneyIcon />} /> */}
        <Metric
          label="Cheltuieli salariale"
          metric={`${formatMoney(userStatistics?.elementReportModels.reduce((acc, current) => acc + current.amountPaid, 0) || 0)} RON`}
          icon={<MoneyIcon />} />
      </Responsive>
      {listProps.results.length > 0 && (
        <ContainerFlex type='row' justify='end'>
          <Button
          type='button'
            variant='outlined'
            size='small'
            onClick={onDownloadUserReport}
          >
            <DownloadIcon /> Descarca raport
          </Button>
        </ContainerFlex>
      )}
      <UserElementStatisticsList {...listProps} />
    </ContainerFlex>
  );
};

export default UserStatisticsDetails;