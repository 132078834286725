import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, PageLayout } from "../../layout";
import {
  AnimatedAppIcon,
  Button,
  ContainerFlex,
  DateRangePicker,
  InputPassword,
  LockIcon,
  Portal,
  Tabs,
  Typography,
} from "../../toolkit";
import {
  ClientStatistics,
  ElementStatistics,
  GeneralStatistic,
  UserStatistics,
  useReports,
} from "../../modules";

const ReportsPage: FC = () => {
  const { selected = "" } = useParams<{ selected?: string }>();
  const navigate = useNavigate();

  const { reportSettings, onDatesSelected, onSubmit, onChange, reportData } =
    useReports();

  const tabs = (
    <Tabs
      selected={selected}
      items={[
        { id: "", label: "General" },
        { id: "clients", label: "Colaboratori" },
        { id: "elements", label: "Elemente" },
        { id: "users", label: "Angajati" },
      ]}
      onTabChanged={(id) => {
        navigate(`/reports/${id}`);
      }}
    />
  );

  return (
    <PageLayout title="Statistici" tabs={tabs}>
      <Container vertical gap={24}>
        <ContainerFlex type="row" justify="space-between" align="center">
          {selected === "" && (
            <Typography type="h5">Statistici generale</Typography>
          )}
          {selected === "clients" && (
            <Typography type="h5">Statistici colaboratori</Typography>
          )}
          {selected === "elements" && (
            <Typography type="h5">Statistici elemente</Typography>
          )}
          {selected === "users" && (
            <Typography type="h5">Statistici angajati</Typography>
          )}
          <DateRangePicker
            defaultStartDate={reportSettings.startDate}
            defaultEndDate={reportSettings.endDate}
            onDatesSelected={onDatesSelected}
          />
        </ContainerFlex>
        {selected === "" && <GeneralStatistic statistics={reportData} />}
        {selected === "clients" && (
          <ClientStatistics
            clientStatistics={reportData?.clientStatistics || []}
          />
        )}
        {selected === "elements" && (
          <ElementStatistics
            elementStatistics={reportData?.elementStatistics || []}
          />
        )}
        {selected === "users" && (
          <UserStatistics
            startDate={reportSettings.startDate}
            endDate={reportSettings.endDate}
          />
        )}
      </Container>
      <Portal
        isOpen={!reportSettings.passwordConfirmed}
        onDismiss={() => navigate(-1)}
        title="Continut securizat"
        overlay={true}
        width={560}
      >
        <form noValidate onSubmit={onSubmit}>
          <ContainerFlex type="column" spacing={3}>
            <AnimatedAppIcon variation="danger">
              <LockIcon size={40} />
            </AnimatedAppIcon>

            <Typography type="p" variation="center">
              Confirma parola pentru a accesa statisticile
            </Typography>
            <InputPassword
              name="password"
              onChange={onChange}
              value={reportSettings.form.password}
              required
              errorMessage={reportSettings.errors.password}
            />
            <ContainerFlex type="row" spacing={2} justify="end">
              <Button
                size="medium"
                variant="outlined"
                type="button"
                onClick={() => navigate(-1)}
              >
                Renunta
              </Button>
              <Button size="medium" variant="contained" type="submit">
                Confirma
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </form>
      </Portal>
    </PageLayout>
  );
};

export default ReportsPage;
