import { FC, ReactNode, useRef, useState } from 'react';
import Button from '../Button/Button';
import { ChevronDown } from '../assets';
import Portal from '../Portal/Portal';
import Typography from '../Typography/Typography';
import styles from './styles.module.css';

type Props = {
  label?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  children: ReactNode;
  errorMessage?: string;
};

const Dropdown: FC<Props> = ({ label, required = false, placeholder, value, children, errorMessage }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={[styles.dropdown, errorMessage ? styles.error : ''].join(' ')}>
      {label && (
        <Typography type='label' variation='bold'>
          {label}{required !== false && <span className={styles.required}>{' '}*</span>}
        </Typography>
      )}
      <Button
        ref={ref}
        type='button'
        size='medium'
        // className={styles.rangePickerButton}
        variant='outlined'
        onClick={() => {
          setIsOpen(true);
        }}>
        {value}
        {!value && (<span>{placeholder}</span>)}
        <ChevronDown />
      </Button>
      <Portal
        isOpen={isOpen}
        anchorEl={ref.current}
        onDismiss={() => {
          setIsOpen(false);
        }}
        width={ref.current?.clientWidth || 300}
      >
        <div onClick={() => setIsOpen(false)}>
          {children}
        </div>
      </Portal>
      {errorMessage && (
        <span>{errorMessage}</span>
      )}
    </div>
  );
};

export default Dropdown;