export const toothCodeMap: { [key: string]: number; } = {
  '11': 1,
  '12': 2,
  '13': 3,
  '14': 4,
  '15': 5,
  '16': 6,
  '17': 7,
  '18': 8,
  '21': 9,
  '22': 10,
  '23': 11,
  '24': 12,
  '25': 13,
  '26': 14,
  '27': 15,
  '28': 16,
  '31': 17,
  '32': 18,
  '33': 19,
  '34': 20,
  '35': 21,
  '36': 22,
  '37': 23,
  '38': 24,
  '41': 25,
  '42': 26,
  '43': 27,
  '44': 28,
  '45': 29,
  '46': 30,
  '47': 31,
  '48': 32
};

export const toothIdMap: { [key: string]: string; } = {
  '1': '11',
  '2': '12',
  '3': '13',
  '4': '14',
  '5': '15',
  '6': '16',
  '7': '17',
  '8': '18',
  '9': '21',
  '10': '22',
  '11': '23',
  '12': '24',
  '13': '25',
  '14': '26',
  '15': '27',
  '16': '28',
  '17': '31',
  '18': '32',
  '19': '33',
  '20': '34',
  '21': '35',
  '22': '36',
  '23': '37',
  '24': '38',
  '25': '41',
  '26': '42',
  '27': '43',
  '28': '44',
  '29': '45',
  '30': '46',
  '31': '47',
  '32': '48'
};
