import { FC } from 'react';

const QuestionIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M9.09 9.3844C9.3251 8.71607 9.78915 8.15251 10.4 7.79353C11.0108 7.43456 11.7289 7.30334 12.4272 7.42311C13.1255 7.54289 13.7588 7.90592 14.2151 8.44793C14.6713 8.98993 14.9211 9.67592 14.92 10.3844C14.92 12.3844 11.92 13.3844 11.92 13.3844M12 17.3844H12.01M22 12.3844C22 17.9072 17.5228 22.3844 12 22.3844C6.47715 22.3844 2 17.9072 2 12.3844C2 6.86155 6.47715 2.3844 12 2.3844C17.5228 2.3844 22 6.86155 22 12.3844Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default QuestionIcon;