import { FC } from 'react';
import { TitanUser } from '../../../models';
import { useUser } from '../../hooks';
import UserFormConfigureStages from './UserFormConfigureStages';
import { Button, ContainerFlex, Typography } from '../../../toolkit';

type Props = {
  user?: TitanUser;
  onClose: () => void;
  onSaved: () => void;
};

const UserStagesForm: FC<Props> = ({ user, onClose, onSaved }) => {

  const {
    userForm,
    stagesOptions,
    onSubmit,
    onStageChanged,
    onStageValueChanged,
    onStageIsPercentChanged,
    onRemoveUserStage,
  } = useUser(onSaved, user);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography type='h6'>
        Configureaza etape pentru {user?.firstName} {user?.lastName}
      </Typography>
      <br />
      <UserFormConfigureStages
        userStages={userForm.userStages || []}
        stagesOptions={stagesOptions || []}
        onStageChanged={onStageChanged}
        onStageValueChanged={onStageValueChanged}
        onStageIsPercentChanged={onStageIsPercentChanged}
        onRemoveUserStage={onRemoveUserStage}
      />
      <br />
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default UserStagesForm;