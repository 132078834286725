import { FC, ReactNode } from 'react';
import styles from './styles.module.css';
import Typography from '../Typography/Typography';

type Props = {
  children?: ReactNode;
  value?: boolean;
  label?: string;
  onRenderLabel?: () => ReactNode;
  onChange: (_?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
};

const Toggle: FC<Props> = ({ value, onChange, label, onRenderLabel }) => {
  return (
    <div className={styles.toggle}>
      <label className={styles.switch}>
        <input type="checkbox" checked={value} onChange={e => onChange(e, e?.target?.checked)} />
        <div className={[styles.slider, styles.round].join(' ')}></div>
      </label>
      {(label || onRenderLabel) && (
        <label>
          {onRenderLabel ? onRenderLabel() : <Typography type='span' variation='bold'>{label}</Typography> }
        </label>
      )}
    </div>
  );
};

export default Toggle;