import { FC } from 'react';
import { RecoverPasswordPageTranslation, textForm, textPage } from '../../../translations';
import { Button, InputPassword } from '../../../toolkit';
import useRecoverPassword from './useRecoverPassword';
import styles from './styles.module.css';

const texts = textPage('recoverPassword') as RecoverPasswordPageTranslation;

type Props = {
  token?: string;
};

const RecoverPasswordForm: FC<Props> = ({ token }) => {
  const {
    recoverPasswordForm,
    onChangeHandler,
    onSubmitHandler
  } = useRecoverPassword(token);

  return (
    <form noValidate onSubmit={onSubmitHandler}>
      <InputPassword
        name="password"
        onChange={onChangeHandler}
        value={recoverPasswordForm.form.password}
        required
        errorMessage={recoverPasswordForm.errors.password}
      />
      <InputPassword
        label={textForm.confirmPasswordLabel}
        placeholder={textForm.confirmPasswordPlaceholder}
        name="confirmPassword"
        onChange={onChangeHandler}
        value={recoverPasswordForm.form.confirmPassword}
        required
        errorMessage={recoverPasswordForm.errors.confirmPassword}
      />
      <Button size='medium' variant='contained' type="submit" className={styles.button}>{texts.btnTxt}</Button>
    </form>
  );
};

export default RecoverPasswordForm;