import { ButtonHTMLAttributes, FC, ReactNode, forwardRef } from 'react';
import styles from './styles.module.css';

type Props = {
  type: 'submit' | 'button';
  size: 'large' | 'medium' | 'small';
  variant: 'contained' | 'outlined' | 'text' | 'danger';
  children?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement> & React.RefAttributes<HTMLButtonElement>;

const Button: FC<Props> = forwardRef<HTMLButtonElement, Props>(({ size = 'medium', variant = 'contained', className, children, ...rest }, ref) => {

  return (
    <button ref={ref} className={[className, styles.button, styles[size], styles[variant]].join(' ')} {...rest}>
      {children}
    </button>
  );
});

export default Button;
