import { FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, PageLayout } from "../../layout";
import {
  AnimatedAppIcon,
  Button,
  ErrorIcon,
  Portal,
  SuccessIcon,
  Tabs,
  Typography,
} from "../../toolkit";
import { Profile, CompanyProfile } from "../../components";
import { useAppSelector } from "../../store";
import { Subscriptions } from "../../modules";

const adminTabs = [
  { id: "", label: "General" },
  { id: "company", label: "Companie" },
];

const ownerTabs = [...adminTabs, { id: "payments", label: "Abonament" }];

const SettingsPage: FC = () => {
  const user = useAppSelector((user) => user.auth.user);
  const { selected = "" } = useParams<{ selected?: string; }>();
  const navigate = useNavigate();
  const selectedTab = ["success", "cancel"].includes(selected)
    ? "payments"
    : selected;

  const tabs = (
    <Tabs
      selected={selectedTab}
      items={user?.isOwner ? ownerTabs : user?.isAdmin ? adminTabs : []}
      onTabChanged={(id) => {
        navigate(`/settings/${id}`);
      }}
    />
  );

  return (
    <PageLayout title="Setari" tabs={tabs}>
      {selectedTab === "" && <Profile />}
      {selectedTab === "company" && <CompanyProfile />}
      {selectedTab === "payments" && <Subscriptions />}
      <Portal
        isOpen={selected === "cancel"}
        onDismiss={() => {
          navigate("/settings/payments");
        }}
        title="Plata finalizata"
        overlay={true}
        width={560}
      >
        <Container vertical gap={24}>
          <AnimatedAppIcon>
            <ErrorIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="h5" variation="center">
            Plata nu a fost finalizata!
          </Typography>
          <Container horizontalAlign="end">
            <Button
              size="medium"
              variant="contained"
              type="button"
              onClick={() => {
                navigate("/settings/payments");
              }}
            >
              Am inteles
            </Button>
          </Container>
        </Container>
      </Portal>
      <Portal
        isOpen={selected === "success"}
        onDismiss={() => {
          navigate("/settings/payments");
        }}
        title="Plata finalizata"
        overlay={true}
        width={560}
      >
        <Container vertical gap={24}>
          <AnimatedAppIcon>
            <SuccessIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Accessul complet la aplicatie va fi actualizat in maxim <br /> 30 de
            minute pentru toti utilizatorii din companie.
          </Typography>
          <Typography type="p" variation="center">
            Pentru a avea acess imediat, va rugam sa efectuati o <br />{" "}
            reautentificare in aplicatie.
          </Typography>
          <Container horizontalAlign="end">
            <Button
              size="medium"
              variant="contained"
              type="button"
              onClick={() => {
                navigate("/settings/payments");
              }}
            >
              Am inteles
            </Button>
          </Container>
        </Container>
      </Portal>
    </PageLayout>
  );
};

export default SettingsPage;
