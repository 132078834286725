import { FC, useRef, useState } from "react";
import {
  AnimatedAppIcon,
  Button,
  Card,
  ContainerFlex,
  Delimiter,
  DownloadIcon,
  IconButton,
  ImageIcon,
  Portal,
  TrashIcon,
  Typography,
  useAppStoreHooks,
} from "../../../toolkit";
import { TitanCase, TitanCaseFile } from "../../../models";
import { textMessages } from "../../../translations";
import { API_URLS, downloadFile } from "../../../lib";
import { useAppSelector } from "../../../store";

type Props = {
  titanCaseId?: number;
  files: (TitanCaseFile | File)[];
  onFilesChanged: (files: (File | TitanCaseFile)[]) => void;
};

const CaseFiles: FC<Props> = ({ titanCaseId, files, onFilesChanged }) => {
  const user = useAppSelector((state) => state.auth.user);
  const inputFileRef = useRef(null);
  const [fileIndexToDelete, setFileIndexToDelete] = useState<number>();

  const {
    postFormData,
    postJson,
    getRequest,
    notify,
    loadingStart,
    loadingEnd,
  } = useAppStoreHooks();

  const onOpenInput = () => {
    const current = inputFileRef.current || { click: () => undefined };
    current.click();
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const newFiles = target.files || [];
    const file = newFiles[0];
    if (!titanCaseId) {
      onFilesChanged([...files, file]);
      return;
    }
    loadingStart();
    try {
      const addedCaseFile = await postFormData<TitanCaseFile>(
        API_URLS.titan.cases.addCaseFile,
        { titanCaseId: titanCaseId, file }
      );
      onFilesChanged([...files, addedCaseFile]);
      loadingEnd();
      notify("Documentul a fost adaugat cu success!", "success");
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onRemoveFile = async (index: number) => {
    const file = files[index];
    const _files = files.filter(
      (_: File | TitanCaseFile, i: number) => i !== index
    );
    if (file instanceof File) {
      onFilesChanged(_files);
      setFileIndexToDelete(undefined);
      return;
    }
    loadingStart();
    try {
      await postJson<TitanCase>(API_URLS.titan.cases.deleteCaseFile, {
        caseFileId: (file as TitanCaseFile).caseFileId,
      });
      loadingEnd();
      notify("Documentul a fost sters.", "success");
      onFilesChanged(_files);
      setFileIndexToDelete(undefined);
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onDownloadCaseFile = async (caseFile: TitanCaseFile) => {
    loadingStart();
    try {
      const response = await getRequest(
        `${API_URLS.titan.cases.downloadCaseFile}/${caseFile.caseFileId}`
      );
      const blob = await response?.blob();
      if (blob) {
        downloadFile(blob, caseFile.fileName);
      }
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  return (
    <>
      <Card type="column" spacing={3}>
        <ContainerFlex type="row" justify="space-between">
          <Typography type="h5">Documente</Typography>
          {(user?.isManagerOrAbove || user?.isDataEntry) && (
            <>
              <Button
                type="button"
                size="small"
                variant="outlined"
                onClick={onOpenInput}
              >
                Adauga
              </Button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={onUpload}
              />
            </>
          )}
        </ContainerFlex>
        <Delimiter />
        {files.length ? (
          files.map((file: TitanCaseFile | File, index: number) => {
            const isNotUploaded = file instanceof File;
            const fileName = isNotUploaded ? file.name : file.fileName;
            return (
              <ContainerFlex key={fileName} type="row" justify="space-between">
                <Typography type="p" variation="bold">
                  {fileName}
                </Typography>
                <ContainerFlex
                  type="row"
                  align="center"
                  justify="center"
                  spacing={2}
                >
                  {!isNotUploaded && (
                    <IconButton
                      onClick={() => onDownloadCaseFile(file as TitanCaseFile)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      setFileIndexToDelete(index);
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </ContainerFlex>
              </ContainerFlex>
            );
          })
        ) : (
          <ContainerFlex
            type="column"
            grow
            align="center"
            justify="center"
            spacing={3}
          >
            <AnimatedAppIcon>
              <ImageIcon size={40} />
            </AnimatedAppIcon>
            {user?.isManagerOrAbove || user?.isDataEntry ? (
              <Typography type="p" variation="center">
                Apasa pe butonul
                <strong> "Adauga Document"</strong> pentru a adauga documente
                asociate cazului
              </Typography>
            ) : (
              <Typography type="p" variation="center">
                Nu exista documente asociate cazului
              </Typography>
            )}
          </ContainerFlex>
        )}
      </Card>
      <Portal
        isOpen={fileIndexToDelete !== undefined}
        onDismiss={() => {
          setFileIndexToDelete(undefined);
        }}
        title="Sterge document"
        overlay={true}
        width={560}
      >
        <ContainerFlex type="column" spacing={3}>
          <AnimatedAppIcon variation="danger">
            <TrashIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Acest document va fi sters.
          </Typography>
          <ContainerFlex spacing={1} type="row" justify="end">
            <Button
              size="medium"
              variant="outlined"
              type="button"
              onClick={() => {
                setFileIndexToDelete(undefined);
              }}
            >
              Renunta
            </Button>
            <Button
              type="button"
              size="medium"
              variant="danger"
              onClick={() => onRemoveFile(fileIndexToDelete!)}
            >
              Sterge
            </Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default CaseFiles;
