import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container, PageLayout, Responsive } from '../../../layout';
import { useCaseDetails, CaseDetails, CaseReport, CaseStages, CaseFittings, CaseFiles } from '../../../modules';
import { Button, Checkbox, ContainerFlex, DownloadIcon } from '../../../toolkit';
import { CaseContact } from '../../../components';
import { useAppSelector } from '../../../store';

const CaseDetailsPage: FC = () => {
  const params = useParams<{ caseId: string; }>();
  const caseId = params.caseId ? Number(params.caseId) : undefined;
  const user = useAppSelector(state => state.auth.user);

  const {
    _case,
    onDownloadCaseReport,
    onDownloadCertificate,
    onToggleFinishCase,
    onCasePaid,
    onFilesChanged,
    onFittingsChanged,
    onCaseStagesChanged,
    onNotesChanged
  } = useCaseDetails(caseId);

  return (
    <PageLayout
      title="Detalii caz"
      tabs={(
        <ContainerFlex type='row' spacing={1}>
          <Button type='button' size="small" variant='outlined' onClick={onDownloadCertificate} disabled={_case === undefined}>
            <DownloadIcon />
            Certificat conformitate
          </Button>
          <Button type='button' size="small" variant='outlined' onClick={onDownloadCaseReport} disabled={_case === undefined}>
            <DownloadIcon />
            Fisa caz
          </Button>
          {user?.isManagerOrAbove && (
            <Button type='button' size="small" variant='outlined' onClick={() => onToggleFinishCase(!_case?.isFinished)} disabled={_case === undefined}>
              <Checkbox value={_case?.isFinished || false} onChange={() => { }} />
              Finalizeaza caz
            </Button>
          )}
        </ContainerFlex>
      )}
    >
      <Container>
        {_case && (
          <Responsive colsFrLg='2-1'>
            <ContainerFlex type='column' spacing={3}>
              <CaseDetails _case={_case} onNotesChanged={onNotesChanged} />
              <CaseStages _case={_case} onCaseStagesChanged={onCaseStagesChanged} />
              <CaseFittings titanCaseId={_case.titanCaseId} fittings={_case.caseFittings || []} onFittingsChanged={onFittingsChanged} />
              <CaseFiles titanCaseId={_case.titanCaseId} files={_case.files || []} onFilesChanged={onFilesChanged} />
            </ContainerFlex>
            <ContainerFlex type='column' spacing={3}>
              <CaseContact _case={_case} />
              {user?.isAdmin && (
                <CaseReport _case={_case} onCasePaid={onCasePaid} />
              )}
            </ContainerFlex>
          </Responsive>
        )}
      </Container>
    </PageLayout>
  );
};

export default CaseDetailsPage;