import { FC, useState } from "react";
import { Container, PageLayout, Responsive } from "../../layout";
import {
  Card,
  Typography,
  DateRangePicker,
  CasesIcon,
  CalendarChecked,
  ToothIcon,
  AnimatedAppIcon,
  Button,
  AddIcon,
  Checkbox,
} from "../../toolkit";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import useDashboard from "./useDashboard";
import { prettyDayMonth, prettyTime } from "../../lib";
import { ActiveCase, Metric } from "../../components";
// import { AssignedFittings } from "../../modules";
// import { useAppSelector } from "../../store";

const startDate = new Date();
startDate.setMinutes(0);
startDate.setSeconds(0);
startDate.setMilliseconds(0);

const endDate = new Date(startDate);
endDate.setMinutes(0);
endDate.setSeconds(0);
endDate.setMilliseconds(0);
endDate.setDate(endDate.getDate() + 7);

const DashboardPage: FC = () => {
  const [dates, setDates] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate,
    endDate,
  });
  const navigate = useNavigate();
  const { dashboard } = useDashboard(startDate, endDate);
  // const user = useAppSelector((state) => state.auth.user);

  return (
    <PageLayout
      title="Acasa"
      tabs={
        <DateRangePicker
          defaultStartDate={dates.startDate}
          defaultEndDate={dates.endDate}
          onDatesSelected={(startDate, endDate) => {
            setDates({ startDate, endDate });
          }}
        />
      }
    >
      <Container vertical gap={32}>
        <Responsive colsMd={3}>
          <Metric
            label="Cazuri Active"
            metric={String(dashboard?.totalCases || 0)}
            icon={<CasesIcon />}
          />
          <Metric
            label="Probe Stabilite"
            metric={String(dashboard?.totalFittings || 0)}
            icon={<CalendarChecked />}
          />
          <Metric
            label="Elemente de creat"
            metric={String(dashboard?.totalElements || 0)}
            icon={<ToothIcon />}
          />
        </Responsive>

        <Responsive colsMd={2}>
          <Container vertical gap={16}>
            <Typography type="h5">Cazuri Active</Typography>
            {dashboard.titanCases.length === 0 ? (
              <Card className={styles.emptyCard}>
                <AnimatedAppIcon variation="primary">
                  <CasesIcon size={40} />
                </AnimatedAppIcon>
                <div>
                  <Typography type="h5" variation="center">
                    Cazuri Active
                  </Typography>
                  <Typography type="p" variation="center">
                    Adauga un caz nou apasand butonul de mai jos
                  </Typography>
                </div>
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  onClick={() => navigate("/cases/new")}
                >
                  <AddIcon />
                  Adauga caz
                </Button>
              </Card>
            ) : (
              dashboard.titanCases.map((_case) => {
                return (
                  <ActiveCase
                    key={_case.titanCaseId}
                    titanCase={_case}
                    onViewDetails={() =>
                      navigate(`/cases/${_case.titanCaseId}`)
                    }
                  />
                );
              })
            )}
          </Container>

          <Container vertical gap={16}>
            {/* {user?.isAdmin && <AssignedFittings />} */}

            <Container vertical gap={16}>
              <Typography type="h5">Probe</Typography>
              {dashboard.fittings.length === 0 && (
                <Card className={styles.emptyCard}>
                  <AnimatedAppIcon variation="primary">
                    <CalendarChecked size={40} />
                  </AnimatedAppIcon>
                  <div>
                    <Typography type="h5" variation="center">
                      Probe
                    </Typography>
                    <Typography type="p" variation="center">
                      Programeaza o noua proba
                    </Typography>
                  </div>
                  <Button
                    type="button"
                    size="medium"
                    variant="contained"
                    onClick={() => navigate("/cases")}
                  >
                    Vezi cazurile
                  </Button>
                </Card>
              )}
              {dashboard.fittings.length > 0 && (
                <Card className={styles.fittingCard}>
                  {dashboard.fittings.map((fitting) => (
                    <div
                      key={fitting.fittingId}
                      className={[
                        styles.fitting,
                        fitting.isCaseFinished ? styles.finished : "",
                      ].join(" ")}
                    >
                      <div className={styles.delimiter}>
                        <Typography type="h5">
                          {prettyDayMonth(fitting.fittingDateTime)}
                        </Typography>
                        <Typography
                          type="p"
                          variation="bold"
                          variation1="center"
                        >
                          {prettyTime(fitting.fittingDateTime)}
                        </Typography>
                      </div>
                      <div className={styles.fittingBody}>
                        <Typography type="p" variation="bold">
                          {fitting.clientName}
                        </Typography>
                        <Typography type="span" variation="bold">
                          {fitting.patientName}
                        </Typography>
                        <Typography type="span">{fitting.notes}</Typography>
                      </div>
                      <Checkbox
                        value={fitting.isFinished || false}
                        onChange={() => {}}
                      />
                    </div>
                  ))}
                </Card>
              )}
            </Container>
          </Container>
        </Responsive>
      </Container>
    </PageLayout>
  );
};

export default DashboardPage;
