import { useCallback, useEffect, useState } from "react";
import { API_URLS } from "../../lib";
import { useAppStoreHooks } from '../../toolkit';

const useCompanyLogo = () => {
  const [companyLogo, setCompanyLogo] = useState<string>("");
  const {
    getRequest,
    postFormData,
    notify
  } = useAppStoreHooks();

  const loadCompanyLogo = useCallback(async () => {
    try {
      const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      const logo = await response?.text();
      setCompanyLogo(logo || '');
    } catch (e: any) {
      notify(e.message, "error");
    }
  }, [getRequest, setCompanyLogo, notify]);

  const onUploadLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files || [];
    const file = files[0];
    try {
      await postFormData(API_URLS.titan.company.upload, { 'logo': file });
      notify('Imaginea a fost adaugata cu success!', 'success');
      await loadCompanyLogo();
    } catch (e: any) {
      notify(e.message, 'error');
    }
  };

  useEffect(() => {
    if (!companyLogo) {
      loadCompanyLogo();
    }
  }, [loadCompanyLogo, companyLogo]);

  return {
    companyLogo,
    onUploadLogo
  };
};


export default useCompanyLogo;