import { Content } from 'pdfmake/interfaces';
import { TitanCaseReport, TitanClientReport, TitanCompany } from '../../models';
import { formatDate } from '../dateFunctions';
import { createReport } from './reportUtils';

const getGeneralDetails = (clientReport: TitanClientReport) => {
  return {
    layout: 'noBorders',
    table: {
      headerRows: 0,
      widths: ['*', '*'],

      body: [
        [
          {
            stack: [
              {
                text: 'Data',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: formatDate(new Date()),
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Medic',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: clientReport.name,
                color: '#ffffff',
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 0],
          },
        ],
        [
          {
            stack: [
              {
                text: 'Perioada raport',
                fontSize: 10,
                color: '#707070',
                margin: [0, 0, 0, 2],
              },
              {
                text: `${formatDate(clientReport.from)} - ${formatDate(
                  clientReport.to
                )}`,
              },
            ],
            margin: [5, 5, 0, 0],
          },
          {
            stack: [
              {
                text: 'Total',
                fontSize: 10,
                color: '#D1D1D1',
                margin: [0, 0, 0, 2],
              },
              {
                text: clientReport.price.toFixed(2),
                color: '#ffffff',
                bold: true,
              },
            ],
            fillColor: '#2F3640',
            margin: [5, 5, 0, 5],
          },
        ],
      ],
    },
  };
};

const getCase = (caseReport: TitanCaseReport) => {
  return [
    {
      text: caseReport.titanCaseId,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: caseReport.patientName,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: caseReport.summary,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: formatDate(caseReport.caseDateTime),
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${caseReport.paid.toFixed(2)} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${caseReport.price.toFixed(2)} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
  ];
};

const getCases = (caseReports: TitanCaseReport[]) => {
  return caseReports.reduce((acc, current) => {
    acc.push(getCase(current));
    return acc;
  }, [] as any[]);
};

const getElements = (caseReports: TitanCaseReport[]) => {
  const elementRecord = caseReports.reduce((acc, current) => {
    current.caseElements.forEach((ce) => {
      const elementCount = acc[ce.elementId] || {
        elementName: ce.name,
        quantity: 0,
        price: ce.price.toFixed(2),
      };
      acc[ce.elementId] = {
        ...elementCount,
        quantity: elementCount.quantity + ce.count,
      };
    });

    return acc;
  }, {} as Record<string, any>);

  const elements = Object.keys(elementRecord).map((key) => [
    {
      text: elementRecord[key].elementName,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: elementRecord[key].quantity,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
    {
      text: `${elementRecord[key].price * elementRecord[key].quantity} RON`,
      fontSize: 10,
      margin: [5, 5, 0, 0],
    },
  ]);
  return {
    elementTotal: Object.keys(elementRecord).reduce(
      (acc, key) => acc + elementRecord[key].quantity,
      0
    ),
    elements,
  };
};

const getCaseList = (clientReport: TitanClientReport) => {
  const cases = getCases(clientReport.caseReports);
  return {
    layout: 'tableLayout',
    table: {
      headerRows: 1,
      widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],

      body: [
        //ID caz, Nume Pacient, data caz, suma platita de pe caz, totalul cazului.
        [
          {
            text: 'ID',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Pacient',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Sumar',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Data',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Platit',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Total',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...cases,
        [
          {},
          {},
          {
            text: 'TOTAL',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: `${clientReport.price.toFixed(2)} RON`,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
            alignment: 'right',
            unbreakable: true,
          },
        ],
        [
          {},
          {},
          {
            text: 'PLATIT',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: `${clientReport.paid.toFixed(2)} RON`,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
            alignment: 'right',
            unbreakable: true,
          },
        ],
        [
          {},
          {},
          {
            text: 'REST DE PLATA',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: '',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: `${(
              (clientReport.price || 0) - (clientReport.paid || 0)
            ).toFixed(2)} RON`,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
            alignment: 'right',
            unbreakable: true,
          },
        ],
      ],
    },
  };
};

const getElementList = (clientReport: TitanClientReport) => {
  const { elementTotal, elements } = getElements(clientReport.caseReports);
  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['*', 'auto', 'auto'],

      body: [
        [
          {
            text: 'Element',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Cantitate',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
          {
            text: 'Pret',
            fontSize: 10,
            color: '#707070',
            margin: [5, 5, 0, 0],
          },
        ],
        ...elements,
        [
          {
            text: 'TOTAL',
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: elementTotal,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
          {
            text: `${clientReport.price.toFixed(2)} RON`,
            bold: true,
            color: '#ffffff',
            fillColor: '#2F3640',
            margin: [5, 0, 0, 0],
          },
        ],
      ],
    },
  };
};

const generateMedicReport = (
  clientReport: TitanClientReport,
  company: TitanCompany,
  type: 'elements' | 'cases' | 'both'
): void => {
  const content = [
    {
      text: 'DETALII GENERALE',
      style: 'section',
      margin: [0, 20, 0, 0],
    },
    getGeneralDetails(clientReport),
    ...(type === 'elements' || type === 'both'
      ? [
          {
            text: 'ELEMENTE',
            style: 'section',
            margin: [0, 50, 0, 0],
          },
          getElementList(clientReport),
        ]
      : []),
    ...(type === 'cases' || type === 'both'
      ? [
          {
            text: 'CAZURI',
            style: 'section',
            margin: [0, 50, 0, 0],
          },
          getCaseList(clientReport),
        ]
      : []),
  ] as Content[];

  createReport(content, {
    header: {
      title: 'RAPORT MEDIC',
    },
    branding: {
      company,
      logo: clientReport.logoUrl,
    },
    name: `raport ${clientReport.name} ${formatDate(
      clientReport.from
    )} ${formatDate(clientReport.to)}`,
  });
};

export default generateMedicReport;
