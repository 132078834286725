import { FC } from "react";
import { Card, DateInput, Input, Typeahead } from "../../../toolkit";
import { TitanCase, TitanCaseElement, TitanCaseFile, TitanCaseFitting, TitanUserStage } from "../../../models";
import { Responsive } from "../../../layout";
import { textForm } from "../../../translations";
import { ColorDropdown, StagesFeature } from '../../../components';
import { useAppSelector } from '../../../store';
import CaseStagesEdit from './CaseStagesEdit/CaseStagesEdit';
import CaseFiles from '../CaseFiles/CaseFiles';
import CaseFittings from '../CaseFittings/CaseFittings';
import CaseElementsEdit from './CaseElementsEdit/CaseElementsEdit';
import { parseDate } from '../../../lib';

type Props = {
  step: number;
  caseForm: {
    form: TitanCase,
    errors: {
      titanClientId?: string;
      patientName?: string;
      color?: string;
    };
  },
  onClientChange: (clientId: number) => void;
  clientOptions?: { key: string | number; name: string; }[];
  onChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onColorChange: (color: string) => void;
  onDateChange: (date: Date) => void;
  onAssignedStagesChanged: (caseElement: TitanCaseElement, userStage: TitanUserStage) => void;
  onElementsChanged: (caseElements: TitanCaseElement[]) => void;
  onFilesChanged: (files: (TitanCaseFile | File)[]) => void;
  onFittingsChanged: (fittings: TitanCaseFitting[]) => void;
};

const CaseEdit: FC<Props> = ({ step, caseForm, onClientChange, clientOptions, onChange, onColorChange, onAssignedStagesChanged, onElementsChanged, onFilesChanged, onFittingsChanged, onDateChange }) => {
  const stagesFeature = useAppSelector(state => state.appSettings.features.stages);

  return (
    <>
      {step === 1 && (
        <>
          <Card>
            <Responsive colsFrMd="1-1-1">
              <Typeahead
                label="Alege medic"
                selectedKey={caseForm.form.titanClientId}
                required
                options={clientOptions || []}
                onChange={selectedKey => onClientChange(selectedKey as number)}
                errorMessage={caseForm.errors.titanClientId}
              />
              <Input
                label={textForm.patientNameLabel}
                placeholder={textForm.patientNamePlaceholder}
                name="patientName"
                onChange={onChange}
                value={caseForm.form.patientName}
                required
                errorMessage={caseForm.errors.patientName}
              />
              <ColorDropdown selected={caseForm.form.color} onColorChange={onColorChange} errorMessage={caseForm.errors.color} />
            </Responsive>
          </Card>
          <CaseElementsEdit caseElements={caseForm.form.caseElements || []} onElementsChanged={onElementsChanged} />
        </>
      )}
      {step === 2 && (
        <>
          {stagesFeature ? (
            <CaseStagesEdit
              caseElements={caseForm.form.caseElements}
              caseStages={caseForm.form.caseStages}
              onStagesChanged={onAssignedStagesChanged}
              onElementsChanged={onElementsChanged}
            />
          ) : (

            <StagesFeature />
          )}
          <Responsive colsFrMd="1-1">
            <CaseFiles
              files={caseForm.form.files || []}
              onFilesChanged={onFilesChanged}
            />
            <CaseFittings
              fittings={caseForm.form.caseFittings || []}
              onFittingsChanged={onFittingsChanged}
            />
          </Responsive>
          <Card>
            <Responsive colsFrMd="1-1">
              <DateInput
                label='Data caz'
                date={parseDate(caseForm.form.caseDateTime)}
                onDateChanged={onDateChange}
              />
              <Input
                label={textForm.patientAddressLabel}
                placeholder={textForm.patientAddressPlaceholder}
                name="deliveryAddress"
                onChange={onChange}
                value={caseForm.form.deliveryAddress}
              />
              <Input
                label={textForm.medicNotesLabel}
                placeholder={textForm.medicNotesPlaceholder}
                name="medicNotes"
                onChange={onChange}
                value={caseForm.form.medicNotes}
                rows={5}
              />
              <Input
                label={textForm.technicianNotesLabel}
                placeholder={textForm.technicianNotesPlaceholder}
                name="notes"
                onChange={onChange}
                value={caseForm.form.notes}
                rows={5}
              />
            </Responsive>
          </Card>
        </>
      )}
    </>
  );
};

export default CaseEdit;