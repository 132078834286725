
export const downloadFileFromResponse = async (response?: Response) => {
  const blob = await response?.blob();
  const disposition = response?.headers.get('Content-Disposition') || ''
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  const fileName = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : ''
  if(blob) {
    downloadFile(blob, fileName)
  }
};

export const downloadFile = (blob: Blob, fileName: string) => {
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const formatMoney = (price: number) => {
  // const RON = new Intl.NumberFormat('ro-RO', {
  //   style: 'currency',
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  //   currency: '',
  // });
  return price.toFixed(2);
};


export const IS_DEV_ENV =
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("dev-dentops");