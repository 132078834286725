import { FC, useState } from 'react';
import { AddIcon, Button, Card, Checkbox, ContainerFlex, Delimiter, Input, Portal, Typography } from '../../../toolkit';
import { TitanCase } from '../../../models';
import { GaugeChart } from '../../../components';
import { useCasePayment } from '../../hooks';
import { textForm } from '../../../translations';

type Props = {
  _case: TitanCase;
  onCasePaid: (amount?: number) => void
};

const CaseReport: FC<Props> = ({ _case, onCasePaid }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { casePayment, onChange, onSubmit, onTogglePayInFull } = useCasePayment(_case, (amount) => {
    setIsOpen(false);
    onCasePaid(amount);
  })
  const chartValue = (_case.paid || 0) * 100 / (_case.price || 1);

  return (
    <>
      <Card type='column' spacing={3}>
        <Typography type='h5'>Raport Plata</Typography>
        <Delimiter />
        <ContainerFlex type='column' spacing={1}>
          <GaugeChart value={chartValue} />
          <Typography type='h5' variation='center'>{_case.paid}/{_case.price} RON</Typography>
        </ContainerFlex>
        <ContainerFlex type='column' justify='center'>
          <Button 
          type='button'
            size='medium' 
            variant='contained' 
            onClick={() => { setIsOpen(true); }} 
            disabled={_case.price === _case.paid}>
              <AddIcon />Adauga plata
          </Button>
        </ContainerFlex>
      </Card>
      <Portal
        isOpen={isOpen}
        onDismiss={() => { setIsOpen(false); }}
        title="Adauga Plata"
        overlay={true}
        width={560}
      >
          <form noValidate onSubmit={onSubmit}>
            <Input
              label={textForm.amountLabel}
              placeholder={textForm.amountPlaceholder}
              name="paymentAmount"
              onChange={onChange}
              value={casePayment.form.paymentAmount ? String(casePayment.form.paymentAmount) : ''}
              required
              errorMessage={casePayment.errors.paymentAmount}
              disabled={casePayment.isPayedInFull}
            />
            <Checkbox value={casePayment.isPayedInFull} onChange={onTogglePayInFull} label='Plata integrala?' />

            <ContainerFlex spacing={1} type='row' justify='end'>
              <Button size='medium' variant='outlined' type="button" onClick={() => { setIsOpen(false); }}>Renunta</Button>
              <Button size='medium' variant='contained' type="submit">Salveaza</Button>
            </ContainerFlex>
          </form>
      </Portal>
    </>
  );
};

export default CaseReport;