import { TitanToken, TitanUser } from '../../models';
import AuthContext from '../../models/AuthContext';
import { API_URLS } from '../constants';

let refreshTokenPromise: Promise<TitanToken> | null = null;

type RefreshTokenResponse = {
  isSuccessful: boolean;
  model: { token: TitanToken; user: TitanUser };
};

const request = (
  url: string,
  method: string,
  payload?: any,
  contentType?: string
) => {
  return (token?: TitanToken) => {
    const headers = {
      Authorization: token ? `${token.type} ${token.value}` : '',
    };

    if (contentType) {
      // @ts-ignore
      headers['Content-Type'] = contentType;
    }

    const body = payload
      ? contentType === 'application/json'
        ? JSON.stringify(payload)
        : payload
      : null;

    return fetch(url, {
      headers,
      method,
      body,
    }).catch((e) => {
      return {
        ok: false,
        text: () => Promise.resolve('Cannot access the server!'),
      } as Response;
    });
  };
};

const refreshToken = (context: AuthContext): Promise<TitanToken> => {
  const { email, refreshToken } = context.user!;
  const callApi = request(
    API_URLS.identity.refresh,
    'POST',
    { email, refreshToken },
    'application/json'
  );
  return callApi()
    .then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401) {
        context.onTokenRefreshError();
      }

      return Promise.resolve({ isSuccessful: false, model: {} } as {
        isSuccessful: boolean;
        model: { token: TitanToken; user: TitanUser };
      });
    })
    .then(({ isSuccessful, model}: RefreshTokenResponse) => {
      if (isSuccessful) {
        if (!model?.token?.value) {
          context.onTokenRefreshError();
        }
        context.onTokenRefreshSuccess(model.token, model.user.refreshToken);

        return model.token;
      }
      context.onTokenRefreshError();
      return {} as TitanToken;
    });
};

const getRefreshTokenPromise = (context: AuthContext): Promise<TitanToken> => {
  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }
  refreshTokenPromise = refreshToken(context).finally(() => {
    setTimeout(() => {
      refreshTokenPromise = null;
    }, 500);
  });
  return refreshTokenPromise;
};

const urlIsProtected = (url: string) => {
  return url.includes('SignIn') === false;
};

const fetchWithToken = (
  context: AuthContext,
  url: string,
  method: string,
  payload: any,
  contentType?: string
) => {
  const callApi = request(url, method, payload, contentType);
  return callApi(context.token).then((response) => {
    if (response.ok) {
      return response;
    }

    if (
      response.status === 401 &&
      urlIsProtected(url) &&
      context.user?.refreshToken
    ) {
      return getRefreshTokenPromise(context).then((token: TitanToken) => {
        return callApi(token);
      });
    } else if (response?.text) {
      return response.text().then((res) => {
        let error: any;
        try {
          error = JSON.parse(res);
        } catch (e: any) {
          throw res;
        }

        if (error instanceof Array) {
          if (error[0]?.errorMessage) {
            throw new Error(error[0]?.errorMessage);
          } else {
            throw new Error(error.join(','));
          }
        }

        if (error instanceof Object) {
          const errors = error.errors || error;
          const errorMessages = Object.keys(errors).reduce(
            (accumulator, key) => {
              accumulator.push(errors[key] as string);
              return accumulator;
            },
            [] as string[]
          );
          throw new Error(errorMessages.join(','));
        }

        throw new Error(res);
      });
    } else {
      debugger;
    }
  });
};

export default fetchWithToken;
