import { FC, InputHTMLAttributes, ReactNode, TextareaHTMLAttributes, forwardRef } from 'react';
import styles from './styles.module.css';
import Typography from '../Typography/Typography';

export type Props = {
  label?: string;
  errorMessage?: string;
  rows?: number;
  value?: string;
  required?: boolean;
  startIcon?: ReactNode;
  endButton?: ReactNode;
  noMargin?: boolean;
  width?: number;
} & InputHTMLAttributes<HTMLInputElement>
  & TextareaHTMLAttributes<HTMLTextAreaElement>
  & React.RefAttributes<any>;

const Input: FC<Props> = forwardRef<any, Props>(
  ({ label, errorMessage, rows, value, required = false, startIcon, endButton, type, noMargin = false, width, ...rest }, ref) => {
    const showEndIcon = type !== 'search' || Boolean(value);

    return (
      <div className={[
        styles.inputField, 
        errorMessage ? styles.error : '', 
        type === 'search' || noMargin !== false ? styles.noMargin : '',
        !width ? styles.fullWidth : '',
        ].join(' ')}
      style={{ width }}>
        {label && (
          <Typography type='label' variation='bold' htmlFor={rest.name}>
            {label}{required !== false && <span className={styles.required}>{' '}*</span>}
          </Typography>
        )}
        {rows ? (
          <textarea ref={ref} value={value || ''} rows={rows} {...rest} />
        ) : (
          <div className={[styles.inputContainer, startIcon ? styles.start : '', endButton ? styles.end : ''].join(' ')}>
            {startIcon}
            <input ref={ref} value={value || ''} type={type} required {...rest} />
            {showEndIcon ? endButton : undefined}
          </div>
        )}
        {errorMessage && (
          <span>{errorMessage}</span>
        )}
      </div>
    );
  });

export default Input;