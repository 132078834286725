import { FC, ReactNode, useEffect } from 'react';
import { ErrorIcon, CloseIcon, SuccessIcon, WarningIcon } from '../assets';
import Typography from '../Typography/Typography';
import IconButton from '../IconButton/IconButton';
import styles from './styles.module.css';


type Props = {
  children?: ReactNode;
  title?: string;
  message: string;
  details?: string;
  type: 'error' | 'success' | 'warning';
  onDismiss: () => void;
};

const Toast: FC<Props> = ({ message, details, type, onDismiss }) => {
  useEffect(() => {
    const to = setTimeout(() => {
      onDismiss();
    }, 5000);
    return () => {
      clearTimeout(to);
    };
  }, [onDismiss]);

  return (
    <div className={[styles.notification, styles[type]].join(' ')}>
      {type === 'error' && (
        <ErrorIcon />
      )}
      {type === 'success' && (
        <SuccessIcon />
      )}
      {type === 'warning' && (
        <WarningIcon />
      )}
      <div>
        <Typography type='p' className={styles.text}>
          {message}
        </Typography>
        {details && (
          <Typography type='p' className={styles.subtext}>
            {details}
          </Typography>
        )}
      </div>
      <div className={styles.dismiss}>
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Toast;