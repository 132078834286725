import { FC } from 'react';
import { TitanDiscountElement } from '../../../models';
import { IColumn, TableProps, formatMoney } from '../../../lib';
import { ContainerFlex, EditNoteIcon, IconButton, MoneyIcon, TrashIcon } from '../../../toolkit';
import { TableList } from '../../../components';
import { useAppSelector } from '../../../store';


interface IDocument extends TitanDiscountElement {
  key: string;
}

type Props = {
  onEdit: (discount: TitanDiscountElement) => void;
  onDelete: (discount: TitanDiscountElement) => void;
  onAdd: () => void;
} & TableProps;


const DiscountList: FC<Props> = (listProps) => {
  const user = useAppSelector(state => state.auth.user);
  const columns: IColumn[] = [
    {
      key: 'elementName',
      name: 'Nume',
      fieldName: 'elementName',
    },
    {
      key: 'elementDescription',
      name: 'Descriere',
      fieldName: 'elementDescription',
    },
    {
      key: 'elementPrice',
      name: 'Pret',
      fieldName: 'elementPrice',
      onRender: (item: IDocument) => {
        return formatMoney(item.elementPrice);
      }
    },
    {
      key: 'discount',
      name: 'Discount',
      fieldName: 'discount',
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      width: 110,
      show: user?.isAdmin,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1}>
            <IconButton variant='outlined' onClick={() => listProps.onEdit(item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton variant='outlined' color='danger' onClick={() => listProps.onDelete(item)}>
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    }
  ];

  const items = listProps.results.map((item: TitanDiscountElement, index: number) => {
    return {
      key: item.elementDiscountId,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici un discount',
        emptyIcon: <MoneyIcon size={40} />,
        emptyTitle: 'Nu ai nici un discount adaugat',
        emptySubTitle: 'Adauga un discount asociat acestui medic apasand butonul de mai jos',
        emptyAction: listProps.onAdd,
        emptyActionText: 'Adauga discount'
      }}
      isSubList={true}
    />
  );
};

export default DiscountList;