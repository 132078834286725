import { useState } from 'react';
import { useNavigate} from 'react-router-dom';

import { API_URLS, validations } from '../../../lib';
import { textMessages } from '../../../translations';
import { useAppStoreHooks } from '../../../toolkit';

const validate = validations({
  email: [['required', textMessages.requiredError], ['email', textMessages.validEmailError]],
});

const useForgotPassword = () => {
  const [forgotPasswordForm, setForgotPasswordForm] = useState<{
    form: {
      email: string;
    },
    errors: {
      email?: string;
    },
  }>({
    form: {
      email: ''
    },
    errors: {},
  });
  const navigate = useNavigate();
  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setForgotPasswordForm({
      ...forgotPasswordForm,
      form: {
        ...forgotPasswordForm.form,
        [target.name]: target.value
      }
    });
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(forgotPasswordForm.form);
    const hasError = Object.keys(errors).length;
    setForgotPasswordForm(prevState => ({
      ...prevState,
      errors,
    }));
    if (hasError) {
      return;
    }
    loadingStart();
    try {
      await postJson(API_URLS.identity.forgotPassword, forgotPasswordForm.form);
      loadingEnd();
      navigate('/forgot-password/success');
      notify(textMessages.emailSentSuccess, "success");
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error");
    }
  };

  return {
    forgotPasswordForm,
    onChangeHandler,
    onSubmitHandler
  };
};

export default useForgotPassword;