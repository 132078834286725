import { FC } from "react";
import { AppTitle, Container } from '../../layout';
import { Card, Typography, ErrorIcon, Link } from '../../toolkit';
import { NotFoundPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('notFound') as NotFoundPageTranslation;

const NotFoundPage: FC = () => {
  return (
    <AppTitle title={texts.title}>
      <Container grow={true} verticalAlign='center' horizontalAlign='middle'>
        <Card className={styles.error}>
          <Container gap={36} vertical={true}>
            <Typography type='h4'>
              <Container verticalAlign='center' gap={10}>
                <ErrorIcon /> {texts.pageNotFoundTitle}
              </Container>
            </Typography>
            <Typography type='p' variation='center' variation1='secondary'>{texts.pageNotFoundDetails}</Typography>

            <Container gap={36} horizontalAlign='middle'>
              <Link to='/' variation='primary'>
                {texts.goHome}
              </Link>
            </Container>
          </Container>
        </Card>
      </Container>
    </AppTitle>
  );
};

export default NotFoundPage;