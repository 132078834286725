export type CalendarDayGridDays = {
  day: number;
  date: Date;
  isToday: boolean;
  current: boolean;
  selected: boolean;
  isIntervalStart: boolean;
  isIntervalEnd: boolean;
};

export const DAY_MILISECOND: number = 86400000;

const getMaxDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const getDayOfTheWeek = (date: Date): number => {
  const shortWeekdayDateMap: { [key: string]: number } = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };
  const key = Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
  return shortWeekdayDateMap[key];
};

const areDatesEqual = (date: Date, compare: Date): boolean => {
  return (
    date &&
    compare &&
    date.getDate() === compare.getDate() &&
    date.getMonth() === compare.getMonth() &&
    date.getFullYear() === compare.getFullYear()
  );
};

export const getDaysInMonth = (
  year: number,
  month: number,
  startDate?: Date,
  endDate?: Date
) => {
  const daysInMonth: CalendarDayGridDays[][] = [];

  const addDaysInMonth = (date: Date, current: boolean) => {
    let currentIndex = daysInMonth.length - 1;
    if (currentIndex < 0) {
      currentIndex = 0;
    }
    if (daysInMonth[currentIndex] && daysInMonth[currentIndex].length === 7) {
      currentIndex++;
    }
    if (!daysInMonth[currentIndex]) {
      daysInMonth.push([]);
    }

    daysInMonth[currentIndex].push({
      day: date.getDate(),
      date: date,
      isToday: areDatesEqual(date, new Date()),
      current,
      selected:
        Boolean(startDate) &&
        Boolean(endDate) &&
        startDate!.getTime() <= date.getTime() &&
        date.getTime() <= endDate!.getTime(),
      isIntervalStart: Boolean(startDate) && areDatesEqual(date, startDate!),
      isIntervalEnd: Boolean(endDate) && areDatesEqual(date, endDate!),
    });
  };

  let weekday = getDayOfTheWeek(new Date(year, month, 1));
  if (weekday !== 0) {
    const maxPreviousMonthDay = getMaxDaysInMonth(year, month - 1);
    for (let index = 0; index < weekday; index++) {
      addDaysInMonth(
        new Date(year, month - 1, maxPreviousMonthDay - (weekday - index - 1)),
        false
      );
    }
  }

  const maxMonthDay = getMaxDaysInMonth(year, month);
  for (let index = 1; index <= maxMonthDay; index++) {
    addDaysInMonth(new Date(year, month, index), true);
  }

  weekday = getDayOfTheWeek(new Date(year, month, maxMonthDay));
  if (weekday !== 6) {
    for (let index = 1; index < 7 - weekday; index++) {
      addDaysInMonth(new Date(year, month + 1, index), false);
    }
  }
  return daysInMonth;
};

export const getDefaultState = (
  defaultStartDate?: Date,
  defaultEndDate?: Date
) => {
  const nowDate = new Date();
  const refStartDate = defaultStartDate
    ? new Date(
        defaultStartDate.getFullYear(),
        defaultStartDate.getMonth() - 1,
        1
      )
    : new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
  const refEndDate = defaultStartDate
    ? new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), 1)
    : new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);

  return {
    startDate:
      defaultStartDate &&
      new Date(
        defaultStartDate.getFullYear(),
        defaultStartDate.getMonth(),
        defaultStartDate.getDate()
      ),
    endDate:
      defaultEndDate &&
      new Date(
        defaultEndDate.getFullYear(),
        defaultEndDate.getMonth(),
        defaultEndDate.getDate()
      ),
    refStartDate,
    refEndDate,
    isCalloutVisible: false,
  };
};

export const getDefaultSingleState = (date: Date) => {
  const refStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const refEndDate = refStartDate;

  return {
    startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    refStartDate,
    refEndDate,
  };
};

export const dateNoTime = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getSelectedInterval = (
  startDate: Date,
  endDate: Date
): "7d" | "1m" | "3m" | "6m" | "1y" | undefined => {
  const sDate = dateNoTime(startDate);
  const eDate = dateNoTime(endDate);

  const monthDiff =
    eDate.getMonth() -
    sDate.getMonth() +
    12 * (eDate.getFullYear() - sDate.getFullYear());

  if (monthDiff === 1) {
    return "1m";
  }

  if (monthDiff === 3) {
    return "3m";
  }

  if (monthDiff === 6) {
    return "6m";
  }

  const daysDiff = Math.ceil(
    Math.abs(eDate.getTime() - sDate.getTime()) / DAY_MILISECOND
  );

  if (daysDiff === 6) {
    return "7d";
  }

  if (daysDiff === 29) {
    return "1m";
  }

  const yearDiff = eDate.getFullYear() - sDate.getFullYear();

  if (yearDiff === 1) {
    return "1y";
  }
};
