import { FC } from 'react';

const Tooth23: FC = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M13.643 13.65C8.34302 15.0131 4.56902 19.1426 2.64102 25.7577C0.071025 34.6581 1.55702 39.7096 7.05802 40.9124C8.86502 41.3133 9.86901 41.3133 13.202 40.752C21.594 39.3087 26.774 37.1438 28.42 34.3374C30.347 30.9696 30.789 25.1163 29.424 20.7863C28.42 17.4988 26.131 15.0933 23.24 14.2514C20.711 13.4896 15.571 13.1689 13.643 13.65Z" />
      <path className='interior' d="M13.143 17.875C13.147 17.8761 13.147 17.8759 13.143 17.875ZM12.124 16.8547C12.25 16.8436 12.401 16.8383 12.57 16.8383C12.911 16.8383 13.214 16.8564 13.413 16.9119C13.465 16.9264 13.542 16.952 13.618 17C13.666 17.0309 13.768 17.1058 13.829 17.2379L13.859 17.1262L15.262 17.3662C15.702 17.4361 16.19 17.6048 16.624 17.8159C17.052 18.0245 17.475 18.2974 17.755 18.6022C17.898 18.7544 18.013 18.882 18.096 18.9825C18.138 19.0325 18.177 19.0813 18.209 19.1263C18.231 19.1576 18.284 19.233 18.315 19.325V19.3262C18.326 19.3577 18.378 19.5139 18.303 19.6928C18.194 19.9556 17.946 20.0058 17.831 20.0058C17.732 20.0058 17.655 19.977 17.628 19.9666C17.59 19.9522 17.558 19.9356 17.534 19.9224C17.445 19.8743 17.335 19.7965 17.217 19.7083C16.854 19.4566 15.898 18.958 15.122 18.6472L13.602 18.0883L13.652 17.9023C13.602 17.9411 13.553 17.9681 13.516 17.9861L13.475 18.0069L13.43 18.0196C13.224 18.0783 12.96 18.0989 12.721 18.0989C12.476 18.0989 12.206 18.0771 11.98 18.0269L11.962 18.023L11.945 18.0178C11.883 17.9992 11.821 17.9777 11.763 17.9519C11.717 17.9313 11.626 17.8873 11.546 17.8059C11.504 17.7624 11.439 17.6825 11.409 17.5638C11.374 17.43 11.395 17.2942 11.457 17.1819C11.553 17.0064 11.717 16.9429 11.756 16.9279C11.756 16.9277 11.756 16.9275 11.757 16.9273C11.873 16.8821 12.013 16.8644 12.124 16.8547ZM23.894 25.6036C23.73 25.278 23.568 24.9941 23.431 24.804C23.373 24.7224 23.279 24.6011 23.162 24.5217C23.117 24.4914 22.942 24.3776 22.711 24.4371C22.433 24.5087 22.346 24.7468 22.325 24.8343L22.31 24.8941V24.9558C22.31 25.0703 22.34 25.1912 22.362 25.2711C22.388 25.3662 22.424 25.4746 22.463 25.5867C22.541 25.8097 22.644 26.0763 22.743 26.333L22.75 26.3535L22.757 26.3683C22.855 26.58 22.996 27.0383 23.142 27.6393C23.284 28.2272 23.422 28.9145 23.52 29.5609C23.52 29.561 23.52 29.5608 23.52 29.5609L23.881 31.9659L24.875 31.867L24.755 29.4214L24.754 29.4166C24.712 28.7057 24.598 27.9373 24.447 27.2623C24.301 26.6071 24.11 25.9917 23.894 25.6036Z" />
      <path className='text' d="M34.552 8.52H28.744V7.056L31.372 4.752C31.82 4.36 32.148 4.008 32.356 3.696C32.564 3.376 32.668 3.032 32.668 2.664V2.508C32.668 2.14 32.548 1.86 32.308 1.668C32.068 1.468 31.776 1.368 31.432 1.368C31 1.368 30.672 1.492 30.448 1.74C30.224 1.98 30.064 2.272 29.968 2.616L28.6 2.088C28.688 1.808 28.812 1.544 28.972 1.296C29.132 1.04 29.332 0.816001 29.572 0.624001C29.82 0.432001 30.108 0.279999 30.436 0.167999C30.764 0.0559993 31.14 0 31.564 0C32.004 0 32.396 0.0639994 32.74 0.191999C33.084 0.311999 33.372 0.484 33.604 0.708C33.844 0.932 34.024 1.196 34.144 1.5C34.272 1.804 34.336 2.136 34.336 2.496C34.336 2.848 34.28 3.168 34.168 3.456C34.056 3.744 33.904 4.02 33.712 4.284C33.52 4.54 33.296 4.788 33.04 5.028C32.784 5.26 32.512 5.496 32.224 5.736L30.46 7.188H34.552V8.52Z"  />
      <path className='text' d="M38.339 3.528C38.819 3.528 39.171 3.432 39.395 3.24C39.627 3.04 39.743 2.788 39.743 2.484V2.4C39.743 2.056 39.631 1.788 39.407 1.596C39.191 1.396 38.891 1.296 38.507 1.296C38.139 1.296 37.811 1.384 37.523 1.56C37.243 1.736 36.999 1.984 36.791 2.304L35.783 1.392C35.927 1.2 36.083 1.02 36.251 0.851999C36.427 0.675999 36.627 0.528001 36.851 0.408001C37.075 0.280001 37.327 0.18 37.607 0.108C37.887 0.0359998 38.203 0 38.555 0C38.979 0 39.363 0.0520001 39.707 0.156C40.059 0.252 40.359 0.395999 40.607 0.587999C40.863 0.779999 41.059 1.012 41.195 1.284C41.331 1.548 41.399 1.848 41.399 2.184C41.399 2.456 41.355 2.7 41.267 2.916C41.187 3.132 41.071 3.32 40.919 3.48C40.775 3.64 40.603 3.772 40.403 3.876C40.211 3.972 40.007 4.044 39.791 4.092V4.164C40.031 4.212 40.259 4.288 40.475 4.392C40.691 4.496 40.875 4.636 41.027 4.812C41.187 4.98 41.311 5.18 41.399 5.412C41.495 5.644 41.543 5.908 41.543 6.204C41.543 6.572 41.467 6.908 41.315 7.212C41.171 7.516 40.963 7.776 40.691 7.992C40.419 8.208 40.095 8.376 39.719 8.496C39.343 8.608 38.923 8.664 38.459 8.664C38.051 8.664 37.691 8.62 37.379 8.532C37.067 8.452 36.791 8.34 36.551 8.196C36.319 8.052 36.115 7.888 35.939 7.704C35.763 7.512 35.611 7.312 35.483 7.104L36.647 6.216C36.743 6.384 36.847 6.54 36.959 6.684C37.071 6.82 37.199 6.94 37.343 7.044C37.487 7.148 37.651 7.228 37.835 7.284C38.019 7.34 38.227 7.368 38.459 7.368C38.923 7.368 39.279 7.256 39.527 7.032C39.775 6.808 39.899 6.5 39.899 6.108V6.024C39.899 5.64 39.763 5.348 39.491 5.148C39.227 4.948 38.843 4.848 38.339 4.848H37.511V3.528H38.339Z"  />
    </svg>

  );
};

export default Tooth23;