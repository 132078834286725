import { FC, useState } from 'react';
import { Button, ChevronDown, ChevronUp, ContainerFlex, DatePicker, IconButton, Input, Typography } from '../../../toolkit';
import { prettyDateTime } from '../../../lib';
import styles from './styles.module.css';

type Props = {
  onSaveFitting: (date: Date, notes: string) => void;
  onCancel: () => void;
};

const defaultDate = new Date();
defaultDate.setHours(12);
defaultDate.setMinutes(0);
defaultDate.setSeconds(0);
defaultDate.setMilliseconds(0);

const CaseFittingForm: FC<Props> = ({ onSaveFitting, onCancel }) => {
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [notes, setNotes] = useState('');

  const hours = selectedDate.getHours();
  const minutes = selectedDate.getMinutes();

  const onDateSelected = (date: Date) => {
    setSelectedDate(new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    ));
  };

  const onHourChange = (hours: number) => {
    setSelectedDate(new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    ));
  };

  const onMinutesChange = (minutes: number) => {
    setSelectedDate(new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    ));
  };

  const onKeyDown = (key: string, callback: (value: number) => void, value: number) => {
    if (key === 'ArrowUp') {
      callback(value + 1);
    } else if (key === 'ArrowDown') {
      callback(value - 1);
    }
  };

  const onSaveDate = () => {
    onSaveFitting(new Date(selectedDate), notes);
    setSelectedDate(defaultDate);
  };

  return (
    <>
      <ContainerFlex type="row">
        <div className={styles.calendar}>
          <DatePicker date={selectedDate} onDateChanged={onDateSelected} />
        </div>
        <ContainerFlex grow type='column' justify='center' spacing={2} className={styles.fittingForm}>
          <Typography type="span" variation='bold' variation1='center'>{prettyDateTime(selectedDate)}</Typography>
          <ContainerFlex type='row' spacing={2} justify='center'>
            <ContainerFlex type='column'>
              <IconButton onClick={() => onHourChange(hours + 1)}>
                <ChevronUp />
              </IconButton>
              <div  className={styles.fittingInput}>
                <input
                  value={String(selectedDate.getHours()).padStart(2, '0')}
                  onChange={(e) => onHourChange(Number((e.target as HTMLInputElement).value))}
                  onKeyDown={(e) => onKeyDown(e.key, onHourChange, hours)}
                />
                <span>Ora</span>
              </div>
              <IconButton onClick={() => onHourChange(hours - 1)}>
              <ChevronDown />
              </IconButton>
            </ContainerFlex>
            <ContainerFlex type='column'>
              <IconButton onClick={() => onMinutesChange(minutes + 30)}>
              <ChevronUp />
              </IconButton>
              <div className={styles.fittingInput}>
                <input
                  value={String(selectedDate.getMinutes()).padStart(2, '0')}
                  onChange={(e) => onMinutesChange(Number((e.target as HTMLInputElement).value))}
                  onKeyDown={(e) => onKeyDown(e.key, onMinutesChange, minutes)}
                />
                <span>Min</span>
              </div>
              <IconButton onClick={() => onMinutesChange(minutes - 30)}>
              <ChevronDown />
              </IconButton>
            </ContainerFlex>
          </ContainerFlex>
          <Input value={notes} onChange={(e) => setNotes(e.target.value)} rows={3}/>
        </ContainerFlex>
      </ContainerFlex>
      <div className={styles.buttons}>
        <Button type='button' size='small' variant='text' onClick={onCancel}>Anuleaza</Button>
        <Button type='button' size="small" variant="contained" onClick={onSaveDate}>Adauga proba</Button>
      </div>
    </>
  );
};

export default CaseFittingForm;