import { FC } from 'react';
import Typography from '../../Typography/Typography';
import IconButton from '../../IconButton/IconButton';
import { CalendarDayGridDays } from '../utils';
import { ChevronLeft, ChevronRight } from '../../assets';
import styles from './styles.module.css';

type Props = {
  refDate: Date;
  days: CalendarDayGridDays[][];
  onDateChange: (date: Date) => void;
  onMonthChanged?: (month: number) => void;
};

const getFormatedMonth = (date: Date): string => {
  if (!date) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long'
  };
  const formatted = Intl.DateTimeFormat('Ro-ro', options).format(date);
  return formatted[0].toUpperCase() + formatted.substring(1);
};

const MonthCalendarGrid: FC<Props> = ({ refDate, days, onDateChange, onMonthChanged }) => {
  const onDateSelected = (date: Date) => () => {
    onDateChange(date);
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.month}>
        {onMonthChanged && (
          <IconButton className={styles.button} onClick={() => onMonthChanged(-1)}>
            <ChevronLeft />
          </IconButton>
        )}
        <Typography type="span" variation='bold'>{getFormatedMonth(refDate)}</Typography>
        {onMonthChanged && (
          <IconButton className={styles.button} onClick={() => onMonthChanged(1)}>
            <ChevronRight />
          </IconButton>
        )}
      </div>
      <div className={styles.weeks}>
        <div className={styles.week}>
          {['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa', 'Du'].map((day, index) => (
            <Typography key={`${day}-${index}`} className={styles.day} type="label" variation='bold'> {day}</Typography>
          ))}
        </div>
        {days.map((week, i) => (
          <div key={i} className={styles.week}>
            {week.map((day, index) => (
              <button
                key={`${day.day}-${i}-${index}`}
                className={[
                  styles.day,
                  !day.current ? styles.fade : day.isToday ? styles.today : '',
                  day.selected ? styles.selected : '',
                  day.isIntervalStart ? styles.start : '',
                  day.isIntervalEnd ? styles.end : '',
                ].join(' ')}
                onClick={onDateSelected(day.date)}>
                {day.day}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthCalendarGrid;