import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URLS, validations } from '../../../lib';
import { TitanUser } from '../../../models';
import { textMessages } from '../../../translations';
import { useAppStoreHooks } from '../../../toolkit';
import { updateUser } from '../../../store';

const validate = validations({
  password: [
    ['required', textMessages.requiredError],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
  confirmPassword: [
    ['required', textMessages.requiredError],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
});

const useRecoverPassword = (token?: string) => {
  const [recoverPasswordForm, setRecoverPasswordForm] = useState<{
    form: {
      password: string;
      confirmPassword: string;
      key?: string;
    };
    errors: {
      password?: string;
      confirmPassword?: string;
    };
  }>({
    form: {
      password: '',
      confirmPassword: '',
      key: token,
    },
    errors: {},
  });
  const navigate = useNavigate();
  const { dispatch, postJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChangeHandler = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setRecoverPasswordForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(recoverPasswordForm.form);
    if (
      recoverPasswordForm.form.password !==
      recoverPasswordForm.form.confirmPassword
    ) {
      if (!errors.confirmPassword) {
        errors.confirmPassword = textMessages.passwordNotmatch;
      }
    }
    const hasError = Object.keys(errors).length;
    setRecoverPasswordForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (hasError) {
      return;
    }

    loadingStart();
    try {
      const response = await postJson<{ model: TitanUser }>(
        API_URLS.identity.resetPassword,
        recoverPasswordForm.form
      );
      loadingEnd();
      notify(textMessages.passwordResetSuccess, 'success');
      dispatch(updateUser(response.model));
      navigate('/recover-password/success');
    } catch (e: any) {
      loadingEnd();
      notify(
        textMessages.passwordResetError,
        'error',
        textMessages.verifyEmail
      );
    }
  };

  return {
    recoverPasswordForm,
    onChangeHandler,
    onSubmitHandler,
  };
};

export default useRecoverPassword;
