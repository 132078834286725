import { FC } from 'react';
import styles from './styles.module.css';
import { DentOpsIcon } from '../../toolkit';

type Props = {
  text: string;
  url?: string;
  size?: 'big' | 'default';
};

const Avatar: FC<Props> = ({ text, url, size = 'default' }) => {
  return (
    <div className={[styles.userLogo, styles[size]].join(' ')}>
      {url ? (
        <img src={url} alt={text} />
      ) : 
      text ||
        (
          <DentOpsIcon />
        )}
    </div>
  );
};

export default Avatar;