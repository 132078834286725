import { FC } from 'react';
import { clearToast, useAppDispatch, useAppSelector } from '../../../store';
import { Toast } from '../../../toolkit';
import styles from './styles.module.css';

const ToastLayout: FC = () => {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(state => state.toasts.value);
  return (
    <div className={styles.container}>
      {toasts.map(n => (
        <Toast key={n.id} message={n.message} type={n.type} details={n.details} onDismiss={() => {
          dispatch(clearToast(n.id));
        }} />
      ))}
    </div>
  );
};

export default ToastLayout;