import AuthContext from '../../models/AuthContext';
import fetchWithToken from './fetchWithToken';

const fetchFormData = async <T>(context: AuthContext, url: string, method: string, payload: any): Promise<T> => {
  const formData = new FormData();

  Object.keys(payload).forEach((key) => {
    const name = key.charAt(0).toUpperCase() + key.substr(1);

    if (payload[key] instanceof Array && payload[key].length > 0 && payload[key][0] instanceof File) {
      payload[key].forEach((element: any) => {
        formData.append(name, element);
      });
    } else if (payload[key] instanceof Array) {
      formData.append(name, JSON.stringify(payload[key]));
    } else if (payload[key] instanceof Date) {
      formData.append(name, payload[key].toISOString());
    } else if (payload[key] instanceof File) {
      formData.append(name, payload[key]);
    } else {
      formData.append(name, payload[key]);
    }
  });

  const response = await fetchWithToken(context, url, method, formData, undefined);
  if (response && response.ok) {
    if (response.status !== 204) {
      return await response.json() as T;
    }
  }
  return Promise.resolve({} as T);
};

export default fetchFormData;