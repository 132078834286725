import { useCallback, useState } from 'react';
import { API_URLS, generateEmployeeReport, useEffectOnMount } from '../../lib';
import { useAppStoreHooks } from '../../toolkit';
import { TitanUserStatistic } from '../../models';
import { useAppSelector } from '../../store';

const useUserStatistics = (
  startDate: Date,
  endDate: Date,
  userIdentifier: string
) => {
  const [userStatistics, setUserStatistics] = useState<TitanUserStatistic>();
  const [finishedDateTime, setFinishedDateTime] = useState<boolean>(false);
  const company = useAppSelector((state) => state.cache.company.company);

  const { postJson, getRequest, loadingStart, loadingEnd, notify } =
    useAppStoreHooks();

  const onDownloadUserReport = async () => {
    loadingStart();
    try {
      const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      const logo = await response?.text();
      generateEmployeeReport(userStatistics!, company!, logo || '');
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  const loadReport = useCallback(
    async (
      startDate: Date,
      endDate: Date,
      userIdentifier: string,
      finishedDateTime: boolean
    ) => {
      setFinishedDateTime(finishedDateTime);
      loadingStart();
      try {
        const userStatistics = await postJson<TitanUserStatistic>(
          API_URLS.titan.user.titanUserReportQuery,
          {
            from: startDate,
            to: endDate,
            titanUserId: userIdentifier,
            useFinishedDateTime: finishedDateTime,
          }
        );
        setUserStatistics(userStatistics);
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(e.message, 'error');
      }
    },
    [postJson, loadingStart, loadingEnd, notify, setFinishedDateTime]
  );

  useEffectOnMount(() => {
    if (userIdentifier) {
      loadReport(startDate, endDate, userIdentifier, false);
    }
  }, [userIdentifier, startDate, endDate, loadReport]);

  return {
    userStatistics,
    onDownloadUserReport,
    loadReport,
    finishedDateTime,
  };
};

export default useUserStatistics;
