import { useState } from "react";
import { API_URLS, validations } from "../../lib";
import { TitanCompany } from "../../models";
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { setRefreshCompany } from '../../store';

const validate = validations({
  name: [["required", "Camp oligatoriu!"]],
  address: [["required", "Camp oligatoriu!"]],
  city: [["required", "Camp oligatoriu!"]],
  password: [["required", "Camp oligatoriu!"]],
  phoneNumber: [["required", "Camp oligatoriu!"]],
});

const useCompany = (company: TitanCompany) => {
  const [companyForm, setCompanyForm] = useState<{
    form: TitanCompany;
    errors: 
    {
      name?: string;
      address?: string;
      city?: string;
      password?: string;
      phoneNumber?: string;
      certificateTechnicianName?: string;
      certificateSeries?: string;
      certificateNumber?: string;
    };
  }>({
    form: {
      ...company
    },
    errors: {}
  });

  const { postJson, notify, loadingStart, loadingEnd, dispatch } = useAppStoreHooks();
  const onSubmit = async () => {
    if (!companyForm.form) {
      return;
    }

    const errors = validate(companyForm.form);
    const hasErrors = Object.keys(errors).length > 0;
    setCompanyForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (hasErrors) {
      return;
    }
    loadingStart();
    try {
      await postJson(API_URLS.titan.company.update, companyForm.form);
      dispatch(setRefreshCompany());
      loadingEnd();
      notify(textMessages.changesHaveBeenSaved, "success");
      setCompanyForm((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          password: ''
        },
      }));
    } catch (e: any) {
      loadingEnd();
      notify(e.message || textMessages.genericError, "error");
    }
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    const form = {
      ...companyForm.form,
      [target.name]: target.value,
    }
    const errors = validate(form);
    setCompanyForm((prevState) => ({
      ...prevState,
      errors,
    }));

    setCompanyForm((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const onChangeRequirePassword = (checked?: boolean) => {
    setCompanyForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form!,
        requirePasswordForStatistics: checked || false,
      },
    }));
  };

  return {
    companyForm,
    onSubmit,
    onChange,
    onChangeRequirePassword,
  };
};

export default useCompany;
