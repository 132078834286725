import { FC, createElement, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import {
  CarretDownIcon,
  CarretUpDownIcon,
  CarretUpIcon,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "../assets";
import Typography from "../Typography/Typography";
import Card, { Props as CardProps } from "../Card/Card";
import IconButton from "../IconButton/IconButton";
import { IColumn } from "../../lib";
import Collapsible from "../Collapsible/Collapsible";

type IDocument = {
  key: string;
} & any;

type PaginationProps = {
  page?: number;
  pageSize?: number;
  total?: number;
  onPageChanged?: (page: number) => void;
};

type RendeProps = {
  columns: IColumn[];
  onRowClick?: (item?: any) => void;
  subTableComponent?: FC<{ row: any; }>;
};

type Props = {
  items: IDocument[];
} & RendeProps &
  PaginationProps &
  Omit<CardProps, "children">;

const getColumnsWidth =
  (columns: IColumn[]) =>
    (totalWidth?: number): number[] => {
      if (Boolean(totalWidth)) {
        const definedWidth = columns.reduce((acc, c) => {
          if (c.width) {
            acc += c.width;
          }
          return acc;
        }, 0);
        const totalWidthFr = columns
          .filter((c) => Boolean(c.width) === false)
          .reduce((acc, c) => acc + (c.widthFr || 1), 0);
        const calculatedWidth = ((totalWidth || 0) - definedWidth) / totalWidthFr;
        return columns.map((c) => c.width || calculatedWidth * (c.widthFr || 1));
      }
      return [];
    };

const Pagination: FC<Partial<PaginationProps>> = ({
  page = 0,
  pageSize = 10,
  total,
  onPageChanged,
}) => {
  const maxPage = total ? Math.floor((total - 1) / pageSize) : -1;
  let totalPages = maxPage + 1;
  let pages: number[] = [];

  if (totalPages > 5) {
    if (page < 2) {
      pages = [0, 1, 2, 3, -1];
    } else if (page + 2 > totalPages) {
      pages = [-1, page - 3, page - 2, page - 1, page];
    } else {
      pages = [-1, page - 1, page, page + 1, -1];
    }
  } else {
    pages = Array.from({ length: totalPages }, (_, i) => i);
  }

  return (
    <>
      {page !== undefined && total && total > pageSize && onPageChanged && (
        <div className={styles.pagination}>
          <IconButton
            variant="outlined"
            disabled={page === 0}
            onClick={() => onPageChanged(0)}
          >
            <ChevronLeft />
          </IconButton>
          {pages.map((p) =>
            p === -1 ? (
              <IconButton key={p} variant="outlined" disabled={true}>
                ...
              </IconButton>
            ) : (
              <IconButton
                key={p}
                variant="outlined"
                className={page === p ? styles.active : undefined}
                onClick={() => onPageChanged(p)}
              >
                {p + 1}
              </IconButton>
            )
          )}
          <IconButton
            variant="outlined"
            disabled={page === maxPage}
            onClick={() => onPageChanged(maxPage)}
          >
            <ChevronRight />
          </IconButton>
        </div>
      )}
    </>
  );
};

const TableRow: FC<
  {
    row: any;
    widths: number[];
  } & RendeProps
> = ({ row, onRowClick, columns, widths, subTableComponent }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <>
      <div
        className={styles.gridRow}
        onClick={() => onRowClick && onRowClick({ ...row })}
      >
        {subTableComponent && (
          <div className={styles.gridCell} style={{ width: 60 }}>
            <IconButton
              className={[styles.rotateAnim, expanded ? styles.rotate180 : ''].join(' ')}
              onClick={() => setExpanded(!expanded)}
            >
              <ChevronDown />
            </IconButton>
          </div>
        )}
        {columns.map((column, idx) => (
          <div
            key={column.key}
            className={styles.gridCell}
            style={{ width: widths[idx] }}
          >
            {column.onRender ? (
              column.onRender({ ...row })
            ) : (
              <Typography type="span">{row[column.fieldName]}</Typography>
            )}
          </div>
        ))}
      </div>
      {subTableComponent && (
        <Collapsible isExpanded={expanded}>
          {createElement(subTableComponent, { row })}
        </Collapsible>
      )}
    </>
  );
};

const Table: FC<Props> = ({
  columns,
  items,
  onRowClick,
  page,
  pageSize,
  total,
  onPageChanged,
  subTableComponent,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [widths, setWidths] = useState<number[]>([]);
  const localColumns = columns.filter((c) => c.show !== false);
  const columnHack = useRef<IColumn[]>(localColumns);
  const calcWidths = useMemo(() => getColumnsWidth(columnHack.current), []);

  useEffect(() => {
    if (containerRef.current) {
      const totalWidth =
        containerRef.current.clientWidth - (subTableComponent ? 60 : 0);
      setWidths(calcWidths(totalWidth));
    }
  }, [containerRef, calcWidths, subTableComponent]);

  return (
    <>
      <Card noPadding className={styles.grid} ref={containerRef} {...rest}>
        {items.length > 0 && (
          <>
            <div className={styles.gridHeader}>
              {subTableComponent && <div style={{ width: 60 }}></div>}
              {localColumns.map((column, idx) => (
                <div
                  key={column.key}
                  className={[
                    styles.gridCell,
                    column.isSorted !== undefined ? styles.sortable : "",
                    column.isSorted === true ? styles.sorted : "",
                  ].join(" ")}
                  onClick={column.onColumnClick}
                  style={{ width: widths[idx] }}
                >
                  <Typography type="span" variation="bold">
                    {column.onRenderHeader
                      ? column.onRenderHeader()
                      : column.name}
                  </Typography>
                  {column.isSorted === false && <CarretUpDownIcon /> }
                  {column.isSorted === true &&
                    column.isSortedDescending === false && <CarretUpIcon />}
                  {column.isSorted === true &&
                    column.isSortedDescending === true && <CarretDownIcon />}
                </div>
              ))}
            </div>
            {items.map((item) => (
              <TableRow
                key={item.key}
                row={item}
                onRowClick={onRowClick}
                columns={localColumns}
                widths={widths}
                subTableComponent={subTableComponent}
              />
            ))}
          </>
        )}
      </Card>
      <Pagination
        page={page}
        pageSize={pageSize}
        total={total}
        onPageChanged={onPageChanged}
      />
    </>
  );
};

export default Table;
