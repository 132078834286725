import { useCallback, useEffect, useRef } from 'react';
import { setCompanyData, setLoadCompany, useAppSelector } from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { TitanCompany } from '../../../models';
import { textMessages } from '../../../translations';
import { API_URLS } from '../../constants';

const useCompanyCache = (expireAfterMinutes?: number) => {
  const refreshing = useRef(false);
  const { company, expireAfterMiliseconds } = useAppSelector(
    (state) => state.cache
  );
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadCompany = useCallback(async () => {
    refreshing.current = true;
    try {
      const company = await getJson<TitanCompany>(
        API_URLS.titan.company.company
      );
      dispatch(setCompanyData(company));
    } catch (e: any) {
      notify(textMessages.genericError, 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }

    if (!company.expireTime) {
      dispatch(setLoadCompany());
      loadCompany();
    } else {
      const timer = company.expireTime - Date.now();
      timeout = setTimeout(() => {
        loadCompany();
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    loadCompany,
    dispatch,
    company.expireTime,
    expireAfterMiliseconds,
    expireAfterMinutes,
  ]);
};
export default useCompanyCache;
