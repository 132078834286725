import { useCallback, useEffect, useState } from "react";
import { useAppStoreHooks } from "../../toolkit";
import { TitanCompanyStatistic, TitanToken, TitanUser } from "../../models";
import { textMessages } from "../../translations";
import { useAppSelector } from "../../store";
import { API_URLS, DEFAULT_MONTH_START, DEFAULT_TODAY_END, validations } from "../../lib";

const validate = validations({
  password: [["required", "Camp oligatoriu!"]],
});

const useReports = () => {
  const user = useAppSelector((state) => state.auth.user);
  const company = useAppSelector((state) => state.cache.company.company);
  
  const [reportSettings, setReportSettings] = useState<{
    startDate: Date;
    endDate: Date;
    passwordConfirmed: boolean;
    form: {
      email: string;
      password: string;
    };
    errors: {
      password?: string;
    };
  }>({
    startDate: DEFAULT_MONTH_START,
    endDate: DEFAULT_TODAY_END,
    passwordConfirmed: !company?.requirePasswordForStatistics,
    form: { email: user?.email || "", password: "" },
    errors: {},
  });
  const [reportData, setReportData] = useState<TitanCompanyStatistic>();
  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onDatesSelected = (startDate?: Date, endDate?: Date) => {
    setReportSettings((prevState) => ({
      ...prevState,
      startDate: startDate || DEFAULT_MONTH_START,
      endDate: endDate || DEFAULT_TODAY_END,
    }));
  };

  const fetchStatistics = useCallback(
    async (startDate: Date, endDate: Date, password?: string) => {
      loadingStart();
      try {
        const statistics = await postJson<TitanCompanyStatistic>(
          API_URLS.titan.company.statistics,
          {
            from: startDate,
            to: endDate,
            password,
          }
        );
        setReportData(statistics);
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, "error", e.message);
      }
    },
    [postJson, setReportData, loadingStart, loadingEnd, notify]
  );

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    setReportSettings((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        password: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let form = { ...reportSettings.form };
    const errors = validate(form);
    const hasErrors = Object.keys(errors).length > 0;
    setReportSettings((prevState) => ({
      ...prevState,
      errors,
    }));

    if (hasErrors) {
      return;
    }
    loadingStart();
    try {
      await postJson<{ token: TitanToken; user: TitanUser }>(
        API_URLS.identity.login,
        form
      );
      setReportSettings((prevState) => ({
        ...prevState,
        passwordConfirmed: true,
      }));
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      setReportSettings((prevState) => ({
        ...prevState,
        errors: {
          password: "Parola introdusa nu este corecta!",
        },
      }));
    }
  };

  useEffect(() => {
    if (reportSettings.passwordConfirmed) {
      fetchStatistics(
        reportSettings.startDate,
        reportSettings.endDate,
        reportSettings.form.password
      );
    }
  }, [
    fetchStatistics,
    reportSettings.passwordConfirmed,
    reportSettings.startDate,
    reportSettings.endDate,
    reportSettings.form.password,
  ]);

  return {
    reportSettings,
    onDatesSelected,
    reportData,
    onSubmit,
    onChange,
  };
};

export default useReports;
