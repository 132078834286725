import { FC } from 'react';
import { useElement } from '../../hooks';
import ElementFormConfigureStages from './ElementFormConfigureStages';
import { Button, ContainerFlex, Typography } from '../../../toolkit';
import { TitanElement, TitanElementStage } from '../../../models';

type Props = {
  element?: TitanElement;
  onClose: () => void;
  onSaved: (elementStages?: TitanElementStage[]) => void;
};

const ElementStagesForm: FC<Props> = ({ element, onClose, onSaved }) => {
  const {
    elementForm,
    stagesOptions,
    onSubmit,
    onStageChanged,
    onRemoveElementStage,
  } = useElement(onSaved, element);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography type='h6'>
        Configureaza etape pentru {element?.name}
      </Typography>
      <br />
      <ElementFormConfigureStages
        elementStages={elementForm.form.elementStages || []}
        stagesOptions={stagesOptions || []}
        onStageChanged={onStageChanged}
        onRemoveElementStage={onRemoveElementStage}
      />
      <ContainerFlex spacing={1} type='row' justify='end'>
        <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
        <Button size='medium' variant='contained' type="submit">Salveaza</Button>
      </ContainerFlex>
    </form>
  );
};

export default ElementStagesForm;