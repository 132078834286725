import { useNavigate } from 'react-router-dom';
import { useAppStoreHooks } from '../../toolkit';
import { reloadList } from '../../store';
import { API_URLS } from '../../lib';

const useCaseDelete = (caseId: number) => {
  const navigate = useNavigate();
  const { deleteRequest, loadingStart, loadingEnd, notify, dispatch } = useAppStoreHooks();


  const onDelete = async () => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.cases.case}/${caseId}/DeleteCase`);
      notify('Cazul a fost sters cu succes.', 'success');
      loadingEnd();
      dispatch(reloadList({ pageKey: API_URLS.titan.cases.list }));
      navigate('/cases');
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  return { onDelete };
};

export default useCaseDelete;