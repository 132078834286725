import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserAddIcon,
  Typography,
  Portal,
  ContainerFlex,
  Button,
  AddIcon,
} from "../../../toolkit";
import { getSortingProps, IColumn, useCacheList } from "../../../lib";
import { TitanClinic } from "../../../models";
import { SearchInput, TableList } from "../../../components";
import ClinicForm from "../ClinicForm/ClinicForm";
import styles from "./styles.module.css";
import { setRefreshClinics, useAppSelector } from "../../../store";

interface IDocument extends TitanClinic {
  key: string;
}

type Props = {};

const filter = (searchTerm: string) => (row: IDocument) => {
  return `${row.clinicName} ${row.email} ${row.phone}`
    .toLowerCase()
    .includes(searchTerm.toLowerCase());
};

const ClinicList: FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const clinics = useAppSelector((state) => state.cache.clinics);
  const listProps = useCacheList(clinics, filter, setRefreshClinics);
  const columns: IColumn[] = [
    {
      key: "no",
      name: "#",
      fieldName: "index",
      width: 70,
    },
    {
      key: "name",
      name: "Nume",
      fieldName: "name",
      ...getSortingProps('clinicName', listProps),
      onRender: (item: IDocument) => (
        <div>
          <Typography type="p" variation="bold">
            {item.clinicName}
          </Typography>
          <Typography type="span" variation="secondary">
            {item.email}
          </Typography>
        </div>
      ),
    },
    {
      key: "city",
      name: "Oras",
      fieldName: "city",
      ...getSortingProps('orderByEmail', listProps)
    },
    {
      key: "address",
      name: "Adresa",
      fieldName: "address",
    },
    {
      key: "phone",
      name: "Telefon",
      fieldName: "phone",
    },
  ];

  const items = listProps.results.map((item: TitanClinic, index: number) => {
    return {
      key: item.clinicId,
      index: listProps.page * 25 + index + 1,
      ...item,
    };
  });

  return (
    <>
      {(listProps.total > 0 || listProps.isFiltered) && (
        <ContainerFlex style={{ width: 1200, margin: "0 auto" }}>
          <div className={styles.tableHeader}>
            <Typography type="h5" variation="center">
              {listProps.results?.length} Clinici
            </Typography>
            <div className={styles.tableControls}>
              <SearchInput
                placeholder="Cauta clinica"
                onChange={listProps.setSearchTerm}
              />
              {user?.isAdmin && (
                <div>
                  <Button
                    type='button'
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <AddIcon />
                    Adauga clinica
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ContainerFlex>
      )}
      <TableList
        columns={columns}
        items={items}
        changePage={listProps.changePage}
        isFiltered={listProps.isFiltered}
        page={listProps.page}
        reload={listProps.reload}
        loading={listProps.loading}
        total={listProps.total}
        onRowClick={(item: IDocument) =>
          navigate(`/clients/clinics/${item.clinicId}`)
        }
        stateAssets={{
          emptySearchText: "Nu am gasit nici o clinica",
          emptyIcon: <UserAddIcon size={40} />,
          emptyTitle: "Momentan nu ai nici o clinica adaugata",
          emptySubTitle: "Adauga prima clinica apasand pe butonul de jos",
          emptyAction: () => {
            setIsOpen(true);
          },
          emptyActionText: "Adauga clinica",
        }}
        isSubList={false}
      />
      <Portal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        title="Adauga Clinica"
        overlay={true}
        width={560}
      >
        <ClinicForm
          onClose={() => {
            setIsOpen(false);
          }}
          onSaved={() => {
            listProps.reload();
            setIsOpen(false);
          }}
        />
      </Portal>
    </>
  );
};

export default ClinicList;
