import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { APP_LOCAL_STORAGE_KEYS } from './constants';
export type NotificationMessageType = {
  id: number;
  type: 'functionality';
  title: string;
  details: string;
  url?: string;
  read: boolean;
  verison?: 'v1' | 'v2'
};


export const setNotifications = (ids: number[]) => {
  // TODO: Move to middleware
  // setTimeout(() => {
  localStorage.setItem(APP_LOCAL_STORAGE_KEYS.NOTIF, JSON.stringify(ids));
  // }, 0);
};

export interface AppNotificationState {
  value: NotificationMessageType[];
}

const initialState: AppNotificationState = {
  value: []
};

type NotificationMessagePayload = Omit<NotificationMessageType, 'id' | 'read'>;

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationMessagePayload>) => {
      state.value = [...state.value, { ...action.payload, id: Date.now(), read: false }];
    },
    markReadNotification: (state, action: PayloadAction<number>) => {
      const notification = state.value.find(n => n.id === action.payload);
      if (notification) {
        notification.read = true;
      }
    },
    markAllNotification: (state) => {
      state.value.forEach(n => {
        n.read = true;
      });
      setNotifications(state.value.map(n => n.id));
    }
  }
});

export const {
  addNotification,
  markReadNotification,
  markAllNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;