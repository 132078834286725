import { FC } from 'react';

type Props = {
  size?: number;
};

const StarIcon: FC<Props> = ({ size = 12 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill="none">
      <path d="M15.0435 4.6041C15.3508 3.98151 15.5045 3.67022 15.7131 3.57076C15.8946 3.48423 16.1054 3.48423 16.2869 3.57076C16.4955 3.67022 16.6492 3.98151 16.9565 4.6041L19.8721 10.5107C19.9628 10.6945 20.0081 10.7864 20.0744 10.8578C20.1331 10.921 20.2035 10.9721 20.2817 11.0085C20.37 11.0496 20.4715 11.0644 20.6743 11.094L27.196 12.0473C27.8827 12.1477 28.2261 12.1979 28.385 12.3656C28.5233 12.5115 28.5883 12.7121 28.562 12.9114C28.5317 13.1404 28.2831 13.3826 27.7859 13.8668L23.0686 18.4616C22.9215 18.6048 22.848 18.6764 22.8005 18.7616C22.7585 18.8371 22.7316 18.92 22.7212 19.0057C22.7095 19.1025 22.7268 19.2037 22.7615 19.406L23.8746 25.8958C23.992 26.5803 24.0507 26.9226 23.9404 27.1257C23.8444 27.3024 23.6738 27.4263 23.4761 27.463C23.2488 27.5051 22.9415 27.3435 22.3268 27.0202L16.4965 23.9541C16.3148 23.8586 16.224 23.8108 16.1283 23.7921C16.0436 23.7754 15.9564 23.7754 15.8717 23.7921C15.776 23.8108 15.6852 23.8586 15.5035 23.9541L9.67321 27.0202C9.05853 27.3435 8.75119 27.5051 8.52394 27.463C8.32622 27.4263 8.1556 27.3024 8.05962 27.1257C7.94931 26.9226 8.00801 26.5803 8.12541 25.8958L9.23849 19.406C9.27319 19.2037 9.29054 19.1025 9.2788 19.0057C9.26841 18.92 9.24146 18.8371 9.19945 18.7616C9.152 18.6764 9.07848 18.6048 8.93142 18.4616L4.21405 13.8668C3.71686 13.3826 3.46826 13.1404 3.43801 12.9114C3.41169 12.7121 3.47671 12.5115 3.61498 12.3656C3.77389 12.1979 4.11727 12.1477 4.80403 12.0473L11.3257 11.094C11.5285 11.0644 11.6299 11.0496 11.7183 11.0085C11.7965 10.9721 11.8669 10.921 11.9256 10.8578C11.9919 10.7864 12.0372 10.6945 12.1279 10.5107L15.0435 4.6041Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default StarIcon;