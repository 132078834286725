import { FC, useState } from "react";
import {
  Card,
  Radio,
  ContainerFlex,
  Delimiter,
  Typography,
  AnimatedAppIcon,
  StagesIcon,
  ToothIcon,
  UserAddIcon,
  Button,
  AddIcon,
  Portal,
} from "../../../../toolkit";
import {
  TitanCaseElement,
  TitanCaseStage,
  TitanElement,
  TitanStage,
  TitanUserStage,
} from "../../../../models";
import {
  setRefreshElements,
  setRefreshStages,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import styles from "./styles.module.css";
import { UserElementStagesForm } from "../../../stages";
import { ElementStagesForm } from "../../../elements";

type Props = {
  caseElements: TitanCaseElement[];
  caseStages: TitanCaseStage[];
  onStagesChanged: (
    caseElement: TitanCaseElement,
    userStage: TitanUserStage
  ) => void;
  onElementsChanged: (caseElements: TitanCaseElement[]) => void;
};

const CRUD_TITLE = {
  user: "Asociaza tehnicieni",
  element: "Asociaza etapa",
};

const CaseStagesEdit: FC<Props> = ({
  caseElements,
  caseStages,
  onStagesChanged,
  onElementsChanged,
}) => {
  const stages = useAppSelector((state) => state.cache.stages.data);
  const elements = useAppSelector((state) => state.cache.elements.data);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [stageConfigure, setStageConfigure] = useState<{
    isOpen: boolean;
    type?: "user" | "element";
    stage?: TitanStage;
    element?: TitanElement;
  }>({
    isOpen: false,
  });
  return (
    <Card type="column" spacing={2}>
      <Typography type="h5">Etape</Typography>
      <Delimiter />
      {caseElements.length ? (
        caseElements.map((caseElement, index) => (
          <ContainerFlex key={caseElement.elementId} type="column" spacing={2}>
            <Typography type="h5">
              <span className={styles.current}>{index + 1}</span>
              {caseElement.name}
            </Typography>
            <ContainerFlex type="row" spacing={3}>
              {caseElement.elementStages?.length ? (
                caseElement.elementStages.map((elementStage, i) => {
                  const userStatges =
                    stages?.find((s) => s.stageId === elementStage.stageId)
                      ?.stageUsers || [];
                  const elementCaseStage = caseStages.find(
                    (cs) =>
                      cs.elementId === elementStage.elementId &&
                      cs.stageId === elementStage.stageId
                  );
                  return (
                    <ContainerFlex
                      key={elementStage.stageId}
                      type="row"
                      spacing={3}
                    >
                      <ContainerFlex type="column" spacing={1}>
                        <Typography type="h6">
                          {elementStage.stageName}
                        </Typography>
                        {userStatges.length > 0 && (
                          <>
                            <Typography type="span">Atribuit la</Typography>
                            {userStatges.map((userStage) => (
                              <Radio
                                key={userStage.userStageId}
                                label={`${userStage.firstName} ${userStage.lastName}`}
                                value={
                                  elementCaseStage?.titanUserId ===
                                  userStage.userIdentifier
                                }
                                onChange={() =>
                                  onStagesChanged(caseElement, userStage)
                                }
                              />
                            ))}
                          </>
                        )}
                        {userStatges.length === 0 && (
                          <ContainerFlex
                            type="column"
                            grow
                            align="center"
                            justify="center"
                            spacing={3}
                          >
                            <AnimatedAppIcon>
                              <UserAddIcon size={40} />
                            </AnimatedAppIcon>
                            <Typography type="p" variation="center">
                              Nu exista tehnicieni asociati.
                            </Typography>
                            {user?.isAdmin && (
                              <Button
                                type="button"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  const stage = stages?.find(
                                    (s) => s.stageId === elementStage.stageId
                                  );
                                  setStageConfigure({
                                    isOpen: true,
                                    type: "user",
                                    stage,
                                  });
                                }}
                              >
                                <AddIcon /> Asociaza tehnicieni
                              </Button>
                            )}
                          </ContainerFlex>
                        )}
                      </ContainerFlex>
                      {i < (caseElement.elementStages?.length || 0) - 1 && (
                        <Delimiter type="vertical" />
                      )}
                    </ContainerFlex>
                  );
                })
              ) : (
                <ContainerFlex
                  type="column"
                  grow
                  align="center"
                  justify="center"
                  spacing={3}
                >
                  <AnimatedAppIcon>
                    <StagesIcon size={40} />
                  </AnimatedAppIcon>
                  <Typography type="p" variation="center">
                    Acest element nu are etape asociate.
                  </Typography>
                  {user?.isAdmin && (
                    <Button
                      type="button"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        const element = elements?.find(
                          (e) => e.elementId === caseElement.elementId
                        );
                        setStageConfigure({
                          isOpen: true,
                          type: "element",
                          element,
                        });
                      }}
                    >
                      <AddIcon /> Asociaza etape
                    </Button>
                  )}
                </ContainerFlex>
              )}
            </ContainerFlex>
            {index < caseElements.length - 1 && <Delimiter />}
          </ContainerFlex>
        ))
      ) : (
        <ContainerFlex
          type="column"
          grow
          align="center"
          justify="center"
          spacing={3}
        >
          <AnimatedAppIcon>
            <ToothIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Nu exista elemente asociate cazului
          </Typography>
        </ContainerFlex>
      )}
      <Portal
        isOpen={stageConfigure.isOpen}
        onDismiss={() => setStageConfigure({ isOpen: false })}
        title={CRUD_TITLE[stageConfigure.type!]}
        overlay={true}
        width={560}
      >
        {stageConfigure.type === "element" && (
          <ElementStagesForm
            element={stageConfigure.element!}
            onClose={() => setStageConfigure({ isOpen: false })}
            onSaved={(elementStages) => {
              const _caseElements = [...caseElements];
              const index = caseElements.findIndex(
                (ce) => ce.elementId === stageConfigure.element?.elementId
              );
              _caseElements[index] = {
                ..._caseElements[index],
                elementStages,
              };
              onElementsChanged(_caseElements);
              setStageConfigure({ isOpen: false });
              dispatch(setRefreshElements());
            }}
          />
        )}
        {stageConfigure.type === "user" && (
          <UserElementStagesForm
            stage={stageConfigure.stage}
            onClose={() => setStageConfigure({ isOpen: false })}
            onSaved={() => {
              setStageConfigure({ isOpen: false });
              dispatch(setRefreshStages());
            }}
            includeElementsConfig={false}
          />
        )}
      </Portal>
    </Card>
  );
};

export default CaseStagesEdit;
