import { FC } from 'react';

type Props = {
  size?: number;
};

const ImageIcon: FC<Props> = ({ size = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 40 40" fill="none">
      <path d="M27 35H11.5523C10.5426 35 10.0377 35 9.80397 34.8003C9.60113 34.6271 9.49348 34.3672 9.51441 34.1013C9.53853 33.7948 9.89552 33.4378 10.6095 32.7239L24.781 18.5523C25.4411 17.8923 25.7711 17.5622 26.1516 17.4386C26.4864 17.3298 26.847 17.3298 27.1817 17.4386C27.5622 17.5622 27.8923 17.8923 28.5523 18.5523L35 25V27M27 35C29.8003 35 31.2004 35 32.27 34.455C33.2108 33.9757 33.9757 33.2108 34.455 32.27C35 31.2004 35 29.8003 35 27M27 35H13C10.1997 35 8.79961 35 7.73005 34.455C6.78924 33.9757 6.02433 33.2108 5.54497 32.27C5 31.2004 5 29.8003 5 27V13C5 10.1997 5 8.79961 5.54497 7.73005C6.02433 6.78924 6.78924 6.02433 7.73005 5.54497C8.79961 5 10.1997 5 13 5H27C29.8003 5 31.2004 5 32.27 5.54497C33.2108 6.02433 33.9757 6.78924 34.455 7.73005C35 8.79961 35 10.1997 35 13V27M17.5 14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5C12.3257 17.5 10.8333 16.0076 10.8333 14.1667C10.8333 12.3257 12.3257 10.8333 14.1667 10.8333C16.0076 10.8333 17.5 12.3257 17.5 14.1667Z" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ImageIcon;