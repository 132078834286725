import { FC } from 'react';
import {
  Tooth11, Tooth12, Tooth13, Tooth14, Tooth15, Tooth16, Tooth17, Tooth18,
  Tooth21, Tooth22, Tooth23, Tooth24, Tooth25, Tooth26, Tooth27, Tooth28,
  Tooth31, Tooth32, Tooth33, Tooth34, Tooth35, Tooth36, Tooth37, Tooth38,
  Tooth41, Tooth42, Tooth43, Tooth44, Tooth45, Tooth46, Tooth47, Tooth48
} from './Teeth';
import styles from './styles.module.css';

const TEETH: Record<string, FC> = {
  '11': Tooth11,
  '12': Tooth12,
  '13': Tooth13,
  '14': Tooth14,
  '15': Tooth15,
  '16': Tooth16,
  '17': Tooth17,
  '18': Tooth18,
  '21': Tooth21,
  '22': Tooth22,
  '23': Tooth23,
  '24': Tooth24,
  '25': Tooth25,
  '26': Tooth26,
  '27': Tooth27,
  '28': Tooth28,
  '31': Tooth31,
  '32': Tooth32,
  '33': Tooth33,
  '34': Tooth34,
  '35': Tooth35,
  '36': Tooth36,
  '37': Tooth37,
  '38': Tooth38,
  '41': Tooth41,
  '42': Tooth42,
  '43': Tooth43,
  '44': Tooth44,
  '45': Tooth45,
  '46': Tooth46,
  '47': Tooth47,
  '48': Tooth48,
};


export type Props = {
  primaryMap: string[];
  secondaryMap?: string[];
  onSelect?: (tooth: string) => void;
  readonly?: boolean;
};

const HumanArcade: FC<Props> = ({ primaryMap, secondaryMap = [], onSelect, readonly }) => {
  const classNames: { [key: string]: string; } = {};
  primaryMap.forEach((tooth) => {
    classNames[tooth] = styles.selectedPrimary;
  });

  secondaryMap.forEach((tooth) => {
    if (!primaryMap.includes(tooth)) {
      classNames[tooth] = styles.selectedSecondary;
    }
  });

  return (
    <div className={[styles.dentalArches, readonly ? '' : styles.editable].join(' ')}>
      {Object.keys(TEETH).map((key: string) => {
        const Tooth = TEETH[key];
        return (
          <div key={key} className={[styles.tooth, styles['tooth' + key], classNames[key]].join(' ')}>
            <button onClick={onSelect ? (e) => { e.preventDefault(); onSelect(key); } : undefined}>
              <Tooth />
            </button>
          </div>
        );
      })}

    </div>
  );
};

export default HumanArcade;