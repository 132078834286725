import { FC } from 'react';
import { AnimatedAppIcon, Card, ContainerFlex, IconButton, ToothIcon, TrashIcon, Typeahead, TypeaheadOption, Typography } from '../../../toolkit';
import { TitanElementStage } from '../../../models';

type Props = {
  stageElements: TitanElementStage[];
  elementOptions: TypeaheadOption[];
  onElementChanged: (elementId: number) => void;
  onRemoveElementStage: (index?: number | undefined) => void;
};

const StageFormConfigureElements: FC<Props> = ({
  stageElements,
  elementOptions,
  onElementChanged,
  onRemoveElementStage
}) => {

  return (
    <ContainerFlex type="column" spacing={1}>
      <Typeahead
        label="Atribuie element"
        options={elementOptions || []}
        onChange={selectedKey => onElementChanged(selectedKey as number)}
      />
      <ContainerFlex type="column" spacing={1}>
        {stageElements.length > 0 && (
          <Card variant='secondary' type="column" spacing={2}>
            <Typography type="span" variation="bold">Elemente Adaugate</Typography>
            {stageElements?.map((element, index) => (

              <ContainerFlex key={element.elementId} type="row" justify="space-between">
                <Typography type="p">{element.elementName}</Typography>
                <IconButton variant='outlined' color='danger' onClick={() => onRemoveElementStage(index)}>
                  <TrashIcon />
                </IconButton>
              </ContainerFlex>
            ))}
          </Card>
        )}
        {stageElements.length === 0 && (
          <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
            <AnimatedAppIcon>
              <ToothIcon size={40} />
            </AnimatedAppIcon>
            <Typography type='p' variation="center">
              Nu exista elemente asociate.<br />
              <Typography type='span' variation='secondary'>
                Alege un element pentru a incepe.
              </Typography>
            </Typography>
          </ContainerFlex>
        )}
      </ContainerFlex>

      <br />
    </ContainerFlex>
  );
};

export default StageFormConfigureElements;