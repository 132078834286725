import { FC } from 'react';
import { SignUpPageTranslation, textPage, textForm } from '../../../translations';
import { Button, Input, Link, Typography, Checkbox, InputPassword } from '../../../toolkit';
import useSignUp from './useSignUp';
import styles from './styles.module.css';
import { Responsive } from '../../../layout';


const texts = textPage('signUp') as SignUpPageTranslation;

const SignUpForm: FC = () => {
  const {
    signUpForm,
    onChangeHandler,
    onSubmitHandler,
    onCheckboxChanged
  } = useSignUp();

  return (
    <form noValidate onSubmit={onSubmitHandler}>
      <Responsive colsXs={2}>
        <Input
          label={textForm.lastNameLabel}
          placeholder={textForm.lastNamePlaceholder}
          name="lastName"
          onChange={onChangeHandler}
          value={signUpForm.form.lastName}
          required
          errorMessage={signUpForm.errors.lastName}
        />
        <Input
          label={textForm.firstNameLabel}
          placeholder={textForm.firstNamePlaceholder}
          name="firstName"
          onChange={onChangeHandler}
          value={signUpForm.form.firstName}
          required
          errorMessage={signUpForm.errors.firstName}
        />
      </Responsive>

      <Input
        label={textForm.emailLabel}
        placeholder={textForm.emailPlaceholder}
        name="email"
        onChange={onChangeHandler}
        value={signUpForm.form.email}
        required
        errorMessage={signUpForm.errors.email}
      />

      <Responsive colsXs={2}>
        <InputPassword
          name="password"
          onChange={onChangeHandler}
          value={signUpForm.form.password}
          required
          errorMessage={signUpForm.errors.password}
        />
        <InputPassword
          label={textForm.confirmPasswordLabel}
          placeholder={textForm.confirmPasswordPlaceholder}
          name="confirmPassword"
          onChange={onChangeHandler}
          value={signUpForm.form.confirmPassword}
          required
          errorMessage={signUpForm.errors.confirmPassword}
        />
      </Responsive>

      <Responsive colsXs={2}>
        <Input
          label={textForm.companyLabel}
          placeholder={textForm.companyPlaceholder}
          name="companyName"
          onChange={onChangeHandler}
          value={signUpForm.form.companyName}
          required
          errorMessage={signUpForm.errors.companyName}
        />

        <Input
          label={textForm.phoneLabel}
          placeholder={textForm.phonePlaceholder}
          name="phoneNumber"
          onChange={onChangeHandler}
          value={signUpForm.form.phoneNumber}
          required
          errorMessage={signUpForm.errors.phoneNumber}
        />
      </Responsive>
      <Responsive colsXs={2}>
        <Input
          label={textForm.companyAddressLabel}
          placeholder={textForm.companyAddressPlaceholder}
          name="companyAddress"
          onChange={onChangeHandler}
          value={signUpForm.form.companyAddress}
          required
          errorMessage={signUpForm.errors.companyAddress}
        />
        <Input
          label={textForm.cityLabel}
          placeholder={textForm.cityPlaceholder}
          name="companyCity"
          onChange={onChangeHandler}
          value={signUpForm.form.companyCity}
          required
          errorMessage={signUpForm.errors.companyCity}
        />
      </Responsive>
      <Checkbox
        onRenderLabel={() => (
          <Typography type='p' variation='secondary' className={styles.p}>
            {texts.IAgree}
            {' '}<a href="https://dentops.ro/termeni-si-conditii" target="blank">{texts.termsAndConditions}</a>
          </Typography>
        )}
        onChange={onCheckboxChanged}
      />
      <Button size='medium' variant='contained' disabled={!signUpForm.termsAccepted} type="submit" className={styles.button}>{texts.btnTxt}</Button>
      <Typography type='p' variation='secondary' variation1='center'>
        {texts.haveAccount}{' '}
        <Link to='/login'>{texts.enterAccount}</Link>
      </Typography>
    </form>
  );
};

export default SignUpForm;