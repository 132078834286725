import { FC, useState } from "react";

import { Container } from "../../layout";
import {
  Button,
  Card,
  Delimiter,
  InputPassword,
  Portal,
  Toggle,
  Typography,
} from "../../toolkit";
import { toggleFeature, useAppDispatch, useAppSelector } from "../../store";
import { MyUserForm, UserPassword, useCompanyEdit } from "../../modules";
import AvatarProfile from "../ProfileAvatar/ProfileAvatar";
import styles from "./styles.module.css";

const Profile: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user!);
  const company = useAppSelector((state) => state.cache.company.company!);
  const features = useAppSelector((state) => state.appSettings.features);
  const { companyForm, onChange, onSubmit, onChangeRequirePassword } =
    useCompanyEdit(company);

  return (
    <Container vertical gap={24} maxWidth={560}>
      <Typography type="h5">Profil personal</Typography>
      <Card>
        <Container vertical gap={24}>
          <AvatarProfile
            text={user.firstName[0] + user.lastName[0]}
            label="Poza de Profil"
          />
          <MyUserForm user={user} onChangePassword={() => setIsOpen(true)} />
        </Container>
      </Card>
      <Typography type="h5">Functionalitati</Typography>
      <Card type="column" spacing={2}>
        {user?.isOwner && (
          <>
            <div className={styles.feature}>
              <div className={styles.description}>
                <Typography type="p" variation="bold">
                  Parola Statistici
                </Typography>
                <Typography type="span" variation="secondary">
                  Adauga o parola pentru accesarea sectiunii “Statistici”.
                </Typography>
              </div>
              <Toggle
                value={companyForm.form.requirePasswordForStatistics}
                onChange={(_, checked) => {
                  onChangeRequirePassword(checked);
                  setIsConfirmOpen(true);
                }}
              />
            </div>
            <Portal
              isOpen={isConfirmOpen}
              onDismiss={() => {
                setIsConfirmOpen(false);
                onChangeRequirePassword(company.requirePasswordForStatistics);
              }}
              title="Confirma parola pentru a salva modificarile"
              overlay={true}
              width={560}
            >
              <InputPassword
                name="password"
                onChange={onChange}
                value={companyForm.form.password}
                required
                errorMessage={companyForm.errors.password}
              />
              <Container gap={10} horizontalAlign="end">
                <Button
                  type="button"
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    setIsConfirmOpen(false);
                    onChangeRequirePassword(company.requirePasswordForStatistics);
                  }}
                >
                  Renunta
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  type="button"
                  onClick={() => {
                    onSubmit();
                    setIsConfirmOpen(false);
                  }}
                >
                  Confirma
                </Button>
              </Container>
            </Portal>
            <Delimiter />
          </>
        )}
        <div className={styles.feature}>
          <div className={styles.description}>
            <Typography type="p" variation="bold">
              Etape
            </Typography>
            <Typography type="span" variation="secondary">
              Etapele vor adauga posibilitatea de a urmari cazul sau chiar
              elementul creat de catre un tehnician.
            </Typography>
          </div>
          <Toggle
            value={features.stages}
            onChange={() => {
              dispatch(toggleFeature({ feature: "stages" }));
            }}
          />
        </div>
      </Card>
      <Portal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        title="Modifica Parola"
        overlay={true}
        width={560}
      >
        <UserPassword
          titanUserId={user.userIdentifier}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Portal>
    </Container>
  );
};

export default Profile;
