import { FC } from "react";
import { IColumn, TableProps, formatMoney } from "../../../lib";
import { TitanClientStatistic } from "../../../models";
import { TableList } from "../../../components";
import { UserAddIcon } from "../../../toolkit";

interface IDocument extends TitanClientStatistic {
  key: string;
}


type Props = {
  onOpenClientDetails: (statistic: TitanClientStatistic) => void
} & TableProps

const ClientStatisticsList: FC<Props> = (listProps) => {
  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Nume',
      fieldName: 'name',
      onRender: (item: IDocument) => (
          <>
            {item.firstName} {item.lastName}
          </>
      )
    },
    {
      key: 'totalCases',
      name: 'Total cazuri',
      fieldName: 'totalCases',
    },
    {
      key: 'paidPrice',
      name: 'Suma platita',
      fieldName: 'paidPrice',
      onRender: (item: IDocument) => (
        <>
          {formatMoney(item.paidPrice)}
        </>
      )
    },
    {
      key: 'totalPrice',
      name: 'Suma totala',
      fieldName: 'totalPrice',
      onRender: (item: IDocument) => (
        <>
          {formatMoney(item.totalPrice)}
        </>
      )
    }
  ];

  const items = listProps.results.map((item: TitanClientStatistic, index: number) => {
    return {
      key: item.titanClientId,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });


  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      onRowClick={listProps.onOpenClientDetails}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici un medic',
        emptyIcon: <UserAddIcon size={40} />,
        emptyTitle: 'Nu exista statistici in perioada selectata.',
      }}
    />
  )
}

export default ClientStatisticsList
