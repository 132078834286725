import { FC, useState } from 'react';
import MonthCalendarGrid from './MonthCalendarGrid/MonthCalendarGrid';
import { getDaysInMonth, getDefaultSingleState } from './utils';

type Props = {
  date: Date;
  onDateChanged: (date: Date) => void;
};

const DatePicker: FC<Props> = ({ date, onDateChanged }) => {
  const [dates, setDates] = useState<{
    startDate?: Date;
    endDate?: Date;
    refStartDate: Date;
    refEndDate: Date;
  }>(getDefaultSingleState(date));


  const onSelectDate = (date: Date) => {
    setDates(prevState => ({
      ...prevState,
      startDate: date,
      endDate: date
    }));
    onDateChanged(date);
  };

  const onMonthChanged = (month: number) => {
    setDates(prevState => ({
      ...prevState,
      refStartDate: new Date(prevState.refStartDate.getFullYear(), prevState.refStartDate.getMonth() + month, 1),
      refEndDate: new Date(prevState.refEndDate.getFullYear(), prevState.refEndDate.getMonth() + month, 1)
    }));
  };

  const days = getDaysInMonth(dates.refStartDate.getFullYear(), dates.refStartDate.getMonth(), dates.startDate, dates.endDate);

  return (
    <MonthCalendarGrid  refDate={dates.refEndDate} days={days} onDateChange={onSelectDate} onMonthChanged={onMonthChanged}/>
  );
};

export default DatePicker;