import { FC, ReactNode } from 'react';
import { AddIcon, AnimatedAppIcon, Button, ContainerFlex, Typography } from '../../toolkit';

export type Props = {
  emptyTitle: string;
  emptyIcon: ReactNode;
  emptySubTitle?: string;
  emptyAction?: () => void;
  emptyActionText?: string;
};

const TableEmptyState: FC<Props> = ({
  emptyTitle,
  emptySubTitle,
  emptyIcon,
  emptyAction,
  emptyActionText
}) => {
  return (
    <ContainerFlex type='column' align='center' justify='center' container={true} grow>
      <ContainerFlex type='column' justify='center' spacing={3}>
        <AnimatedAppIcon>
          {emptyIcon}
        </AnimatedAppIcon>
        <div>
          <Typography type='h5' variation="center">{emptyTitle}</Typography>
          <Typography type='p' variation="center">{emptySubTitle}</Typography>
        </div>
        {emptyAction && (
          <ContainerFlex type='row' justify='center'>
            <Button
            type='button'
              size='medium'
              variant='contained'
              onClick={emptyAction}>
              <AddIcon />{emptyActionText}
            </Button>
          </ContainerFlex>
        )}
      </ContainerFlex>
    </ContainerFlex >
  );
};

export default TableEmptyState;