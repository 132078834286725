import { FC, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  logout,
  setCacheExpiry,
  toggleMenu,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import {
  AddIcon,
  AlignLeftIcon,
  AnimatedAppIcon,
  Button,
  CasesIcon,
  ClientsIcon,
  ContainerFlex,
  DentOpsIcon,
  DentOpsLogo,
  HomeIcon,
  IconButton,
  LaboratoryIcon,
  LogoutIcon,
  Portal,
  SettingsIcon,
  StatisticsIcon,
  Typography,
} from "../../../toolkit";
import styles from "./styles.module.css";
import { MenuTranslation, textPage } from "../../../translations";
import { SubscriptionInfo } from "../../../components";
import Container from "../../Container/Container";
import { AuthenticatedUser } from "../../../models";
import { parseDate } from '../../../lib';

const texts = textPage("menu") as MenuTranslation;

interface ITitanMenuItem {
  url: string;
  name: string;
  icon: FC;
  isAuthorized: boolean;
}

type Props = {
  onNavClick?: () => void;
};

const getLinks = (user: AuthenticatedUser) => {
  const links: ITitanMenuItem[] = [
    {
      icon: HomeIcon,
      name: texts.home,
      url: "/dashboard",
      isAuthorized: true,
    },
    {
      icon: ClientsIcon,
      name: texts.clients,
      url: "/clients",
      isAuthorized: user.isManagerOrAbove,
    },
    {
      icon: CasesIcon,
      name: texts.cases,
      url: "/cases",
      isAuthorized: user.isTechnicianOrAbove,
    },
    {
      icon: LaboratoryIcon,
      name: texts.laboratory,
      url: "/configure",
      isAuthorized: user.isAdmin,
    },
    {
      icon: StatisticsIcon,
      name: texts.statistics,
      url: "/reports",
      isAuthorized: user.isAdmin,
    },
  ];

  return links.filter((l) => l.isAuthorized);
};

const Menu: FC<Props> = ({ onNavClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user!);
  const subscription = useAppSelector((state) => state.cache.subscription);

  const { collapsedMenu } = useAppSelector((state) => state.appSettings);

  const menuToggle = () => {
    dispatch(toggleMenu());
  };

  const signOut = () => {
    dispatch(logout());
    dispatch(setCacheExpiry({ expireAfterMinutes: -1 }));
    navigate("/login");
  };

  const hasSubsription = subscription.hasSubscription;

  const links = getLinks(user);

  return (
    <aside
      className={[styles.menu, collapsedMenu ? styles.collapsed : ""].join(" ")}
    >
      <div className={styles.companyName}>
        {!collapsedMenu ? (
          <>
            <DentOpsLogo />
            <IconButton variant="outlined" onClick={menuToggle}>
              <AlignLeftIcon />
            </IconButton>
          </>
        ) : (
          <DentOpsIcon />
        )}
      </div>

      <ul className={styles.menuList}>
        {links.map((item) => {
          const Icon = item.icon;
          return (
            <li key={item.url}>
              <NavLink
                to={item.url}
                className={[
                  styles.menuLink,
                  !hasSubsription ? styles.disabled : "",
                ].join(" ")}
                onClick={onNavClick}
              >
                <Icon />
                <span>{item.name}</span>
              </NavLink>
            </li>
          );
        })}
        {(user.isDataEntry || user.isManagerOrAbove) && (
          <li className={styles.addCase}>
            <NavLink
              to="/new-case"
              className={[
                styles.menuLinkButton,
                !hasSubsription ? styles.disabled : "",
              ].join(" ")}
              onClick={onNavClick}
            >
              <AddIcon />
              <span>{texts.addCase}</span>
            </NavLink>
          </li>
        )}

        <li>
          <NavLink
            to="/settings"
            className={styles.menuLink}
            onClick={onNavClick}
          >
            <SettingsIcon />
            <span>{texts.settings}</span>
          </NavLink>
        </li>
        <li>
          <button
            className={styles.buttonLink}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <LogoutIcon />
            <span>{texts.logout}</span>
          </button>
        </li>
      </ul>
      <div className={styles.menuBottom}>
        {collapsedMenu ? (
          <IconButton variant="outlined" onClick={menuToggle}>
            <AlignLeftIcon />
          </IconButton>
        ) : (
          <>
            {(subscription.isDemo || subscription.hasSubscription) && (
              <SubscriptionInfo
                isDemo={subscription.isDemo || false}
                subscriptionDate={parseDate(subscription.subscriptionDate!)}
                status={subscription.status || ''}
              />
            )}
          </>
        )}
      </div>
      <Portal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        title="Deconectare"
        overlay={true}
        width={480}
      >
        <ContainerFlex type="column" spacing={3}>
          <div></div>
          <AnimatedAppIcon>
            <LogoutIcon size={40} />
          </AnimatedAppIcon>
          <Typography type="p" variation="center">
            Ai ales sa fii deconectat. Continua?
          </Typography>
          <Container horizontalAlign="end" gap={20}>
            <Button
              type="button"
              size="medium"
              variant="text"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Renunta
            </Button>
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={signOut}
            >
              Continua
            </Button>
          </Container>
        </ContainerFlex>
      </Portal>
    </aside>
  );
};

export default Menu;
