import { FC } from "react";
import { AppTitle } from '../../layout';
import { LoginForm } from '../../modules';
import { Card, Typography } from '../../toolkit';
import { LoginPageTranslation, textPage } from '../../translations';
import styles from './styles.module.css';

const texts = textPage('login') as LoginPageTranslation;

const LoginPage: FC = () => {
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <div>
          <Typography type='h2' variation='center'>{texts.title}</Typography>
          <Typography type='p' variation='center' variation1='secondary'>{texts.subTitle}</Typography>
        </div>
        <LoginForm />
      </Card>
    </AppTitle>
  );
};

export default LoginPage;