import { FC } from "react";
import { Button, Card, Checkbox, CloseIcon, ContainerFlex, Delimiter, IconButton, Input, Toggle, TrashIcon, Typeahead, TypeaheadOption, Typography } from "../../../../../toolkit";
import { TitanCaseElement, TitanCaseToothElement } from "../../../../../models";
import styles from './styles.module.css';

const options: TypeaheadOption[] = [
  { key: 'Implant', name: 'Implant' },
  { key: 'Stalp natural', name: 'Stalp natural' },
  { key: 'Corp punte', name: 'Corp punte' },
  { key: 'Lipsa', name: 'Lipsa' }
];

export type Props = {
  selectedCaseElementId?: number;
  element: TitanCaseElement;
  setSelectedCaseElementId: (caseElementId: number) => void;
  onElementChange: (elementId: number, element: Partial<TitanCaseElement>) => void;
  onElementRemove: (elementId: number) => void;
  onElementTeethChange: (elementId: number, tooth: TitanCaseToothElement) => void;
  onElementTeethRemove: (elementId: number, code: string) => void;
};

const CaseToothCard: FC<Props> = ({ selectedCaseElementId, element, setSelectedCaseElementId, onElementChange, onElementRemove, onElementTeethChange, onElementTeethRemove }) => {
  return (
    <Card noPadding type='column' spacing={2} className={selectedCaseElementId === element.elementId ? styles.selected : undefined}>
      <ContainerFlex type='row' align='center' justify='space-between' style={{ padding: '16px 16px 0' }}>
        <Checkbox
          value={selectedCaseElementId === element.elementId}
          label={element.name}
          onChange={(_, checked) => {
            if (checked) {
              setSelectedCaseElementId(element.elementId);
            }
          }} />
        <IconButton onClick={() => onElementRemove(element.elementId)}>
          <CloseIcon />
        </IconButton>
      </ContainerFlex>
      <Delimiter />
      <ContainerFlex type='column' align='center' justify='space-between' style={{ padding: '0 16px' }} spacing={1}>
        {element.teeth?.length ? element.teeth.map((tooth) => (
          <ContainerFlex key={tooth.code} type="row" grow spacing={1} align="center">
            <ContainerFlex>
              <Button type='button' variant="contained" size="medium">
                {tooth.code}
              </Button>
            </ContainerFlex>
            <ContainerFlex grow>
              <Typeahead
                placeholder="Selecteaza un tip"
                selectedKey={tooth.toothType}
                options={options}
                noMargin
                onChange={(selectedKey) => {
                  onElementTeethChange(element.elementId, { ...tooth, toothType: selectedKey as string, });
                  setSelectedCaseElementId(element.elementId);
                }}
              />
            </ContainerFlex>
            <ContainerFlex>
              <IconButton onClick={() => onElementTeethRemove(element.elementId, tooth.code)}>
                <TrashIcon />
              </IconButton>
            </ContainerFlex>
          </ContainerFlex>
        )) : (
          <Typography type='p' variation="center">
            Selecteaza pozitia din schema (optional)
          </Typography>
        )}
      </ContainerFlex>
      <Delimiter />
      <ContainerFlex type='row' align='center' justify='space-between' style={{ padding: '0 16px 12px' }}>
        <Toggle value={element.countShouldBeOne} label='Alcatuiesc un element?' onChange={(_, checked) => {
          onElementChange(
            element.elementId,
            {
              countShouldBeOne: checked,
              count: checked ? 1 : (element.teeth?.length || 1)
            }
          );
          setSelectedCaseElementId(element.elementId);
        }} />
        <Input
          onChange={(e) => {
            const count = Number(e.target.value);
            if (!isNaN(count)) {
              onElementChange(
                element.elementId,
                {
                  count
                }
              );
              setSelectedCaseElementId(element.elementId);
            }
          }}
          disabled={element.countShouldBeOne}
          value={String(element.count || '')}
          required
          noMargin
          width={60}
        />
      </ContainerFlex>
    </Card>
  );
};

export default CaseToothCard;
