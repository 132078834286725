import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
  Content,
  ContentTable,
  TDocumentDefinitions,
} from 'pdfmake/interfaces';
import { TitanCompany } from '../../models';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const dentOpsSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2253 26.624C16.3562 27.3483 17.6208 28.0529 19.0299 28.7318C19.1527 28.7889 19.2725 28.8534 19.3932 28.9183C20.0373 29.2647 20.7042 29.6235 21.9662 28.9415C25.9729 26.7766 29.3112 10.9389 26.4763 3.87537C24.7598 -0.402004 17.728 0.517201 14.1018 2.31484C35.7076 13.7639 23.2237 22.0621 15.2253 26.624Z" fill="#27729B" />
      <path d="M0.571255 6.60229C0.851446 5.04602 1.46275 4.05361 1.92001 3.67422C4.24768 1.0581 8.51056 2.19496 13.3508 4.2742C39.2876 15.4157 18.6453 22.7328 15.2534 25.2711C15.1999 25.3111 11.9842 27.0853 8.12161 29.1515C5.59076 30.5055 4.28639 28.4324 4.25463 26.2274C4.03187 13.9473 -0.0874535 10.2552 0.571255 6.60229Z" fill="#3D9BCE" />
    </svg>`;

type ReportConfig = {
  header: {
    title: string;
    subTitle?: string;
  };
  branding: {
    logo: string;
    company: TitanCompany;
  };
  name: string;
};

const getReportHeading = (
  company: TitanCompany,
  hasBranding: boolean
): Content => {
  const brandingCol = {
    width: 50,
    fit: [50, 50],
    ...(hasBranding
      ? {
          image: 'logo',
        }
      : {
          svg: dentOpsSvg,
        }),
  };

  return {
    columns: [
      brandingCol,
      [
        {
          text: company.name,
          fontSize: 14,
          margin: [5, 10, 0, 0],
        },
        {
          text: `${company.address} ${company.city ? ',' : ''} ${company.city}`,
          fontSize: 8,
          margin: [5, 0, 0, 0],
        },
      ],
    ],
  } as Content;
};

const createTableDefinition = (
  contents: Content[],
  config: ReportConfig
): TDocumentDefinitions => {
  return {
    pageMargins: [50, 50, 50, 50],
    header: {
      columns: [
        {
          alignment: 'right',
          margin: [50, 10, 50, 10],
          color: '#656565',
          stack: [
            {
              text: config.header.title,
              fontSize: 12,
            },
            {
              text: config.header.subTitle || '',
              fontSize: 10,
            },
          ],
        },
      ],
    },
    content: [
      getReportHeading(config.branding.company, Boolean(config.branding.logo)),
      ...contents,
    ],
    background: function (currentPage: number) {
      if (currentPage > 1) {
        return;
      }
      return {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 595.28,
            h: 250,
            color: '#EFF3F6',
          },
        ],
      };
    },
    footer: {
      columns: [
        {
          absolutePosition: { x: 232, y: 0 },
          svg: dentOpsSvg,
        },
        [
          {
            absolutePosition: { x: 267, y: 0 },
            text: 'DentOps',
            fontSize: 12,
            margin: [5, 0, 0, 0],
          },
          {
            absolutePosition: { x: 267, y: 12 },
            text: 'Dental management solutions',
            fontSize: 8,
            margin: [5, 0, 0, 0],
          },
        ],
      ],
    },

    styles: {
      defaultStyle: {
        fontSize: 12,
        color: '#707070',
      },
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 10],
        color: '#656565',
      },
      section: {
        fontSize: 14,
        bold: true,
        alignment: 'left',
        margin: [0, 20, 0, 10],
        color: '#656565',
      },
      label: {
        fontSize: 5,
        color: '#707070',
        margin: [0, 0, 0, 2],
      },
    },
    images: {
      logo: config.branding.logo,
    },
  };
};

export const createReport = (contents: Content[], config: ReportConfig) => {
  const documentDefinition = createTableDefinition(contents, config);
  const tableLayouts = {
    tableLayout: {
      hLineWidth: function (i: number, node: ContentTable) {
        if (i === 0 || i >= node.table.body.length - 2) {
          return 0;
        }
        return i === node.table.headerRows ? 1 : 0.5;
      },
      vLineWidth: function (i: number) {
        return 0;
      },
      hLineColor: function (i: number) {
        return i === 1 ? 'black' : '#aaa';
      },
      paddingLeft: function (i: number) {
        return i === 0 ? 0 : 8;
      },
      paddingRight: function (i: number, node: ContentTable) {
        return i === (node.table.widths?.length || 1) - 1 ? 0 : 8;
      },
    },
    lightHorizontalLines: {
      hLineWidth: function (i: number, node: ContentTable) {
        if (i === 0 || i >= node.table.body.length) {
          return 0;
        }
        return i === node.table.headerRows ? 1 : 0.5;
      },
      vLineWidth: function (i: number) {
        return 0;
      },
      hLineColor: function (i: number) {
        return i === 1 ? 'black' : '#aaa';
      },
      paddingLeft: function (i: number) {
        return i === 0 ? 0 : 8;
      },
      paddingRight: function (i: number, node: ContentTable) {
        return i === (node.table.widths?.length || 1) - 1 ? 0 : 8;
      },
    },
  };

  const pdfName = `${config.name}.pdf`;
  pdfMake
    .createPdf(documentDefinition, tableLayouts)
    .download(pdfName.replace(/ /g, '_'));
};
