import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { AppTitle } from "../../layout";
import {
  Button,
  Card,
  Typography,
  AnimatedAppIcon,
  MailIcon,
} from "../../toolkit";
import { SignUpPageTranslation, textPage } from "../../translations";
import styles from "./styles.module.css";
import { useFacebookPixelTag } from "../../lib";

const texts = textPage("signUp") as SignUpPageTranslation;

const SignUpSuccessPage: FC = () => {
  const navigate = useNavigate();
  useFacebookPixelTag();
  return (
    <AppTitle title={texts.title}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <MailIcon />
        </AnimatedAppIcon>
        <Typography type="h2" variation="center">
          {texts.activateAccount}
        </Typography>
        <Typography type="p" variation="center" variation1="secondary">
          {texts.subTitleActivate}
        </Typography>
        <Button
          type="button"
          size="medium"
          variant="contained"
          className={styles.button}
          onClick={() => navigate("/")}
        >
          {texts.enterAccount}
        </Button>
      </Card>
    </AppTitle>
  );
};

export default SignUpSuccessPage;
