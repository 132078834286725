import { FC } from 'react';

const Tooth33: FC = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className='fundal' d="M4.49797 2.35902C1.88797 3.64202 1.48596 5.00501 1.48596 12.061C1.52596 21.282 2.40996 23.327 7.14796 24.73C10.28 25.652 15.54 25.692 17.186 24.81C20.078 23.247 23.169 18.556 23.651 14.948C24.254 10.618 18.592 5.526 10.039 2.68C6.38497 1.437 6.30497 1.43702 4.49797 2.35902Z" />
      <path className='interior' d="M9.659 21.123C9.588 21.143 9.47801 21.171 9.36901 21.169H9.36701C9.33001 21.169 9.15101 21.166 9.00201 21.023C8.78501 20.816 8.841 20.554 8.881 20.454C8.918 20.361 8.974 20.295 8.999 20.268C9.031 20.232 9.065 20.201 9.096 20.174C9.157 20.121 9.23301 20.064 9.31501 20.006C9.64301 19.775 10.224 19.424 10.968 19.003L10.977 18.998C12.426 18.216 14.639 16.637 15.892 15.465V15.464L15.898 15.459C16.543 14.875 17.148 14.342 17.596 13.95C17.817 13.756 18.001 13.596 18.132 13.483L18.136 13.479C18.188 13.433 18.229 13.398 18.259 13.372C18.313 13.325 18.334 13.307 18.33 13.311L18.476 13.165H18.683C18.781 13.165 18.928 13.195 19.048 13.319C19.16 13.434 19.184 13.566 19.189 13.635C19.198 13.752 19.164 13.846 19.155 13.869V13.871C19.13 13.938 19.093 14.002 19.067 14.046C19.008 14.146 18.919 14.277 18.815 14.425C18.603 14.724 18.297 15.131 17.954 15.574L17.949 15.581C17.024 16.735 15.627 17.94 14.138 18.941C12.654 19.939 11.038 20.762 9.659 21.123Z" />
      <path className='text' d="M30.144 34.528C30.624 34.528 30.976 34.432 31.2 34.24C31.432 34.04 31.548 33.788 31.548 33.484V33.4C31.548 33.056 31.436 32.788 31.212 32.596C30.996 32.396 30.696 32.296 30.312 32.296C29.944 32.296 29.616 32.384 29.328 32.56C29.048 32.736 28.804 32.984 28.596 33.304L27.588 32.392C27.732 32.2 27.888 32.02 28.056 31.852C28.232 31.676 28.432 31.528 28.656 31.408C28.88 31.28 29.132 31.18 29.412 31.108C29.692 31.036 30.008 31 30.36 31C30.784 31 31.168 31.052 31.512 31.156C31.864 31.252 32.164 31.396 32.412 31.588C32.668 31.78 32.864 32.012 33 32.284C33.136 32.548 33.204 32.848 33.204 33.184C33.204 33.456 33.16 33.7 33.072 33.916C32.992 34.132 32.876 34.32 32.724 34.48C32.58 34.64 32.408 34.772 32.208 34.876C32.016 34.972 31.812 35.044 31.596 35.092V35.164C31.836 35.212 32.064 35.288 32.28 35.392C32.496 35.496 32.68 35.636 32.832 35.812C32.992 35.98 33.116 36.18 33.204 36.412C33.3 36.644 33.348 36.908 33.348 37.204C33.348 37.572 33.272 37.908 33.12 38.212C32.976 38.516 32.768 38.776 32.496 38.992C32.224 39.208 31.9 39.376 31.524 39.496C31.148 39.608 30.728 39.664 30.264 39.664C29.856 39.664 29.496 39.62 29.184 39.532C28.872 39.452 28.596 39.34 28.356 39.196C28.124 39.052 27.92 38.888 27.744 38.704C27.568 38.512 27.416 38.312 27.288 38.104L28.452 37.216C28.548 37.384 28.652 37.54 28.764 37.684C28.876 37.82 29.004 37.94 29.148 38.044C29.292 38.148 29.456 38.228 29.64 38.284C29.824 38.34 30.032 38.368 30.264 38.368C30.728 38.368 31.084 38.256 31.332 38.032C31.58 37.808 31.704 37.5 31.704 37.108V37.024C31.704 36.64 31.568 36.348 31.296 36.148C31.032 35.948 30.648 35.848 30.144 35.848H29.316V34.528H30.144Z"  />
      <path className='text' d="M37.339 34.528C37.819 34.528 38.171 34.432 38.395 34.24C38.627 34.04 38.743 33.788 38.743 33.484V33.4C38.743 33.056 38.631 32.788 38.407 32.596C38.191 32.396 37.891 32.296 37.507 32.296C37.139 32.296 36.811 32.384 36.523 32.56C36.243 32.736 35.999 32.984 35.791 33.304L34.783 32.392C34.927 32.2 35.083 32.02 35.251 31.852C35.427 31.676 35.627 31.528 35.851 31.408C36.075 31.28 36.327 31.18 36.607 31.108C36.887 31.036 37.203 31 37.555 31C37.979 31 38.363 31.052 38.707 31.156C39.059 31.252 39.359 31.396 39.607 31.588C39.863 31.78 40.059 32.012 40.195 32.284C40.331 32.548 40.399 32.848 40.399 33.184C40.399 33.456 40.355 33.7 40.267 33.916C40.187 34.132 40.071 34.32 39.919 34.48C39.775 34.64 39.603 34.772 39.403 34.876C39.211 34.972 39.007 35.044 38.791 35.092V35.164C39.031 35.212 39.259 35.288 39.475 35.392C39.691 35.496 39.875 35.636 40.027 35.812C40.187 35.98 40.311 36.18 40.399 36.412C40.495 36.644 40.543 36.908 40.543 37.204C40.543 37.572 40.467 37.908 40.315 38.212C40.171 38.516 39.963 38.776 39.691 38.992C39.419 39.208 39.095 39.376 38.719 39.496C38.343 39.608 37.923 39.664 37.459 39.664C37.051 39.664 36.691 39.62 36.379 39.532C36.067 39.452 35.791 39.34 35.551 39.196C35.319 39.052 35.115 38.888 34.939 38.704C34.763 38.512 34.611 38.312 34.483 38.104L35.647 37.216C35.743 37.384 35.847 37.54 35.959 37.684C36.071 37.82 36.199 37.94 36.343 38.044C36.487 38.148 36.651 38.228 36.835 38.284C37.019 38.34 37.227 38.368 37.459 38.368C37.923 38.368 38.279 38.256 38.527 38.032C38.775 37.808 38.899 37.5 38.899 37.108V37.024C38.899 36.64 38.763 36.348 38.491 36.148C38.227 35.948 37.843 35.848 37.339 35.848H36.511V34.528H37.339Z"  />
    </svg>
  );
};

export default Tooth33;