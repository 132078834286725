import { FC } from 'react';
import { API_URLS, usePaginatedListV3 } from '../../../lib';
import PaymentList from '../PaymentList/PaymentList';
import { Portal } from '../../../toolkit';
import { TitanPayment } from '../../../models';
import PaymentCRUD from '../PaymentCRUD/PaymentCRUD';

type Props = {
  clientId?: number;
  clinicId?: number;
  isOpen: boolean;
  isDelete: boolean;
  payment?: TitanPayment;
  onEdit: (payment: TitanPayment) => void;
  onDelete: (payment: TitanPayment) => void;
  onReset: () => void;
  onAdd: () => void;
};

const getUrl = (clientId?: number, clinicId?: number) => {
  if (clientId) {
    return `${API_URLS.titan.clients.payments.list}?titanClientId=${clientId}`;
  }
  return `${API_URLS.titan.clients.payments.list}?clinicId=${clinicId}`;
};

const ClientPayments: FC<Props> = ({ clientId, clinicId, isOpen, isDelete, payment, onEdit, onDelete, onReset, onAdd }) => {
  const paymentListProps = usePaginatedListV3(getUrl(clientId, clinicId));

  return (
    <>
      <PaymentList {...paymentListProps} clientId={clientId} clinicId={clinicId} onEdit={onEdit} onDelete={onDelete} onAdd={onAdd} />
      <Portal
        isOpen={isOpen}
        onDismiss={onReset}
        title={isDelete ? 'Sterge plata' : payment ? 'Modifica plata' : "Adauga Plata"}
        overlay={true}
        width={560}
      >
        <PaymentCRUD
          clientId={clientId || -1}
          isDelete={isDelete}
          payment={payment}
          onClose={onReset}
          onRefresh={() => {
            paymentListProps.reload();
            onReset();
          }} />
      </Portal>
    </>
  );
};

export default ClientPayments;