import { FC } from 'react';
import { ElementsCRUD, StagesCRUD, UsersCRUD } from '../../modules';
import { PageLayout } from '../../layout';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from '../../toolkit';

const ConfigurePage: FC = () => {
  const { selected = '' } = useParams<{ selected?: string; }>();
  const navigate = useNavigate();

  const tabs = (
    <Tabs
      selected={selected}
      items={[
        { id: '', label: 'Elemente' },
        { id: 'users', label: 'Angajati' },
        { id: 'stages', label: 'Etape' },
      ]}
      onTabChanged={id => { navigate(`/configure/${id}`); }}
    />
  );

  return (
    <PageLayout title="Laborator" tabs={tabs}>
      {selected === '' && (
        <ElementsCRUD />
      )}
      {selected === 'users' && (
        <UsersCRUD />
      )}
      {selected === 'stages' && (
        <StagesCRUD />
      )}
    </PageLayout>
  );
};

export default ConfigurePage;