import { FC } from 'react';

const DentOpsLogo: FC = () => (
  <svg width="190" height="32" viewBox="0 0 388 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M102.752 69.5194V20.3494H118.79C123.982 20.3494 128.492 21.4054 132.32 23.5174C136.192 25.5854 139.184 28.4674 141.296 32.1634C143.408 35.8154 144.464 40.0614 144.464 44.9014C144.464 49.6974 143.408 53.9654 141.296 57.7054C139.184 61.4014 136.192 64.3054 132.32 66.4174C128.492 68.4854 123.982 69.5194 118.79 69.5194H102.752ZM111.728 61.5994H118.988C122.332 61.5994 125.214 60.9174 127.634 59.5534C130.098 58.1454 132.012 56.2094 133.376 53.7454C134.74 51.2374 135.422 48.2894 135.422 44.9014C135.422 41.4694 134.74 38.5214 133.376 36.0574C132.012 33.5934 130.098 31.6794 127.634 30.3154C125.214 28.9514 122.332 28.2694 118.988 28.2694H111.728V61.5994Z" fill="#0F2D3E" />
    <path d="M167.909 70.3114C164.213 70.3114 160.979 69.4754 158.207 67.8034C155.435 66.1314 153.279 63.8654 151.739 61.0054C150.199 58.1454 149.429 54.9774 149.429 51.5014C149.429 47.8934 150.199 44.7034 151.739 41.9314C153.323 39.1154 155.457 36.8934 158.141 35.2654C160.869 33.6374 163.905 32.8234 167.249 32.8234C170.065 32.8234 172.529 33.2854 174.641 34.2094C176.797 35.1334 178.623 36.4094 180.119 38.0374C181.615 39.6654 182.759 41.5354 183.551 43.6474C184.343 45.7154 184.739 47.9594 184.739 50.3794C184.739 50.9954 184.695 51.6334 184.607 52.2934C184.563 52.9534 184.453 53.5254 184.277 54.0094H156.557V47.4094H179.393L175.301 50.5114C175.697 48.4874 175.587 46.6834 174.971 45.0994C174.399 43.5154 173.431 42.2614 172.067 41.3374C170.747 40.4134 169.141 39.9514 167.249 39.9514C165.445 39.9514 163.839 40.4134 162.431 41.3374C161.023 42.2174 159.945 43.5374 159.197 45.2974C158.493 47.0134 158.229 49.1034 158.405 51.5674C158.229 53.7674 158.515 55.7254 159.263 57.4414C160.055 59.1134 161.199 60.4114 162.695 61.3354C164.235 62.2594 165.995 62.7214 167.975 62.7214C169.955 62.7214 171.627 62.3034 172.991 61.4674C174.399 60.6314 175.499 59.5094 176.291 58.1014L183.287 61.5334C182.583 63.2494 181.483 64.7674 179.987 66.0874C178.491 67.4074 176.709 68.4414 174.641 69.1894C172.617 69.9374 170.373 70.3114 167.909 70.3114Z" fill="#0F2D3E" />
    <path d="M191.294 69.5194V33.6154H199.412V40.6774L198.752 39.4234C199.588 37.2674 200.952 35.6394 202.844 34.5394C204.78 33.3954 207.024 32.8234 209.576 32.8234C212.216 32.8234 214.548 33.3954 216.572 34.5394C218.64 35.6834 220.246 37.2894 221.39 39.3574C222.534 41.3814 223.106 43.7354 223.106 46.4194V69.5194H214.46V48.4654C214.46 46.8814 214.152 45.5174 213.536 44.3734C212.92 43.2294 212.062 42.3494 210.962 41.7334C209.906 41.0734 208.652 40.7434 207.2 40.7434C205.792 40.7434 204.538 41.0734 203.438 41.7334C202.338 42.3494 201.48 43.2294 200.864 44.3734C200.248 45.5174 199.94 46.8814 199.94 48.4654V69.5194H191.294Z" fill="#0F2D3E" />
    <path d="M246.785 69.9154C242.737 69.9154 239.591 68.8154 237.347 66.6154C235.147 64.3714 234.047 61.2254 234.047 57.1774V41.3374H227.843V33.6154H228.503C230.263 33.6154 231.627 33.1534 232.595 32.2294C233.563 31.3054 234.047 29.9634 234.047 28.2034V25.4314H242.693V33.6154H250.943V41.3374H242.693V56.7154C242.693 57.9034 242.891 58.9154 243.287 59.7514C243.727 60.5874 244.387 61.2254 245.267 61.6654C246.191 62.1054 247.357 62.3254 248.765 62.3254C249.073 62.3254 249.425 62.3034 249.821 62.2594C250.261 62.2154 250.679 62.1714 251.075 62.1274V69.5194C250.459 69.6074 249.755 69.6954 248.963 69.7834C248.171 69.8714 247.445 69.9154 246.785 69.9154Z" fill="#0F2D3E" />
    <path d="M282.628 70.3114C279.02 70.3114 275.654 69.6734 272.53 68.3974C269.406 67.1214 266.678 65.3394 264.346 63.0514C262.014 60.7634 260.188 58.0794 258.868 54.9994C257.592 51.8754 256.954 48.5094 256.954 44.9014C256.954 41.2494 257.592 37.8834 258.868 34.8034C260.144 31.7234 261.948 29.0394 264.28 26.7514C266.612 24.4634 269.34 22.7034 272.464 21.4714C275.588 20.1954 278.976 19.5574 282.628 19.5574C286.28 19.5574 289.668 20.1954 292.792 21.4714C295.916 22.7474 298.644 24.5294 300.976 26.8174C303.308 29.0614 305.112 31.7234 306.388 34.8034C307.708 37.8834 308.368 41.2494 308.368 44.9014C308.368 48.5094 307.708 51.8754 306.388 54.9994C305.068 58.0794 303.242 60.7634 300.91 63.0514C298.578 65.3394 295.85 67.1214 292.726 68.3974C289.646 69.6734 286.28 70.3114 282.628 70.3114ZM282.628 62.1274C285.048 62.1274 287.27 61.7094 289.294 60.8734C291.318 59.9934 293.078 58.7834 294.574 57.2434C296.114 55.7034 297.28 53.8774 298.072 51.7654C298.908 49.6534 299.326 47.3654 299.326 44.9014C299.326 42.4374 298.908 40.1714 298.072 38.1034C297.28 35.9914 296.114 34.1654 294.574 32.6254C293.078 31.0414 291.318 29.8314 289.294 28.9954C287.27 28.1594 285.048 27.7414 282.628 27.7414C280.252 27.7414 278.052 28.1594 276.028 28.9954C274.004 29.8314 272.222 31.0414 270.682 32.6254C269.186 34.1654 268.02 35.9914 267.184 38.1034C266.348 40.1714 265.93 42.4374 265.93 44.9014C265.93 47.3654 266.348 49.6534 267.184 51.7654C268.02 53.8774 269.186 55.7034 270.682 57.2434C272.222 58.7834 274.004 59.9934 276.028 60.8734C278.052 61.7094 280.252 62.1274 282.628 62.1274Z" fill="#0F2D3E" />
    <path d="M315.624 82.7194V33.6154H323.742V40.7434L322.95 38.9614C324.182 37.0254 325.854 35.5294 327.966 34.4734C330.078 33.3734 332.52 32.8234 335.292 32.8234C338.68 32.8234 341.738 33.6594 344.466 35.3314C347.194 37.0034 349.35 39.2474 350.934 42.0634C352.562 44.8794 353.376 48.0474 353.376 51.5674C353.376 55.0434 352.584 58.2114 351 61.0714C349.416 63.9314 347.26 66.1974 344.532 67.8694C341.804 69.4974 338.702 70.3114 335.226 70.3114C332.63 70.3114 330.232 69.8054 328.032 68.7934C325.876 67.7374 324.16 66.2414 322.884 64.3054L324.27 62.5894V82.7194H315.624ZM334.236 62.3914C336.216 62.3914 337.976 61.9294 339.516 61.0054C341.056 60.0814 342.244 58.8054 343.08 57.1774C343.96 55.5494 344.4 53.6794 344.4 51.5674C344.4 49.4554 343.96 47.6074 343.08 46.0234C342.244 44.3954 341.056 43.1194 339.516 42.1954C337.976 41.2274 336.216 40.7434 334.236 40.7434C332.344 40.7434 330.628 41.2054 329.088 42.1294C327.592 43.0534 326.404 44.3514 325.524 46.0234C324.688 47.6514 324.27 49.4994 324.27 51.5674C324.27 53.6794 324.688 55.5494 325.524 57.1774C326.404 58.8054 327.592 60.0814 329.088 61.0054C330.628 61.9294 332.344 62.3914 334.236 62.3914Z" fill="#0F2D3E" />
    <path d="M373.629 70.3114C369.801 70.3114 366.457 69.4094 363.597 67.6054C360.781 65.7574 358.845 63.2714 357.789 60.1474L364.257 57.0454C365.181 59.0694 366.457 60.6534 368.085 61.7974C369.757 62.9414 371.605 63.5134 373.629 63.5134C375.213 63.5134 376.467 63.1614 377.391 62.4574C378.315 61.7534 378.777 60.8294 378.777 59.6854C378.777 58.9814 378.579 58.4094 378.183 57.9694C377.831 57.4854 377.325 57.0894 376.665 56.7814C376.049 56.4294 375.367 56.1434 374.619 55.9234L368.745 54.2734C365.709 53.3934 363.399 52.0514 361.815 50.2474C360.275 48.4434 359.505 46.3094 359.505 43.8454C359.505 41.6454 360.055 39.7314 361.155 38.1034C362.299 36.4314 363.861 35.1334 365.841 34.2094C367.865 33.2854 370.175 32.8234 372.771 32.8234C376.159 32.8234 379.151 33.6374 381.747 35.2654C384.343 36.8934 386.191 39.1814 387.291 42.1294L380.691 45.2314C380.075 43.6034 379.041 42.3054 377.589 41.3374C376.137 40.3694 374.509 39.8854 372.705 39.8854C371.253 39.8854 370.109 40.2154 369.273 40.8754C368.437 41.5354 368.019 42.3934 368.019 43.4494C368.019 44.1094 368.195 44.6814 368.547 45.1654C368.899 45.6494 369.383 46.0454 369.999 46.3534C370.659 46.6614 371.407 46.9474 372.243 47.2114L377.985 48.9274C380.933 49.8074 383.199 51.1274 384.783 52.8874C386.411 54.6474 387.225 56.8034 387.225 59.3554C387.225 61.5114 386.653 63.4254 385.509 65.0974C384.365 66.7254 382.781 68.0014 380.757 68.9254C378.733 69.8494 376.357 70.3114 373.629 70.3114Z" fill="#0F2D3E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M45.3617 79.5188C48.8457 81.75 52.7413 83.9207 57.082 86.012C57.4603 86.1879 57.8294 86.3865 58.2011 86.5864C60.1851 87.6536 62.2397 88.7588 66.1274 86.658C78.4699 79.9888 88.7538 31.2005 80.0207 9.44125C74.733 -3.73529 53.0716 -0.903657 41.9009 4.63401C108.458 39.9031 70.001 65.4659 45.3617 79.5188Z" fill="#27729B" />
    <path d="M0.219503 17.8419C1.08263 13.0478 2.96576 9.99069 4.37438 8.82198C11.5448 0.762951 24.6767 4.26507 39.5871 10.6702C119.486 44.9918 55.8969 67.5324 45.4482 75.3515C45.2833 75.4748 35.3774 80.9404 23.4785 87.3053C15.6822 91.4762 11.664 85.0899 11.5662 78.2974C10.88 40.4684 -1.80966 29.0948 0.219503 17.8419Z" fill="#3D9BCE" />
  </svg>
);

export default DentOpsLogo;