import { FC } from "react";
import { useParams } from 'react-router-dom';
import { Container, PageLayout } from '../../../layout';
import { Button, ContainerFlex, Stepper } from "../../../toolkit";
import { CaseEdit, useCaseCreate } from "../../../modules";

const NewCasePage: FC = () => {
  const params = useParams<{ clientId: string; }>();
  const clientId = params.clientId ? +params.clientId : undefined;
  const caseCreateOptions = useCaseCreate(clientId);
  return (
    <PageLayout title="Caz Nou" tabs={(
      <ContainerFlex type="row" justify="space-between" grow>
        <div></div>
        <Stepper
          selected={caseCreateOptions.step}
          items={[
            {
              id: 1,
              label: 'Adauga elemente',
            },
            {
              id: 2,
              label: 'Detalii caz',
            }
          ]}
          onStepChanged={id => caseCreateOptions.onStepChanged(id as number)}
        />
        <ContainerFlex type="row" spacing={2}>
          {caseCreateOptions.step === 1 && (
            <>
              <Button type='button' size="small" variant='outlined' onClick={() => caseCreateOptions.onSubmit(caseCreateOptions.caseForm.form)}>Finalizeaza</Button>
              <Button type='button' size="small" variant='contained' onClick={() => caseCreateOptions.onStepChanged(2)}>Continua</Button>
            </>
          )}
          {caseCreateOptions.step === 2 && (
            <>
              <Button type='button' size="small" variant='outlined' onClick={() => caseCreateOptions.onStepChanged(1)}>Inapoi</Button>
              <Button type='button' size="small" variant='contained' onClick={() => caseCreateOptions.onSubmit(caseCreateOptions.caseForm.form)}>Finalizeaza</Button>

            </>
          )}
        </ContainerFlex>
      </ContainerFlex>
    )}>
      <Container vertical gap={16}>
        <CaseEdit {...caseCreateOptions} />
      </Container>
    </PageLayout >
  );
};

export default NewCasePage;