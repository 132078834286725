import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TitanClient,
  TitanClinic,
  TitanCompany,
  TitanElement,
  TitanStage,
  TitanSubscriptionDetails,
  TitanUser,
  TitanUserRole,
} from '../models';
import { DENTOPS_ROLES, parseDate } from '../lib';

const MINUTE = 60000;
const DEFAULT_CACHE_MINUTES = 10;
const MAKE_INACTIVE_AFTER = 30 * MINUTE;

interface CacheBase {
  loading: boolean;
  expireTime?: number;
  lastLoadDate?: string;
}

export interface ListCache<T> extends CacheBase {
  data?: T[];
}

export interface SubscriptionCache extends CacheBase {
  subscription?: TitanSubscriptionDetails;
  hasSubscription?: boolean;
  isDemo?: boolean;
  subscriptionDate?: string;
  status?: string;
  loaded: boolean;
}

export interface CompanyCache extends CacheBase {
  company?: TitanCompany;
}

interface CacheState {
  clients: ListCache<TitanClient>;
  elements: ListCache<TitanElement>;
  stages: ListCache<TitanStage>;
  users: ListCache<TitanUser>;
  roles: ListCache<TitanUserRole>;
  technicians: ListCache<TitanUser>;
  clinics: ListCache<TitanClinic>;
  subscription: SubscriptionCache;
  company: CompanyCache;
  expireAfterMiliseconds?: number;
  inactiveAfter: number;
}

const initialState: CacheState = {
  clients: {
    loading: false,
  },
  elements: {
    loading: false,
  },
  stages: {
    loading: false,
  },
  users: {
    loading: false,
  },
  roles: {
    loading: false,
  },
  technicians: {
    loading: false,
  },
  clinics: {
    loading: false,
  },
  subscription: {
    loading: false,
    loaded: false,
  },
  company: {
    loading: false,
  },
  inactiveAfter: 0,
};

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: { ...initialState },
  reducers: {
    setCacheExpiry: (
      state,
      action: PayloadAction<{ expireAfterMinutes?: number } | undefined>
    ) => {
      if (action.payload?.expireAfterMinutes === -1) {
        return { ...initialState };
      } else {
        state.expireAfterMiliseconds =
          (action.payload?.expireAfterMinutes || DEFAULT_CACHE_MINUTES) *
          MINUTE;
        state.inactiveAfter = Date.now() + MAKE_INACTIVE_AFTER;
      }
      // console.log('inavtivity update', new Date(state.inactiveAfter));
    },
    setLoadClients: (state) => {
      state.clients.loading = true;
    },
    setClientsData: (state, action: PayloadAction<any[]>) => {
      const now = new Date();
      state.clients.loading = false;
      state.clients.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.clients.expireTime = now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.clients.expireTime = undefined;
      }
      state.clients.data = action.payload;
    },
    setRefreshClients: (state) => {
      state.clients.expireTime = Date.now();
    },
    setLoadClinics: (state) => {
      state.clinics.loading = true;
    },
    setClinicsData: (state, action: PayloadAction<any[]>) => {
      const now = new Date();
      state.clinics.loading = false;
      state.clinics.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.clinics.expireTime = now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.clinics.expireTime = undefined;
      }
      state.clinics.data = action.payload;
    },
    setRefreshClinics: (state) => {
      state.clinics.expireTime = Date.now();
    },
    setLoadElements: (state) => {
      state.elements.loading = true;
    },
    setElementsData: (state, action: PayloadAction<any[]>) => {
      const now = new Date();
      state.elements.loading = false;
      state.elements.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.elements.expireTime =
          now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.elements.expireTime = undefined;
      }
      state.elements.data = action.payload;
    },
    setRefreshElements: (state) => {
      state.elements.expireTime = Date.now();
    },
    setLoadStages: (state) => {
      state.stages.loading = true;
    },
    setStagesData: (state, action: PayloadAction<any[]>) => {
      const now = new Date();
      state.stages.loading = false;
      state.stages.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.stages.expireTime = now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.stages.expireTime = undefined;
      }
      state.stages.data = action.payload;
    },
    setRefreshStages: (state) => {
      state.stages.expireTime = Date.now();
    },
    setLoadUsers: (state) => {
      state.users.loading = true;
    },
    setUsersData: (state, action: PayloadAction<TitanUser[]>) => {
      const now = new Date();
      state.users.loading = false;
      state.users.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.users.expireTime = now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.users.expireTime = undefined;
      }
      state.users.data = action.payload;
      state.technicians.data = action.payload.filter((u) =>
        u.roles?.some((r) => r.name === DENTOPS_ROLES.TECHNICIAN)
      );
    },
    setRefreshUsers: (state) => {
      state.users.expireTime = Date.now();
    },
    setLoadRoles: (state) => {
      state.roles.loading = true;
    },
    setRolesData: (state, action: PayloadAction<any[]>) => {
      const now = new Date();
      state.roles.loading = false;
      state.roles.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.roles.expireTime = now.getTime() + state.expireAfterMiliseconds;
        // console.log('cache refresh', new Date(state.clients.expireTime));
      } else {
        state.roles.expireTime = undefined;
      }
      state.roles.data = action.payload;
    },
    setRefreshRoles: (state) => {
      state.roles.expireTime = Date.now();
    },
    setLoadSubscription: (state) => {
      state.subscription.loading = true;
    },
    setSubscriptionData: (
      state,
      action: PayloadAction<TitanSubscriptionDetails | undefined>
    ) => {
      const now = new Date();
      state.subscription.loading = false;
      state.subscription.loaded = true;
      state.subscription.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.subscription.expireTime =
          now.getTime() + state.expireAfterMiliseconds;
      } else {
        state.subscription.expireTime = undefined;
      }

      if (action.payload) {
        const cancelAtPeriodEnd =
          action.payload.cancelAtPeriodEnd ||
          (action.payload.status === 'canceled' &&
            parseDate(action.payload.currentPeriodEnd).getTime() > Date.now());

        state.subscription.subscription = {
          ...action.payload,
          cancelAtPeriodEnd,
        };
        state.subscription.hasSubscription =
          ['trialing', 'active'].includes(action.payload.status) ||
          (action.payload.status === 'canceled' &&
            parseDate(action.payload.currentPeriodEnd).getTime() > Date.now());
        state.subscription.isDemo =
          'trialing' === action.payload.status.toLowerCase() &&
          !action.payload.subscriptionId;
        state.subscription.subscriptionDate = action.payload.currentPeriodEnd;
        state.subscription.status = action.payload.status;
      } else {
        state.subscription.hasSubscription = false;
        state.subscription.isDemo = false;
      }
    },
    setRefreshSubscription: (state) => {
      state.subscription.expireTime = Date.now();
    },
    setLoadCompany: (state) => {
      state.company.loading = true;
    },
    setCompanyData: (state, action: PayloadAction<TitanCompany>) => {
      const now = new Date();
      state.company.loading = false;
      state.company.lastLoadDate = now.toISOString();
      if (state.inactiveAfter < now.getTime()) {
        state.expireAfterMiliseconds = undefined;
      }

      if (state.expireAfterMiliseconds) {
        state.company.expireTime = now.getTime() + state.expireAfterMiliseconds;
      } else {
        state.company.expireTime = undefined;
      }

      state.company.company = action.payload;
    },
    setRefreshCompany: (state) => {
      state.company.expireTime = Date.now();
    },
  },
});

export const {
  setCacheExpiry,
  setLoadClients,
  setClientsData,
  setRefreshClients,
  setLoadClinics,
  setClinicsData,
  setRefreshClinics,
  setLoadElements,
  setElementsData,
  setRefreshElements,
  setLoadStages,
  setStagesData,
  setRefreshStages,
  setLoadUsers,
  setUsersData,
  setRefreshUsers,
  setLoadRoles,
  setRolesData,
  setRefreshRoles,
  setLoadSubscription,
  setSubscriptionData,
  setRefreshSubscription,
  setLoadCompany,
  setCompanyData,
  setRefreshCompany,
} = cacheSlice.actions;

export default cacheSlice.reducer;
