import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedUser, TitanToken, TitanUser } from '../models';
import { APP_LOCAL_STORAGE_KEYS, APP_LOCAL_STORAGE_LEGACY_KEYS } from './constants';
import { DENTOPS_ROLES } from '../lib';

export interface AppAuthState {
  token?: TitanToken;
  user?: AuthenticatedUser;
}

export type LoginPayload = {
  token: TitanToken;
  user: TitanUser;
};

export type RefreshTokenPayload = {
  token: TitanToken;
  refreshToken: string;
};
export const initialState: AppAuthState = {};

export const getUserWithRoleProps = (user: TitanUser): AuthenticatedUser => {
  const isOwner = user?.roles?.some(r => r.name === DENTOPS_ROLES.OWNER);
  const isAdmin = isOwner || user?.roles?.some(r => r.name === DENTOPS_ROLES.ADMIN);
  const isManagerOrAbove = isAdmin || user?.roles?.some(r => r.name === DENTOPS_ROLES.MANAGER);
  const isTechnicianOrAbove = isManagerOrAbove || user?.roles?.some(r => r.name === DENTOPS_ROLES.TECHNICIAN);
  const isDataEntry = user?.roles?.some(r => r.name === DENTOPS_ROLES.DATAENTRY);
  const isDataEntryOrAbove = isTechnicianOrAbove || isDataEntry;
  return {
    ...user,
    isOwner,
    isAdmin,
    isManagerOrAbove,
    isTechnicianOrAbove,
    isDataEntryOrAbove,
    isDataEntry
  };
};

const setAuth = (auth: AppAuthState) => {
  // TODO: Move to middleware
  // setTimeout(() => {
  if (!auth.token) {
    localStorage.removeItem(APP_LOCAL_STORAGE_LEGACY_KEYS.JWT);
  }
  if (!auth.user) {
    localStorage.removeItem(APP_LOCAL_STORAGE_LEGACY_KEYS.USER);
  }

  if (auth.token && auth.user) {
    localStorage.setItem(APP_LOCAL_STORAGE_KEYS.AUTH, JSON.stringify(auth));
  } else {
    localStorage.removeItem(APP_LOCAL_STORAGE_KEYS.AUTH);
  }

  // }, 0);
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onTokenRefreshSuccess: (state, action: PayloadAction<RefreshTokenPayload>) => {
      const { token, refreshToken } = action.payload;
      state.token = token;
      state.user = state.user ? { ...state.user, refreshToken } : undefined;
      setAuth(state);
    },
    onTokenRefreshError: (state) => {
      state.user = undefined;
      state.token = undefined;
      setAuth(state);
    },
    updateUser: (state, action: PayloadAction<TitanUser>) => {
      state.user = getUserWithRoleProps(action.payload);
      setAuth(state);
    },
    login: (state, action: PayloadAction<LoginPayload>) => {
      const { token, user } = action.payload;
      state.token = { ...token };
      state.user = getUserWithRoleProps(user);
      setAuth(state);
    },
    logout: (state) => {
      state.user = undefined;
      state.token = undefined;
      setAuth(state);
    },
  }
});

export const {
  onTokenRefreshSuccess,
  onTokenRefreshError,
  updateUser,
  login,
  logout
} = authSlice.actions;

export default authSlice.reducer;

