import React, { useCallback, useEffect, useState } from 'react';
import { TitanClientReport, TitanTenderCase } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import {
  API_URLS,
  DEFAULT_MONTH_START,
  DEFAULT_TODAY_END,
  generateMedicReport,
} from '../../lib';
import { useAppSelector } from '../../store';

const useClientReport = (titanClientId: number, titanClientName: string) => {
  const [reportSettings, setReportSettings] = useState<{
    startDate: Date;
    endDate: Date;
    includePaidCases: boolean;
    includeUnfinishedCases: boolean;
  }>({
    startDate: DEFAULT_MONTH_START,
    endDate: DEFAULT_TODAY_END,
    includePaidCases: false,
    includeUnfinishedCases: false,
  });
  const [clientReport, setClientReport] = useState<{
    total: number;
    amountLeft: number;
    totalCases: number;
    totalElements: number;
  }>();
  const [tenderConfig, setTenderConfig] = useState<{
    caseTenderList?: TitanTenderCase[];
    selectedCases: number[];
    report?: TitanClientReport;
  }>({
    selectedCases: [],
  });
  const company = useAppSelector((state) => state.cache.company.company);

  const { getJson, postJson, getRequest, loadingStart, loadingEnd, notify } =
    useAppStoreHooks();

  const loadSummary = useCallback(
    async (startDate: Date, endDate: Date) => {
      const sDate = startDate.toISOString();
      const eDate = endDate.toISOString();
      loadingStart();
      try {
        const summary = await getJson<{
          total: number;
          amountLeft: number;
          totalCases: number;
          totalElements: number;
        }>(
          `${API_URLS.titan.clients.summary}?titanClientId=${titanClientId}&from=${sDate}&to=${eDate}`
        );
        loadingEnd();
        setClientReport(summary);
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, 'error');
      }
    },
    [loadingStart, getJson, loadingEnd, setClientReport, notify, titanClientId]
  );

  const onDatesSelected = (startDate: Date, endDate: Date) => {
    setReportSettings((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
    }));
    loadSummary(startDate, endDate);
  };

  const onIncludePaidCasesChanged = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setReportSettings((prevState) => ({
      ...prevState,
      includePaidCases: checked || false,
    }));
  };

  const onIncludeUnfinishedCasesChanged = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setReportSettings((prevState) => ({
      ...prevState,
      includeUnfinishedCases: checked || false,
    }));
  };

  const generateReport = async () => {
    loadingStart();
    const sDate = reportSettings.startDate.toISOString();
    const eDate = reportSettings.endDate.toISOString();
    try {
      // const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      // const logo = await response?.text();
      const report = await postJson<TitanClientReport>(
        API_URLS.titan.tender.tender,
        {
          titanClientId,
          from: sDate,
          to: eDate,
          includePaidCases: reportSettings.includePaidCases,
          includeUnfinishedCases: reportSettings.includeUnfinishedCases,
          titanCaseIds: tenderConfig.selectedCases,
        }
      );
      setTenderConfig((prevState) => ({
        ...prevState,
        report,
      }));
      // generateMedicReport(
      //   {
      //     ...report,
      //     caseReports: report.tenderCases,
      //     logoUrl: logo || "",
      //     name: titanClientName || "",
      //   },
      //   company!,
      //   'both'
      // );
      // onDismissTender();
      loadingEnd();
      notify('Raportul a fost generat cu success', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error');
    }
  };

  const onDownload = async (type: 'elements' | 'cases' | 'both') => {
    loadingStart();
    try {
      const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      const logo = await response?.text();
      generateMedicReport(
        {
          ...tenderConfig.report!,
          caseReports: tenderConfig.report!.tenderCases,
          logoUrl: logo || '',
          name: titanClientName || '',
        },
        company!,
        type
      );
      onDismissTender();
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error');
    }
  };

  const onGenerateTender = async () => {
    const sDate = reportSettings.startDate.toISOString();
    const eDate = reportSettings.endDate.toISOString();
    loadingStart();
    try {
      const report = await postJson<TitanTenderCase[]>(
        API_URLS.titan.tender.cases,
        {
          titanClientId,
          from: sDate,
          to: eDate,
          includePaidCases: reportSettings.includePaidCases,
          includeUnfinishedCases: reportSettings.includeUnfinishedCases,
        }
      );
      loadingEnd();
      setTenderConfig((prevState) => ({
        ...prevState,
        caseTenderList: report,
      }));
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error');
    }
  };

  const onDismissTender = () => {
    setTenderConfig((prevState) => ({
      ...prevState,
      caseTenderList: undefined,
      selectedCases: [],
      report: undefined,
    }));
  };

  const toggleSelectedCase = (titanCaseId: number) => {
    setTenderConfig((prevState) => {
      const selectedCases = prevState.selectedCases.includes(titanCaseId)
        ? prevState.selectedCases.filter((c) => c !== titanCaseId)
        : [...prevState.selectedCases, titanCaseId];
      return {
        ...prevState,
        selectedCases,
      };
    });
  };

  const onSelectAllCasesChanged = (checked?: boolean) => {
    if (checked) {
      setTenderConfig((prevState) => ({
        ...prevState,
        selectedCases: (prevState.caseTenderList || []).map(
          (c) => c.titanCaseId
        ),
      }));
    } else {
      setTenderConfig((prevState) => ({
        ...prevState,
        selectedCases: [],
      }));
    }
  };

  useEffect(() => {
    loadSummary(DEFAULT_MONTH_START, DEFAULT_TODAY_END);
  }, [loadSummary]);

  return {
    reportSettings,
    clientReport,
    tenderConfig,
    onDatesSelected,
    onIncludePaidCasesChanged,
    onIncludeUnfinishedCasesChanged,
    onGenerateTender,
    generateReport,
    onDismissTender,
    toggleSelectedCase,
    onSelectAllCasesChanged,
    onDownload
  };
};

export default useClientReport;
