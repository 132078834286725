import { useCallback, useMemo } from 'react';
import { onTokenRefreshError, onTokenRefreshSuccess, setCacheExpiry, showToast, useAppDispatch, useAppSelector } from '../store';
import { setLoadingEnd, setLoadingStart } from '../store/loadingReducer';
import fetchWithToken from '../lib/base/fetchWithToken';
import { TitanToken } from '../models';
import fetchJson from '../lib/base/fetchJson';
import fetchFormData from '../lib/base/fetchFormData';

export const useAppStoreHooks = () => {
  const dispatch = useAppDispatch();
  const { token, user } = useAppSelector(state => state.auth);

  const context = useMemo(() => ({
    token,
    user,
    onTokenRefreshSuccess: (token: TitanToken, refreshToken: string) => dispatch(onTokenRefreshSuccess({ token, refreshToken })),
    onTokenRefreshError: () => {
      dispatch(onTokenRefreshError())
      dispatch(setCacheExpiry({ expireAfterMinutes: -1 }))
    }
  }), [dispatch, token, user]);

  const deleteRequest = useCallback(
    (url: string) => fetchWithToken(context, url, "DELETE", null),
    [context]
  );

  const getJson = useCallback(
    <T>(url: string) => {
      return fetchJson<T>(context, url, "GET");
    },
    [context]
  );

  const getRequest = useCallback(
    (url: string) => fetchWithToken(context, url, "GET", null),
    [context]
  );

  const postRequest = useCallback(
    (url: string, payload: any) => fetchWithToken(context, url, "POST", payload, 'application/json'),
    [context]
  );

  const postFormData = useCallback(
    <T>(url: string, payload: any) =>
      fetchFormData<T>(context, url, "POST", payload),
    [context]
  );

  const postJson = useCallback(
    <T>(url: string, payload: any) =>
      fetchJson<T>(context, url, "POST", payload),
    [context]
  );

  const putJson = useCallback(<T>(url: string, payload: any) => fetchJson<T>(context, url, 'PUT', payload), [context]);

  const notify = useCallback((message: string, type: 'error' | 'success' | 'warning', details?: string) => {
    dispatch(showToast({ message, type, details }));
  }, [dispatch]);

  const loadingStart = useCallback(() => {
    dispatch(setLoadingStart());
  }, [dispatch]);


  const loadingEnd = useCallback(() => {
    dispatch(setLoadingEnd());
  }, [dispatch]);

  return {
    dispatch,
    deleteRequest,
    getJson,
    getRequest,
    postFormData,
    postJson,
    postRequest,
    putJson,
    notify,
    loadingStart,
    loadingEnd
  };
};
