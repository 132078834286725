import { useCallback, useEffect, useRef } from 'react';
import { setLoadRoles, setRolesData, useAppSelector } from '../../../store';
import { useAppStoreHooks } from '../../../toolkit';
import { TitanUser } from '../../../models';
import { textMessages } from '../../../translations';
import { API_URLS } from '../../constants';

const useRolesCache = () => {
  const refreshing = useRef(false);
  const { roles, expireAfterMiliseconds } = useAppSelector(
    (state) => state.cache
  );
  const { dispatch, getJson, notify } = useAppStoreHooks();

  const loadRoles = useCallback(async () => {
    refreshing.current = true;
    try {
      const response = await getJson<{ model: TitanUser[]}>(API_URLS.identity.user.roles);
      dispatch(setRolesData(response.model));
    } catch (e: any) {
      notify(textMessages.genericError, 'warning');
    }
    refreshing.current = false;
  }, [getJson, dispatch, notify]);

  useEffect(() => {
    if (refreshing.current || !expireAfterMiliseconds) {
      return;
    }
    if (!roles.expireTime) {
      dispatch(setLoadRoles());
      loadRoles();
    }
  }, [loadRoles, dispatch, roles.expireTime, expireAfterMiliseconds]);
};

export default useRolesCache;
