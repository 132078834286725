import { useState } from 'react';
import { API_URLS, validations } from '../../lib';
import { TitanDiscountElement } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';

const validate = validations({
  elementId: [['required', 'Camp oligatoriu!']],
  discount: [['required', 'Camp oligatoriu!'], ['number', 'Adauga numar!']]
});

const useDiscounts = (clientId: number, onSaved: () => void, discount?: TitanDiscountElement) => {
  const [discountForm, setDiscountForm] = useState<{
    form: TitanDiscountElement,
    errors: {
      elementId?: string;
      discount?: string;
    };
  }>({
    form: discount || {} as TitanDiscountElement,
    errors: {}
  });

  const { putJson, postJson, deleteRequest, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setDiscountForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value
      }
    }));
  };

  const onElementChange = (elementId: number) => {
    setDiscountForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        elementId
      }
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(discountForm.form);
    setDiscountForm(prevState => ({
      ...prevState,
      errors
    }));
    if (Object.keys(errors).length) {
      return;
    }
    loadingStart();
    try {
      if (discountForm.form.titanClientId) {
        await putJson(API_URLS.titan.elements.discount.update, { ...discountForm.form, discount: +discountForm.form.discount });
        notify('Discount-ul a fost modificat.', 'success');
      } else {
        await postJson<TitanDiscountElement>(API_URLS.titan.elements.discount.create, {
          titanClientId: clientId,
          discount: +discountForm.form.discount,
          elementId: discountForm.form.elementId
        });
        notify('Discount-ul a fost adaugat.', 'success');
      }
      onSaved();
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onDelete = async (elementDiscountId: number) => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.elements.discount.delete}/${elementDiscountId}`);
      loadingEnd();
      onSaved();
      notify('Discount-ul a fost sters.', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
    return false;
  };

  return {
    discountForm,
    onChange,
    onSubmit,
    onElementChange,
    onDelete
  };
};

export default useDiscounts;