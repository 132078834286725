import { FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URLS } from "../../lib";
import { TitanUser } from "../../models";
import { AppTitle } from "../../layout";
import {
  AnimatedAppIcon,
  Card,
  Typography,
  Button,
  DentOpsIcon,
  useAppStoreHooks,
} from "../../toolkit";
import {
  SignUpPageTranslation,
  textMessages,
  textPage,
} from "../../translations";
import { updateUser, useAppSelector } from "../../store";
import styles from "./styles.module.css";

const texts = textPage("signUp") as SignUpPageTranslation;

const ActivateAccountPage: FC = () => {
  const params = useParams<{ token: string; }>();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { dispatch, notify, postJson, loadingStart, loadingEnd } = useAppStoreHooks();

  const activateAccount = async () => {
    loadingStart();
    try {
      const user = await postJson<{ model: TitanUser; }>(API_URLS.identity.user.activateUser, {
        key: params.token,
      });
      loadingEnd();
      notify("Contul a fost activat!", "success");
      dispatch(updateUser(user.model));
      navigate("/confirm-email/success");
    } catch (e: any) {
      loadingEnd();
      notify(
        textMessages.accountVerificationFailed,
        "error",
        textMessages.verifyEmail
      );
    }
  };

  return (
    <AppTitle title={texts.activateAccount}>
      <Card className={styles.form}>
        <AnimatedAppIcon>
          <DentOpsIcon />
        </AnimatedAppIcon>
        <Typography type="h2" variation="center">
          {user ? `${texts.hello} ${user.firstName}!` : texts.activateAccount}
        </Typography>
        <Typography type="p" variation="center" variation1="secondary">
          {texts.subTitleActivate1}
        </Typography>
        <Button
          type="button"
          size="medium"
          variant="contained"
          className={styles.button}
          onClick={activateAccount}
        >
          {texts.activateAccountBtn}
        </Button>
      </Card>
    </AppTitle>
  );
};

export default ActivateAccountPage;
