import { FC, useRef, useState } from "react";
import styles from "./styles.module.css";
import {
  markAllNotification,
  useAppDispatch,
  useAppSelector,
} from "../../../store";
import {
  BellIcon,
  Button,
  IconButton,
  Portal,
  QuestionIcon,
  Typography,
} from "../../../toolkit";
import { Avatar, Notification } from "../../../components";

const Header: FC = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const notifications = useAppSelector((state) => state.notifications.value);
  const unread = notifications.filter((n) => n.read === false && (!n.verison || n.verison === 'v2'));

  return (
    <nav className={styles.header}>
      <Button type="button" size="medium" variant="text" onClick={() => window.open("https://dentopsstorage.blob.core.windows.net/tutorial/Tutorial%20DentopsV2.pdf", "_blank")}>
        <QuestionIcon /> Ghid
      </Button>
      <span className={styles.delimiter}></span>
      <div className={styles.notification}>
        <IconButton
          variant="outlined"
          ref={ref}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <BellIcon />
        </IconButton>
        {unread.length > 0 && <span className={styles.bubble}></span>}
        <Portal
          isOpen={isOpen}
          anchorEl={ref.current}
          onDismiss={() => {
            setIsOpen(false);
            dispatch(markAllNotification());
          }}
          title="Notificari"
          width={375}
        >
          {notifications.length === 0 && (
            <Typography type="p">Nu exista notificari!</Typography>
          )}
          {notifications.map((m) => (
            <Notification key={m.id} {...m} />
          ))}
        </Portal>
      </div>
      {user && <Avatar text={user.firstName[0] + user.lastName[0]} />}
    </nav>
  );
};

export default Header;
