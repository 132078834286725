import { useState } from "react";
import { API_URLS, validations } from "../../lib";
import { TitanPayment } from "../../models";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";

const validate = validations({
  amount: [
    ["required", "Camp oligatoriu!"],
    ["number", "Suma trebuie sa fie un numar!"],
  ],
});

const usePayments = (
  clientId: number,
  onSaved: () => void,
  payment?: TitanPayment
) => {
  const [paymentForm, setPaymentForm] = useState<{
    form: TitanPayment;
    errors: {
      amount?: string;
    };
  }>({
    form:
      payment ||
      ({ createdDateTime: new Date().toISOString() } as TitanPayment),
    errors: {},
  });

  const { postJson, deleteRequest, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setPaymentForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onDateChange = (date: Date) => {
    setPaymentForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        createdDateTime: date.toISOString(),
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(paymentForm.form);
    setPaymentForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (Object.keys(errors).length) {
      return;
    }
    loadingStart();
    try {
      if (paymentForm.form.titanClientId) {
        await postJson(
          API_URLS.titan.clients.payments.update,
          paymentForm.form
        );
        notify("Plata a fost modificata.", "success");
      } else {
        await postJson<TitanPayment>(API_URLS.titan.clients.payments.create, {
          titanClientId: clientId,
          amount: +paymentForm.form.amount,
        });
        notify("Plata a fost adaugata.", "success");
      }
      onSaved();
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onDelete = async (clientPaymentId: number) => {
    loadingStart();
    try {
      await deleteRequest(
        `${API_URLS.titan.clients.payments.delete}/${clientPaymentId}`
      );
      loadingEnd();
      onSaved();
      notify("Plata a fost stearsa", "success");
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
    return false;
  };

  return {
    paymentForm,
    onChange,
    onSubmit,
    onDateChange,
    onDelete,
  };
};

export default usePayments;
