import { FC } from 'react';
import { TitanUser } from '../../../models';
import { IColumn, TableProps } from '../../../lib';
import { TableList } from '../../../components';
import { UserAddIcon } from '../../../toolkit';

interface IDocument extends TitanUser {
  key: string;
}


type Props = {
  onOpenUserDetails: (statistic: TitanUser) => void
} & TableProps;


const UserList: FC<Props> = (listProps) => {

  const columns: IColumn[] = [
    {
      key: 'name',
      name: 'Nume',
      fieldName: 'name',
      onRender: (item: IDocument) => (
        <span>{item.firstName} {item.lastName}</span>
      )
    },
    {
      key: 'email',
      name: 'Email',
      fieldName: 'email',
    }
  ];

  const items = listProps.results.map((item: TitanUser, index: number) => {
    return {
      key: item.userIdentifier,
      index: index + 1,
      ...item
    };
  });

  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      onRowClick={listProps.onOpenUserDetails}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici un angajat',
        emptyIcon: <UserAddIcon size={40} />,
        emptyTitle: "Nu exista statistici in perioada selectata.",
      }}
    />
  );
};

export default UserList;