import { FC } from "react";
import { TitanClinic } from "../../../models";
import { useClinic } from "../../hooks";
import { Container, Responsive } from "../../../layout";
import { Button, Input } from "../../../toolkit";
import { textForm } from "../../../translations";

type Props = {
  clinic?: TitanClinic;
  onClose: () => void;
  onSaved: () => void;
};

const ClinicForm: FC<Props> = ({ clinic, onClose, onSaved }) => {
  const { clinicForm, onChange, onSubmit } = useClinic(onSaved, clinic);
  return (
    <div>
      <form noValidate onSubmit={onSubmit}>
        <Input
          label={textForm.clinicNameLabel}
          placeholder={textForm.clinicNamePlaceholder}
          name="clinicName"
          onChange={onChange}
          value={clinicForm.form.clinicName}
          required
          errorMessage={clinicForm.errors.clinicName}
        />

        <Responsive colsXs={3} spanFirst>
          <Input
            label={textForm.addressLabel}
            placeholder={textForm.addressPlaceholder}
            name="address"
            onChange={onChange}
            value={clinicForm.form.address}
            required
            errorMessage={clinicForm.errors.address}
          />
          <Input
            label={textForm.cityLabel}
            placeholder={textForm.cityPlaceholder}
            name="city"
            onChange={onChange}
            value={clinicForm.form.city}
            required
            errorMessage={clinicForm.errors.city}
          />
        </Responsive>
        <Input
          label={textForm.emailLabel}
          placeholder={textForm.emailPlaceholder}
          name="email"
          onChange={onChange}
          value={clinicForm.form.email}
          required
          errorMessage={clinicForm.errors.email}
        />

        <Input
          label={textForm.phoneLabel}
          placeholder={textForm.phonePlaceholder}
          name="phone"
          onChange={onChange}
          value={clinicForm.form.phone}
          required
          errorMessage={clinicForm.errors.phone}
        />

        <Container gap={10} horizontalAlign="end">
          <Button
            size="medium"
            variant="outlined"
            type="button"
            onClick={onClose}
          >
            Renunta
          </Button>
          <Button size="medium" variant="contained" type="submit">
            Salveaza
          </Button>
        </Container>
      </form>
    </div>
  );
};

export default ClinicForm;
