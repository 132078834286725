import { API_URLS } from '../../lib';
import { TitanCaseStage } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from "../../translations";

const useStageFinalize = (caseStages: TitanCaseStage[], onCaseStagesChanged: (caseStages: TitanCaseStage[]) => void) => {
  const { postJson, notify, loadingStart, loadingEnd } = useAppStoreHooks();

  const toggleFinalize = async (caseStage: TitanCaseStage) => {
    loadingStart();
    try {
      await postJson(API_URLS.titan.cases.toggleIsCaseStageFinished, {
        caseStageId: caseStage.caseStageId,
        isFinished: !caseStage.isFinished
      });
      loadingEnd();
      const caseStagesCopy = [...caseStages];
      const index = caseStages.findIndex(cs => cs.caseStageId === caseStage.caseStageId)
      caseStagesCopy[index].isFinished = !caseStagesCopy[index].isFinished;
      onCaseStagesChanged(caseStagesCopy);
      notify('Etapa a fost salvata', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  return {
    toggleFinalize
  };
};

export default useStageFinalize;