import { FC } from 'react';
import { TitanDiscountElement } from '../../../models';
import { API_URLS, useInternalPaginatedList } from '../../../lib';
import DiscountList from '../DiscountList/DiscountList';
import { Portal } from '../../../toolkit';
import DiscountCRUD from '../DiscountCRUD/DiscountCRUD';

type Props = {
  clientId: number;
  isOpen: boolean;
  isDelete: boolean;
  discount?: TitanDiscountElement;
  onEdit: (discount: TitanDiscountElement) => void;
  onDelete: (discount: TitanDiscountElement) => void;
  onAdd: () => void;
  onReset: () => void;
};

const ClientDiscounts: FC<Props> = ({ clientId, isOpen, isDelete, discount, onEdit, onDelete, onAdd, onReset }) => {
  const discountListProps = useInternalPaginatedList(`${API_URLS.titan.elements.discount.list}/${clientId}`);

  return (
    <>
      <DiscountList {...discountListProps} onEdit={onEdit} onDelete={onDelete} onAdd={onAdd} />
      <Portal
        isOpen={isOpen}
        onDismiss={onReset}
        title={isDelete ? 'Sterge discount' : discount ? 'Modifica discount' : "Adauga discount"}
        overlay={true}
        width={560}
      >
        <DiscountCRUD
          clientId={clientId}
          isDelete={isDelete}
          discount={discount}
          onClose={onReset}
          onRefresh={() => {
            discountListProps.reload();
            onReset();
          }} />
      </Portal>
    </>
  );
};

export default ClientDiscounts;